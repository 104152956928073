import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { CycleManagerService } from './cycle-manager.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import {
  ObjSheetPopupComponent,
  REFERRAL_ACTION,
  REFERRAL,
  VIEW_MODE
} from 'app/company-report/objectives-individual/objective/obj-sheet-popup/obj-sheet-popup.component';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { CloneCycleDialogComponent } from 'app/shared/clone-cycle-dialog/clone-cycle-dialog.component';
import { environment } from '../../../../environments/environment';
import { CyclesUtilitiesService } from 'app/shared/services/cycles-utilities.service';
import { __core_private_testing_placeholder__ } from '@angular/core/testing';
import { CompanyReportService } from 'app/company-report/company-report.service';

@Component({
  selector: 'app-cycle-manager',
  templateUrl: './cycle-manager.component.html',
  styleUrls: ['./cycle-manager.component.scss', '../../../common_styles.scss']
})
export class CycleManagerComponent implements OnInit {
  @Input() cycle: any;
  @Input() manager: string;
  @Input() currentView: string;
  @Input() forcedCurrentState: string;
  @Input() currentSearch: string;
  @Input() cSearch: string;
  @Input() currentFilter: string;
  @Input() detailsRow: string;
  @Input() isGlobal: boolean;
  @Output() reload = new EventEmitter();

  userInfo: any;
  availableLanguages: any;
  selectedLang: string;
  currentState: string;
  activePhase: any;
  originalCycle: any;

  iamManager: boolean;
  showAdvancedOptions: boolean;
  isLocalAdmin: boolean = false;
  isCompanyAdmin: boolean = false;
  isSuperAdmin: boolean = false;

  constructor(
    private srv: CycleManagerService,
    private coreService: CoreService,
    private ref: ChangeDetectorRef,
    private modalService: BsModalService,
    public cyclesUtilitiesService: CyclesUtilitiesService,
    private companyReportService: CompanyReportService
  ) {
    this.showAdvancedOptions = environment.ShowCycleAdvanced;
  }

  ngOnInit() {
    this.userInfo = this.coreService.getUserInfo();
    this.isSuperAdmin = this.userInfo.roles.indexOf('SuperAdmin') >= 0;
    this.isCompanyAdmin = this.userInfo.roles.indexOf('CompanyAdmin') >= 0;
    this.isLocalAdmin = this.userInfo.roles.indexOf('LocalAdmin') >= 0;

    this.iamManager = this.userInfo.isManager || this.userInfo.isEvaluator;
    this.currentView =
      this.currentView !== 'about' && this.currentView !== 'manage'
        ? 'manage'
        : this.currentView;
    this.srv
      .getCompanyLanguages(this.cycle.CompanyId)
      .subscribe((result: any) => {
        this.availableLanguages = result;
        this.selectedLang = this.userInfo.language; //this.availableLanguages[0].Code;
      });
    this.currentState = this.forcedCurrentState
      ? this.forcedCurrentState
      : this.cycle.CycleInfo.Status;
    if (this.currentState === 'Evaluation') {
      this.handleEvaluation();
    }
    this.activePhase = this.getActivePhases();
  }

  openclose(isEditing) {
    this.companyReportService
      .setCycleEditing(this.cycle.Id, isEditing)
      .subscribe((r) => {
        this.cycle.IsEditing = isEditing;
        this.emitReloadEvent();
      });
  }

  handleEvaluation() {
    this.originalCycle = JSON.parse(JSON.stringify(this.cycle));
    this.cycle.Details.EmploeesList.map((eachEmployee) => {
      eachEmployee.Objectives.List = eachEmployee.Objectives.List.filter(
        (eachObjective) => {
          return eachObjective.EvaluationStatus !== 'NotApproved';
        }
      );
      if (eachEmployee.Objectives.EvaluationStatusSummary) {
        const notApproved =
          eachEmployee.Objectives.EvaluationStatusSummary.filter(
            (eachStatus) => {
              return eachStatus.Status === 'NotApproved';
            }
          );
        if (notApproved.length) {
          eachEmployee.Objectives.Number.Total =
            eachEmployee.Objectives.Number.Total - notApproved[0].Value;
        }
        eachEmployee.Objectives.Number.Value =
          eachEmployee.Objectives.Number.Total;
        eachEmployee.Objectives.EvaluationStatusSummary =
          eachEmployee.Objectives.EvaluationStatusSummary.filter(
            (eachStatus) => {
              return eachStatus.Status !== 'NotApproved';
            }
          );
      }
    });
    this.cycle = JSON.parse(JSON.stringify(this.cycle));
  }

  handleManage() {
    this.currentView = 'manage';
    if (this.currentState === 'Unknown') {
      if (
        this.cyclesUtilitiesService.showExecutionPhase(
          this.cycle,
          this.isCompanyAdmin ? 'ADMIN' : 'MANAGER'
        )
      ) {
        this.currentState = 'Progress';
      } else {
        this.currentState = 'Definition';
      }
    }
  }

  handleStatusChange() {
    if (this.originalCycle) {
      this.cycle = JSON.parse(JSON.stringify(this.originalCycle));
      this.originalCycle = null;
    }
    if (
      this.cycle.Details.ActiveFilter &&
      this.cycle.Details.ActiveFilter.Status
    ) {
      this.cycle.Details.ActiveFilter.Status = 'None';
      this.cycle = JSON.parse(JSON.stringify(this.cycle));
      this.emitReloadEvent();
    }
    if (this.currentState === 'Evaluation') {
      this.handleEvaluation();
    }
    this.ref.detectChanges();
  }

  getMidNight(d) {
    let endDate = new Date(d);
    endDate = new Date(
      endDate.getFullYear(),
      endDate.getMonth(),
      endDate.getDate(),
      23,
      59
    );
    return endDate.getTime();
  }

  canEdit() {
    if (this.isSuperAdmin || this.isCompanyAdmin) {
      if (window.location.href.indexOf('CompanyAdmin') !== -1) {
        return true;
      } else {
        return false;
      }
    }
  }

  getIcon(periodName: string) {
    const now = new Date();
    const todayTime = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    const startPeriodTime = new Date(
      this.cycle.CycleInfo[periodName].Start
    ).getTime();
    const endPeriodTime = this.getMidNight(
      this.cycle.CycleInfo[periodName].End
    );

    // Fase non ancora iniziata
    if (startPeriodTime > todayTime) {
      return '';
    }

    // Fase in corso
    else if (todayTime >= startPeriodTime && todayTime <= endPeriodTime) {
      return 'fa-clock-o';
    }

    // Fase terminata
    else if (todayTime > endPeriodTime) {
      return 'fa-hourglass-end';
    }
  }

  getActivePhases() {
    let txt = '';
    if (this.cycle.CycleInfo.ActivePhases.length == 0) {
      if (this.cycle.CycleInfo.TimeStatus === 'Future') {
        txt = this.coreService.getTranslation('Generic.Future');
      } else if (this.cycle.CycleInfo.TimeStatus === 'Past') {
        txt = this.coreService.getTranslation('Generic.Expired');
      } else {
        txt = this.coreService.getTranslation('Generic.Pause');
      }
    } else {
      txt = this.coreService.getTranslation('CYCLE_MANAGER.CurrentlyIn') + ' ';
      let i = 0;
      for (i = 0; i < this.cycle.CycleInfo.ActivePhases.length - 1; i++) {
        txt += this.getPhaseName(this.cycle.CycleInfo.ActivePhases[i]) + ', ';
      }
      txt += this.getPhaseName(this.cycle.CycleInfo.ActivePhases[i]);
    }

    return txt;
  }

  getPhaseName(ph) {
    let txt = '';
    switch (ph) {
      case 'Definition':
        txt = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.Definition'
        );
        break;
      case 'Progress':
        txt = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.Execution'
        );
        break;
      case 'Evaluation':
        txt = this.coreService.getTranslation(
          'INDIVIDUAL_OBJECTIVES.Evaluation'
        );
        break;
    }

    return txt;
  }

  onReload($event: any) {
    console.log('onReload', $event);
    this.currentSearch = $event.searchInput ? $event.searchInput : '';
    this.currentFilter = $event.advFilters ? $event.advFilters : '';
    this.detailsRow = $event.detailsRow ? $event.detailsRow : '';
    this.emitReloadEvent($event);
  }

  emitReloadEvent($event: any = {}) {
    this.reload.emit({
      state: this.currentState,
      view: this.currentView,
      search: this.currentSearch,
      advFilters: this.currentFilter,
      detailsRow: this.detailsRow,
      cycle: this.cycle
    });
  }

  assignObjective() {
    const obj: any = {
      Author: {
        Id: this.userInfo.linkedEmployeeId,
        Picture: this.userInfo.imageUrl,
        Name: this.userInfo.fullName
      },
      Name: '',
      Description: '',
      Image: undefined,
      Category: undefined,
      DueDate: this.cycle.CycleInfo.Period.End,
      Visibility: this.cycle.WhoCanEdit,
      LimitedViewer: [],
      CheckList: [],
      Comments: [],
      Weight: 0,
      Employees: [],
      ApprovalStatus: 'None',
      SelfEvaluationValue: 0,
      ChallengeValue: -1,
      InclinationValue: -1,
      PostInclinationValue: -1,
      PostChallengeValue: -1,
      Evaluation: 0,
      DefinitionState: 2 //this.cyclesUtilitiesService.afterDefinitionPhase(this.cycle, this.isCompanyAdmin ? 'ADMIN' : 'MANAGER') ? 2 : 0
    };

    let state = 'DEFINITION_STATE';
    switch (this.currentState) {
      case 'Progress':
        state = 'EXECUTION_STATE';
        break;
      case 'Evaluation':
        state = 'EVALUATION_STATE';
        break;
    }

    const content: any = {
      objective: obj,
      state: state,
      cycle: this.cycle,
      ViewMode: this.isCompanyAdmin ? VIEW_MODE.ADMIN : VIEW_MODE.MANAGER,
      referral: REFERRAL.CYCLES,
      referralAction: REFERRAL_ACTION.ASSIGN
    };

    console.log('objective', obj);
    console.log('this.cycle.CycleInfo', this.cycle.CycleInfo);

    const modal = this.modalService.show(ObjSheetPopupComponent, {
      class: 'objective-sheet-popup'
    });
    (<ObjSheetPopupComponent>modal.content).showModal(content);
    (<ObjSheetPopupComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          console.log('result', result);
          this.emitReloadEvent();
        }
      }
    );

    // ----- Handles in sheets objective remotions
    (<ObjSheetPopupComponent>modal.content).assignObjective.subscribe(
      (result: any) => {
        this.companyReportService
          .assignObjective(result.id, result.objective)
          .subscribe((r) => {
            this.emitReloadEvent();
          });
      }
    );
  }

  showEdit() {
    const now = new Date();
    const todayTS = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    const startTS = new Date(this.cycle.Interval.Start).getTime();
    return this.isCompanyAdmin && this.cycle.IsSent && todayTS < startTS;
  }

  showRevoke() {
    const now = new Date();
    const todayTS = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    ).getTime();
    const startTS = new Date(this.cycle.Interval.Start).getTime();
    return this.isCompanyAdmin && this.cycle.IsSent && todayTS < startTS;
  }

  editCycle() {
    this.srv.updateCycle(this.cycle.Id).subscribe((result: any) => {});
  }

  revokeCycle() {
    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmOk'
      ),
      NoCaption: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmCancel'
      ),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmTitle'
      ),
      subtitle: this.coreService.getTranslation(
        'OBJECTIVE_SHEET_POPUP.SuspendConfirmSubTitle'
      )
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.srv.deleteCycle(this.cycle.Id).subscribe((result: any) => {});
        }
      }
    );
  }

  cloneCycle() {
    this.srv.cloneCycle(this.cycle.Id).subscribe((result: any) => {});
  }

  importCycle() {
    const content: any = {};

    const modal = this.modalService.show(CloneCycleDialogComponent, {
      class: 'clone-dialog'
    });
    (<CloneCycleDialogComponent>modal.content).showModal(content);
    (<CloneCycleDialogComponent>modal.content).onClose.subscribe(
      (result: any) => {
        if (result.clone) {
          let selectedCycle = result.selectedCycle;

          /*
        this.srv.importCycle(result.selectedCycle.Id, this.cycle.Id).subscribe(
          (result: any) => {}
        )
        */

          let content: any = {
            YesCaption: this.coreService.getTranslation(
              'OBJECTIVE_SHEET_POPUP.ImportConfirmOk'
            ),
            NoCaption: this.coreService.getTranslation(
              'OBJECTIVE_SHEET_POPUP.ImportConfirmCancel'
            ),
            type: 'YesNo',
            title: this.coreService.getTranslation(
              'OBJECTIVE_SHEET_POPUP.ImportConfirmTitle'
            ),
            subtitle: this.coreService.getTranslation(
              'OBJECTIVE_SHEET_POPUP.ImportConfirmSubTitle'
            )
          };

          content.title = content.title.replace(
            '{{CycleName}}',
            selectedCycle.Name
          );
          content.subtitle = content.subtitle.replace(
            '{{CycleName}}',
            this.cycle.Name
          );

          const modal = this.modalService.show(SimpleDialogComponent, {
            class: 'self-evaluation'
          });
          (<SimpleDialogComponent>modal.content).showModal(content);
          (<SimpleDialogComponent>modal.content).onClose.subscribe(
            (result: boolean) => {
              if (result) {
                this.srv
                  .importCycle(selectedCycle.Id, this.cycle.Id)
                  .subscribe((result: any) => {});
              }
            }
          );
        }
      }
    );
  }

  canAssign() {
    // Se sono MANAGER e sono entro la fase di definizione OPPURE sono ADMIN
    if (
      this.iamManager &&
      this.cyclesUtilitiesService.inDefinitionPhase(this.cycle, 'MANAGER')
    ) {
      return true;
    } else {
      if (!(window.location.href.indexOf('CompanyAdmin') !== -1)) {
        return false;
      }
    }

    if (this.isCompanyAdmin || this.isLocalAdmin) {
      return true;
    }
  }
}
