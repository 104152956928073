import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { CoreService } from 'app/shared/services/core.service';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'wall-filter-dropdown',
  templateUrl: './wall-filter-dropdown.component.html',
  styleUrls: ['./wall-filter-dropdown.component.scss']
})
export class WallFilterDropdownComponent implements OnInit {
  @ViewChild('ngSelect') ngSelect: NgSelectComponent;
  @Input() dataList: any[] = [];
  @Input() closeOnSelect = false;
  @Input() multiple = true;
  @Input() clearSearchOnAdd = false;
  @Input() clearable = true;
  @Input() control: FormControl;
  searchable: boolean;
  isOpen: boolean = false;
  userInfo: any;
  constructor(public coreService: CoreService) {}

  ngOnInit(): void {
    this.userInfo = this.coreService.getUserInfo();
  }

  onOutsideClick(e) {
    if (e && e.target) {
      if (
        (e.target.outerHTML.indexOf('fa fa-search search') === -1 &&
          e.target.outerHTML.indexOf('fa fa-check') === -1 &&
          e.target.outerHTML.indexOf('check-wrapper') === -1) ||
        e.target.outerHTML.indexOf('custom-select') !== -1
      ) {
        this.ngSelect.searchTerm = null;
        this.ngSelect.filter('');
        this.ngSelect.searchable = false;
        this.searchable = false;
        this.ngSelect.blur();
        this.isOpen = false;
      }
    }
  }

  selectSearch() {
    this.searchable = true;
    this.isOpen = true;
    this.ngSelect.searchable = true;
    this.ngSelect.focus();
  }

  deselect(element) {
    const value = this.control.value ? this.control.value : [];
    const arr = value.filter((x) => x.value !== element.value);
    this.control.patchValue(arr);
  }
}
