import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef
} from '@angular/material/bottom-sheet';
import { CoreService } from 'app/shared/services/core.service';
@Component({
  selector: 'bottom-sheet-mobile-dropdown',
  templateUrl: './bottom-sheet-mobile-dropdown.component.html',
  styleUrls: ['./bottom-sheet-mobile-dropdown.component.scss']
})
export class BottomSheetMobileDropdownComponent implements OnInit {
  selected: any;
  @Output() itemSelected = new EventEmitter();

  constructor(
    public coreService: CoreService,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetMobileDropdownComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.selected = this.data.selectedItem;
  }

  onChange(item) {
    if (item?.Id) {
      setTimeout(() => {
        this.data.selectedItem = item;
        this.coreService.setRadioSelect({
          item: this.data.selectedItem ? this.data.selectedItem : [],
          id: this.data.id
        });
        this.data.selectedItem = null;
      }, 10);
    } else if (item?.value) {
      setTimeout(() => {
        this.data.selectedItem = item;
        this.coreService.setRadioSelect({
          item: this.data.selectedItem ? this.data.selectedItem : [],
          id: this.data.id
        });
        this.data.selectedItem = null;
      }, 10);
    } else if (item?.Value) {
      setTimeout(() => {
        this.data.selectedItem = item;
        this.coreService.setRadioSelect({
          item: this.data.selectedItem ? this.data.selectedItem : [],
          id: this.data.id
        });
        this.data.selectedItem = null;
      }, 10);
    }
    this.itemSelected.emit(item);
  }

  onChangeCategory(item) {
    if (item?.Id) {
      setTimeout(() => {
        this.data.selectedItem = item;

        this.coreService.setRadioSelect({
          item: this.data.selectedItem ? this.data.selectedItem : [],
          id: this.data.id
        });
        this.data.selectedItem = null;
      }, 10);
    }
    this.itemSelected.emit(item);
  }

  onChangeVisibility(item) {
    if (item?.Value) {
      setTimeout(() => {
        this.data.selectedItem = item;
        this.coreService.setRadioSelect({
          item: this.data.selectedItem ? this.data.selectedItem : [],
          id: this.data.id
        });
        this.data.selectedItem = null;
      }, 10);
    }
    this.itemSelected.emit(item);
  }

  closeBottomSheet() {
    this._bottomSheetRef.dismiss();
    this.coreService.setRadioSelect({
      item: this.data.selectedItem,
      id: this.data.id
    });
  }
  selectionChange(e) {
    this.itemSelected.emit(e);
  }
}
