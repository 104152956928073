import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Input
} from '@angular/core';

declare var jQuery: any;
declare var cytoscape: any;

@Component({
  selector: 'ava-cytoscape',
  templateUrl: 'ava-cytoscape.template.html',
  styleUrls: ['ava-cytoscape.template.scss']
})
export class AvaCytoscapeComponent implements OnChanges {
  @Input() public elements: any;
  @Input() public style: any;
  @Input() public layout: any;
  @Input() public zoom: any;
  @Input() public sidebar: boolean = true;
  @Input() public automove: any;
  @Input() public showHighlightableNodes: any;
  @Input() public outputJsonFile: any;
  @Input() public outputImageFile: any;

  @Input() filterNodesFunction: (args: any) => boolean;
  @Input() filterEdgesFunction: (args: any) => boolean;
  @Input() doDetailFunction: (args: any) => boolean;
  @Input() filterHighLightableNodes: (args: any) => string;
  @Input() getNodeInfoHTML: (node: any) => string;
  @Input() getEdgeInfoHTML: (edge: any) => string;

  private personalIconsStates: any;
  private cy: any;
  private nodeNumber: number;
  private edgeNumber: number;
  private popperRef: any;
  private edgePopperRef: any;

  layoutPadding = 10;
  animationDuration = 500;
  easing = 'ease';

  // search parameters
  minMetricValue = 0.25; // filter out nodes from search results if they have total scores lower than this
  minSimilarityValue = 0; // only include in total metric if the individual sim val is on [0.5, 1]
  highlightInProgress = false;
  lastHighlighted: any = null;
  lastUnhighlighted: any = null;
  delayPromise = (duration) =>
    new Promise((resolve) => setTimeout(resolve, duration));

  getOrgPos = function (n) {
    let p = n.originalPosition; //this.nodePoitionMap[n.id()]
    return p;
  };

  layoutOptions: any;

  edgeHandlerDefaults = {
    canConnect: function (sourceNode, targetNode) {
      // whether an edge can be created between source and target
      return !sourceNode.same(targetNode); // e.g. disallow loops
    },
    edgeParams: function (sourceNode, targetNode) {
      // for edges between the specified source and target
      // return element object to be passed to cy.add() for edge
      return {};
    },
    hoverDelay: 150, // time spent hovering over a target node before it is considered selected
    snap: true, // when enabled, the edge can be drawn by just moving close to a target node (can be confusing on compound graphs)
    snapThreshold: 50, // the target node must be less than or equal to this many pixels away from the cursor/finger
    snapFrequency: 15, // the number of times per second (Hz) that snap checks done (lower is less expensive)
    noEdgeEventsInDraw: true, // set events:no to edges during draws, prevents mouseouts on compounds
    disableBrowserGestures: true // during an edge drawing gesture, disable browser gestures such as two-finger trackpad swipe and pinch-to-zoom
  };

  panZoomDefaults = {
    zoomFactor: 0.05, // zoom factor per zoom tick
    zoomDelay: 45, // how many ms between zoom ticks
    minZoom: 0.1, // min zoom level
    maxZoom: 10, // max zoom level
    fitPadding: 50, // padding when fitting
    panSpeed: 10, // how many ms in between pan ticks
    panDistance: 10, // max pan distance per tick
    panDragAreaSize: 75, // the length of the pan drag box in which the vector for panning is calculated (bigger = finer control of pan speed and direction)
    panMinPercentSpeed: 0.25, // the slowest speed we can pan by (as a percent of panSpeed)
    panInactiveArea: 8, // radius of inactive area in pan drag box
    panIndicatorMinOpacity: 0.5, // min opacity of pan indicator (the draggable nib); scales from this to 1.0
    zoomOnly: false, // a minimal version of the ui only with zooming (useful on systems with bad mousewheel resolution)
    fitSelector: undefined, // selector of elements to fit
    animateOnFit: function () {
      // whether to animate on fit
      return false;
    },
    fitAnimationDuration: 1000, // duration of animation on fit

    // icon class names
    sliderHandleIcon: 'fa fa-minus',
    zoomInIcon: 'fa fa-plus',
    zoomOutIcon: 'fa fa-minus',
    resetIcon: 'fa fa-expand'
  };

  public constructor(private el: ElementRef) {
    this.layout = this.layout || {
      name: 'grid',
      directed: true,
      padding: 0
    };

    this.zoom = this.zoom || {
      min: 0.05,
      max: 2
    };

    this.personalIconsStates = {
      showTags: false,
      labelSize: 10
    };

    this.style =
      this.style ||
      cytoscape
        .stylesheet()
        .selector('node')
        .css({
          content: 'data(name)',
          shape: 'rectangle',
          'text-valign': 'center',
          'background-color': 'data(faveColor)',
          width: '200px',
          height: '100px',
          color: 'black'
        })
        .selector(':selected')
        .css({
          'border-width': 3,
          'border-color': '#333'
        })
        .selector('edge')
        .css({
          label: 'data(label)',
          color: 'black',
          'curve-style': 'bezier',
          opacity: 0.666,
          width: 'mapData(strength, 70, 100, 2, 6)',
          'target-arrow-shape': 'triangle',
          'line-color': 'data(faveColor)',
          'source-arrow-color': 'data(faveColor)',
          'target-arrow-color': 'data(faveColor)'
        })
        .selector('edge.questionable')
        .css({
          'line-style': 'dotted',
          'target-arrow-shape': 'diamond'
        })
        .selector('.faded')
        .css({
          opacity: 0.25,
          'text-opacity': 0
        });
  }

  ngOnInit() {
    let global = this;

    this.layoutOptions = {
      container: document.getElementById('cy'),
      layout: this.layout,
      minZoom: this.zoom.min,
      maxZoom: this.zoom.max,
      style: this.style,
      elements: this.elements,

      ready: function () {
        this.cy = this;
        //Enable zoom with mouse wheel
        this.cy.userZoomingEnabled(true);
        //View zoom panel on left
        this.cy.panzoom(global.panZoomDefaults);

        //TODO => NON FUNZIONA !!!   this.cy.edgehandles( global.edgeHandlerDefaults );

        //Store original nodes positions
        global.storeOriginalPositions();

        global.setupZoomOnTaping();

        global.setupTooltip();

        global.nodeNumber = this.cy.nodes().length;
        global.edgeNumber = this.cy.edges().length;
      }
    };
  }

  setupZoomOnTaping() {
    //Zoom taping on nodes
    this.cy.on('tap', (e) => {
      if (e.target === this.cy) {
        // this.unhighlight();
        this.showAll();
      } else {
        // this.highlight(e.target);
        // this.showBranch(e.target);
      }
    });
  }

  storeOriginalPositions() {
    var nodes = this.cy.nodes();
    nodes.forEach((n) => {
      let p = n.position();
      n.originalPosition = JSON.parse(JSON.stringify(p));
    });
  }

  setupTooltip() {
    //Popup tooltip on nodes
    let global = this;
    this.cy.edges().on('mouseover', (event) => {
      var edge = event.target;
      var list = document.getElementsByClassName('edgeTooltip');
      while (list.length > 0) list[0].remove();

      this.edgePopperRef = edge.popper({
        content: () => {
          let html = global.getEdgeInfoHTML(edge);
          if (html) {
            let div: any = document.createElement('div');

            div.id = edge.data('id');
            div.classList.add('edgeTooltip');
            div.innerHTML = global.getEdgeInfoHTML(edge);
            document.body.appendChild(div);

            return div;
          }
        },
        popper: {
          placement: 'right',
          removeOnDestroy: true
        }
      });

      global.edgePopperRef = this.edgePopperRef;
      let update = () => {
        this.edgePopperRef.update();
      };

      edge.on('position', update);

      global.cy.on('pan zoom resize', update);
    });

    this.cy.nodes().on('mouseover', (event) => {
      event.target.addClass('hovered');
      if (event.cy.container()) {
        event.cy.container().style.cursor = 'pointer';
      }
      var node = event.target;
      var list = document.getElementsByClassName('nodeToolTip');
      while (list.length > 0) list[0].remove();

      this.popperRef = node.popper({
        content: () => {
          let div: any = document.createElement('div');

          div.id = node.data('id');
          div.classList.add('nodeToolTip');
          div.innerHTML = global.getNodeInfoHTML(node);
          document.body.appendChild(div);

          return div;
        },
        popper: {
          placement: 'right',
          removeOnDestroy: true
        }
      });

      global.popperRef = this.popperRef;
      let update = () => {
        this.popperRef.update();
      };

      node.on('position', update);

      global.cy.on('pan zoom resize', update);
    });
    this.cy.edges().on('mouseout', () => {
      var list = document.getElementsByClassName('edgeTooltip');
      while (list.length > 0) list[0].remove();
      if (this.edgePopperRef) {
        this.edgePopperRef.destroy();
      }
    });

    this.cy.nodes().on('mouseout', (event) => {
      setTimeout(() => {
        const element = document.getElementsByClassName('nodeToolTip');
        if (element && element?.length && element[0].matches(':hover')) {
          //Mouse is inside element
        } else {
          event.target.removeClass('hovered');
          if (event.cy.container()) {
            event.cy.container().style.cursor = 'default';
          }
          var list = document.getElementsByClassName('nodeToolTip');
          while (list.length > 0) list[0].remove();
          if (this.popperRef) {
            this.popperRef.destroy();
          }
        }
      }, 2000);
    });

    this.cy.nodes().on('cxttap ', () => {
      var list = document.getElementsByClassName('nodeToolTip');
      while (list.length > 0) list[0].remove();
      if (this.popperRef) {
        this.popperRef.destroy();
      }
    });
  }

  public removeAllToolTips() {
    var list = document.getElementsByClassName('nodeToolTip');
    while (list.length > 0) list[0].remove();
    if (this.popperRef) {
      this.popperRef.destroy();
    }

    var listEdge = document.getElementsByClassName('edgeTooltip');
    while (listEdge.length > 0) listEdge[0].remove();
    if (this.edgePopperRef) {
      this.edgePopperRef.destroy();
    }
  }

  public sharpenNode(filter) {
    const allNodes = this.cy.nodes();
    allNodes.removeClass('sharpen');
    if (filter.Name && filter.Name.length > 2) {
      const selectedNodes = this.cy.nodes().filter(function (ele) {
        const nodeName = ele.data('name').toLowerCase();
        const filterName = filter.Name.toLowerCase();
        return nodeName.indexOf(filterName) >= 0;
      });

      selectedNodes.addClass('sharpen');
    }
  }

  //Applies filters invoking external filter delegates
  public applyFilters() {
    this.cy.startBatch();
    var allNodes = this.cy.nodes();
    this.cy.remove(allNodes);
    var allEdges = this.cy.edges();
    this.cy.remove(allEdges);

    var edgesToAdd = this.elements.edges.filter(this.filterEdgesFunction);
    var nodesToAdd = this.elements.nodes.filter(this.filterNodesFunction);

    this.cy.add(nodesToAdd);
    this.cy.add(edgesToAdd);
    var layout = this.cy.makeLayout(this.layoutOptions.layout);
    layout.run();

    this.nodeNumber = this.cy.nodes().length;
    this.edgeNumber = this.cy.edges().length;

    this.setupTooltip();
    this.setupZoomOnTaping();

    this.setHighlightableNodes();
    this.cy.endBatch();
  }

  //-----------------------------------------------------------------------------
  download(filename, text) {
    let element = document.createElement('a');
    element.setAttribute('href', text);
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  downloadJson(myJson) {
    var sJson = JSON.stringify(myJson);
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/json;charset=UTF-8,' + encodeURIComponent(sJson)
    );
    let filename = this.outputJsonFile ?? 'Objectives.json';
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); // simulate click
    document.body.removeChild(element);
  }

  //--------------------------------------------------------------------------------------------
  onClick(key) {
    var currentZoom = this.cy.zoom();
    var state = this.personalIconsStates;
    switch (key) {
      case 'Refresh':
        var layout = this.cy.makeLayout(this.layoutOptions.layout);
        layout.run();

        break;
      case 'ZoomIn':
        if (currentZoom < 2) this.cy.zoom(currentZoom + 0.2);
        break;
      case 'ZoomOut':
        if (currentZoom > 0.25) this.cy.zoom(currentZoom - 0.2);
        break;
      case 'ShowTags':
        this.cy.nodes().forEach(function (nd) {
          if (state.showTags) {
            nd.css({ 'font-size': 0 });
          } else {
            nd.css({ 'font-size': state.labelSize });
          }
        });
        this.personalIconsStates.showTags = !this.personalIconsStates.showTags;
        break;
      case 'LabelBig':
        this.cy.nodes().forEach(function (nd) {
          nd.css({ 'font-size': 15 });
        });
        this.personalIconsStates.labelSize = 15;
        break;
      case 'LabelSmall':
        this.cy.nodes().forEach(function (nd) {
          nd.css({ 'font-size': 10 });
        });
        this.personalIconsStates.labelSize = 10;
        break;
      case 'ExportPic':
        let options = { bg: '#102a3b' };
        let png64 = this.cy.png(options);
        // put the png data in an img tag
        let fName = this.outputImageFile ?? 'Objectives.png';
        this.download(fName, png64);
        break;
      case 'ExportNet':
        var json = this.cy.json();

        this.downloadJson(json);

        break;
    }
  }

  //--------------------------------------------------------------------------------------------
  public ngOnChanges(changes: SimpleChanges): any {
    this.render();
  }

  //--------------------------------------------------------------------------------------------
  public render() {
    let self = this;
    if (this.layoutOptions) {
      this.layoutOptions.elements = this.elements;
      this.layoutOptions.layout = this.layout;

      if (this.layoutOptions.container) {
        this.cy = cytoscape(this.layoutOptions);

        // this.cy.cxtmenu({
        //   selector: 'node',
        //   atMouse: true,
        //   commands: [
        //     {
        //       content: '<span class="fa fa-flash fa-2x"></span>',
        //       select: function (ele) {
        //         self.doDetailFunction(ele.id());
        //       },
        //     },

        //     // {
        //     //   content: '<span class="fa fa-star fa-2x"></span>',
        //     //   select: function(ele){
        //     //     alert( ele.data('name') );
        //     //   },
        //     //   enabled: false
        //     // },

        //     // {
        //     //   content: 'Text',
        //     //   select: function(ele){
        //     //    alert( ele.position() );
        //     //   }
        //     // }
        //   ],
        // });
        this.cy.on('click', 'node', function (event) {
          const node = event.target;
          self.doDetailFunction(node.id());
        });
        this.setupZoomOnTaping();
        this.setupTooltip();

        this.setHighlightableNodes();

        // if(global.automove) {
        //   this.cy.automove({
        //     nodesMatching: function(n){
        //       return n.data('type')===global.automove.nodesMatching.type;
        //     } cy.$('#mid').neighbourhood().nodes(),
        //     reposition: global.automove.reposition,
        //     dragWith: cy.$('#mid')
        //   });
        // }
      }
    }
  }
  setHighlightableNodes() {
    if (this.showHighlightableNodes) {
      const allNodes = this.cy.nodes();
      allNodes.removeClass('highligtable');
      const selectedNodes = this.cy
        .nodes()
        .filter(this.filterHighLightableNodes);
      selectedNodes.addClass('highligtable');
    }
  }
  //--------------------------------------------------------------------------------------------
  hasHighlight() {
    return this.lastHighlighted != null;
  }

  //--------------------------------------------------------------------------------------------
  highlight(node) {
    let global = this;
    if (this.highlightInProgress) {
      return Promise.resolve();
    }

    this.highlightInProgress = true;

    const allEles = this.cy.elements();
    const nhood = (this.lastHighlighted = node.closedNeighborhood());
    const others = (this.lastUnhighlighted = allEles.not(nhood));

    const showOverview = () => {
      global.cy.batch(() => {
        allEles.removeClass('faded highlighted hidden');

        nhood.addClass('highlighted');
        others.addClass('hidden');

        others.positions(this.getOrgPos);
      });

      const layout = nhood.layout({
        name: 'preset',
        positions: this.getOrgPos,
        fit: true,
        animate: true,
        animationDuration: this.animationDuration,
        animationEasing: this.easing,
        padding: this.layoutPadding
      });

      layout.run();

      return layout.promiseOn('layoutstop');
    };

    const runLayout = () => {
      const p = global.getOrgPos(node);

      const layout = nhood.layout({
        name: 'concentric',
        fit: true,
        animate: true,
        animationDuration: this.animationDuration,
        animationEasing: this.easing,
        boundingBox: {
          x1: p.x - 1,
          x2: p.x + 1,
          y1: p.y - 1,
          y2: p.y + 1
        },
        avoidOverlap: true,
        concentric: function (ele) {
          if (ele.same(node)) {
            return 2;
          } else {
            return 1;
          }
        },
        levelWidth: () => {
          return 1;
        },
        padding: global.layoutPadding
      });

      const promise = layout.promiseOn('layoutstop');

      layout.run();

      return promise;
    };

    const showOthersFaded = () => {
      global.cy.batch(() => {
        others.removeClass('hidden').addClass('faded');
      });
    };

    return Promise.resolve()
      .then(showOverview)
      .then(() => this.delayPromise(this.animationDuration))
      .then(runLayout)
      .then(showOthersFaded)
      .then(() => {
        this.highlightInProgress = false;
      });
  }
  //--------------------------------------------------------------------------------------------
  unhighlight() {
    if (!this.hasHighlight()) {
      return Promise.resolve();
    }

    let global = this;

    const allEles = this.cy.elements();
    const allNodes = this.cy.nodes();

    this.cy.stop();
    allNodes.stop();

    const nhood = this.lastHighlighted;
    const others = this.lastUnhighlighted;

    this.lastHighlighted = this.lastUnhighlighted = null;

    const hideOthers = function () {
      others.addClass('hidden');

      return Promise.resolve();
    };

    const resetClasses = function () {
      global.cy.batch(function () {
        allEles
          .removeClass('hidden')
          .removeClass('faded')
          .removeClass('highlighted');
      });
      global.cy.reset();
      return Promise.resolve();
    };

    const animateToOrgPos = function (nhood) {
      return Promise.all(
        nhood.nodes().map((n) => {
          return n
            .animation({
              position: global.getOrgPos(n),
              duration: global.animationDuration,
              easing: global.easing
            })
            .play()
            .promise();
        })
      );
    };

    const restorePositions = () => {
      global.cy.batch(() => {
        others.nodes().positions(global.getOrgPos);
      });

      return animateToOrgPos(nhood.nodes());
    };

    return Promise.resolve()
      .then(hideOthers)
      .then(restorePositions)
      .then(resetClasses)
      .then(() => {
        global.layoutOptions.layout = undefined;
        global.layoutOptions.layout = global.layout;
        global.cy.center();
      });
  }

  //--------------------------------------------------------------------------------------------
  showBranch(node) {
    let global = this;
    if (this.highlightInProgress) {
      return Promise.resolve();
    }

    this.highlightInProgress = true;
    const allEles = this.cy.elements();
    const nsub = node.successors();
    const npred = node.predecessors();
    const others = allEles.not(nsub).not(npred).not(node);

    const showOverview = () => {
      global.cy.batch(() => {
        allEles.removeClass('faded highlighted');
        node.addClass('highlighted');
        nsub.addClass('highlighted');
        npred.addClass('highlighted');
        // global.doDetailFunction(node.id());
      });
    };

    const showOthersFaded = () => {
      global.cy.batch(() => {
        others.addClass('faded');
      });
    };
    return Promise.resolve()
      .then(showOverview)
      .then(showOthersFaded)
      .then(() => {
        this.highlightInProgress = false;
      });
  }

  showAll() {
    let global = this;
    const allEles = this.cy.elements();

    const showAll = () => {
      global.cy.batch(() => {
        allEles.removeClass('faded highlighted');
      });
    };
    return Promise.resolve().then(showAll);
  }
}
