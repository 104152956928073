import { Injectable } from '@angular/core';
import { RestfulService } from 'app/shared/services/restful.service';
import { CoreService } from 'app/shared/services/core.service';

@Injectable()
export class CycleManagerService {
  public allcompanyemployees = [];
  constructor(private restfulWebService: RestfulService) {}

  getAndSetAllEmployees(ID) {
    this.restfulWebService.get(`CompanyExt/EmployeesExt?id=${ID}`).subscribe(
      (d: any) => {
        this.allcompanyemployees = d || [];
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getCompanyLanguages(ID) {
    return this.restfulWebService.get(
      `language/CompanyLanguages?companyId=${ID}`
    );
  }

  getCompanyObjCategories(id) {
    return this.restfulWebService.get(
      `CompanyExt/ObjectiveCategories?companyId=${id}`
    );
  }

  saveCompanyObjCategory(cat) {
    return this.restfulWebService.post(`CompanyExt/SaveObjectiveCategory`, cat);
  }

  updateCompanyObjCategory(cat) {
    return this.restfulWebService.put(
      `CompanyExt/UpdateObjectiveCategory`,
      cat
    );
  }
  deleteCompanyObjCategory(cat) {
    return this.restfulWebService.delete(
      `CompanyExt/DeleteCompanyObjectiveCategory?id=${cat.Id}`
    );
  }

  getObjectiveById(objectiveId: string, manager: string) {
    return this.restfulWebService.get(
      `Cycles/SingleObjective/?id=${objectiveId}&mode=${manager}`
    );
  }

  nudge(empId, managerId, toUser, cycleId, objectiveId) {
    if (objectiveId) {
      return this.restfulWebService.get(
        `Cycles/Nudge/?userId=${empId}&managerUserId=${managerId}&toUser=${toUser}&cycleId=${cycleId}&objectiveId=${objectiveId}`
      );
    } else {
      return this.restfulWebService.get(
        `Cycles/Nudge/?userId=${empId}&managerUserId=${managerId}&toUser=${toUser}&cycleId=${cycleId}`
      );
    }
  }

  getGlobalScales() {
    return this.restfulWebService.get(`CompanyExt/GlobalScales`);
  }

  getCompanyScales(companyId) {
    return this.restfulWebService.get(
      `CompanyExt/Scales?companyId=${companyId}`
    );
  }

  saveCompanyScale(scale) {
    return this.restfulWebService.post(`CompanyExt/SaveScale`, scale);
  }

  getCompanyCycles(isGlobal: boolean, manager: string = '') {
    let apiURL = `Cycles/List${manager}`;
    if (apiURL === 'Cycles/List' && !isGlobal) {
      apiURL = `Cycles/List?asManager=false&isGlobal=false`;
    }
    return this.restfulWebService.get(apiURL);
  }

  getSingleCycle(
    id: string,
    isGlobal: boolean,
    manager: string = '',
    body: any = {}
  ) {
    let apiUrl = `Cycles/Single${manager}?id=${id}&isGlobal=${isGlobal}`;

    return this.restfulWebService.post(apiUrl, body);
  }

  getSystemLanguages() {
    return this.restfulWebService.get(`language`);
  }

  getAllExchangers() {
    return this.restfulWebService.get(`TokenExchanger`);
  }

  getCompanyExchangers(companyId) {
    return this.restfulWebService.get(`CompanyExt/Exchangers/${companyId}`);
  }

  saveCompanyDetails(company) {
    return this.restfulWebService.put(`CompanyExt/SavePartial`, company);
  }

  getLocalLanguages() {
    let Translations = [];
    let lan = localStorage.getItem('languages');
    let tempLanArr = lan ? JSON.parse(lan) : [];
    if (tempLanArr && tempLanArr.length > 0) {
      for (let l of tempLanArr) {
        Translations.push({
          LanguageCode: l.Code,
          Name: '',
          Description: ''
        });
      }
    }
    return Translations;
  }

  getAllEmployees() {
    return this.allcompanyemployees;
  }

  getCompanies(pageStart, pageSize) {
    return this.restfulWebService.get(
      `company?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  addCompany(company) {
    if (!company.Id) return this.restfulWebService.post(`company`, company);
    else return this.restfulWebService.put(`company/${company.Id}`, company);
  }

  deletePlan(ID) {
    return this.restfulWebService.delete(`company/${ID}`);
  }

  getPlans() {
    return this.restfulWebService.get(`plan`);
  }

  getCompanyDetails(ID) {
    return this.restfulWebService.get(`company/full/${ID}`);
  }

  getCompanySkills(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/SkillsExtPaged/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }
  getCompanyBehaviors(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/BehaviorsExtPaged/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyRoles(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/RolesExtPaged/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyTeams(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/TeamsExtPaged/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyGoals(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `companyext/goals/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyProjectTeams(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/ProjectTeamsExtPaged/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyActivities(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `companyext/activities/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyEmployees(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/EmployeesExtPaged${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  addCompanyTeam(team) {
    if (!team.Id) return this.restfulWebService.post(`TeamExt`, team);
    else return this.restfulWebService.put(`TeamExt/${team.Id}`, team);
  }

  addCompanyActivity(activity) {
    if (!activity.Id)
      return this.restfulWebService.post(`ActivityExt`, activity);
    else
      return this.restfulWebService.put(`ActivityExt/${activity.Id}`, activity);
  }

  addCompanyProjectTeam(team) {
    if (!team.Id) return this.restfulWebService.post(`ProjectTeamExt`, team);
    else return this.restfulWebService.put(`ProjectTeamExt/${team.Id}`, team);
  }

  addCompanyGoal(goal) {
    if (!goal.Id) return this.restfulWebService.post(`CompanyGoalExt`, goal);
    else return this.restfulWebService.put(`CompanyGoalExt/${goal.Id}`, goal);
  }

  addCompanySkill(skill, reqBody, isFromLibrary) {
    if (isFromLibrary)
      return this.restfulWebService.post(`skillExt/AddFromLib`, reqBody);
    else {
      if (!skill.Id) return this.restfulWebService.post(`skillExt`, skill);
      else return this.restfulWebService.put(`skillExt/${skill.Id}`, skill);
    }
  }

  addCompanyRole(role, reqBody, isFromLibrary) {
    if (isFromLibrary)
      return this.restfulWebService.post(`roleExt/AddFromLib`, reqBody);
    else {
      if (!role.Id) return this.restfulWebService.post(`roleExt`, role);
      else return this.restfulWebService.put(`roleExt/${role.Id}`, role);
    }
  }

  addCompanyEmployee(employee) {
    if (!employee.Id)
      return this.restfulWebService.post(`EmployeeAdmin`, employee);
    else
      return this.restfulWebService.put(
        `EmployeeAdmin/${employee.Id}`,
        employee
      );
  }
  deleteCompanyTeam(ID) {
    return this.restfulWebService.delete(`team/${ID}`);
  }

  deleteCompanyActivity(ID) {
    return this.restfulWebService.delete(`activity/${ID}`);
  }

  deleteCompanyProjectTeam(ID) {
    return this.restfulWebService.delete(`projectteam/${ID}`);
  }

  deleteCompanyGoal(ID) {
    return this.restfulWebService.delete(`companygoal/${ID}`);
  }

  deleteCompanyEmployee(ID) {
    return this.restfulWebService.delete(`employeeAdmin/${ID}`);
  }

  deleteCompanySkill(ID) {
    return this.restfulWebService.delete(`skillext/${ID}`);
  }

  deleteCompanyRole(ID) {
    return this.restfulWebService.delete(`roleext/${ID}`);
  }

  companyUploadLogo(ID, file) {
    return this.restfulWebService.fileUpload(`company/logo/${ID}`, file);
  }

  companyEmployeeUpload(ID, file) {
    return this.restfulWebService.fileUpload(`upload/employees?id=${ID}`, file);
  }

  companyTeamUploadPicture(ID, file) {
    return this.restfulWebService.fileUpload(`teamExt/picture/${ID}`, file);
  }

  companySkillUploadPicture(ID, file) {
    return this.restfulWebService.fileUpload(`skillExt/picture/${ID}`, file);
  }

  getAllCategories() {
    return this.restfulWebService.get(`SkillCategoryExt`);
  }

  getAllRoleCategories() {
    return this.restfulWebService.get(`RoleCategory`);
  }

  getAllSubcategories(catId) {
    return this.restfulWebService.get(
      `SubCategoryExt/Subcategories?categoryId=${catId}`
    );
  }

  getAllGlobalSkills() {
    return this.restfulWebService.get(`globalskillext`);
  }

  getAllGlobalRoles() {
    return this.restfulWebService.get(`globalroleext`);
  }

  getAllCompanyActivities(ID) {
    return this.restfulWebService.get(`company/activities/${ID}`);
  }

  getAllCompanyTeams(ID) {
    return this.restfulWebService.get(`company/teams/${ID}`);
  }

  getAllCompanyRoles(ID) {
    return this.restfulWebService.get(`company/roles/${ID}`);
  }

  //getAllCompanyEmployees(ID) { return this.restfulWebService.get(`company/employees/${ID}`) }

  getAllCompanyEmployees() {
    return this.getAllEmployees();
  }

  getAllMeasures() {
    return this.restfulWebService.get(`measureext`);
  }

  getGoalMeasures(id) {
    return this.restfulWebService.get(`LTI/Measures?goalId=${id}`);
  }

  getAllCompanyEmployeesWithTeam(companyId, teamId) {
    return this.restfulWebService.get(
      `companyext/employeesforteam?companyid=${companyId}&teamid=${teamId}`
    );
  }

  getLanguageJSON() {
    return this.restfulWebService.getJSON(
      'assets/json/ISO-639-1-language.json'
    );
  }

  invitionMailSelected(reqBody) {
    return this.restfulWebService.post('admin/MassiveSendVerificationMail', {
      Users: reqBody
    });
  }

  resetPasswordSelected(reqBody) {
    return this.restfulWebService.post('admin/MassiveResetPassword', {
      Users: reqBody
    });
  }

  getCategoriesIcons() {
    return this.restfulWebService.get(`Integration/Icons`);
  }

  getGlobalCategories() {
    return this.restfulWebService.get(`ObjectivesCategoryExt`);
  }

  deleteCycle(ID) {
    return this.restfulWebService.delete(`Cycles/Delete?id=${ID}`);
  }

  sendCycle(ID) {
    return this.restfulWebService.get(`Cycles/Send?cycleId=${ID}`);
  }

  scheduleCycle(ID, date) {
    return this.restfulWebService.get(
      `Cycles/Schedule?cycleId=${ID}&when=${date}`
    );
  }

  addCycle(cycle) {
    return this.restfulWebService.post(`Cycles/Save`, cycle);
  }

  updateCycle(cycle) {
    return this.restfulWebService.put(`Cycles/Update`, cycle);
  }

  approveObjective(obj) {
    return this.restfulWebService.get(`Cycles/Approve?objectiveId=${obj.Id}`);
  }

  setObjectiveWeight(obj) {
    return this.restfulWebService.get(
      `Cycles/SetWeight?objectiveId=${obj.Id}&weight=${obj.Weight}`
    );
  }

  bossEvaluate(objective) {
    return this.restfulWebService.get(
      `CycleObjective/BossEvaluate?objectiveId=${objective.Id}&value=${objective.BossValue.Value}`
    );
  }

  cloneCycle(ID) {
    return this.restfulWebService.delete(`Cycles/Clone?id=${ID}`);
  }

  importCycle(from, to) {
    return this.restfulWebService.delete(`Cycles/Import?from=${from}&to=${to}`);
  }

  /* OKR */

  singleInExecution(body, isGlobal) {
    return this.restfulWebService.post(
      `Cycles/SingleInExecution?isGlobal=${isGlobal}`,
      body
    );
  }
  singleInExecutionAsManager(body) {
    return this.restfulWebService.post(
      `Cycles/SingleInExecutionAsManager`,
      body
    );
  }
  saveOkrDetails(body) {
    return this.restfulWebService.post(`CycleObjective/SaveOkrDetails`, body);
  }
}
