import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360SingleTabsComponent } from './pp360-single-tabs/pp360-single-tabs.component';
import { Pp360TabsComponent } from './pp360-tabs.component';

@NgModule({
  declarations: [Pp360TabsComponent, Pp360SingleTabsComponent],
  imports: [CommonModule],
  exports: [Pp360TabsComponent, Pp360SingleTabsComponent]
})
export class Pp360TabsModule {}
