import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pp360-single-tabs',
  templateUrl: './pp360-single-tabs.component.html',
  styleUrls: ['./pp360-single-tabs.component.css'],
  host: { class: 'pp360-single-tabs' }
})
export class Pp360SingleTabsComponent implements OnInit {
  @Input() caption: string;
  @Input() icon: string;
  @Output() click: EventEmitter<any> = new EventEmitter<any>();
  @Input() isActive: boolean;
  @Input() tooltip: string;
  @Input() style = {};

  constructor() {}

  ngOnInit(): void {}
}
