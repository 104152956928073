import { Component, OnInit, Input } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-summary-box',
  templateUrl: './summary-box.component.html',
  styleUrls: ['./summary-box.component.scss']
})
export class SummaryBoxComponent implements OnInit {
  @Input() public plan: any;
  @Input() public originalPlan: any;
  @Input() public yellowBox: boolean;
  @Input() public type: string;
  @Input() public currentYear: number;

  constructor(
    private coreService: CoreService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {}

  formatNumber(x: any, decimal: number = 2, currency: boolean = true) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    if (currency)
      return Number(value).toLocaleString(this.translateService.currentLang, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    else
      return Number(value).toLocaleString(this.translateService.currentLang, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
  }

  getTargetValue(y) {
    let plan = this.plan;
    if (
      (this.plan.IsActive || this.plan.IsReviewing) &&
      this.type == 'before'
    ) {
      plan = this.originalPlan;
    }
    let n = 0.0;
    if (plan) {
      if (!y) {
        if (plan.TotalBonus) n = plan.TotalBonus.Target;
      } else {
        if (plan.TargetMinMax && plan.TargetMinMax[y]) {
          n = plan.TargetMinMax[y].value;
        }
      }
    }
    return this.formatNumber(n, 0);
  }

  getMinValue(y) {
    let plan = this.plan;
    if (
      (this.plan.IsActive || this.plan.IsReviewing) &&
      this.type == 'before'
    ) {
      plan = this.originalPlan;
    }
    let n = 0.0;
    if (plan) {
      if (plan.TargetMinMax) {
        if (!y) {
          n = plan.TargetMinMax.Total.min;
        } else {
          if (plan.TargetMinMax && plan.TargetMinMax[y]) {
            n = plan.TargetMinMax[y].min;
          }
        }
      }
    }

    return this.formatNumber(n, 0);
  }
  getMaxValue(y) {
    let plan = this.plan;
    if (
      (this.plan.IsActive || this.plan.IsReviewing) &&
      this.type == 'before'
    ) {
      plan = this.originalPlan;
    }
    let n = 0.0;
    if (plan) {
      if (plan.TargetMinMax) {
        if (!y) {
          n = plan.TargetMinMax.Total.max;
        } else {
          if (plan.TargetMinMax && plan.TargetMinMax[y]) {
            n = plan.TargetMinMax[y].max;
          }
        }
      }
    }

    return this.formatNumber(n, 0);
  }

  plansLength() {
    if (!this.plan || !this.plan.YearEnd || !this.plan.YearStart) return [];
    return new Array(this.plan.YearEnd - this.plan.YearStart + 1)
      .fill(1)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  check(event: any) {
    console.log(event);
  }
}
