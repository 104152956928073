import {
  Component,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  ChangeDetectorRef,
  HostListener,
  Input,
  Output,
  EventEmitter,
  Inject
} from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { initScreen } from '../../../app.helpers';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';
import { CoreService } from 'app/shared/services/core.service';
import { AuthService } from '../../../auth/auth.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
//import { APPCONFIG } from '../../../app.config';
import { RestfulService } from 'app/shared/services/restful.service';
import { AppConfigService } from 'app/app-config.service';

import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { navigationService } from 'app/shared/services/navigation.service';
import { SharedService } from 'app/shared/services/shared.service';

// Components
import { PasswordChangeComponent } from 'app/shared/components/password-change/password-change.component';
import { AdminProfileChangeComponent } from 'app/shared/components/admin-profile-change/admin-profile-change.component';
import { SimpleDialogComponent } from '../../../shared/simple-dialog/simple-dialog.component';
import { NewPatComponent } from 'app/shared/components/new-pat/new-pat.component';
import { EvaluationComponent } from 'app/shared/components/evaluation/evaluation.component';
import { GiveTokenComponent } from 'app/shared/components/give-token/give-token.component';
import { SearchEmployeeComponent } from './search-employee/search-employee.component';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { ChoicePopUpComponent } from 'app/shared/components/choice-pop-up/choice-pop-up.component';
import { NewsDialogComponent } from 'app/employee/wall/news-dialog/news-dialog.component';
import {
  MsalGuardConfiguration,
  MsalService,
  MSAL_GUARD_CONFIG
} from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
declare var jQuery: any;

@Component({
  selector: 'topnavbar',
  providers: [AuthService],
  templateUrl: 'topnavbar.template.html',
  styleUrls: ['topnavbar.template.scss', '../../../common_styles.scss']
})
export class TopNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() the_Wall: boolean = true;

  @Output() refreshRequired = new EventEmitter();
  @Output() employeeList = new EventEmitter();
  superScript = '2';
  notifies = 0;
  userInfo: any;
  isSideMenu: boolean = false;
  showSideBar: boolean = false;
  headerWidth: boolean;
  bsModalRef: BsModalRef;
  notifyRows: any[];
  allRows: any[];
  config: any;
  mottoLoaded = false;
  motto: string = undefined;
  alertVisible = false;
  popupValue: boolean = false;
  isUser: boolean = true;
  userinfo: any = [];
  userImage: string = '';
  userData;
  selectedRole;
  counts: number = 0;
  isRoleChange: boolean = false;
  public lastClickedMenu: any;
  fname: any;
  mname: any;
  lname: any = '';
  patData: any;
  profileName: any;
  availableTokens: any = 0;
  evaluation_Employees: any;
  enablePatButton: boolean = false;
  enableTokenButton: boolean = false;
  enableFeedbackButton: boolean = false;
  employeesList: any;

  @ViewChild('tipOfTheDay') tipOfTheDay: ElementRef;
  subscriptionAppConfig: Subscription;

  employees: any;
  settings: any = {
    selectAllText: '',
    unSelectAllText: '',
    enableSearchFilter: true,
    enableFilterSelectAll: false,
    classes: 'employeesDropDown',
    showCheckbox: false,
    singleSelection: true
  };
  all_Employees_Teams: any;
  isMobile: boolean;
  tokenHover = false;
  isMobiles: boolean;
  hideTopnavbar: boolean;
  addBtn: boolean;
  NotifyValue: boolean;
  scrollShow: boolean = false;
  constructor(
    public router: Router,
    public coreService: CoreService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    public authService: AuthService,
    public modalService: BsModalService,
    private translate: TranslateService,
    private pp360SpinnerService: Pp360SpinnerService,
    private restfulWebService: RestfulService,
    private appConfigService: AppConfigService,
    private ref: ChangeDetectorRef,
    private screenSizeService: ScreenSizeService,
    private navService: navigationService,
    public sharedService: SharedService,
    private route: Router // private keyboard: Keyboard
  ) {
    this.coreService.userInfoChanged.subscribe((arg) =>
      this.manageUserInfoChanged(arg)
    );
    this.coreService.onNewNotification.subscribe((e) =>
      this.manageNewNotification(e)
    );
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        if (
          val.urlAfterRedirects.indexOf('user-profile/my-profile') !== -1 &&
          this.isMobiles
        ) {
          this.hideTopnavbar = true;
        } else {
          this.hideTopnavbar = false;
        }
      }
    });

    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
      this.isMobiles = res <= 800;
    });
  }

  roleArray = [
    { class: 'fas fa-x fa-user', role: 'User', text: 'Menu.EmployeeView' },
    {
      class: 'fas fa-x fa-user-tag',
      role: 'LocalAdmin',
      text: 'Menu.LocalAdminView'
    },
    {
      class: 'fas fa-x fa-user-cog',
      role: 'CompanyAdmin',
      text: 'Menu.CompanyAdminView'
    },
    {
      class: 'fas fa-x fa-users-cog',
      role: 'SuperAdmin',
      text: 'Menu.SuperAdminView'
    }
  ];

  managerSlide = {
    class: 'fas fa-x fa-user-tie',
    role: 'Manager',
    text: this.coreService.getTranslation('Menu.ManagerView')
  };
  nav = {
    activeRoute: true,
    iClass: 'fa fa-book sideIcon',
    refName: 'feed',
    routerLink: '/User/wall/feed',
    title: 'Menu.TheWall'
  };

  ngAfterContentInit() {
    this.config = this.appConfigService._appConfig;
    let result = this.superScript.sup();
    this.readUserInfo();
    this.restfulWebService
      .get('Account/Notifications')
      .subscribe((data: any) => {
        this.manageNewNotification(data);
      });
    setTimeout(() => initScreen(), 100);

    this.settings['maxHeight'] = this.isMobile
      ? $('#scrolling').height()
      : $('body').height() * 0.7;

    this.settings['searchPlaceholderText'] =
      this.coreService.getTranslation('Generic.Search');
  }
  ngAfterViewInit() {
    //  var x = this.tipOfTheDay.nativeElement.offsetHeight;
    /*
    this.router.events.subscribe(e => {
      console.log(e);
      if (e instanceof NavigationEnd) {


        if (this.subscriptionAppConfig) this.subscriptionAppConfig.unsubscribe();

        this.subscriptionAppConfig = this.appConfigService.getConfig$.subscribe(
          (newConfig: any) => {
            console.log('newConfig', newConfig);
            if (!newConfig) return;
            this.config = newConfig;
            this.readUserInfo();
          }
        );

      }
    });
    */

    this.subscriptionAppConfig = this.appConfigService.getConfig$.subscribe(
      (newConfig: any) => {
        /*
          console.log('newConfig', newConfig);
          if (!newConfig || JSON.stringify(this.config) === JSON.stringify(newConfig)) return;
          this.config = newConfig;
          this.readUserInfo();
          */
        this.ref.detectChanges();
      }
    );

    $('body').on('click', (e) => {
      if (
        !$('li.menu_Icon.dropdown').is(e.target) &&
        $('li.menu_Icon.dropdown').has(e.target).length === 0 &&
        $('.open').has(e.target).length === 0
      ) {
        //$('li.menu_Icon.dropdown').removeClass('open');
        this.alertVisible = false;
      }
    });
    this.toggleNavigation();
  }

  ngOnDestroy() {
    // this.subscriptionAppConfig.unsubscribe
  }

  manageUserInfoChanged(arg) {
    this.readUserInfo();
  }

  manageNewNotification(data) {
    this.allRows = data;
    this.notifyRows = this.allRows;
    if (this.notifyRows) {
      this.notifyRows.forEach((element) => {
        element.Date = this.sharedService.getLocalDate(element.Date);
      });
    }

    this.notifies = this.allRows.filter((x) => !x.Checked).length;
  }

  showHideAlerts() {
    this.NotifyValue = true;
    if (this.isMobile) {
      $('body').removeClass('mini-navbar');
    }
    if (this.notifyRows && this.notifyRows.length > 0) {
      this.alertVisible = !this.alertVisible;
      //Chek visualized notifies
      if (this.alertVisible) {
        this.notifyRows.forEach((item) => {
          this.checkNotify(item);
        });
      }
    }
  }

  @HostListener('window:scroll', ['$event'])
  scrollHandler(event) {
    this.scrollShow = true;
    if (this.scrollShow) {
      setTimeout(() => {
        this.scrollShow = false;
      }, 1000);
    }
  }

  async ngOnInit() {
    this.restfulWebService
      .get(`CompanyExt/AllEmployees`)
      .subscribe((employees: any) => {
        this.employees = [];
        employees.Rows.forEach((employee: any) => {
          employee['id'] = employee.Id;
          employee['itemName'] = employee.Name;
          this.employees.push(employee);
        });
      });

    this.userData = this.coreService.getUserInfo();

    if (!this.userData) {
      this.router.navigate(['login']);
    }
    this.getEmployee();
    this.getList();
    this.getEmployeesForEvaluation();
    if (this.userData) {
      this.availableTokens = this.userData?.availableTokens;
    }
    this.userImage = this.userData?.imageUrl;
    const userRoles = this.coreService.getUserRole().split(',');
    const roleArray = this.roleArray.filter((s) => {
      return userRoles.includes(s.role);
    });
    if (this.userData.isManager) {
      roleArray.splice(1, 0, this.managerSlide);
      // roleArray.push(this.managerSlide);
    }
    this.roleArray = roleArray;
    if (this.userData) {
      this.setRole();
    } else {
      this.selectedRole = this.roleArray[0]?.role;
    }
    if (this.selectedRole?.role === 'Manager') {
      this.nav = {
        activeRoute: true,
        title: 'Menu.MyDashboard',
        iClass: 'fa fa-th-large sideIcon',
        refName: 'Dashboard/manager',
        routerLink: '/Manager/dashboard'
      };
    }
    this.userData.fullName = this.userData.fullName
      .replace(/[^a-zA-Z ]/g, '')
      .replace(/\s{2,}/g, ' ')
      .trim();
    if (this.userData.fullName.split(' ')[0].length > 8) {
      this.fname = this.userData.fullName.split(' ')[0][0] + '.';
    } else {
      this.fname = this.userData.fullName.split(' ')[0];
    }
    if (this.userData.fullName.split(' ')[1].length > 8) {
      this.mname = this.userData.fullName.split(' ')[1][0] + '.';
    } else {
      this.mname = this.userData.fullName.split(' ')[1];
    }
    if (this.userData.fullName.split(' ')[2]) {
      if (this.userData.fullName.split(' ')[2].length > 8) {
        this.lname = this.userData.fullName.split(' ')[2][0] + '.';
      } else {
        this.lname = this.userData.fullName.split(' ')[2];
      }
    }
    this.profileName = this.fname + ' ' + this.mname + ' ' + this.lname;
    this.profileName = this.profileName.trim();
    await this.getUpdatedUserInfo();
  }

  setRole() {
    this.userData = this.coreService.getUserInfo();
    if (this.userData) {
      if (this.userData.PreferredRole === 'Manager') {
        this.selectedRole = this.managerSlide;
      } else {
        this.selectedRole = this.roleArray.find(
          (x) => x.role === this.userData?.PreferredRole
        );
      }
    }
  }

  onRoleChange(event: any) {
    if (event) {
      this.isRoleChange = true;
      this.selectedRole = event?.value;
    }
  }

  readUserInfo() {
    this.userInfo = this.coreService.getUserInfo();
    this.config.earnedTokens = this.userInfo.earnedTokens;
    this.config.availableTokens = this.userInfo.availableTokens;
    this.motto = this.getTooltipOfTheDay();
    this.getMottoText(this.motto);
    this.getMottoAuthor(this.motto);
    if (this.selectedRole?.role === 'Manager') {
      this.nav = {
        activeRoute: true,
        title: 'Menu.MyDashboard',
        iClass: 'fa fa-th-large sideIcon',
        refName: 'Dashboard/manager',
        routerLink: '/Manager/dashboard'
      };
    } else {
      this.nav = {
        activeRoute: true,
        iClass: 'fa fa-book sideIcon',
        refName: 'feed',
        routerLink: '/User/wall/feed',
        title: 'Menu.TheWall'
      };
    }
  }

  viewNotify(item) {
    if (!item.Viewed) {
      this.restfulWebService
        .post('NotificationExt/View?id=' + item.Id, undefined)
        .subscribe((data: any) => {
          item.Viewed = true;
        });
    }
    if (item.Link) {
      const obj = this.splitURI(item.Link);
      let navigationExtras: NavigationExtras = {
        queryParams: obj.queryParams,
        fragment: 'single'
      };
      this.router.navigate([obj.uri], navigationExtras);
    } else {
      let navigationExtras: NavigationExtras = {
        queryParams: {
          RefId: item.ReferenceId,
          Type: item.NotificationType,
          Date: item.Date,
          Description: item.Description
        },
        fragment: 'single'
      };

      this.router.navigate(['/User/wall/notify'], navigationExtras);
    }
    this.alertVisible = false;
    this.NotifyValue = false;
  }

  checkNotify(item) {
    if (!item.Checked) {
      this.restfulWebService
        .post('NotificationExt/Check?id=' + item.Id, undefined)
        .subscribe((data: any) => {
          item.Checked = true;
          this.notifyRows = this.allRows;
          this.notifies = this.allRows.filter((x) => !x.Checked).length;
        });
    }
  }

  deleteNotifty(item) {
    const content: any = {
      YesCaption: this.coreService.getTranslation('YES'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('Messages.SureDelete')
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.restfulWebService
            .post('NotificationExt/Delete?id=' + item.Id, undefined)
            .subscribe((data: any) => {
              this.allRows = this.allRows.filter((x) => x.Id !== item.Id);
              this.notifyRows = this.allRows;
              this.notifies = this.allRows.filter((x) => !x.Checked).length;
            });
        }
      }
    );
  }

  openPopup() {
    if (this.isMobile) {
      $('body').removeClass('mini-navbar');
    }
    this.popupValue = true;
  }

  addEvent() {
    this.addBtn = true;
  }

  onPopupClose() {
    if (this.popupValue || this.addBtn || this.NotifyValue) {
      this.setRole();
      this.isRoleChange = false;
      this.popupValue = false;
      this.NotifyValue = false;
      this.addBtn = false;
    }
  }

  onSetting() {
    this.router.navigate(['/User/user-profile/my-profile']);
    this.onPopupClose();
  }

  onCancel() {
    this.setRole();
    this.isRoleChange = false;
  }
  onSave() {
    // this.selectedRole =
    this.pp360SpinnerService.showSpinner();
    this.authService.savePrefferedRole(this.selectedRole?.role).subscribe(
      (res) => {
        this.pp360SpinnerService.showSpinner(false);
        this.navService.setupMenus();
        this.coreService.setPreferredRole(this.selectedRole?.role);
        this.authService.redirectUser(this.selectedRole?.role);
        this.navService.setUpdateSideMenu(true);
        this.popupValue = false;
        this.NotifyValue = false;
        // window.location.replace(this.authService.getNextURL(this.selectedRole.role));
      },
      () => this.pp360SpinnerService.showSpinner(false)
    );
    this.isRoleChange = false;
  }

  splitURI(uri) {
    let retObj = {
      uri: uri,
      queryParams: {}
    };
    var items = uri.split('?');
    if (items.length > 1) {
      retObj.uri = items[0];
      var params = items[1].split('&');
      params.forEach((element: any) => {
        var tk = element.split('=');
        retObj.queryParams[tk[0]] = tk[1];
      });
    }

    return retObj;
  }

  viewAllNotify() {
    var params = {};
    var i = 0;

    let navigationExtras: NavigationExtras = {
      fragment: 'all'
    };
    this.NotifyValue = false;
    this.router.navigate(['/User/wall/notify'], navigationExtras);
  }

  mottoText: string;
  getMottoText(txt) {
    var l = txt.indexOf('(');
    if (l > 0) {
      this.mottoText = txt.substring(0, l);
    } else this.mottoText = txt;
  }

  mottoAuthor: string;
  getMottoAuthor(txt) {
    var l = txt.indexOf('(');
    if (l > 0) {
      this.mottoAuthor = txt.substring(l);
    } else
      this.mottoAuthor =
        '(' + this.coreService.getTranslation('Generic.Anonymous') + ')';
  }

  getIsAuthorOnNewLine(txt) {
    if (!txt) return false;
    return (
      window.innerWidth > 500 || (window.innerWidth <= 500 && txt.length < 65)
    );
  }

  showAuthorOnNewLine(txt) {
    if (!txt) return false;
    return (
      window.innerWidth > 500 || (window.innerWidth <= 500 && txt.length < 95)
    );
  }

  getTooltipOfTheDay() {
    const N = 68;
    const mail = this.userInfo.email.toLowerCase();
    let a = 0;
    let sum = 0;
    for (a = 0; a < mail.length; a++) {
      let ch = mail.charCodeAt(a) - 97;
      if (ch > 0) {
        sum += ch;
      }
    }
    let date = new Date();
    let dayInYear =
      (Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()) -
        Date.UTC(date.getFullYear(), 0, 0)) /
      24 /
      60 /
      60 /
      1000;

    let index = dayInYear + sum;
    while (index > N) {
      index -= N;
    }

    var key = 'Motto.' + index;
    return this.coreService.getTranslation(key);
  }

  toggleNavigation() {
    if (this.isMobile) {
      jQuery('body').toggleClass('mini-navbar');
    }
    smoothlyMenu();
  }

  showHideFilter() {
    this.config.showFilter = !this.config.showFilter;
  }

  onImageClick() {
    jQuery('.menuUserIcon').toggleClass('menuUserIconOpens');
  }

  onLogout() {
    localStorage.clear();
    this.onPopupClose();
    this.router.navigate(['login']);
  }

  logout() {
    document.documentElement.style.setProperty('--white', '#ffffff');
    document.documentElement.style.setProperty('--feed-background', '#f2f2f2');
    document.documentElement.style.setProperty(
      '--login-textfield-color',
      '#a09ab2'
    );
    document.documentElement.style.setProperty(
      '--login-textfield-border',
      '#676688'
    );
    document.documentElement.style.setProperty('--login-text-color', '#0c0039');
    document.documentElement.style.setProperty('--login-button', '#0057ff');
    document.documentElement.style.setProperty('--login-text-error', '#ff1a00');
    document.documentElement.style.setProperty(
      '--login-button-hover',
      '#0045cc'
    );
    document.documentElement.style.setProperty('--okr-green', '#2caf66');

    const activeAccount =
      this.msalService.instance.getActiveAccount() ||
      this.msalService.instance.getAllAccounts()[0];

    if (activeAccount) {
      if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
        this.msalService
          .logoutPopup({
            account: activeAccount
          })
          .subscribe(() => {
            localStorage.clear();
            this.router.navigate(['login']);
          });
      } else {
        this.msalService
          .logoutRedirect({
            account: activeAccount
          })
          .subscribe(() => {
            localStorage.clear();
            this.router.navigate(['login']);
          });
      }
    } else {
      this.onLogout();
    }
  }

  onChangePassword() {
    this.bsModalRef = this.modalService.show(PasswordChangeComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onMenuClick(nav) {
    this.lastClickedMenu = nav.refChildName;
    if (nav.refChildName) {
      this.navService.ToggleMenuStatus(nav.refChildName);
    }
  }

  onProfileView() {
    this.bsModalRef = this.modalService.show(AdminProfileChangeComponent, {
      class: 'modal-lg',
      ignoreBackdropClick: true
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.bsModalRef.content.userInfo = this.userInfo;
  }

  filterWall(filterType: string) {
    this.appConfigService.filterWall$.next(filterType);
  }

  getNewPat(employee: any) {
    let e = JSON.parse(JSON.stringify(employee));
    e.isPreselect = true;
    e.Type = 'Employee';
    e.Id = 'e:' + employee.Id;
    let emp = [e];
    this.bsModalRef = this.modalService.show(NewPatComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true
    });

    this.bsModalRef.content.preselectEmployee(emp);

    (<NewPatComponent>this.bsModalRef.content).refreshWallRequired.subscribe(
      (newPatData: boolean) => {
        this.appConfigService.refreshWall$.next(true); //TODO ??????????????????
        (<NewPatComponent>(
          this.bsModalRef.content
        )).refreshWallRequired.unsubscribe();
      }
    );
  }

  getNewEvaluation(employee: any) {
    employee.isPreselect = true;
    employee.Type = 'Employee';
    let emp = [employee];
    this.bsModalRef = this.modalService.show(EvaluationComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true
    });
    this.bsModalRef.content.type = 'get';
    this.bsModalRef.content.evaluation_Employees = emp;
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  getTokenPopUp(employee: any) {
    employee.isPreselect = true;
    employee.Type = 'Employee';
    let emp = [employee];
    this.bsModalRef = this.modalService.show(GiveTokenComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true
    });
    this.bsModalRef.content.employeesList = emp;
  }

  searchEmployeeText: string;
  checkOpenList() {
    if (this.isMobile) {
      this.searchEmployeeText = '';
      $('body').removeClass('mini-navbar');
      const modal = this.modalService.show(SearchEmployeeComponent, {
        class: 'SearchEmployeesPopup',
        ignoreBackdropClick: true
      });
      (<SearchEmployeeComponent>modal.content).showModal({
        employees: this.employees
      });

      (<SearchEmployeeComponent>modal.content).onClose.subscribe(
        (result: any) => {
          if (result && result.action) {
            if (result.action === 'getNewPat') this.getNewPat(result.employee);
            if (result.action === 'getNewEvaluation')
              this.getNewEvaluation(result.employee);
            if (result.action === 'getTokenPopUp')
              this.getTokenPopUp(result.employee);
          }
        }
      );
    }
  }

  onWall() {
    this.route.navigate(['/User/wall/feed']);
  }

  getNewPats() {
    this.addBtn = false;
    if (this.route.url === '/Manager/dashboard') {
      this.bsModalRef = this.modalService.show(NewPatComponent, {
        class: 'wallPopup manager-deshboard-popup',
        ignoreBackdropClick: true
      });
    } else {
      this.bsModalRef = this.modalService.show(NewPatComponent, {
        class: 'wallPopup',
        ignoreBackdropClick: true
      });
    }

    this.bsModalRef.content.all_Employees_Teams = this.all_Employees_Teams;
    this.bsModalRef.content.closeBtnName = 'Close';

    (<NewPatComponent>this.bsModalRef.content).refreshWallRequired.subscribe(
      (newPatData: boolean) => {
        this.refreshWall(newPatData);
        (<NewPatComponent>(
          this.bsModalRef.content
        )).refreshWallRequired.unsubscribe();
      }
    );
  }

  choicePop() {
    this.addBtn = false;
    if (this.route.url === '/Manager/dashboard') {
      this.bsModalRef = this.modalService.show(ChoicePopUpComponent, {
        class: 'choicePopUp ammazza wallPopup manager-deshboard-popup1',
        ignoreBackdropClick: true,
        initialState: {
          evaluation_Employees: this.evaluation_Employees || []
        }
      });
    } else {
      this.bsModalRef = this.modalService.show(ChoicePopUpComponent, {
        class: 'choicePopUp ammazza wallPopup',
        ignoreBackdropClick: true,
        initialState: {
          evaluation_Employees: this.evaluation_Employees || []
        }
      });
    }
    this.bsModalRef.content.postEval.subscribe((data: any) => {
      this.refreshWall(data);
      this.bsModalRef.hide();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  getTokenPopUps() {
    if (this.availableTokens !== 0) {
      this.addBtn = false;
      if (this.route.url === '/Manager/dashboard') {
        this.bsModalRef = this.modalService.show(GiveTokenComponent, {
          class: 'wallPopup manager-deshboard-popup2',
          ignoreBackdropClick: true
        });
      } else {
        this.bsModalRef = this.modalService.show(GiveTokenComponent, {
          class: 'wallPopup',
          ignoreBackdropClick: true
        });
      }
      this.bsModalRef.content.employeesList = this.all_Employees_Teams;
      this.bsModalRef.content.closeBtnName = 'Close';
    }
  }

  getNewsPopUps() {
    this.addBtn = false;
    let modal;
    if (this.route.url === '/Manager/dashboard') {
      modal = this.modalService.show(NewsDialogComponent, {
        class: 'wallPopup manager-deshboard-popup3',
        ignoreBackdropClick: true
      });
    } else {
      modal = this.modalService.show(NewsDialogComponent, {
        class: 'wallPopup',
        ignoreBackdropClick: true
      });
    }
    modal.content.initModal(null);
    (<NewsDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.refreshWall(this.patData);
        }
      }
    );
  }

  refreshWall(data) {
    this.refreshRequired.emit(data);
  }

  getList() {
    this.enablePatButton = false;
    this.sharedService
      .getFilterEmployees(this.userData.linkedEmployeeId, 'Pat', 'List')
      .subscribe(
        (data: any) => {
          data.forEach((element: any) => {
            if (element.Id && element.Id.indexOf(':') < 0) {
              if (element.Type == 'Team') {
                element['Name'] = element.Name + ' Team';
                element['Id'] = 't:' + element['Id'];
              } else {
                element['Name'] = element.Name;
                element['Id'] = 'e:' + element['Id'];
              }
            }
          });
          this.all_Employees_Teams = data;
          this.employeeList.emit(data);
          this.enablePatButton = true;
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
  }

  getEmployeesForEvaluation() {
    this.enableFeedbackButton = false;
    this.sharedService
      .getFilterEmployees(this.userData.linkedEmployeeId, 'Feedback', 'List')
      .subscribe(
        (data: any) => {
          this.evaluation_Employees = data;
          this.enableFeedbackButton = true;
        },
        (err: any) => {
          console.log('err');
        }
      );
  }

  getEmployee() {
    this.enableTokenButton = false;
    this.sharedService
      .getFilterEmployees(this.userData.linkedEmployeeId, 'Token', 'List')
      .subscribe(
        (data: any) => {
          this.employeesList = data;
          this.enableTokenButton = true;
        },
        (err: any) => {
          console.log('errr');
        }
      );
  }
  setLogo() {
    let a = '';
    if (
      this?.userInfo?.Theme?.Code &&
      (this?.userInfo?.belongingCompanyName === 'Demo Company' ||
        this?.userInfo?.belongingCompanyName === 'Test Company' ||
        this?.userInfo?.belongingCompanyName === 'Active Value Advisors')
    ) {
      // console.log(this.userInfo.Theme);
      if (this.userInfo.Theme.VariationCode == 'L') {
        a =
          './assets/images/logos/' + this.userInfo.Theme.Code + '_L_logo.png ';
      } else {
        a =
          './assets/images/logos/' + this.userInfo.Theme.Code + '_F_logo.png ';
      }
    } else {
      a = this.coreService.getImageURL(
        this.userInfo.belongingCompanyLogo,
        this.coreService.getDefaultImage()
      );
    }
    return a;
  }

  getUpdatedUserInfo() {
    this.restfulWebService
      .get(`Account/UserInfo`)
      .subscribe(this.getUserInfoObserver);
  }
  getUserInfoObserver = {
    next: (userInfo: any) => {
      this.changeProperty(this.userInfo, userInfo, 'Theme');
      this.coreService.setUserInfo(this.userInfo);
      this.coreService.changedProperty(['Theme']);
    }
  };
  changeProperty(currentItem, newItem, property) {
    currentItem[property] = newItem[property];
  }
}
