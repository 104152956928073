import { Component, OnInit, OnDestroy } from '@angular/core';
import { initScreen } from '../../app.helpers';
import { AppConfigService } from 'app/app-config.service';
import { smoothlyMenu } from '../../app.helpers';
import { CoreService } from '../../shared/services/core.service';
import { AdminLtiService } from '../admin-lti.service';
import { TranslateService } from '@ngx-translate/core';
import { calculateLTIPlanParametes } from '../functions';
import { Observable } from 'rxjs';
import { combineLatest } from 'rxjs';
import { interval } from 'rxjs';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})
export class ResultsComponent implements OnInit {
  //  loading = false;

  employeesList: any;
  parameters: any;
  sideHistoryItems: any;
  currentPlan: any;
  formats: any;
  measures: any;
  scales: any;
  goals: any;
  currentView: string = 'results';
  currentYear: number;
  intervalSubscription: any;
  employeesMap = {};

  private parametersObsrv$;
  private plansObsrv$;
  private employeesObsrv$;
  private formatsObsrv$;
  private scalesObsrv$;
  private measuresObsrv$;
  private goalsObsrv$;

  constructor(
    private coreService: CoreService,
    private adminLtiService: AdminLtiService,
    private translateService: TranslateService,
    private appConfigService: AppConfigService,
    private router: Router,
    private pp360SpinnerService: Pp360SpinnerService
  ) {
    $('#page-wrapper').removeClass('background1');
    $('#page-wrapper').addClass('background2');
  }

  getIcon(consultationType: string) {
    switch (consultationType.toLowerCase()) {
      case 'punctual':
        return 'fa fa-stop-circle-o';
        break;
      case 'cumulative':
        return 'fa fa-plus-circle';
        break;
      default:
        return 'fa fa-arrows-h';
        break;
    }
  }

  toggleNavigation(): void {
    // $("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  ngAfterViewInit() {
    setTimeout(() => initScreen(), 100);

    this.appConfigService.replaceHeader$.next({
      showIcons: false,
      showTitle: true,
      pageIcon: 'fa-handshake-o',
      pageTitle: this.coreService.getTranslation('Menu.AdminLTI')
    });
  }

  ngOnInit() {
    this.toggleNavigation();
    this.pp360SpinnerService.showSpinner(true);
    this.parametersObsrv$ = this.adminLtiService.getParameters();
    this.plansObsrv$ = this.adminLtiService.getPlans();
    this.employeesObsrv$ = this.adminLtiService.getEmployees();

    this.formatsObsrv$ = this.adminLtiService.getFormats();
    this.scalesObsrv$ = this.adminLtiService.getScales();
    this.measuresObsrv$ = this.adminLtiService.getAllMeasures();
    this.goalsObsrv$ = this.adminLtiService.getGoals();

    combineLatest(
      this.parametersObsrv$,
      this.plansObsrv$,
      this.employeesObsrv$,
      this.formatsObsrv$,
      this.scalesObsrv$,
      this.measuresObsrv$,
      this.goalsObsrv$,
      (
        parametersObsrv,
        plansObsrv,
        employeesObsrv,
        formatsObsrv,
        scalesObsrv,
        measuresObsrv,
        goalsObsrv
      ) => ({
        parametersObsrv,
        plansObsrv,
        employeesObsrv,
        formatsObsrv,
        scalesObsrv,
        measuresObsrv,
        goalsObsrv
      })
    ).subscribe((pair: any) => {
      console.log(pair);
      this.parameters = pair.parametersObsrv;
      let plans: any = pair.plansObsrv;
      this.employeesList = pair.employeesObsrv;
      this.formats = pair.formatsObsrv;
      this.scales = pair.scalesObsrv;
      this.measures = pair.measuresObsrv;
      this.goals = pair.goalsObsrv;
      this.sideHistoryItems = plans.filter((x) => x.active);
      this.createEmployeeMap();
      this.pp360SpinnerService.showSpinner(false);
    });

    this.intervalSubscription = interval(3000).subscribe(() =>
      calculateLTIPlanParametes(this.currentPlan, true)
    );
  }

  ngOnDestroy() {
    this.intervalSubscription.unsubscribe();
  }

  checkDisabled() {
    // TODO: Aggiungere check per abilitare pulsante send
    return !this.currentPlan;
  }

  createEmployeeMap() {
    this.employeesList.forEach((emp) => {
      this.employeesMap[emp.Id] = emp;
    });
  }

  getTooltipConsultationType(type: string) {
    switch (type) {
      case 'Punctual':
        return this.coreService.getTranslation(
          'ADMIN_LTI.GATEWAYS.BODY.PUNCTUAL'
        );
        break;
      case 'Cumulative':
        return this.coreService.getTranslation(
          'ADMIN_LTI.GATEWAYS.BODY.CUMULATIVE'
        );
        break;
      case 'Average':
        return this.coreService.getTranslation(
          'ADMIN_LTI.GATEWAYS.BODY.AVERAGE'
        );
        break;
    }
  }

  getMeasure(obj) {
    let m = '';
    if (obj.Measure.MeasureType == 'money') {
      if (obj.Currency) m += obj.Currency;
      if (obj.Format) m += '[' + obj.Format.Name + ']';
    } else if (obj.Measure.MeasureType == 'percentage') {
      m = '%';
    } else if (obj.Measure.MeasureType == 'numeric') {
      if (obj.Format) m += '[' + obj.Format.Name + ']';
    } else if (obj.Measure.MeasureType == 'scale') {
      if (obj.Scale) m += '[' + obj.Scale.Name + ']';
    }
    return m;
  }

  selectPlan(plan: any) {
    this.adminLtiService.getPlan(plan.Id).subscribe((result: any) => {
      if (!this.currentPlan || this.currentPlan.Id != result.Id) {
        this.currentPlan = result;
        if (
          this.currentPlan.PartecipantsByYear &&
          this.currentPlan.PartecipantsByYear.length > 0
        ) {
          //Map Employees to partecipants

          this.currentPlan.PartecipantsByYear.forEach((pby) => {
            let emps = [];
            pby.Partecipants.forEach((element: any) => {
              let emp = this.employeesMap[element.EmployeeId];
              if (emp) {
                var empClone = JSON.parse(JSON.stringify(emp));
                empClone.TargetBonusPercentage = element.TargetBonusPercentage;
                empClone.GrossAnnualSalary = element.GrossAnnualSalary;
                emps.push(empClone);
              }
            });
            pby.Partecipants = emps;
          });
        }
      }
      this.currentView = 'results';
      this.initYearlyValues();
      this.currentYear = new Date().getFullYear();

      this.connectMeasures(this.currentPlan.Gateways);
      this.connectMeasures(this.currentPlan.Objectives);
      this.connectMeasures(this.currentPlan.Correctives);

      console.log(this.currentPlan);
    });
  }

  connectMeasures(list) {
    list.forEach((g) => {
      if (g.GoalId) {
        let gl = this.goals.find((y) => y.Id == g.GoalId);
        if (gl) g.Goal = gl;
      }

      let m = this.measures.find((y) => y.Id == g.MeasureId);
      if (m) g.Measure = m;

      if (g.ScaleId) {
        let s = this.scales.find((y) => y.Id == g.ScaleId);
        if (s) g.Scale = s;
      }

      if (g.FormatId) {
        let f = this.formats.find((y) => y.Id == g.FormatId);
        if (f) g.Format = f;
      }

      g.YearlyValues.forEach((yv) => {
        if (yv.TargetValue == -100000) yv.TargetValue = undefined;
      });
    });
  }

  isSelected(item) {
    if (this.currentPlan) {
      return this.currentPlan.Id === item.Id;
    } else return false;
  }

  sendResults() {
    let values = [];
    this.getValuesToSave(
      'gateway',
      this.currentPlan.Id,
      values,
      this.currentPlan.Gateways
    );
    this.getValuesToSave(
      'objective',
      this.currentPlan.Id,
      values,
      this.currentPlan.Objectives
    );
    this.getValuesToSave(
      'corrective',
      this.currentPlan.Id,
      values,
      this.currentPlan.Correctives
    );

    if (values.length > 0) {
      this.adminLtiService.sendResult(this.currentPlan.Id, values).subscribe(
        (results: any) => {
          this.coreService.toasterMessage(
            'success',
            'ResultLTI',
            this.coreService.getTranslation('ADMIN_LTI.RESULTS.RESULTS_SAVED')
          );
        },
        (error: any) => {
          this.coreService.toasterMessage('error', 'ResultLTI', error.Message);
        }
      );
    } else {
      this.coreService.toasterMessage(
        'warning',
        'ResultLTI',
        this.coreService.getTranslation('ADMIN_LTI.RESULTS.NOTHING_TO_SAVE')
      );
    }
  }
  /**
   * Get the list of values to be saved
   * @param plan
   */
  getValuesToSave(t, id, values, objects) {
    let idx = 0;
    objects.forEach((item: any) => {
      item.YearlyValues.forEach((y) => {
        if (y.CurrentValue && y.CurrentValue != 0) {
          var v = {
            PlanId: id,
            Type: t,
            Index: idx,
            Year: y.Year,
            CurrentValue: y.CurrentValue / 1
          };
          values.push(v);
        }
      });

      idx++;
    });
  }

  formatValue(obj: any, x: any, decimal: number = 0) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    if (obj.Measure.MeasureType === 'money') {
      let v = Number(value).toLocaleString(this.translateService.currentLang);
      return v + obj.Format.Name;
    }
    return value;
  }

  formatNumber(x: any, decimal: number = 0) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    return Number(value).toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  }

  /*----------------------------------------------------------------------------
      _____                 _ _
     |  __ \               | | |
     | |__) |___  ___ _   _| | |_ ___
     |  _  // _ \/ __| | | | | __/ __|
     | | \ \  __/\__ \ |_| | | |_\__ \
     |_|  \_\___||___/\__,_|_|\__|___/

  ----------------------------------------------------------------------------*/

  errorMessage: string;
  onValueChange(obj, y) {
    console.log('onValueChange', obj, y);
    /*
    let value = Number(y.TargetValue);
    y.hasError = false;
    this.errorMessage = undefined;
    if (value>=0 && this.currentMeasureObj && this.currentMeasureObj.MeasureType === 'scale') {
      const currentScale = this.item.scales.find(scale => scale.Id === this.item.ScaleId);
      if (currentScale) {
        //console.log('value', value, value !== null);
        if ((value < currentScale.Minimum || value > currentScale.Maximum) && value) {
          y.hasError = true;
          this.errorMessage = this.coreService.getTranslation("ERRORS.OUT_OF_RANGE");
        } else if (value === 0) {
          y.hasError = true;
          this.errorMessage = this.coreService.getTranslation("ERRORS.OUT_OF_RANGE");
        }

        console.log('value', value);
      }
    }
    if (y.hasError) {

    }
    */
  }

  gatewaysBind: any = {};
  objectiveBind: any = {};
  correctiveBind: any = {};

  initYearlyValues() {
    this.currentPlan.Gateways.forEach((gateway) => {
      gateway.Map = { YearlyValues: {} };
      gateway.YearlyValues.forEach((yearlyValue) => {
        gateway.Map.YearlyValues[yearlyValue.Year] = yearlyValue;
      });
    });
    this.currentPlan.Objectives.forEach((objective) => {
      objective.Map = { YearlyValues: {} };
      objective.YearlyValues.forEach((yearlyValue) => {
        objective.Map.YearlyValues[yearlyValue.Year] = yearlyValue;
      });
    });
    this.currentPlan.Correctives.forEach((corrective) => {
      corrective.Map = { YearlyValues: {} };
      corrective.YearlyValues.forEach((yearlyValue) => {
        corrective.Map.YearlyValues[yearlyValue.Year] = yearlyValue;
      });
    });
  }

  plansLength() {
    if (
      !this.currentPlan ||
      !this.currentPlan.YearEnd ||
      !this.currentPlan.YearStart
    )
      return [];
    const end =
      this.currentPlan.PayoutStructure &&
      this.currentPlan.PayoutStructure.PayoutYearEnd
        ? Math.max(
            this.currentPlan.YearEnd,
            this.currentPlan.PayoutStructure.PayoutYearEnd
          )
        : this.currentPlan.YearEnd;
    return new Array(end - this.currentPlan.YearStart + 1)
      .fill(1)
      .map((val, idx) => this.currentPlan.YearStart + idx);
  }
  /******************************************************************************/
  isSuccessTarget(item: any) {
    if (item.weightedmultiplier) {
      return item.weightedmultiplier.value > 0;
    }
    return false;
  }

  /******************************************************************************/
  getSensitive(item: any) {
    if (!item.IsCorridor) {
      return '';
    } else {
      return item.Floor / 100 + ' - ' + item.Cap / 100;
    }
  }
  /******************************************************************************/
  getMultiplier(item: any) {
    if (item.multiplier && item.multiplier.value) {
      return item.multiplier.value.toFixed(3);
    }
    return '';
  }
  /******************************************************************************/
  getTotMultiplierValue(type: string, year: number) {
    if (this.currentPlan && this.currentPlan.Multipliers) {
      let item = this.currentPlan.Multipliers.ByYear.find(
        (x) => x.year == year
      );
      if (item) {
        switch (type) {
          case 'gateway':
            return item.multipliers.gateways
              ? item.multipliers.gateways.value
              : 1;
            break;
          case 'objective':
            return item.multipliers.objectives
              ? item.multipliers.objectives.value
              : 1;
            break;
          case 'corrective':
            return item.multipliers.correctives
              ? item.multipliers.correctives.value
              : 1;
            break;
        }
      }
    }

    return 0;
  }
  /*------------------------------*/
  getTotMultiplier(type: string, year: number) {
    if (this.currentPlan && this.currentPlan.Multipliers) {
      let n = this.getTotMultiplierValue(type, year);
      return n.toFixed(3);
    } else {
      return '';
    }
  }
  /******************************************************************************/
  getSummaryTargetBonus(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.Bonuses) {
      let item = this.currentPlan.Bonuses.find((x) => x.Year == year);
      if (item) n = item.Target;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryAdmittedBonus(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.Bonuses) {
      let item = this.currentPlan.Bonuses.find((x) => x.Year == year);
      if (item) n = item.Admitted.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getMin(obj: any, v: any) {
    if (!obj.IsCorridor || !v) {
      return '';
    } else {
      let x = (obj.Min * v) / 100;
      return this.formatValue(obj, x);
    }
  }
  /******************************************************************************/
  getMax(obj: any, v: any) {
    if (!obj.IsCorridor || !v) {
      return '';
    } else {
      let x = (obj.Max * v) / 100;
      return this.formatValue(obj, x);
    }
  }

  /******************************************************************************/
  getSummaryAccrued(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.Bonuses) {
      let item = this.currentPlan.Bonuses.find((x) => x.Year == year);
      if (item) n = item.Accrued.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryPayable(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let key = year + '_' + year;
      let item = this.currentPlan.ByPayoutYearTotals[key];
      if (item) n = item.Cash.value + item.Equity.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryPayableCash(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let key = year + '_' + year;
      let item = this.currentPlan.ByPayoutYearTotals[key];
      if (item) n = item.Cash.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryPayableEquity(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let key = year + '_' + year;
      let item = this.currentPlan.ByPayoutYearTotals[key];
      if (item) n = item.Equity.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryDeferredValue(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let key = year + '_' + year;
      let item = this.currentPlan.ByPayoutYearTotals[key];
      if (item) n = item.DeferredCash.value + item.DeferredEquity.value;
    }
    return n;
  }
  /*--------------------------------*/
  getSummaryDeferred(year: number) {
    let n = this.getSummaryDeferredValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryDeferredCash(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let key = year + '_' + year;
      let item = this.currentPlan.ByPayoutYearTotals[key];
      if (item) n = item.DeferredCash.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryDeferredEquity(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let key = year + '_' + year;
      let item = this.currentPlan.ByPayoutYearTotals[key];
      if (item) n = item.DeferredEquity.value;
    }
    return this.formatNumber(n);
  }

  /******************************************************************************/
  getSummaryPastYearPayableValue(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let item = this.currentPlan.PastYears[year];
      n = item.Cash + item.Equity;
    }
    return n;
  }
  /*--------------------------------*/
  getSummaryPastYearPayable(year: number) {
    let n = this.getSummaryPastYearPayableValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryBonusBankOpeningValue(year: number) {
    if (this.currentPlan && year > this.currentPlan.YearStart) {
      return this.getSummaryBonusBankClosingVaue(year - 1);
    } else {
      return 0;
    }
  }
  /**------------------------------- */
  getSummaryBonusBankOpening(year: number) {
    let n = this.getSummaryBonusBankOpeningValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryBonusBankBaseValue(year: number) {
    let n = 0;
    if (this.currentPlan) {
      if (this.currentPlan.CorrectiveType == 'Rolling') {
        n =
          this.getSummaryBonusBankOpeningValue(year) -
          this.getSummaryPastYearPayableValue(year) +
          this.getSummaryDeferredValue(year);
      } else {
        if (year == this.currentPlan.PayoutStructure.PayoutYearEnd) {
          n =
            this.getSummaryBonusBankOpeningValue(year) -
            this.getSummaryPastYearPayableValue(year) +
            this.getSummaryDeferredValue(year);
        } else {
          n = this.getSummaryBonusBankOpeningValue(year);
        }
      }
    }

    return n;
  }
  /*--------------------------------*/
  getSummaryBonusBankBase(year: number) {
    let n = this.getSummaryBonusBankBaseValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryBonusBankCorrectiveValue(year: number) {
    let n =
      this.getSummaryBonusBankBaseValue(year) *
      (this.getTotMultiplierValue('corrective', year) - 1);
    return n;
  }
  /***--------- */
  getSummaryBonusBankCorrective(year: number) {
    let n = this.getSummaryBonusBankCorrectiveValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getSummaryBonusBankClosingVaue(year: number) {
    let n = 0;
    if (this.currentPlan) {
      n =
        this.getSummaryBonusBankBaseValue(year) *
        this.getTotMultiplierValue('corrective', year);
    }

    return n;
  }
  /*--------------------------------*/
  getSummaryBonusBankClosing(year: number) {
    let n = this.getSummaryBonusBankClosingVaue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  /******************************************************************************/
  /******************************************************************************/
  /**                       LAST YEAR                                           */
  /******************************************************************************/
  getPastYearsPayable(year: number) {
    let n = this.getSummaryPastYearPayableValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getPastYearsPayableCash(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let item = this.currentPlan.PastYears[year];
      n = item.Cash;
    }

    return this.formatNumber(n);
  }
  /******************************************************************************/
  getPastYearsPayableEquity(year: number) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.ByPayoutYearTotals) {
      let item = this.currentPlan.PastYears[year];
      n = item.Equity;
    }

    return this.formatNumber(n);
  }
  /******************************************************************************/
  getPastYearsPayableArray(year: number) {
    return new Array(year - this.currentPlan.YearStart)
      .fill(1)
      .map((val, idx) => year - 1 - idx);
  }

  /*----------------------------------------------------------------------------
    ____                          _____       _        _ _
   |  _ \                        |  __ \     | |      (_) |
   | |_) | ___  _ __  _   _ ___  | |  | | ___| |_ __ _ _| |
   |  _ < / _ \| '_ \| | | / __| | |  | |/ _ \ __/ _` | | |
   | |_) | (_) | | | | |_| \__ \ | |__| |  __/ || (_| | | |
   |____/ \___/|_| |_|\__,_|___/ |_____/ \___|\__\__,_|_|_|
  ----------------------------------------------------------------------------*/

  getPartecipantsByYear(year: number) {
    const partecipants = this.currentPlan.PartecipantsByYear.filter(
      (planYear) => planYear.Year == year
    );
    return partecipants.length > 0 && partecipants[0].Partecipants.length > 0
      ? partecipants[0].Partecipants
      : [];
  }
  /******************************************/
  getPartecipant(year, partecipant) {
    let partecipants = this.getPartecipantsByYear(year);
    let p = partecipants.find((x) => x.EmployeeId == partecipant.EmployeeId);
    return p;
  }

  /******************************************************************************/
  getBonusAccruedPartecipant(year: number, partecipant: any) {
    let n = 0;
    if (partecipant.Values) {
      n = partecipant.Values.Accrued.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusPayablePartecipantValue(year: number, partecipant: any) {
    let n = 0;
    if (partecipant.Values) {
      let item = partecipant.Values.PaybleDeferredArray.find(
        (x) => x.PayYear == year
      );
      if (item) n = item.Cash.value + item.Equity.value;
    }
    return n;
  }
  /*------------*/
  getBonusPayablePartecipant(year: number, partecipant: any) {
    let n = this.getBonusPayablePartecipantValue(year, partecipant);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusCashPartecipant(year: number, partecipant: any) {
    let n = 0;
    if (partecipant.Values) {
      let item = partecipant.Values.PaybleDeferredArray.find(
        (x) => x.PayYear == year
      );
      if (item) n = item.Cash.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusEquityPartecipant(year: number, partecipant: any) {
    let n = 0;
    if (partecipant.Values) {
      let item = partecipant.Values.PaybleDeferredArray.find(
        (x) => x.PayYear == year
      );
      if (item) n = item.Equity.value;
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusDeferredPartecipantValue(year: number, partecipant: any) {
    let n = 0;
    if (partecipant.Values) {
      let item = partecipant.Values.PaybleDeferredArray.find(
        (x) => x.PayYear == year
      );
      if (item) n = item.DeferredCash.value + item.DeferredEquity.value;
    }
    return n;
  }
  /*------------*/
  getBonusDeferredPartecipant(year: number, partecipant: any) {
    let n = this.getBonusDeferredPartecipantValue(year, partecipant);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusAccruedPartecipantValue(year: number): number {
    let n = 0;
    if (this.currentPlan && this.currentPlan.Bonuses) {
      let item = this.currentPlan.Bonuses.find((x) => x.Year == year);
      if (item) {
        n = item.Accrued.value;
      }
    }
    return n;
  }
  /*-------------------------*/
  getTotalBonusAccruedPartecipant(year: number) {
    let n = this.getTotalBonusAccruedPartecipantValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusPayablePartecipantValue(year: number): number {
    let n = 0;
    if (this.currentPlan && this.currentPlan.Bonuses) {
      let item = this.currentPlan.Bonuses.find((x) => x.Year == year);
      if (item) {
        let itm = item.Payable[year];
        if (itm) {
          n = item.Payable[year].Equity.value + item.Payable[year].Cash.value;
        }
      }
    }
    return n;
  }
  /*-------------------------*/
  getTotalBonusPayablePartecipant(year: number) {
    let n = this.getTotalBonusPayablePartecipantValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusCashPartecipant(year: number) {
    let n = 0;
    if (this.currentPlan && this.currentPlan.Bonuses) {
      let item = this.currentPlan.Bonuses.find((x) => x.Year == year);
      if (item) {
        n = item.Payable[year].Cash.value;
      }
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusEquityPartecipant(year: number) {
    let n = 0;
    if (this.currentPlan && this.currentPlan.Bonuses) {
      let item = this.currentPlan.Bonuses.find((x) => x.Year == year);
      if (item) {
        n = item.Payable[year].Equity.value;
      }
    }
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusDeferredPartecipant(year: number) {
    let n: number =
      this.getTotalBonusAccruedPartecipantValue(year) -
      this.getTotalBonusPayablePartecipantValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusDetailBankOpeningValue(year: number, partecipant: any) {
    if (this.currentPlan && year > this.currentPlan.YearStart) {
      var oldPartecipant = this.getPartecipant(year - 1, partecipant);
      return this.getBonusDetailBankClosingValue(year - 1, oldPartecipant);
    } else {
      return 0;
    }
  }
  /*---------------------------*/
  getBonusDetailBankOpening(year: number, partecipant: any) {
    let n = this.getBonusDetailBankOpeningValue(year, partecipant);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusDetailPastYearsPayableValue(year: number, partecipant: any) {
    let n = 0.0;
    if (this.currentPlan && this.currentPlan.PartecipantsByYear) {
      let items = this.currentPlan.PartecipantsByYear.filter(
        (x) => x.Year < year
      );
      items.forEach((element: any) => {
        let foundEmployee = element.Partecipants.find(
          (x) => x.EmployeeId == partecipant.EmployeeId
        );
        let payments = foundEmployee.Values.PaybleDeferredArray.filter(
          (x) => x.PayYear < year
        );
        if (payments) {
          payments.forEach((e: any) => {
            n += e.Cash.value + e.Equity.value;
          });
        }
      });
    }

    return n;
  }
  /*-------------------------*/
  getBonusDetailPastYearsPayable(year: number, partecipant: any) {
    let n = this.getBonusDetailPastYearsPayableValue(year, partecipant);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusDetailDeferred(year: number, partecipant: any) {
    return this.getBonusDeferredPartecipant(year, partecipant);
  }
  /******************************************************************************/
  getBonusDetailBankClosingValue(year: number, partecipant: any) {
    let n = 0;
    let base = 0;
    if (this.currentPlan) {
      if (this.currentPlan.CorrectiveType == 'Rolling') {
        base =
          this.getBonusDetailBankOpeningValue(year, partecipant) -
          this.getBonusDetailPastYearsPayableValue(year, partecipant) +
          this.getBonusDeferredPartecipantValue(year, partecipant);
      } else {
        if (year == this.currentPlan.PayoutStructure.PayoutYearEnd) {
          base =
            this.getBonusDetailBankOpeningValue(year, partecipant) -
            this.getBonusDetailPastYearsPayableValue(year, partecipant) +
            this.getBonusDeferredPartecipantValue(year, partecipant);
        } else {
          base = this.getBonusDetailBankOpeningValue(year, partecipant);
        }
      }
    }
    return base * this.getTotMultiplierValue('corrective', year);
  }
  /*-----------------------*/
  getBonusDetailBankClosing(year: number, partecipant: any) {
    let n = this.getBonusDetailBankClosingValue(year, partecipant);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusDetailTotalPayableValue(year: number, partecipant: any) {
    let n =
      this.getBonusPayablePartecipantValue(year, partecipant) +
      this.getBonusDetailPastYearsPayableValue(year, partecipant);
    return n;
  }
  /*-----------------------*/
  getBonusDetailTotalPayable(year: number, partecipant: any) {
    let n = this.getBonusDetailTotalPayableValue(year, partecipant);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusDetailCash(year: number, partecipant: any) {
    let n = this.getBonusDetailTotalPayableValue(year, partecipant);
    if (this.currentPlan) {
      let item = this.currentPlan.PayoutStructure.Rows.find(
        (x) => x.AccrueYear == year
      );
      if (item) {
        let py = item.PayoutValues.find((x) => x.PayoutYear == year);
        if (py) {
          n *= py.Cash / (py.Cash + py.Equity);
        } else {
          n = 0;
        }
      } else {
        n = 0;
      }
    }

    return this.formatNumber(n);
  }
  /******************************************************************************/
  getBonusDetailEquity(year: number, partecipant: any) {
    let n = this.getBonusDetailTotalPayableValue(year, partecipant);
    if (this.currentPlan) {
      let item = this.currentPlan.PayoutStructure.Rows.find(
        (x) => x.AccrueYear == year
      );
      if (item) {
        let py = item.PayoutValues.find((x) => x.PayoutYear == year);
        if (py) {
          n *= py.Equity / (py.Cash + py.Equity);
        } else {
          n = 0;
        }
      } else {
        n = 0;
      }
    }

    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusDetailBankOpening(year: number) {
    return this.getSummaryBonusBankOpening(year);
  }
  /******************************************************************************/
  getTotalBonusDetailPastYearsPayable(year: number) {
    return this.getSummaryPastYearPayable(year);
  }
  /******************************************************************************/
  getTotalBonusDetailDeferred(year: number) {
    return this.getTotalBonusDeferredPartecipant(year);
  }
  /******************************************************************************/
  getTotalBonusDetailBankClosing(year: number) {
    return this.getSummaryBonusBankClosing(year);
  }
  /******************************************************************************/
  getTotalBonusDetailTotalPayableValue(year: number) {
    let n =
      this.getTotalBonusPayablePartecipantValue(year) +
      this.getSummaryPastYearPayableValue(year);
    return n;
  }
  /*----------------------------*/
  getTotalBonusDetailTotalPayable(year: number) {
    let n = this.getTotalBonusDetailTotalPayableValue(year);
    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusDetailCash(year: number) {
    let n = this.getTotalBonusDetailTotalPayableValue(year);
    if (this.currentPlan) {
      let item = this.currentPlan.PayoutStructure.Rows.find(
        (x) => x.AccrueYear == year
      );
      if (item) {
        let py = item.PayoutValues.find((x) => x.PayoutYear == year);
        if (py) {
          n *= py.Cash / (py.Cash + py.Equity);
        } else {
          n = 0;
        }
      } else {
        n = 0;
      }
    }

    return this.formatNumber(n);
  }
  /******************************************************************************/
  getTotalBonusDetailEquity(year: number) {
    let n = this.getTotalBonusDetailTotalPayableValue(year);
    if (this.currentPlan) {
      let item = this.currentPlan.PayoutStructure.Rows.find(
        (x) => x.AccrueYear == year
      );
      if (item) {
        let py = item.PayoutValues.find((x) => x.PayoutYear == year);
        if (py) {
          n *= py.Equity / (py.Cash + py.Equity);
        } else {
          n = 0;
        }
      } else {
        n = 0;
      }
    }

    return this.formatNumber(n);
  }
  goBack() {
    this.router.navigate(['/TileMenu/menu/company-admin']);
  }
}
