import {
  AfterViewInit,
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManagerOkrPopupComponent } from '../manager-okr-popup/manager-okr-popup.component';
import { EngagementPopupComponent } from '../engagement-popup/engagement-popup.component';
import { EngagementPopupPlusComponent } from '../engagement-popup-plus/engagement-popup-plus.component';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { CoreService } from 'app/shared/services/core.service';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragEnter,
  CdkDropList,
  CdkDropListGroup,
  DragRef,
  moveItemInArray,
  transferArrayItem
} from '@angular/cdk/drag-drop';

@Component({
  selector: 'engagement',
  templateUrl: './engagement.component.html',
  styleUrls: ['./engagement.component.scss']
})
export class EngagementComponent implements OnInit, AfterViewInit {
  bsModalRef: BsModalRef;
  @Output() refreshRequired = new EventEmitter();
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  initialLoad = false;

  private target: CdkDropList = null;
  private targetIndex: number;
  private source: CdkDropList = null;
  private sourceIndex: number;
  private dragRef: DragRef = null;
  showHide: boolean = false;
  boxWidth = '200px';
  boxHeight = '200px';

  engagementData: any = [];
  deletedItem: any = [];
  constructor(
    private modalService: BsModalService,
    public pp360SpinnerService: Pp360SpinnerService,
    private coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.getManagerData();
  }

  ngAfterViewInit() {
    // const placeholderElement = this.placeholder.element.nativeElement;
    // placeholderElement.style.display = 'none';
    // placeholderElement.parentNode.removeChild(placeholderElement);
  }
  async getManagerData() {
    this.pp360SpinnerService.showSpinner(true);
    this.coreService.getManagerEnteryPointDetail().subscribe(
      async (data: any) => {
        this.engagementData = await data.Engagement;
        this.deletedItem = await data.HiddenBoxes.Engagement;
        // console.log(this.engagementData);
        this.pp360SpinnerService.showSpinner(false);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'manager', err.Message);
        this.pp360SpinnerService.showSpinner(false);
      }
    );
  }
  removeItem(item) {
    let body = [item.Value, item.Key];
    if (item) {
      this.pp360SpinnerService.showSpinner(true);
      this.coreService.deleteExcludeBox(body).subscribe(
        (data: any) => {
          this.getManagerData();
          this.pp360SpinnerService.showSpinner(false);
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'manager', err.Message);
          this.pp360SpinnerService.showSpinner(false);
        }
      );
    }
  }
  openPopup(item) {
    console.log('hii', item);
    this.coreService
      .getManagerEnteryPointBoxDetail(item.Key)
      .subscribe((data: any) => {
        console.log('data', data);
        if (data) {
          this.bsModalRef = this.modalService.show(EngagementPopupComponent, {
            class: 'okr-top-align-pop',
            ignoreBackdropClick: false,
            initialState: {
              response: data,
              type: data.Key
            }
          });
        }
        // this.bsModalRef.content.closeBtnName = "Close";
        this.showHide = false;
      });
    // this.bsModalRef = this.modalService.show(EngagementPopupComponent, {
    //   class: "okr-top-align-pop ",
    //   ignoreBackdropClick: false,
    //   initialState: {
    //     response: item,
    //     type: item.Key,
    //   },
    // });
    // this.bsModalRef.content.postEval.subscribe((data: any) => {
    //   this.refreshWall(data);
    //   this.bsModalRef.hide();
    // });
  }
  openPopupPlus() {
    this.bsModalRef = this.modalService.show(EngagementPopupPlusComponent, {
      class: 'top-align',
      ignoreBackdropClick: false,
      initialState: {
        response: this.deletedItem,
        type: 'engagement'
      }
    });
    this.bsModalRef.content.onClose.subscribe((data: any) => {
      if (data == 'added') {
        this.refreshWall(data);
        this.getManagerData();
        this.bsModalRef.hide();
      }
    });
    this.bsModalRef.content.closeBtnName = 'Close';
    this.showHide = false;
  }
  refreshWall(data) {
    this.refreshRequired.emit(data);
  }

  setBoxPriority() {
    const body = this.engagementData.map((eachOkr) => {
      return eachOkr.Key;
    });
    this.coreService.setPriorityBox(body).subscribe((data: any) => {
      console.log(data);
    });
  }

  onDropListDropped() {
    if (!this.target) {
      return;
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const placeholderParentElement: HTMLElement =
      placeholderElement.parentElement;

    placeholderElement.style.display = 'none';

    placeholderParentElement.removeChild(placeholderElement);
    placeholderParentElement.appendChild(placeholderElement);
    placeholderParentElement.insertBefore(
      this.source.element.nativeElement,
      placeholderParentElement.children[this.sourceIndex]
    );

    if (this.placeholder._dropListRef.isDragging()) {
      this.placeholder._dropListRef.exit(this.dragRef);
    }

    this.target = null;
    this.source = null;
    this.dragRef = null;

    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.engagementData, this.sourceIndex, this.targetIndex);
      this.setBoxPriority();
    }
  }

  onDropListEntered({ item, container }: CdkDragEnter) {
    if (container == this.placeholder) {
      return;
    }

    const placeholderElement: HTMLElement =
      this.placeholder.element.nativeElement;
    const sourceElement: HTMLElement = item.dropContainer.element.nativeElement;
    const dropElement: HTMLElement = container.element.nativeElement;
    const dragIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      this.source ? placeholderElement : sourceElement
    );
    const dropIndex: number = Array.prototype.indexOf.call(
      dropElement.parentElement.children,
      dropElement
    );

    if (!this.source) {
      if (!this.initialLoad) {
        this.sourceIndex = dragIndex - 1;
      } else {
        this.sourceIndex = dragIndex;
      }

      this.source = item.dropContainer;

      placeholderElement.style.width = this.boxWidth + 'px';
      placeholderElement.style.height = this.boxHeight + 40 + 'px';

      sourceElement.parentElement.removeChild(sourceElement);
    }
    if (!this.initialLoad) {
      this.targetIndex = dropIndex - 1;
      this.initialLoad = true;
    } else {
      this.targetIndex = dropIndex;
    }

    this.target = container;
    this.dragRef = item._dragRef;

    placeholderElement.style.display = '';

    dropElement.parentElement.insertBefore(
      placeholderElement,
      dropIndex > dragIndex ? dropElement.nextSibling : dropElement
    );

    this.placeholder._dropListRef.enter(
      item._dragRef,
      item.element.nativeElement.offsetLeft,
      item.element.nativeElement.offsetTop
    );
  }
}
