import {
  Component,
  OnInit,
  AfterContentChecked,
  Renderer2,
  EventEmitter,
  Output
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from '../../../super-admin/user/user.service';
import { CoreService } from 'app/shared/services/core.service';
import { Router } from '@angular/router';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {
  userInfo: any;
  isActionProcessing: boolean = false;
  show1: boolean = false;
  show2: boolean = false;
  show3: boolean = false;
  errorCorrect = false;
  checkNew: boolean = false;
  comparePassword: boolean = false;
  checkPass: boolean = false;
  public isMobile: boolean;
  showerror: boolean;
  showerr: boolean = false;
  showerrors: boolean = false;
  @Output()
  message: EventEmitter<string> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    public service: UserService,
    public coreService: CoreService,
    private pp360SpinnerService: Pp360SpinnerService,
    private router: Router,
    public screenSizeService: ScreenSizeService,
    private renderer: Renderer2,
    private modalService: BsModalService
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 800;
    });
  }

  ngOnInit() {
    this.userInfo = {
      oldPassword: '',
      newPassword: '',
      confirmPassword: ''
    };
  }
  get isUppercase() {
    var pwd = this.userInfo.newPassword;
    var hasUpper = /[A-Z]/.test(pwd);
    return hasUpper;
  }

  get isLowerCase() {
    var pwd = this.userInfo.newPassword;
    var hasLower = /[a-z]/.test(pwd);
    return hasLower;
  }

  get isNumber() {
    var pwd = this.userInfo.newPassword;
    var hasNumber = /[0-9]/.test(pwd);
    return hasNumber;

    this.show1 = false;
    this.show2 = false;
    this.show3 = false;
  }

  onLogout() {
    const userInfo = this.coreService.getUserInfo();
    localStorage.clear();
    this.router.navigate(['login'], {
      queryParams: { isForgotPassword: true, email: userInfo.email }
    });
  }

  onSubmit(form) {
    this.errorCorrect = false;
    if (form.form.valid) {
      this.isActionProcessing = true;
      this.pp360SpinnerService.showSpinner();
      this.service.changePassword(this.userInfo).subscribe(
        (data: any) => {
          this.isActionProcessing = false;
          this.coreService.toasterMessage(
            'success',
            'Change Password',
            this.coreService.getTranslation('Messages.PasswordUpdate')
          );
          this.bsModalRef.hide();
          this.pp360SpinnerService.showSpinner(false);
        },
        (err: any) => {
          this.isActionProcessing = false;
          this.errorCorrect = true;
          this.pp360SpinnerService.showSpinner(false);
        }
      );
    }
  }
  reset(form) {
    form.reset();
    this.checkNew = false;
    this.comparePassword = false;
    this.checkPass = false;
  }
  back() {
    this.message.emit('true');
  }
  onForgotPassword() {
    this.router.navigate(['login']);
  }
  myFocusNewPass() {
    this.renderer.selectRootElement('#newPassword').focus();
  }
  myFocusCurrentPass() {
    this.renderer.selectRootElement('#oldPassword').focus();
  }
  myFocusPass() {
    this.renderer.selectRootElement('#confirmPassword').focus();
  }
  checkCondition() {
    this.checkNew = false;
    this.comparePassword = false;
    if (this.userInfo.newPassword.length != 0) {
      if (
        this.userInfo.newPassword?.length > 5 &&
        this.isUppercase &&
        this.isLowerCase &&
        this.isNumber
      ) {
        this.checkNew = false;
        if (this.userInfo.newPassword == this.userInfo.oldPassword) {
          this.comparePassword = true;
        } else {
          this.comparePassword = false;
        }
      } else {
        this.checkNew = true;
      }
    }
  }

  checkPassword() {
    if (
      this.userInfo.confirmPassword.length != 0 &&
      this.userInfo.confirmPassword != this.userInfo.newPassword
    ) {
      this.checkPass = true;
    } else {
      this.checkPass = false;
    }
  }
}
