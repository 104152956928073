import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-new-evaluation',
  templateUrl: './new-evaluation.component.html',
  styleUrls: ['./new-evaluation.component.css']
})
export class NewEvaluationComponent implements OnInit {
  isAfterContentChecked: boolean = false;
  showMore = false;
  evaluation_Employees: any;
  user_Info: any;
  numberOfSkills = 0;
  settingsEmployees = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };

  throttle = 50;
  scrollDistance = 2;
  scrollUpDistance = 2;

  dropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };
  targetEmployeeId: any;
  selectedEmployee: any;
  isSelectedSkills: boolean = false;
  isSkillProcessing: boolean = true;
  showDiv: boolean = false;
  skills: any;
  isSubmit: boolean = false;
  checkBoxValue: boolean = false;
  currentEmployeeID: any;
  selectedEmployees: any;
  selectedSkill: any;
  selectedSkillMap: any;
  selectedSkillIds: any;
  targetEmployee: any;
  SelectedEmployee: any;
  tempArr = [];
  data = [];
  isRecieved: boolean = false;
  isOpen = true;
  isPreselected: boolean = false;
  @Output() postEval = new EventEmitter();
  eval_arr: any[] = [];
  evalArr: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    private sharedService: SharedService,
    private coreService: CoreService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.user_Info = this.coreService.getUserInfo();

    this.user_Info.EvaluationScale.Values.forEach((element: any) => {
      let trans = element.Translations.find(
        (x) => x.LanguageCode === this.user_Info.language
      );
      let desc = element.Description;
      let label = element.Name;
      if (trans) {
        desc = trans.Description;
        label = trans.Name;
      }
      this.eval_arr.push({
        rate: element.Value,
        activeRate: false,
        label: label,
        description: desc
      });

      this.evalArr.push({
        rate: element.Value,
        activeRate: 0,
        label: label,
        description: desc
      });
    });

    this.currentEmployeeID = this.user_Info.linkedEmployeeId;
  }

  getNumberOfAnsered() {
    var count = 0;
    var item = this.evaluation_Employees[0];
    item.AskSkills.forEach((el) => {
      if (el['Amount'] > 0) count++;
    });
    return count;
  }

  ngAfterContentChecked() {
    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      this.evaluation_Employees =
        this.bsModalRef['content'].evaluation_Employees || [];
      this.selectedEmployees = this.evaluation_Employees;
      this.evaluation_Employees.forEach((item: any) => {
        if (item.isPreselect === true) {
          if (item.FirstName) {
            item['Name'] = item.SecondName + ' ' + item.FirstName;
          }
          this.selectedEmployee = item;
          this.isSelectedSkills = true;
          // this.isPreselected = true;
          this.getSkills(this.currentEmployeeID, item.Id);
        }

        if (item.Type === 'ReceivedAsk') {
          console.log('item received');
          this.isRecieved = true;

          item.AskSkills.forEach((el) => {
            el['ratingOnSKill'] = JSON.parse(JSON.stringify(this.eval_arr));
            el['Amount'] = 0;
            this.numberOfSkills++;
          });

          this.targetEmployee = item;
          for (let i = 0; i < item.AskSkills.length; i++) {
            this.data.push(item.AskSkills[i]);
          }
        }
      });
      this.isAfterContentChecked = true;
    }
  }

  onSelectEmployeeChange(event) {
    this.targetEmployeeId = this.selectedEmployees;
    this.getSkills(this.currentEmployeeID, this.targetEmployeeId);
  }

  onSelectSkillChange(event) {
    var feedbackMap = {};
    if (this.selectedSkill) {
      this.selectedSkill.forEach((element: any) => {
        feedbackMap[element.Id] = element.Amount;
      });
    }
    this.selectedSkill = [];
    this.selectedSkillMap = {};
    this.selectedSkillIds.forEach((skId) => {
      var foundSkill;
      this.skills.forEach((sk) => {
        if (sk.Id === skId) {
          foundSkill = sk;
          foundSkill.ratingOnSKill = JSON.parse(JSON.stringify(this.eval_arr));
          var v = feedbackMap[skId];
          if (v) {
            for (let i = 0; i <= v - 1; i++) {
              foundSkill.ratingOnSKill[i].activeRate = true;
            }
            foundSkill.Amount = v;
          } else foundSkill.Amount = 0;

          this.selectedSkill.push(foundSkill);
          this.selectedSkillMap[foundSkill.Id] = foundSkill;
        }
      });
    });

    this.showDiv = true;
  }

  getSkills(currentEmployeeID, employeeId) {
    this.sharedService
      .evaluationSkills(currentEmployeeID, employeeId)
      .subscribe(
        (data: any) => {
          this.isSkillProcessing = false;
          this.skills = data;
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
  }

  giveRating(skill, index) {
    if (skill.ratingOnSKill[index].activeRate == false) {
      skill.ratingOnSKill.forEach((ele) => {
        ele.activeRate = false;
      });
      for (let i = 0; i <= index; i++) {
        skill.ratingOnSKill[i].activeRate = true;
      }
      skill['Amount'] = index + 1;
    } else {
      skill.ratingOnSKill.forEach((ele) => {
        ele.activeRate = false;
      });
      skill['Amount'] = 0;
    }
  }

  value(val) {
    this.checkBoxValue = val.target.checked;
  }

  postEvaluation(reqBody) {
    this.sharedService.postEvaluation(reqBody).subscribe(
      (data: any) => {
        data['ratingOnSkill'] = JSON.parse(JSON.stringify(this.evalArr));
        for (let i = 0; i < data.OverAll; i++) {
          data.ratingOnSkill[i]['activeRate'] = 1;
        }

        let half = Math.floor(data.OverAll / 0.5 + 0.5);
        let round = Math.round(data.OverAll) / 0.5;
        data['RoundedValue'] = data.OverAll.toFixed(1);
        data['valueClass'] = 'badValue';
        if (data.RoundedValue > 2) {
          data['valueClass'] = 'mediumValue';
        }
        if (data.RoundedValue > 3.4) {
          data['valueClass'] = 'goodValue';
        }
        data['Stars'] = Math.round(data.OverAll);
        data['HasHalf'] = half > round;

        for (let i = 0; i < data.Stars; i++) {
          data.ratingOnSkill[i].activeRate = 2;
        }
        if (data.HasHalf) {
          data.ratingOnSkill[data.Stars].activeRate = 1;
        }
        this.emptySelected();
        this.postEval.emit(data);
        this.bsModalRef.hide();
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  emptySelected() {
    this.evaluation_Employees.forEach((element) => {
      element.selected = false;
    });
  }

  onSubmit(form) {
    this.isSubmit = true;
    let reqBody = {};
    const modal = this.modalService.show(ConfirmDialogComponent, {
      class: 'modal-md'
    });
    (<ConfirmDialogComponent>modal.content).showConfirmationModal('submitted');
    (<ConfirmDialogComponent>modal.content).onClose.subscribe((result: any) => {
      if (result) {
        if (form.feedback == undefined) form.feedback = '';
        let skillarr = [];
        if (form.sel_skill != undefined) {
          form.sel_skill.forEach((item: any) => {
            var foundSkill = this.selectedSkillMap[item];
            skillarr.push({
              Skill: {
                Id: foundSkill.Id
              },
              Amount: foundSkill.Amount
            });
          });
        } else {
          this.targetEmployee.AskSkills.forEach((item: any) => {
            skillarr.push({
              Skill: {
                Id: item.Id
              },
              Amount: item.Amount
            });
          });
        }

        if (this.selectedEmployee != undefined) {
          this.targetEmployeeId = this.selectedEmployee.Id;
        }
        if (this.targetEmployee != undefined) {
          if (
            this.targetEmployee.Type === 'GivenEvaluation' ||
            this.targetEmployee.Type === 'GivenToken'
          ) {
            this.targetEmployeeId = this.targetEmployee.Target.Id;
          }
          if (
            this.targetEmployee.Type === 'ReceivedEvaluation' ||
            this.targetEmployee.Type === 'ReceivedToken' ||
            this.targetEmployee.Type === 'ReceivedAsk'
          ) {
            this.targetEmployeeId = this.targetEmployee.Author.Id;
          }
        }
        if (
          this.targetEmployee == undefined &&
          form.sel_emp_team !== undefined
        ) {
          this.targetEmployeeId = form.sel_emp_team;

          reqBody = {
            Author: {
              Id: this.currentEmployeeID
            },
            Target: {
              Id: this.targetEmployeeId
            },
            IsAnonymous: this.checkBoxValue,
            Description: form.feedback,
            Evaluations: skillarr
          };
        } else if (this.targetEmployee !== undefined) {
          if (
            this.targetEmployee.Type === 'ReceivedAsk' ||
            this.targetEmployee.Type === 'SentAsk'
          ) {
            reqBody = {
              Author: {
                Id: this.currentEmployeeID
              },
              AskId: this.targetEmployee.AskId,
              Target: {
                Id: this.targetEmployeeId
              },
              IsAnonymous: this.checkBoxValue,
              Description: form.feedback,
              Evaluations: skillarr
            };
          }
        } else {
          reqBody = {
            Author: {
              Id: this.currentEmployeeID
            },
            Target: {
              Id: this.targetEmployeeId
            },
            IsAnonymous: this.checkBoxValue,
            Description: form.feedback,
            Evaluations: skillarr
          };
        }

        this.postEvaluation(reqBody);
      }
    });
  }

  isDisabled() {
    if (!this.isRecieved) {
      var disabled =
        this.isSubmit ||
        !this.selectedEmployees ||
        !this.selectedEmployees.length ||
        !this.selectedSkillIds ||
        !this.selectedEmployees.length;
      if (!disabled) {
        this.selectedSkill.forEach((item: any) => {
          if (item.Amount == 0) {
            disabled = true;
            return;
          }
        });
      }
    } else {
      this.data.forEach((item: any) => {
        if (item.Amount == 0) {
          disabled = true;
          return;
        }
      });
    }

    return disabled;
  }
}
