import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: [
    './language-selector.component.scss',
    '../../../common_styles.scss'
  ]
})
export class LanguageSelectorComponent implements OnInit {
  @Input() languages: any;
  @Input() hideTooltip: boolean = false;
  @Input() maxShowedItems: number;
  @Input() forcedLanguage: any;
  slideConfig: any;

  @Output() onSelectLanguage = new EventEmitter();

  selectedLang: any;
  userInfo: any;
  languagesSorted: any;

  constructor(
    public coreService: CoreService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.userInfo = this.coreService.getUserInfo();
    if (this.forcedLanguage) {
      this.selectedLang = this.forcedLanguage;
    } else {
      this.selectedLang = this.userInfo.language;
    }

    this.slideConfig = {
      slidesToShow: this.maxShowedItems,
      slidesToScroll: this.maxShowedItems,
      infinite: false
    };
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.forcedLanguage)
      this.selectedLang = changes.forcedLanguage.currentValue;
  }

  selectLanguage(language: any) {
    this.selectedLang = language.Code;
    this.onSelectLanguage.emit(language.Code);
  }

  getSortedLanguage() {
    return this.languages.sort((a: any, b: any) =>
      a.Code === this.userInfo.defaultlanguage ? -1 : 0
    );
  }

  getTooltip(language: any) {
    if (this.hideTooltip) return;
    let tooltip: string = '';
    if (this.userInfo.defaultLanguage !== language.Code) {
      tooltip = language.Code;
    } else {
      tooltip =
        this.translate.instant('Generic.DefaultLanguage') +
        ' ' +
        this.userInfo.defaultlanguage;
    }
    return tooltip;
  }
}
