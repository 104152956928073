import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { companyService } from 'app/super-admin/company/company.service';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';

@Component({
  selector: 'app-clone-cycle-dialog',
  templateUrl: './clone-cycle-dialog.component.html',
  styleUrls: [
    './clone-cycle-dialog.component.scss',
    '../../common_styles.scss',
    '../../magic-checkbox.scss'
  ]
})
export class CloneCycleDialogComponent implements OnInit {
  public onClose: Subject<any>;
  cycles: any;
  selectedCycle: any;
  showClone: any = {};

  settings: any = {
    selectAllText: '',
    unSelectAllText: '',
    enableSearchFilter: false,
    enableFilterSelectAll: false,
    classes: 'cyclesDropDown',
    showCheckbox: false,
    singleSelection: true
  };

  cloneDialogValue: string;

  public constructor(
    private _bsModalRef: BsModalRef,
    private srv: companyService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  selectCloneType(type: string) {
    if (this.cycles.length === 0) return;
    this.cloneDialogValue = type;
  }

  viewMode: string;
  currentCycle: any;
  public showModal(content: any): void {
    this.viewMode = content.viewMode;
    this.currentCycle = content.currentCycle;
    this.showClone = content.showClone;

    if (this.showClone.canCloneToThis) {
      this.cloneDialogValue = 'this';
    } else {
      this.cloneDialogValue = 'other';
    }
    this.pp360SpinnerService.showSpinner(true);
    this.srv.getCompanyCycles(true).subscribe((result: any) => {
      // console.log('loadCycles', result);

      if (this.viewMode === 'MANAGER') {
        const now = new Date();
        const todayTS = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate()
        ).getTime();

        this.cycles = result.filter((c: any) => {
          const ManagerDefinitionIntervalTS = new Date(
            c.ManagerDefinitionInterval.End
          ).getTime();
          return (
            c.Id !== this.currentCycle.Id &&
            todayTS <= ManagerDefinitionIntervalTS &&
            c.Id !== this.currentCycle.Id
          );
        });
      } else {
        this.cycles = result.filter((c: any) => c.Id !== this.currentCycle.Id);
      }
      this.pp360SpinnerService.showSpinner(false);
    });
  }

  public onConfirm(go: boolean): void {
    this.onClose.next({
      clone: true,
      selectedCycle: this.selectedCycle,
      go: go,
      target: this.cloneDialogValue
    });
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.onClose.next({ clone: false });
    this._bsModalRef.hide();
  }
}
