import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  TemplateRef,
  Input,
  HostListener,
  ViewChild,
  ElementRef,
  Renderer2
} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { ConfirmDialogComponent } from 'app/shared/confirm-dialog/confirm-dialog.component';
import { IosAutocompleteComponent } from '../ios-autocomplete/ios-autocomplete.component';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { Subscription } from 'rxjs';
import { Subject } from 'rxjs';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-evaluation',
  templateUrl: './evaluation.component.html',
  styleUrls: [
    './evaluation.component.scss',
    '../../../common_styles.scss',
    '../common_popup_styles.scss',
    '../../../magic-checkbox.scss'
  ],
  providers: [
    Keyboard,
    {
      provide: BsDropdownConfig,
      useValue: { autoClose: true }
    }
  ]
})
export class EvaluationComponent implements OnInit {
  @Input() evaluationEmployees = [];
  @Input() selectedFeedback;
  iOS: boolean =
    !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  filteredSkills: any[];
  filteredBehaviors: any[];
  filteredGoals: any[];
  evaluationActivities: any[];
  globalEvaluationActivities: any[];
  isAfterContentChecked: boolean = false;
  showMore = false;
  evaluation_Employees: any;
  evaluation_select_Employees: any;
  type: string;
  user_Info: any;
  valueCheck: boolean = false;
  selectSkill: any;
  selctArray: any = [];
  openSkill: boolean = false;
  searchSkillText: any = '';
  numberOfSkills = 0;
  show = false;
  showMandatory: boolean = false;
  numberOfBehaviors = 0;
  settingsEmployees = {
    singleSelection: true,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true,
    closeDropDownOnSelection: true
  };

  subscriptionOnHidden: Subscription;

  throttle = 50;
  scrollDistance = 2;
  scrollUpDistance = 2;
  feedback: string;

  dropdownSettings = {
    singleSelection: false,
    idField: 'Id',
    textField: 'Name',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    allowSearchFilter: true
  };
  evalMendatory: boolean = false;
  targetEmployeeId: any;
  selectedEmployee: any;
  selectedEmployeeName: string;
  isSelectedSkills: boolean = false;
  isSelectedBehaviors: boolean = false;
  isSkillProcessing: boolean = true;
  isBehaviorsProcessing: boolean = true;
  isGoalProcessing: boolean = true;
  isActivityProcessing: boolean = true;
  selectedActivityId: any;
  askId: any;
  activityName = '';
  showDiv: boolean = false;
  skills: any;
  behaviors: any;
  goals: any;
  isSubmit: boolean = false;
  checkBoxValue: boolean = false;
  currentEmployeeID: any;
  selectedSkill: any;
  selectedSkillMap: any;
  selectedSkillIds: any;
  selectedBehavior: any;
  selectedBehaviorMap: any;
  selectedBehaviorIds: any;
  targetEmployee: any;
  SelectedEmployee: any;
  selectedGoal: any;
  selectedGoalObj: any;
  tempArr = [];
  data = [];
  activityFName: any;
  isRecieved: boolean = false;
  isOpen = true;
  evaluatedData: any = [];
  isPreselected: boolean = false;
  @Output() postEval = new EventEmitter();
  @Output() updateEval = new EventEmitter();
  @Output() message: EventEmitter<string> = new EventEmitter();
  @Output() checkDefine: EventEmitter<boolean> = new EventEmitter();

  eval_arr: any[] = [];
  evalArr: any[] = [];

  selectedFilterSkill: any;
  selectedFilterBehavior: any;
  radioType = 'skill';
  isAnonymous: boolean = false;

  selectedItemIOS: any[] = [];
  iosModalRef: any;
  isKeyResultss: boolean = false;
  public onClose: Subject<any>;
  public refreshWallRequired: Subject<any>;

  searchSkillTexts: any;
  radioTypes: string;
  sLabel: any = '';
  dataold: any[];
  check: boolean = true;
  reloadAct: boolean = true;
  //search
  searchable: boolean;
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  searchManually: boolean;

  constructor(
    private _renderer: Renderer2,
    public bsModalRef: BsModalRef,
    private sharedService: SharedService,
    private coreService: CoreService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {}

  ngOnInit() {
    this.onClose = new Subject();
    this.refreshWallRequired = new Subject();
    this.user_Info = this.coreService.getUserInfo();
    this.user_Info.EvaluationScale.Values.forEach((element: any) => {
      let trans = element.Translations.find(
        (x) => x.LanguageCode === this.user_Info.language
      );
      let desc = element.Description;
      let label = element.Name;
      if (trans) {
        desc = trans.Description;
        label = trans.Name;
      }
      this.eval_arr.push({
        rate: element.Value,
        activeRate: false,
        label: label,
        description: desc
      });

      this.evalArr.push({
        rate: element.Value,
        activeRate: 0,
        label: label,
        description: desc
      });
    });

    this.currentEmployeeID = this.user_Info.linkedEmployeeId;
    if (this.selectedFeedback?.PostSubject == 2) {
      this.isKeyResults = true;
    }
    if (
      this.selectedFeedback &&
      this.selectedFeedback?.Type !== 'ReceivedAsk'
    ) {
      // if (!this.selectedFeedback.Evaluations.Skill.IsBehavior) {
      //   this.radioType = 'skill';
      //   this.evaluation_select_Employees.AskSkills.forEach(el => {
      //     el['ratingOnSKill'] = JSON.parse(JSON.stringify(this.eval_arr));
      //     el['Amount'] = 0;
      //     this.numberOfSkills++;
      //   });
      //   this.filteredSkills = this.evaluation_select_Employees.AskSkills;
      // }
      // else if (this.selectedFeedback.Evaluations.Skill.IsBehavior) {
      //   console.log("behavior");

      //   this.radioType = 'behavior';
      //   this.behaviors = this.evaluation_select_Employees.AskBehaviors;
      //   this.behaviors.forEach((behavior: any) => {
      //     behavior.ratingOnSKill = JSON.parse(JSON.stringify(this.eval_arr));
      //     behavior.Amount = 0;
      //   });
      //   this.filteredBehaviors = JSON.parse(JSON.stringify(this.behaviors));
      //   console.log("1223",this.radioType);

      // }
      // else {
      //   this.radioType = 'goal';
      //   this.evaluation_select_Employees.AskGoals.forEach(el => {
      //     el['ratingOnGoal'] = JSON.parse(JSON.stringify(this.eval_arr));
      //     el['Amount'] = 0;
      //     this.numberOfSkills++;
      //   });
      //   this.goals = this.evaluation_select_Employees.AskGoals;
      //   this.filteredGoals = JSON.parse(JSON.stringify(this.goals));
      // }
      // this.isSelectedSkills = true;

      if (this.selectedFeedback.Activity) {
        this.activityFName = this.selectedFeedback.Activity.Name;
      }
      this.selctArray = this.selectedFeedback.Evaluations;
      this.onBtnClick(this.selctArray);
      if (this.selctArray.length) {
        if (this.selctArray[0].Goal) {
          this.filteredGoals = this.selectedFeedback.Evaluations;
          this.filteredGoals[0].isOpen = true;
          if (this.selctArray.length === 1) {
            this.searchSkillText = this.selctArray[0].Goal.Name;
            this.isPreselected = true;
          } else {
            this.checkEvaluted();
            // this.searchSkillText = `${this.selctArray.length} ` + this.coreService.getTranslation("skillsEvaluted");
          }
        } else {
          this.filteredSkills = this.selectedFeedback.Evaluations;
          this.filteredSkills[0].isOpen = true;
          if (this.selctArray.length === 1) {
            this.searchSkillText = this.selctArray[0].Skill.Name;
            this.isPreselected = true;
          } else {
            this.checkEvaluted();
            // this.searchSkillText = `${this.selctArray.length} `+ this.coreService.getTranslation("skillsEvaluted");
          }
        }
      } else if (this.selctArray.length == 0) {
        this.searchSkillText = '';
      }
      this.show = true;
      this.openSkill = true;
    }
    this.dataold = this.filteredSkills;
  }
  checkEvaluted() {
    for (let filterData of this.selctArray) {
      this.evaluatedData.push(filterData);
    }
    this.evaluatedData = [...new Set(this.evaluatedData)];

    if (!this.searchable) {
      if (this.selctArray.length == 1) {
        this.searchSkillText = this.selctArray[0].Name;
      }
      if (this.selctArray.length > 1) {
        if (this.selectedFeedback) {
          if (this.radioType == 'goal') {
            this.searchSkillText =
              this.coreService.getTranslation('objectivesEvaluated_Pre') +
              `${this.selctArray.length} ` +
              this.coreService.getTranslation('objectivesEvaluated_Post');
          } else if (this.radioType == 'behavior') {
            this.searchSkillText =
              this.coreService.getTranslation('behaviorsEvaluated_Pre') +
              `${this.selctArray.length} ` +
              this.coreService.getTranslation('behaviorsEvaluated_Post');
          } else {
            this.searchSkillText =
              this.coreService.getTranslation('skillsEvaluted_Pre') +
              `${this.selctArray.length} ` +
              this.coreService.getTranslation('skillsEvaluted_Post');
          }
        } else {
          if (this.radioType == 'goal') {
            this.searchSkillText =
              this.coreService.getTranslation('objectivesEvaluated_Pre') +
              `${this.selctArray.length} ` +
              this.coreService.getTranslation('objectivesEvaluated_Post_s');
          } else if (this.radioType == 'behavior') {
            this.searchSkillText =
              this.coreService.getTranslation('behaviorsEvaluated_Pre') +
              `${this.selctArray.length} ` +
              this.coreService.getTranslation('behaviorsEvaluated_Post_s');
          } else {
            this.searchSkillText =
              this.coreService.getTranslation('skillsEvaluted_Pre') +
              `${this.selctArray.length} ` +
              this.coreService.getTranslation('skillsEvaluted_Post_s');
          }
        }
      }
    }

    return this.searchSkillText;
  }
  valueAnonymous(val) {
    this.isAnonymous = val.target.checked;
  }

  getNumberOfAnsered() {
    var count = 0;
    var item = this.evaluation_Employees[0];
    item.AskSkills.forEach((el) => {
      if (el['Amount'] > 0) count++;
    });
    return count;
  }

  isKeyResults: boolean = false;
  selectedActivity: any;
  refreshRadioSelection() {
    if (this.user_Info.hasSkills) {
      this.radioType = 'skill';
      this.isKeyResults = false;
      this.evaluationActivities = this.globalEvaluationActivities;
      this.selectedGoalObj = undefined;
    } else if (this.user_Info.hasObjectives) {
      this.radioType = 'goal';
      this.isKeyResults = false;
      this.evaluationActivities = [];
      this.selectedGoalObj = [];
      this.selectedActivity = undefined;
      this.selectedActivityId = undefined;
    } else if (this.user_Info.hasBehaviors) {
      this.radioType = 'behavior';
      this.isKeyResults = false;
      this.evaluationActivities = this.globalEvaluationActivities;
      this.selectedGoalObj = undefined;
    }
    if (this.selectedFeedback) {
      const isObj = this.selectedFeedback?.Evaluations.find((e) => e.Goal);
      if (isObj && this.selectedFeedback.PostSubject == 2) {
        this.radioType = 'goal';
        this.filteredGoals = this.selectedFeedback.Evaluations;
      }
      if (this.selectedFeedback.PostSubject == 1) {
        this.radioType = 'behavior';
        this.filteredBehaviors = this.selectedFeedback.Evaluations;
      }
      if (this.selectedFeedback.PostSubject == 0) {
        this.radioType = 'skill';
        this.filteredSkills = this.selectedFeedback.Evaluations;
      }
    }
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    if (!this.selectedFeedback && this.show) {
      this.show = false;
      this.searchable = false;
      if (this.radioType === 'skill' && this.searchable) {
        this.filteredSkills = JSON.parse(JSON.stringify(this.skills));
        this.filteredSkills.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
            ? -1
            : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : 0;
        });
      } else if (this.radioType === 'behavior' && this.searchable) {
        this.filteredBehaviors = JSON.parse(JSON.stringify(this.behaviors));
        this.filteredBehaviors.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
            ? -1
            : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : 0;
        });
      } else if (this.radioType === 'goal' && this.searchable) {
        this.filteredGoals = this.selectedFeedback.Evaluations;
        this.filteredGoals.sort((a: any, b: any) => {
          return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
            ? -1
            : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
              ? 1
              : 0;
        });
      }
    }
  }
  initData() {
    this.evaluation_Employees = this.evaluationEmployees || [];
    this.evaluation_select_Employees = this.selectedFeedback || [];
    this.selectedEmployee =
      this.evaluation_Employees.length == 1
        ? this.evaluation_Employees[0]
        : undefined;
    this.selectedEmployeeName =
      this.evaluation_Employees.length == 1
        ? this.evaluation_Employees[0].Name
        : undefined;
    var isReceivedAsk = false;
    // console.log("248",this.evaluation_Employees);
    // console.log("249",this.evaluation_select_Employees);

    if (this.evaluation_select_Employees.Type === 'ReceivedAsk') {
      this.isRecieved = true;
      // console.log(this.evaluation_select_Employees);
      this.searchSkillTexts = this.evaluation_select_Employees.Author.FullName;

      if (this.evaluation_select_Employees.Activity) {
        this.activityName = this.evaluation_select_Employees.Activity.Name;
        this.selectedActivityId = this.evaluation_select_Employees.Activity.Id;
      }
      this.askId = this.evaluation_select_Employees.AskId;
      this.selectedEmployee = this.evaluation_select_Employees.Author;
      this.targetEmployee = this.evaluation_select_Employees.Author;
      this.targetEmployeeId = this.targetEmployee.Id;
      this.selectedEmployeeName = this.evaluation_select_Employees.Author.Name;
      isReceivedAsk = true;
      if (this.evaluation_select_Employees.AskSkills) {
        this.radioType = 'skill';
        this.evaluation_select_Employees.AskSkills.forEach((el) => {
          el['ratingOnSKill'] = JSON.parse(JSON.stringify(this.eval_arr));
          el['Amount'] = 0;
          this.numberOfSkills++;
        });
        this.filteredSkills = this.evaluation_select_Employees.AskSkills;
      } else if (this.evaluation_select_Employees.AskBehaviors) {
        this.radioType = 'behavior';
        this.behaviors = this.evaluation_select_Employees.AskBehaviors;
        this.behaviors.forEach((behavior: any) => {
          behavior.ratingOnSKill = JSON.parse(JSON.stringify(this.eval_arr));
          behavior.Amount = 0;
        });
        this.filteredBehaviors = JSON.parse(JSON.stringify(this.behaviors));
      } else {
        this.radioType = 'goal';
        this.evaluation_select_Employees.AskGoals.forEach((el) => {
          el['ratingOnGoal'] = JSON.parse(JSON.stringify(this.eval_arr));
          el['Amount'] = 0;
          this.numberOfSkills++;
        });
        this.goals = this.evaluation_select_Employees.AskGoals;
        this.filteredGoals = JSON.parse(JSON.stringify(this.goals));
      }
      this.isSelectedSkills = true;
    } else {
      // this.isRecieved = false;
      // console.log(this.evaluation_select_Employees);
      // this.behaviors = this.evaluation_select_Employees.Evaluations;
      //   this.behaviors.forEach((behavior: any) => {
      //     console.log(behavior);
      //     if(behavior.Skill){
      //       if(behavior.Skill.IsBehavior){
      //       this.radioType = 'behavior';
      //       }
      //     }
      //   });
      //   this.filteredBehaviors = JSON.parse(JSON.stringify(this.behaviors));
      // this.evaluation_select_Employees.Evaluations.forEach((elem:any)=>{
      //   console.log(elem);
      //   if(elem.Skill){
      //     if(elem.Skill.IsBehavior){
      //     // this.radioType = 'behavior';
      //     // this.behaviors = elem;
      //     // this.behavior.ratingOnSKill = JSON.parse(JSON.stringify(this.eval_arr));
      //     // this.behavior.Amount = 0;
      //   } else {
      //     this.radioType = 'skill';
      //   }
      // }
      //   if(elem.Goal){
      //     this.radioType = 'goal';
      //   }
      // })
      // console.log(this.radioType);
    }

    this.evaluation_Employees.forEach(async (item: any) => {
      if (item.isPreselect === true && item.Type === 'Employee') {
        if (item.FirstName) {
          item['Name'] = item.FirstName + ' ' + item.SecondName;
        }
        this.selectedEmployee = item;
        this.targetEmployeeId = item.Id;
        this.isSelectedSkills = true;
        this.isSelectedBehaviors = true;
        this.isPreselected = true;
        await this.getSkills(this.currentEmployeeID, item.Id);
        await this.getBehaviors(this.currentEmployeeID, item.Id);
        await this.getGoals(this.currentEmployeeID, item.Id);
        await this.getActivities(item.Id);
      }

      // if (item.Type === 'ReceivedAsk') {
      //   this.isRecieved = true;

      //   if (item.Activity) {
      //     this.activityName = item.Activity.Name;
      //     this.selectedActivityId = item.Activity.Id;
      //   }
      //   this.askId = item.AskId;
      //   this.selectedEmployee = item.Author;
      //   this.targetEmployee = item.Author;
      //   this.targetEmployeeId = this.targetEmployee.Id;
      //   this.selectedEmployeeName = item.Author.Name;
      //   isReceivedAsk = true;
      //   if (item.AskSkills) {
      //     this.radioType = 'skill';
      //     item.AskSkills.forEach(el => {
      //       el['ratingOnSKill'] = JSON.parse(JSON.stringify(this.eval_arr));
      //       el['Amount'] = 0;
      //       this.numberOfSkills++;
      //     });
      //     this.filteredSkills = item.AskSkills;
      //   }
      //   else if (item.AskBehaviors) {
      //     this.radioType = 'behavior';
      //     this.behaviors = item.AskBehaviors;
      //     this.behaviors.forEach((behavior: any) => {
      //       behavior.ratingOnSKill = JSON.parse(JSON.stringify(this.eval_arr));
      //       behavior.Amount = 0;
      //     });
      //     this.filteredBehaviors = JSON.parse(JSON.stringify(this.behaviors));
      //   }
      //   else {
      //     this.radioType = 'goal';
      //     item.AskGoals.forEach(el => {
      //       el['ratingOnGoal'] = JSON.parse(JSON.stringify(this.eval_arr));
      //       el['Amount'] = 0;
      //       this.numberOfSkills++;
      //     });
      //     this.goals = item.AskGoals;
      //     this.filteredGoals = JSON.parse(JSON.stringify(this.goals));
      //   }
      //   this.isSelectedSkills = true;
      // }
    });
  }

  ngAfterContentChecked() {
    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      this.isAfterContentChecked = true;
    }
  }

  ngAfterContentInit() {
    this.refreshRadioSelection();
    this.initData();
  }

  onGoalChange(e: any) {
    if (!e) return;
    this.selectedGoalObj = e;
    if (this.selectedGoalObj && this.selectedGoalObj.IsFromCycle) {
      // console.log('onGoalChange', this.selectedGoalObj);
      this.isKeyResults = true;
      // this.evaluationActivities = [];
      if (this.selectedGoalObj.KeyResults) {
        this.selectedGoalObj.KeyResults.forEach((element: any) => {
          this.evaluationActivities.push({
            Id: element.Item1,
            Name: element.Item2
          });
        });
      }
    } else {
      this.evaluationActivities = this.globalEvaluationActivities;
      this.isKeyResults = false;
    }
  }

  async onSelectEmployeeChange(event) {
    if (!event) return;
    let empId = this.clearId(event.Id);
    this.targetEmployeeId = empId;
    this.selectedEmployee = event;
    await this.getSkills(this.currentEmployeeID, this.targetEmployeeId);
    await this.getBehaviors(this.currentEmployeeID, this.targetEmployeeId);
    await this.getGoals(this.currentEmployeeID, this.targetEmployeeId);
    await this.getActivities(this.targetEmployeeId);
  }

  async getSkills(currentEmployeeID, employeeId) {
    this.isSkillProcessing = true;
    if (employeeId.indexOf(':') !== -1) {
      employeeId = employeeId.split(':')[1];
    }
    const data = await this.sharedService
      .evaluationSkills(currentEmployeeID, employeeId)
      .toPromise();
    this.isSkillProcessing = false;
    this.skills = data;
    this.skills.forEach((skill: any) => {
      skill.ratingOnSKill = JSON.parse(JSON.stringify(this.eval_arr));
      skill.Amount = 0;
      skill['Descriptions'] = skill.Description;
      skill.Description = '';
    });
    this.filteredSkills = JSON.parse(JSON.stringify(this.skills));
    this.filteredSkills.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
  }

  async getBehaviors(currentEmployeeID, employeeId) {
    this.isBehaviorsProcessing = true;
    if (employeeId.indexOf(':') !== -1) {
      employeeId = employeeId.split(':')[1];
    }
    const data = await this.sharedService
      .evaluationBehaviors(currentEmployeeID, employeeId)
      .toPromise();
    this.isBehaviorsProcessing = false;
    this.behaviors = data;
    this.behaviors.forEach((behavior: any) => {
      behavior.ratingOnSKill = JSON.parse(JSON.stringify(this.eval_arr));
      behavior.Amount = 0;
      behavior['Descriptions'] = behavior.Description;
      behavior.Description = '';
    });
    this.filteredBehaviors = JSON.parse(JSON.stringify(this.behaviors));
    this.filteredBehaviors.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
  }

  emptySelected() {
    this.evaluation_Employees.forEach((element) => {
      element.selected = false;
    });
  }

  async getGoals(currentEmployeeID, employeeId) {
    this.isGoalProcessing = true;
    if (employeeId.indexOf(':') !== -1) {
      employeeId = employeeId.split(':')[1];
    }
    const data = await this.sharedService
      .evaluationGoals(currentEmployeeID, employeeId)
      .toPromise();
    this.isGoalProcessing = false;
    this.goals = data;
    this.goals.forEach((goal: any) => {
      goal.ratingOnGoal = JSON.parse(JSON.stringify(this.eval_arr));
      goal.Amount = 0;
      goal['Descriptions'] = goal.Description;
      goal.Description = '';
    });
    this.filteredGoals = JSON.parse(JSON.stringify(this.goals));
    this.filteredGoals.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
  }

  async getActivities(employeeId) {
    if (employeeId.indexOf(':') !== -1) {
      employeeId = employeeId.split(':')[1];
    }
    this.isActivityProcessing = true;
    const data: any = await this.sharedService
      .evaluationActivities(employeeId)
      .toPromise();
    this.isActivityProcessing = false;
    this.evaluationActivities = data;
    this.evaluationActivities.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
    this.globalEvaluationActivities = data;
    this.globalEvaluationActivities.sort((a: any, b: any) => {
      return a.Name.toLowerCase().trim() < b.Name.toLowerCase().trim()
        ? -1
        : a.Name.toLowerCase().trim() > b.Name.toLowerCase().trim()
          ? 1
          : 0;
    });
  }

  giveRating(item, index) {
    if (this.commonarray.indexOf(item.Id) < 0) {
      this.dataSkill(item);
    }
    if (this.radioType === 'skill' || this.radioType === 'behavior') {
      if (item['Amount'] > 0 && index === item['Amount'] - 1) {
        item.ratingOnSKill.forEach((ele) => {
          ele.activeRate = false;
        });
        item['Amount'] = 0;
      } else {
        item.ratingOnSKill.forEach((ele) => {
          ele.activeRate = false;
        });
        for (let i = 0; i <= index; i++) {
          item.ratingOnSKill[i].activeRate = true;
          this.sLabel = item.ratingOnSKill[i].label;
        }
        item['Amount'] = index + 1;
      }
    } else if (this.radioType === 'goal') {
      if (item['Amount'] > 0 && index === item['Amount'] - 1) {
        item.ratingOnGoal.forEach((ele) => {
          ele.activeRate = false;
        });
        item['Amount'] = 0;
      } else {
        item.ratingOnGoal.forEach((ele) => {
          ele.activeRate = false;
        });
        for (let i = 0; i <= index; i++) {
          item.ratingOnGoal[i].activeRate = true;
          this.sLabel = item.ratingOnGoal[i].label;
        }
        item['Amount'] = index + 1;
      }
      if (item.IsFromCycle) {
        this.onGoalChange(item);
      } else {
        this.evaluationActivities = this.globalEvaluationActivities;
        this.isKeyResultss = false;
      }
    }
    // for (let s of item.ratingOnSKill){
    //   if(index+1==s.rate){
    //     this.sLabel=s.label;
    //   }
    // }
  }

  value(val) {
    this.checkBoxValue = val.target.checked;
  }

  postEvaluation(reqBody) {
    this.sharedService.postEvaluation(reqBody).subscribe(
      (data: any) => {
        data['ratingOnSkill'] = JSON.parse(JSON.stringify(this.evalArr));
        for (let i = 0; i < data.OverAll; i++) {
          data.ratingOnSkill[i]['activeRate'] = 1;
        }

        let half = Math.floor(data.OverAll / 0.5 + 0.5);
        let round = Math.round(data.OverAll) / 0.5;
        data['RoundedValue'] = data.OverAll.toFixed(1);
        data['valueClass'] = 'badValue';
        if (data.RoundedValue > 2) {
          data['valueClass'] = 'mediumValue';
        }
        if (data.RoundedValue > 3.4) {
          data['valueClass'] = 'goodValue';
        }
        data['Stars'] = Math.round(data.OverAll);
        data['HasHalf'] = half > round;

        for (let i = 0; i < data.Stars; i++) {
          data.ratingOnSkill[i].activeRate = 2;
        }
        if (data.HasHalf) {
          data.ratingOnSkill[data.Stars].activeRate = 1;
        }

        this.postEval.emit(data);
        // this.coreService.toasterMessage(
        //   'success',
        //   'company',
        //   this.coreService.getTranslation("Messages.Evaluation Update")
        // );
        this.coreService.setShowPublished({ type: 'Feedback', isEdit: false });
        // this.emptySelected();
        this.bsModalRef.hide();
        this.pp360SpinnerService.showSpinner(false);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isSubmit = false;
        this.pp360SpinnerService.showSpinner(false);
      }
    );

    this.bsModalRefConfirmation.hide();
    this.refreshWallRequired.next(reqBody);
    this.onClose.next(reqBody);
    this.bsModalRef.hide();
  }

  ngOnDestroy() {
    if (this.subscriptionOnHidden) this.subscriptionOnHidden.unsubscribe();
  }

  sendingConfirmation: boolean = false;
  bsModalRefConfirmation: any;
  onConfirm() {
    if (this.isDisabled() || this.sendingConfirmation) return;
    this.sendingConfirmation = true;
    this.selectedEmployee.Id = this.selectedEmployee.Id.replace('e:', '');
    this.isSubmit = true;
    let reqBody = {};
    const content: any = {
      YesCaption: this.coreService.getTranslation('CONFIRM'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('WALL.EVALUATION_CHANGES'),
      subtitle: this.coreService.getTranslation('WALL.EVALUATION_CHANGES_TITLE')
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.pp360SpinnerService.showSpinner(true);
          //if (form.feedback == undefined) form.feedback = '';
          let skillarr = [];
          let goalarr = [];
          if (this.filteredSkills) {
            this.filteredSkills.forEach((item: any) => {
              if (item.Amount > 0) {
                skillarr.push({
                  Skill: {
                    Id: item.Id
                  },
                  Amount: item.Amount,
                  Description: item.Description
                });
              }
            });
          }

          if (this.filteredBehaviors) {
            this.filteredBehaviors.forEach((item: any) => {
              if (item.Amount > 0) {
                skillarr.push({
                  Skill: {
                    Id: item.Id
                  },
                  Amount: item.Amount,
                  Description: item.Description
                });
              }
            });
          }

          if (this.filteredGoals) {
            this.filteredGoals.forEach((item: any) => {
              if (item.Amount > 0) {
                goalarr.push({
                  Goal: {
                    Id: item.Id,
                    IsFromCycle: item.IsFromCycle
                  },
                  Amount: item.Amount,
                  Description: item.Description
                });
              }
            });
          }

          if (this.selectedEmployee != undefined) {
            this.targetEmployeeId = this.selectedEmployee.Id;
          }

          if (
            this.targetEmployee == undefined &&
            this.selectedEmployee !== undefined
          ) {
            //????????
            this.targetEmployeeId = this.selectedEmployee.Id;

            reqBody = {
              Author: {
                Id: this.currentEmployeeID
              },
              Target: {
                Id: this.targetEmployeeId
              },
              IsAnonymous: this.isAnonymous,
              Description: this.feedback,
              Evaluations:
                this.radioType === 'skill' || this.radioType === 'behavior'
                  ? skillarr
                  : goalarr
            };
          } else if (this.targetEmployee !== undefined) {
            if (this.askId) {
              reqBody = {
                Author: {
                  Id: this.currentEmployeeID
                },
                AskId: this.askId,
                Target: {
                  Id: this.targetEmployeeId
                },
                IsAnonymous: this.isAnonymous,
                Description: this.feedback,
                Evaluations:
                  this.radioType === 'skill' || this.radioType === 'behavior'
                    ? skillarr
                    : goalarr
              };
            }
          } else {
            reqBody = {
              Author: {
                Id: this.currentEmployeeID
              },
              Target: {
                Id: this.clearId(this.targetEmployeeId)
              },
              IsAnonymous: this.isAnonymous,
              Description: this.feedback,
              Evaluations:
                this.radioType === 'skill' || this.radioType === 'behavior'
                  ? skillarr
                  : goalarr
            };
          }
          let activityId = this.selectedActivityId;
          if (activityId && activityId.length > 0) {
            reqBody['Activity'] = {
              Id: activityId
            };
          }

          if (this.selectedFeedback && this.isRecieved) {
            this.giveUpdateFeedback(reqBody);
          } else if (this.selectedFeedback && !this.isRecieved) {
            this.updateFeedback(reqBody);
          } else {
            this.postEvaluation(reqBody);
          }

          this.evaluation_Employees.forEach((element: any) => {
            element.selected = false;
          });
        } else {
          this.isSubmit = false;
          this.pp360SpinnerService.showSpinner(false);
          this.sendingConfirmation = false;
        }
      }
    );
  }

  updateFeedback(req) {
    const evalt = [];
    req.Evaluations.forEach((element) => {
      evalt.push({
        Id: element.Skill ? element.Skill.Id : element.Goal.Id,
        Description: element.Description
      });
    });
    req.Evaluations = evalt;
    req.Id = this.selectedFeedback.Id;
    this.sharedService.updateEvaluation(req).subscribe(
      (data: any) => {
        this.updateEval.emit(data);
        // this.coreService.toasterMessage(
        //   'success',
        //   'company',
        //   this.coreService.getTranslation("Messages.Evaluation Update")
        // );
        this.coreService.setShowPublished({ type: 'Feedback', isEdit: true });
        this.emptySelected();
        this.bsModalRef.hide();
        this.pp360SpinnerService.showSpinner(false);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isSubmit = false;
        this.pp360SpinnerService.showSpinner(false);
      }
    );
    this.bsModalRefConfirmation.hide();
  }

  giveUpdateFeedback(req) {
    const evalt = [];
    req.Evaluations.forEach((element) => {
      if (element.Skill) {
        evalt.push({
          Amount: element.Amount,
          Description: element.Description,
          Skill: {
            Id: element.Skill.Id
          }
        });
      } else {
        evalt.push({
          Amount: element.Amount,
          Description: element.Description,
          Goal: {
            Id: element.Goal.Id,
            IsFromCycle: true
          }
        });
      }
    });
    req.Evaluations = evalt;
    // req.Id = this.selectedFeedback.Id;
    req.Description = 'desc';

    this.sharedService.postEvaluation(req).subscribe(
      (data: any) => {
        // this.coreService.toasterMessage(
        //   'success',
        //   'company',
        //   this.coreService.getTranslation("Messages.EvaluationSent")
        // );
        this.postEval.emit(data);
        this.coreService.setShowPublished({ type: 'Feedback', isEdit: false });
        this.emptySelected();
        this.bsModalRef.hide();
        this.pp360SpinnerService.showSpinner(false);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
        this.isSubmit = false;
        this.pp360SpinnerService.showSpinner(false);
      }
    );
    this.bsModalRefConfirmation?.hide();
    this.bsModalRef.hide();
  }
  clearId(id) {
    let empId = null;
    if (id.indexOf(':') < 0) {
      empId = id;
    } else {
      empId = id.substring(2);
    }
    return empId;
  }

  isDisabled() {
    if (!this.isRecieved) {
      var disabled =
        this.isSubmit ||
        !this.selectedEmployee ||
        (this.radioType === 'skill' && !this.filteredSkills) ||
        (this.radioType === 'goal' && !this.filteredGoals) ||
        (this.radioType === 'behavior' && !this.filteredBehaviors);
      if (!disabled) {
        var atLeastOne = false;
        if (this.radioType === 'skill' && this.filteredSkills) {
          this.filteredSkills.forEach((item: any) => {
            if (item.Amount > 0) {
              atLeastOne = true;
              return;
            }
          });
        }
        if (this.radioType === 'behavior' && this.filteredBehaviors) {
          this.filteredBehaviors.forEach((item: any) => {
            if (item.Amount > 0) {
              atLeastOne = true;
              return;
            }
          });
        }
        if (this.radioType === 'goal' && this.filteredGoals) {
          this.filteredGoals.forEach((item: any) => {
            if (item.Amount > 0) {
              atLeastOne = true;
              return;
            }
          });
        }
        disabled = !atLeastOne;
      }
    } else {
      var atLeastOne = true;
      if (this.radioType === 'skill' && this.filteredSkills) {
        this.filteredSkills.forEach((item: any) => {
          if (item.Amount <= 0) {
            atLeastOne = false;
            return;
          }
        });
      }

      if (this.radioType === 'behavior' && this.filteredBehaviors) {
        this.filteredBehaviors.forEach((item: any) => {
          if (item.Amount <= 0) {
            atLeastOne = false;
            return;
          }
        });
      }

      if (this.radioType === 'goal' && this.filteredGoals) {
        this.filteredGoals.forEach((item: any) => {
          if (item.Amount <= 0) {
            atLeastOne = false;
            return;
          }
        });
      }
      disabled = !atLeastOne;
    }

    return disabled;
  }

  getImageTemplate(data: any) {
    return this.coreService.getImageURL(
      data.Picture,
      'assets/images/user.jpeg'
    );
  }

  getFilteredEmployeeList(): any[] {
    return this.evaluation_Employees;
  }

  filterSkills($event) {
    let value = $event.target.value;

    this.filteredSkills = [];
    this.skills?.forEach((skill: any) => {
      if (skill.Name.toUpperCase().startsWith(value.toUpperCase())) {
        this.filteredSkills.push(skill);
      }
    });
  }

  filterBehaviors($event) {
    let value = $event.target.value;
    this.filteredBehaviors = [];
    this.behaviors?.forEach((behavior: any) => {
      if (behavior.Name.toUpperCase().startsWith(value.toUpperCase())) {
        this.filteredBehaviors.push(behavior);
      }
    });
  }

  filterGoals($event) {
    let value = $event.target.value;
    this.filteredGoals = [];
    this.goals?.forEach((goal: any) => {
      if (goal.Name.toUpperCase().startsWith(value.toUpperCase())) {
        this.filteredGoals.push(goal);
      }
    });
  }

  hasVoted(type: any) {
    if (
      !type ||
      ((this.radioType === 'skill' || this.radioType === 'behavior') &&
        !type.ratingOnSKill) ||
      (this.radioType === 'goal' && !type.ratingOnGoal)
    )
      return;
    let ret =
      this.radioType === 'skill' || this.radioType === 'behavior'
        ? type.ratingOnSKill.filter((s) => s.activeRate)
        : type.ratingOnGoal.filter((s) => s.activeRate);
    return ret.length > 0;
  }

  checkDisable(goal: any) {
    // const almoustVoted = this.filteredGoals.filter((goal: any) => goal.ratingOnGoal.filter(s => s.activeRate));
    const almoustVoted = this.filteredGoals.filter((goal: any) => {
      const ratings = goal.ratingOnGoal.filter((rate: any) => {
        return rate.activeRate === true;
      });
      return ratings.length > 0;
    });

    return almoustVoted.length > 0 && !this.hasVoted(goal);
  }

  showToaster(goal: any) {
    if (this.checkDisable(goal)) {
      this.coreService.toasterMessage(
        'warning',
        '',
        this.translate.instant('Generic.OneObjectiveATime')
      );
    }
  }

  onFocusFilterEmployees() {
    setTimeout(() => {
      $('typeahead-container.dropdown.open').css(
        'width',
        $('#employee_selector').outerWidth() + 'px'
      );
      $('typeahead-container ul.dropdown-menu').css(
        'width',
        $('#employee_selector').outerWidth() + 'px'
      );
    }, 1);
  }

  getAccordioHeader(skill: any) {
    return this.hasVoted(skill) ? `${skill.Name} ` : ` ${skill.Name} `;
  }
  getAccordioHeaders(skill: any) {
    return this.hasVoted(skill) ? `${skill.Name} ` : ` ${skill.Name} `;
  }

  iosAutocompleteDialog() {
    if (this.isPreselected) return;
    this.iosModalRef = this.modalService.show(IosAutocompleteComponent, {
      class: 'iosAutocompleteDialog',
      ignoreBackdropClick: true
    });
    this.iosModalRef.content.setData(this.evaluation_Employees);
    this.iosModalRef.content.selectionMultiple = false;
    this.iosModalRef.content.selectionViewType = 'EMPLOYEES_TEAMS';
    this.iosModalRef.content.title = this.translate.instant(
      'Generic.ChooseEmployee'
    );
    this.iosModalRef.content.getResponse.subscribe((data: any) => {
      this.selectedEmployee = data.selected[0];
      this.evaluation_Employees = data.originalList;
      this.onSelectEmployeeChange(data.selected[0]);
    });
  }

  searchEmployeeText: string;
  switchTypeAhead() {
    this.selectedEmployee = undefined;
    this.searchEmployeeText = '';
    setTimeout(() => {
      $('#gtEmployeeList').trigger('click');
    }, 100);
  }

  selectItem(event: any) {
    this.onSelectEmployeeChange(event.item);
  }

  scrollTo(event: any) {
    if ($(event.target).closest('accordion-group').index() > 2) {
      setTimeout(() => {
        $('.accordionWrapper').scrollTop(
          $(event.target).closest('accordion-group').index() * 50
        );
      }, 250);
    }
  }

  onSelectActivity(event: any) {
    this.selectedActivityId = event.Id;
  }
  onSelectedEmployee(data: any) {
    this.selectedEmployee = data.selected[0];
    this.evaluation_Employees = data.originalList;
    this.onSelectEmployeeChange(data.selected[0]);
    this.checkFill(this.selectedEmployee);
    if (!this.selectedEmployee) {
      this.selctArray = [];
      this.selectSkill = '';
      this.evaluatedData = [];
      this.commonarray = [];
      this.searchSkillText = '';
      this.isKeyResults = true;
      this.selectedGoalObj = undefined;
      this.selectedActivity = undefined;
      this.selectedActivityId = undefined;
    }
    // console.log(this.selectedEmployee);
    // console.log(this.evaluation_Employees);
  }
  s: any;
  commonarray = [];
  mainIdArray = [];
  searchSkillId: any;
  sk: any = [];
  expertText: boolean = false;
  isShow: boolean = false;
  showId: any;
  dd: any;

  skillDetail(skilldata) {
    this.skills = this.filteredSkills;
    this.behaviors = this.filteredBehaviors;
    this.goals = this.filteredGoals;
    if (skilldata.Amount != 0) {
      if (skilldata?.ratingOnSKill) {
        this.sLabel = skilldata.ratingOnSKill[skilldata.Amount - 1].label;
      }
      if (skilldata?.ratingOnGoal) {
        this.sLabel = skilldata.ratingOnGoal[skilldata.Amount - 1].label;
      }
    } else {
      this.sLabel = '';
    }

    this.selectSkill = skilldata.Id;
    skilldata.isOpen = !skilldata.isOpen;
    if (skilldata.Id == this.dd) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
    this.dd = skilldata.Id;
    // console.log(skilldata);
    this.showId = skilldata.Id;
  }
  cou: number = 0;
  dataSkill(skilldata) {
    if (this.commonarray.includes(skilldata.Id)) {
      this.removeEle(skilldata.Id);
    } else {
      let df = {
        target: {
          value: ''
        }
      };
      this.filterSkills(df);
      this.filterBehaviors(df);
      this.filterGoals(df);
      // this.searchable = true;
      if (this.searchable == true) {
        this.searchInput.nativeElement.focus();
        this.searchInput.nativeElement.value = '';
        this._renderer.removeAttribute(
          this.searchInput.nativeElement,
          'readonly'
        );
      }
      // this.searchManually = true;

      if (skilldata.Amount != 0) {
        if (skilldata?.ratingOnSKill) {
          this.sLabel = skilldata.ratingOnSKill[skilldata.Amount - 1].label;
        }
        if (skilldata?.ratingOnGoal) {
          this.sLabel = skilldata.ratingOnGoal[skilldata.Amount - 1].label;
        }
      } else {
        this.sLabel = '';
      }
      if (this.selectedFeedback) {
        return;
      }
      // console.log("click on dataskill");

      // console.log(skilldata);

      skilldata.EventType = 'skill';
      for (let i in this.filteredSkills) {
        // console.log(this.filteredSkills[i]['Id']);

        this.mainIdArray.push(this.filteredSkills[i]['Id']);
      }
      this.mainIdArray = [...new Set(this.mainIdArray)];
      // console.log(this.mainIdArray);
      this.selectSkill = skilldata.Id;
      this.valueCheck = !this.valueCheck;
      let c = 0;
      if (this.selctArray.length == 0) {
        this.selctArray.push(skilldata);
      }
      if (this.selctArray.length > 0) {
        for (let i in this.selctArray) {
          if (this.selctArray[i]['Id'] == skilldata.Id) {
            c = 1;
          }
        }
        if (c == 0) {
          this.selctArray.push(skilldata);
          this.s = skilldata.Id;
        }
      }
      // console.log(this.selctArray);
      for (let i in this.selctArray) {
        this.searchSkillText = this.selctArray[i]['Name'];
        this.searchSkillId = this.selctArray[i]['Id'];
      }
      this.commonarray.push(this.searchSkillId);
      this.commonarray = [...new Set(this.commonarray)];
      // console.log(this.commonarray);
      this.sk = this.commonarray;
      // console.log(this.sk);

      if (this.selctArray.length > 1) {
        this.checkEvaluted();
        // this.searchSkillText = `${this.selctArray.length} ` + this.coreService.getTranslation("skillsEvaluted");
      } else if (this.selctArray.length == 1) {
        this.searchSkillText = this.selctArray[0]['Name'];
      } else if (this.selctArray.length == 0) {
        this.searchSkillText = '';
      }
      this.expertText = true;

      for (let i in this.selctArray) {
        if (this.selctArray[i]['Amount'] == 0) {
          this.evalMendatory == true;
        }
      }
      if (this.cou == this.selctArray) {
        this.evalMendatory == true;
      }
      this.searchSkillText = '';
      // console.log(this.filteredSkills);
    }
  }

  clickit($event) {
    $event.stopPropagation();
    this.show = !this.show;
    this.searchable = false;
  }

  removeEle(eleRemove) {
    for (let i in this.selctArray) {
      if (this.selctArray[i]['Id'] == eleRemove) {
        this.selctArray[i].Amount = 0;
        this.selctArray[i].Description = '';
        this.sLabel = '';
        if (this.radioType == 'goal') {
          for (let j of this.selctArray[i].ratingOnGoal) {
            j.activeRate = false;
          }
        } else {
          for (let j of this.selctArray[i].ratingOnSKill) {
            j.activeRate = false;
          }
        }
        this.selctArray.splice(i, 1);
      }
    }
    this.commonarray.forEach((element, index) => {
      if (element == eleRemove) this.commonarray.splice(index, 1);
    });

    this.sk = this.commonarray;

    if (this.selctArray.length > 1) {
      this.checkEvaluted();
      // this.searchSkillText = `${this.selctArray.length} ` + this.coreService.getTranslation("skillsEvaluted");
    } else if (this.selctArray.length == 1) {
      this.searchSkillText = this.selctArray[0]['Name'];
    } else if (this.selctArray.length == 0) {
      this.searchSkillText = '';
    }
  }

  isSkillNotEvaluated(skills) {
    if (!this.sk.length || !skills) {
      return false;
    }
    const dd = skills.find(
      (skill) => this.sk.indexOf(skill.Id) > -1 && skill.Amount === 0
    );
    return !!dd;
  }
  onBtnClick(skill) {
    this.message.emit(skill);
  }

  checkFill(data) {
    if (data) {
      this.check = false;
    } else {
      this.check = true;
    }
    this.checkDefine.emit(this.check);
  }
  reloadActivity() {
    this.reloadAct = false;
    setTimeout(() => {
      this.reloadAct = true;
    }, 10);
  }
  canSearch(event) {
    event.stopPropagation();
    this.searchable = true;
    this.searchManually = true;
    this.searchSkillText = '';
    this.searchInput.nativeElement.focus();
    this._renderer.removeAttribute(this.searchInput.nativeElement, 'readonly');
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView();
  }
  blurInput(event) {
    this.searchInput.nativeElement.value = '';
    this.showMandatory = true;
    event.stopPropagation();
    this.filterSkills(event);
  }
}
