import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminLtiService } from '../../admin-lti.service';
import { TranslateService } from '@ngx-translate/core';
import { LTIObjectiveDTO } from '../../models';
import { CoreService } from 'app/shared/services/core.service';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss', '../../../magic-checkbox.scss']
})
export class GatewayComponent implements OnInit {
  @Input() public item: any;
  @Input() public type: string;
  @Input() public index: number;
  @Input() public IsActive: boolean;
  @Output() deleteItem = new EventEmitter();

  //isOpen: boolean = false;
  currentMeasureObj: any;
  currentFormatObj: any;
  currentScaleObj: any;
  roundBonusValues: boolean = false;
  NotScale: boolean = true;

  constructor(
    private adminLtiService: AdminLtiService,
    private translateService: TranslateService,
    private coreService: CoreService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    console.log('GatewayComponent', this.item);

    if (!this.item.ConsultationType) {
      this.item.ConsultationType = 'Punctual';
    }

    this.item.YearlyValues.forEach((element: any) => {
      element.TargetValue =
        !element.TargetValue || element.TargetValue == 0
          ? undefined
          : element.TargetValue;
    });

    //this.item['isOpen'] = false;

    if (this.item.GoalId) {
      this.adminLtiService
        .getMeasures(this.item.GoalId)
        .subscribe((measures: any) => {
          this.item['measures'] = measures;

          if (this.item.MeasureId) {
            this.NotScale = true;
            this.currentMeasureObj = this.item['measures'].filter(
              (measure: any) => measure.Id === this.item.MeasureId
            )[0];
            this.item['Measure'] = this.currentMeasureObj; //=> attach Measure to DataModel

            if (
              this.currentMeasureObj.MeasureType === 'money' ||
              this.currentMeasureObj.MeasureType === 'number'
            ) {
              this.adminLtiService.getFormats().subscribe((formats: any) => {
                this.item['formats'] = formats;
                if (this.item.FormatId) {
                  this.currentFormatObj = this.item['formats'].filter(
                    (format: any) => format.Id === this.item.FormatId
                  )[0];
                  this.item['Format'] = this.currentFormatObj; //=> attach Format to DataModel
                }
              });
            } else if (this.currentMeasureObj.MeasureType === 'scale') {
              this.adminLtiService.getScales().subscribe((scales: any) => {
                this.item['scales'] = scales;
                this.NotScale = false;
                if (this.item.ScaleId) {
                  this.currentScaleObj = this.item['scales'].filter(
                    (scale: any) => scale.Id === this.item.ScaleId
                  )[0];
                  this.item.Min = this.currentScaleObj.Minimum;
                  this.item.Max = this.currentScaleObj.Maximum;
                }
              });
            }
          }
        });
    }
  }

  removeItem() {
    console.log('deleteDraft');
    const content: any = {
      YesCaption: this.coreService.getTranslation('YES'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('Messages.SureDelete')
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.deleteItem.emit({
            type: this.type,
            index: this.index
          });
        }
      }
    );
  }

  onGoalChange(goalId: any) {
    this.item['GoalId'] = goalId;
    this.selectGoal(goalId);
  }

  selectGoal(goalId: string) {
    if (!goalId) return;
    this.adminLtiService.getMeasures(goalId).subscribe((measures: any) => {
      this.item['measures'] = measures;
    });
    this.item.YearlyValues.forEach((element: any) =>
      this.onValueChange(element)
    );
  }

  onMeasureChange(measureId: string) {
    if (!measureId) return;
    try {
      this.NotScale = true;
      this.currentMeasureObj = this.item['measures'].filter(
        (measure: any) => measure.Id === measureId
      )[0];

      this.item['Measure'] = this.currentMeasureObj; //=> attach Measure to DataModel

      console.log(this.currentMeasureObj);

      this.item.ConsultationType = 'Punctual';
      if (
        this.currentMeasureObj.MeasureType === 'money' ||
        this.currentMeasureObj.MeasureType === 'number' ||
        this.currentMeasureObj.MeasureType === 'int'
      ) {
        this.adminLtiService.getFormats().subscribe((formats: any) => {
          this.item['formats'] = formats;
        });
      } else if (this.currentMeasureObj.MeasureType === 'scale') {
        this.adminLtiService.getScales().subscribe((scales: any) => {
          this.item['scales'] = scales;
          this.NotScale = false;
        });
      }

      this.item.YearlyValues.forEach((element: any) => {
        console.log(element);
        element.TargetValue = undefined;
        element.YearlyValues = undefined;
        //this.onValueChange(element)
      });

      this.initDatatable();
    } catch (error) {}
  }

  onFormatChange(formatId: string) {
    if (!formatId) return;
    try {
      this.currentFormatObj = this.item['formats'].filter(
        (format: any) => format.Id === formatId
      )[0];
      this.item['Format'] = this.currentFormatObj; //=> attach Format to DataModel
      this.item.YearlyValues.forEach((element: any) =>
        this.onValueChange(element)
      );
    } catch (error) {}
  }

  onScaleChange(scaleId: string) {
    if (!scaleId) return;
    try {
      this.currentScaleObj = this.item['scales'].filter(
        (scale: any) => scale.Id === scaleId
      )[0];
      this.item.Min = this.currentScaleObj.Maximum < 100 ? 1 : 10;
      this.item.Max = this.currentScaleObj.Maximum < 100 ? 1 : 10;
      this.item.YearlyValues.forEach((element: any) =>
        this.onValueChange(element)
      );
      console.log('onScaleChange', this.item);
    } catch (error) {}
  }

  isOpenChange(event: any) {
    this.item.isOpen = event;
  }

  getHeadingGateway() {
    if (!this.item.isOpen && this.item['GoalId']) {
      const goal = this.item.goals.filter(
        (goal) => goal.Id.toString() === this.item['GoalId'].toString()
      );
      if (goal) {
        return goal[0].Name;
      }
    } else if (this.item.isOpen && this.item['GoalId']) {
      return ''; //this.coreService.getTranslation("ADMIN_LTI.GATEWAYS.BODY.SET_NAME");
    } else if (!this.item.isOpen && !this.item['GoalId']) {
      let head =
        this.coreService.getTranslation('ADMIN_LTI.GATEWAYS.BODY.CHOOSE') + ' ';

      if (this.type == 'gateway') {
        head += this.coreService.getTranslation(
          'ADMIN_LTI.GATEWAYS.BODY.GATEWAY'
        );
      } else if (this.type == 'objective') {
        head += this.coreService.getTranslation(
          'ADMIN_LTI.GATEWAYS.BODY.OBJECTIVE'
        );
      } else if (this.type == 'corrective') {
        head += this.coreService.getTranslation(
          'ADMIN_LTI.GATEWAYS.BODY.CORRECTIVE'
        );
      }
      return head;
    }
  }

  getWeight() {
    return this.item['Weight'];
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    return Number(value).toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  }

  formatValue(obj: any, x: any, decimal: number = 0) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    const value = isNaN(x) ? x.toFixed(decimal) : x;
    if (obj.Measure && obj.Format) {
      if (obj.Measure.MeasureType === 'money') {
        let v = Number(value).toLocaleString(this.translateService.currentLang);
        return v + obj.Format.Name;
      }
    }
    return value;
  }

  checkVisible(type: number) {
    switch (type) {
      case 1:
        return (
          this.currentMeasureObj.ConsultationMode >= type &&
          this.currentMeasureObj.ConsultationMode !== 6
        );
        break;
      case 2:
        return (
          this.currentMeasureObj.ConsultationMode === 7 ||
          this.currentMeasureObj.ConsultationMode === 6 ||
          this.currentMeasureObj.ConsultationMode === 3 ||
          this.currentMeasureObj.ConsultationMode === 2
        );
        break;
      case 4:
        return this.currentMeasureObj.ConsultationMode >= 4;
        break;
    }
  }

  setChecked(radio: string, value: number) {
    //  $(radio).prop('checked', true );// !$(radio).is(':checked'));
    this.item['ConsultationType'] = value;
  }

  errorMessage: string;
  onValueChange(y) {
    let value = Number(y.TargetValue);
    y.hasError = false;
    this.errorMessage = undefined;
    if (
      value >= 0 &&
      this.currentMeasureObj &&
      this.currentMeasureObj.MeasureType === 'scale'
    ) {
      const currentScale = this.item.scales.find(
        (scale) => scale.Id === this.item.ScaleId
      );
      if (currentScale) {
        //console.log('value', value, value !== null);
        if (
          (value < currentScale.Minimum || value > currentScale.Maximum) &&
          value
        ) {
          y.hasError = true;
          this.errorMessage = this.coreService.getTranslation(
            'ERRORS.OUT_OF_RANGE'
          );
        } else if (value === 0) {
          y.hasError = true;
          this.errorMessage = this.coreService.getTranslation(
            'ERRORS.OUT_OF_RANGE'
          );
        }

        console.log('value', value);
      }
    }
    if (y.hasError) {
    }
  }

  getTargetValue(item: any, year: any) {
    let retValue = 0;
    let previosItems: any;
    let count = 0;
    switch (item.ConsultationType) {
      case 'Average': //average
        previosItems = item.YearlyValues.filter((x) => x.Year <= year.Year);
        previosItems.forEach((element: any) => {
          retValue += element.TargetValue;
          count++;
        });
        retValue = retValue / count;
        break;
      case 'Cumulative': //cumulative
        previosItems = item.YearlyValues.filter((x) => x.Year <= year.Year);
        previosItems.forEach((element: any) => {
          retValue += element.TargetValue;
        });

        break;
      case 'Punctual': //punctual
        retValue = item.YearlyValues.find(
          (x) => x.Year == year.Year
        ).TargetValue;
        break;
    }

    return retValue;
  }

  getMinValue(item: any, year: any) {
    let tv = this.getTargetValue(item, year);
    if (item.Measure && item.Measure.MeasureType === 'scale') {
      let n = tv - item.Min / 1;
      if (this.currentScaleObj) {
        if (n < this.currentScaleObj.Minimum) n = this.currentScaleObj.Minimum;
      }
      return n;
    } else {
      tv = (tv * item.Min) / 100;
      return tv;
    }
  }

  getMaxValue(item: any, year: any) {
    let tv = this.getTargetValue(item, year);
    if (item.Measure && item.Measure.MeasureType === 'scale') {
      let n = tv + item.Max / 1;
      if (this.currentScaleObj) {
        if (n > this.currentScaleObj.Maximum) n = this.currentScaleObj.Maximum;
      }
      return n;
    } else {
      tv = (tv * item.Max) / 100;
      return tv;
    }
  }

  isEditable(year: number) {
    const actualYear = new Date().getFullYear();
    return !this.IsActive || year > actualYear;
  }

  table: any;
  initDatatable() {
    return;
    if (this.table) $('.consultationTypeTable').DataTable().clear().destroy();
    console.log('initDatatable');
    setTimeout(() => {
      let dtOptions: any = {
        destroy: true,
        paging: false,
        searching: false,
        ordering: false,
        bInfo: false,
        //scrollY: 'calc(100vh - 390px)',
        scrollX: true,
        fixedColumns: true,
        scrollCollapse: true
      };
      this.table = $('.consultationTypeTable').DataTable(dtOptions);
      this.table.columns.adjust().draw();
    }, 100);
  }

  checkCorridor(item: any) {
    item['IsCorridor'] = !item['IsCorridor'];
    console.log('checkCorridor', item);
  }
}
