import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CoreService } from '../services/core.service';

@Component({
  selector: 'send-mail-dialog',
  templateUrl: './send-mail-dialog.component.html',
  styleUrls: ['./send-mail-dialog.component.scss']
})
export class SendMailDialogComponent implements OnInit {
  public onClose: Subject<any>;
  public content: any = {};
  body: any;
  selectedDest: string = 'reported';

  public constructor(
    private _bsModalRef: BsModalRef,
    public coreService: CoreService
  ) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public showModal(content: any): void {
    this.content = content;

    setTimeout(() => {
      $('.onConfirm').on('click', (event: any) => {
        if (!event.detail || event.detail == 1) {
          //activate on first click only to avoid hiding again on multiple clicks
          this.onConfirm();
        }
      });
    }, 1000);
  }

  private onConfirm(): void {
    this._bsModalRef.hide();
    this.onClose.next({
      sendmail: true,
      dest:
        this.selectedDest == 'reported'
          ? this.content.reported
          : this.content.reporter,
      subject: this.content.subject,
      object: this.body
    });
  }

  public onCancel(): void {
    this._bsModalRef.hide();
    this.onClose.next({
      sendmail: false
    });
  }
}
