import { Injectable } from '@angular/core';
import { FingerprintAIO } from '@ionic-native/fingerprint-aio/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';

@Injectable({
  providedIn: 'root'
})
export class BiometricService {
  public isAvailable: boolean = false;
  public type: string = '';

  constructor(
    private faio: FingerprintAIO,
    private nativeStorage: NativeStorage
  ) {}

  // Check se è disponibile scansione biometrica
  biometricIsAvailable(): Promise<any> {
    return this.faio.isAvailable();
  }

  // Lancio il login
  performBiometricLogin(): Promise<any> {
    console.log('performBiometricLogin');
    //return this.faio.loadBiometricSecret({
    //    title: 'Biometric Authentication', // (Android Only) | optional | Default: "<APP_NAME> Biometric Sign On"
    //    subtitle: '', // (Android Only) | optional | Default: null
    //    description: 'Please authenticate', // optional | Default: null
    //    fallbackButtonTitle: 'Use Backup', // optional | When disableBackup is false defaults to "Use Pin".
    //    // When disableBackup is true defaults to "Cancel"
    //    disableBackup: true,  // optional | default: false
    //});
    return this.faio
      .show({
        title: 'Biometric Authentication', // (Android Only) | optional | Default: "<APP_NAME> Biometric Sign On"
        subtitle: '', // (Android Only) | optional | Default: null
        description: 'Please authenticate' // optional | Default: null
      })
      .then(() => {
        return this.nativeStorage.getItem('bio_secret');
      })
      .catch(() => {
        return Promise.reject(undefined);
      });
  }

  // Salvo il secret
  registerBiometricSecret(secret: string) {
    // const data4Biometric = CryptoJS.AES.encrypt(secret, "PatPat360.Employee");
    console.log('registerBiometricSecret', secret);
    //this.faio.registerBiometricSecret({
    //    secret: secret,
    //    disableBackup: true
    //})
    //    .then((result: any) => {
    //        console.log('registerBiometricSecret result=>', result);
    //    })
    //    .catch((error: any) => console.log('registerBiometricSecret error=>', error));

    this.nativeStorage
      .setItem('bio_secret', secret)
      .then((result: any) => {
        console.log('registerBiometricSecret result=>', result);
      })
      .catch((error: any) =>
        console.log('registerBiometricSecret error=>', error)
      );
  }
}
