import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecipientComponent } from './recipient/recipient.component';
import { PartecipantLTIService } from './partecipant-lti.service';
import { PartecipantLTIRouting } from './partecipant-lti.routing';
import { SharedModule } from '../shared/shared.module';
import { DocumentsComponent } from './recipient/documents/documents.component';
import { TargetBonusComponent } from './recipient/target-bonus/target-bonus.component';
import { ObjectivesComponent } from './recipient/objectives/objectives.component';
import { BonusSimulationComponent } from './recipient/bonus-simulation/bonus-simulation.component';
import { ResultsComponent } from './recipient/results/results.component';

import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NouisliderModule } from 'ng2-nouislider';
import { MobileMenuComponent } from './recipient/mobile/mobile-menu/mobile-menu.component';
import { BonusAccruedComponent } from './recipient/mobile/bonus-accrued/bonus-accrued.component';
import { BonusBankComponent } from './recipient/mobile/bonus-bank/bonus-bank.component';
import { BonusPayableComponent } from './recipient/mobile/bonus-payable/bonus-payable.component';
import { MyLTIFormComponent } from './recipient/mobile/my-ltiform/my-ltiform.component';
import { TermsConditionsComponent } from './recipient/mobile/terms-conditions/terms-conditions.component';
import { VexatiousClausesComponent } from './recipient/mobile/vexatious-clauses/vexatious-clauses.component';
import { ObjectivesMComponent } from './recipient/mobile/objectives/objectives.component';
import { PayoutStructureComponent } from './recipient/mobile/payout-structure/payout-structure.component';
import { GlossaryComponent } from './recipient/mobile/glossary/glossary.component';
import { AvaSankeyComponent } from '../components/common/ava-sankey/ava-sankey.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PartecipantLTIRouting,
    PdfViewerModule,
    NouisliderModule,
    ProgressbarModule.forRoot()
  ],
  providers: [PartecipantLTIService],
  declarations: [
    RecipientComponent,
    DocumentsComponent,
    TargetBonusComponent,
    ObjectivesComponent,
    BonusSimulationComponent,
    ResultsComponent,
    MobileMenuComponent,
    BonusAccruedComponent,
    BonusBankComponent,
    BonusPayableComponent,
    MyLTIFormComponent,
    TermsConditionsComponent,
    VexatiousClausesComponent,
    ObjectivesMComponent,
    PayoutStructureComponent,
    GlossaryComponent,
    AvaSankeyComponent
  ]
})
export class PartecipantLTIModule {}
