import { Routes, CanActivate, CanActivateChild } from '@angular/router';

import { StarterViewComponent } from './views/appviews/starterview.component';
import { LoginComponent } from './views/appviews/login.component';

import { BlankLayoutComponent } from './components/common/layouts/blankLayout.component';
import { BasicLayoutComponent } from './components/common/layouts/basicLayout.component';
import { TopNavigationLayoutComponent } from './components/common/layouts/topNavigationLayout.component';
import { PageNotFoundComponent } from './components/common/page-not-found/page-not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { AdminGuard } from './shared/guards/admin.guard';
import { CompanyAdminGuard } from './shared/guards/company-admin.guard';
import { EmployeeGuard } from './shared/guards/employee.guard';
import { OAuthComponent } from './components/common/oauth.component';
import { ManagerGuard } from './shared/guards/manager.guard';

export const ROUTES: Routes = [
  {
    path: '',
    component: BlankLayoutComponent,
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'TileMenu',
    // canActivate: [ManagerGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./shared/components/tilemenu/tilemenu.module').then(
        (m) => m.TilemenuModule
      )
  },
  {
    path: 'Manager',
    canActivate: [ManagerGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./manager/manager-dashboard/manager-dashboard.module').then(
        (m) => m.ManagerDashboardModule
      )
  },
  {
    path: 'Team',
    canActivate: [EmployeeGuard],
    component: BasicLayoutComponent,
    loadChildren: () => import('./team/team.module').then((m) => m.TeamModule)
  },
  {
    path: 'SuperAdmin',
    canActivate: [AdminGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./super-admin/super-admin.module').then((m) => m.SuperAdminModule)
  },
  {
    path: 'CompanyAdmin',
    canActivate: [CompanyAdminGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./company-admin/company-admin.module').then(
        (m) => m.CompanyAdminModule
      )
  },
  {
    path: 'User',
    canActivate: [EmployeeGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./employee/employee.module').then((m) => m.EmployeeModule)
  },
  {
    path: 'Personal',
    // canActivate: [EmployeeGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./personal/personal.module').then((m) => m.PersonalModule)
  },
  {
    path: 'Objectives',
    // canActivate: [EmployeeGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./personal/personal.module').then((m) => m.PersonalModule)
  },
  {
    path: 'OKRs',
    // canActivate: [EmployeeGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./personal/personal.module').then((m) => m.PersonalModule)
  },
  {
    path: 'Personal-Growth',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./personal-growth/personal-growth.module').then(
        (m) => m.PersonalGrowthModule
      )
  },

  {
    path: 'Analytics',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./analytics/analytics.module').then((m) => m.AnalyticsModule)
  },

  {
    path: 'Surveys',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./surveys/surveys.module').then((m) => m.SurveysModule)
  },

  {
    path: 'MyWallet',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./my-wallet/my-wallet.module').then((m) => m.MyWalletModule)
  },

  {
    path: 'AdminLTI',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./admin-lti/admin-lti.module').then((m) => m.AdminLtiModule)
  },

  {
    path: 'PartecipantLTI',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./partecipant-lti/partecipant-lti.module').then(
        (m) => m.PartecipantLTIModule
      )
  },

  {
    path: 'Integrations',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./integrations/integrations.module').then(
        (m) => m.IntegrationModule
      )
  },

  {
    path: 'Company',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./company-report/company-report.module').then(
        (m) => m.CompanyReportModule
      )
  },

  {
    path: 'Manager',
    // canActivate: [EmployeeGuard],
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./manager-report/manager-report.module').then(
        (m) => m.ManagerReportModule
      )
  },

  {
    path: 'Global',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./global-report/global-report.module').then(
        (m) => m.GlobalReportModule
      )
  },

  {
    path: 'wall',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./employee/wall/wall.module').then((m) => m.WallModule)
  },

  {
    path: 'notify',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./employee/wall/wall.module').then((m) => m.WallModule)
  },
  {
    path: 'oauth',
    component: OAuthComponent
  },
  {
    path: 'error',

    component: PageNotFoundComponent
  },
  {
    path: 'sso_error',
    component: PageNotFoundComponent
  },
  /* {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'starterview', component: StarterViewComponent }
    ]
  },*/

  // Handle all other routes
  //{ path: '**', component: PageNotFoundComponent }
  {
    path: '**',
    component: BasicLayoutComponent,
    loadChildren: () =>
      import('./employee/wall/wall.module').then((m) => m.WallModule)
  }
];
