import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImageSelectorComponent } from 'app/company-report/objectives-individual/objective/obj-sheet-popup/image-selector/image-selector.component';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { CoreService } from 'app/shared/services/core.service';
import { companyService } from 'app/super-admin/company/company.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'pp360-attachment',
  templateUrl: './pp360-attachment.component.html',
  styleUrls: ['./pp360-attachment.component.scss']
})
export class Pp360AttachmentComponent implements OnInit {
  userInfo: any;
  companyId;
  languages;
  isFilePreview = false;
  coverPageImage;
  isCoverUpdated: boolean;
  isMobile = false;
  loading = false;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('reUpload') reUpload: ElementRef;
  @Input() attachment: any;
  @Input() userLanguage: any;
  selectedLang: any;

  constructor(
    private coreService: CoreService,
    private modalService: BsModalService,
    private srv: companyService,
    public screenSizeService: ScreenSizeService
  ) {
    this.userInfo = this.coreService.getUserInfo();

    this.companyId = this.userInfo.belongingCompanyId;
    this.srv.getCompanyLanguages(this.companyId).subscribe((result: any) => {
      this.languages = result;
      this.setAttahments();
    });

    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  ngOnInit(): void {
    this.selectedLang = JSON.parse(JSON.stringify(this.userLanguage));
  }

  setAttahments() {
    this.languages.forEach((element) => {
      if (
        this.attachment.AttachmentData[element.Code].Name &&
        this.attachment.AttachmentData[element.Code].Description &&
        this.attachment.AttachmentData[element.Code].Cover
      ) {
        element.text = this.attachment.AttachmentData[element.Code].Name;
      }
      if (this.selectedLang === element.Code) {
        this.getCoverImage();
      }
    });
  }

  openFile() {
    this.fileInput.nativeElement.click();
  }

  openReuploadFile() {
    this.reUpload.nativeElement.click();
  }

  fileUploaded(file, id = null) {
    this.loading = true;
    var formdata: FormData = new FormData();
    formdata.append('file', file[0], file[0].name);

    this.srv.uploadePerformanceReviewAttachment(formdata, id).subscribe(
      (uploadID) => {
        const fileName = file[0].name.replace(/\.[^/.]+$/, '');
        this.attachment.AttachmentData[this.selectedLang].Name = fileName;
        this.attachment.AttachmentData[this.selectedLang].UploadedFileId =
          uploadID;
        if (id) {
          this.isCoverUpdated = false;
        }
        this.loading = false;
        this.getAttachmentID(uploadID);
      },
      (err) => (this.loading = false)
    );
  }

  getAttachmentID(id: any): void {
    this.loading = true;
    this.srv.getAttachementThumbnail(id).subscribe((res) => {
      this.coverPageImage = res;
      this.isFilePreview = true;
      this.attachment.AttachmentData[this.selectedLang].Cover = null;
      this.languages.forEach((element) => {
        if (this.selectedLang === element.Code) {
          element.text = 'text';
          this.checkForTick(element);
        }
      });
      this.loading = false;
    });
  }

  reUploadFile(file) {
    const id = this.attachment.AttachmentData[this.selectedLang].UploadedFileId;
    if (id) {
      this.fileUploaded(file, id);
    }
  }

  changeLanguage(value: any) {
    this.selectedLang = value;
    this.getCoverImage();
  }

  getCoverImage() {
    const cover = this.attachment.AttachmentData[this.selectedLang].Cover;
    if (cover) {
      this.coverPageImage = cover;
      this.isFilePreview = true;
      this.isCoverUpdated = true;
    } else {
      if (this.attachment.AttachmentData[this.selectedLang].UploadedFileId) {
        this.getAttachmentID(
          this.attachment.AttachmentData[this.selectedLang].UploadedFileId
        );
      } else {
        this.coverPageImage = null;
        this.isFilePreview = false;
        this.isCoverUpdated = false;
      }
    }
  }

  updateAttachmentCover(file: File): void {
    this.loading = true;
    let formdata: FormData = new FormData();
    formdata.append('file', file, file.name);
    this.srv.uploadAttachmentCover(formdata).subscribe((res: any) => {
      if (res && res.ImageUrl) {
        this.coverPageImage = environment.serverURI + res.ImageUrl;
        this.isCoverUpdated = true;
        this.attachment.AttachmentData[this.selectedLang].Cover = res.ImageUrl;
        this.languages.forEach((element) => {
          if (this.selectedLang === element.Code) {
            element.text = 'text';
            this.checkForTick(element);
          }
        });
      }
      this.loading = false;
    });
  }

  resetCover() {
    const id = this.attachment.AttachmentData[this.selectedLang].UploadedFileId;
    this.getAttachmentID(id);
    this.isCoverUpdated = false;
  }

  selectImage() {
    if (this.isCoverUpdated) {
      this.resetCover();
      return;
    }
    const content: any = {
      canSelectLocalImage: true
    };
    let modal = this.modalService.show(ImageSelectorComponent, {
      class: 'image-selector'
    });
    (<ImageSelectorComponent>modal.content).showModal(content);

    if (this.isMobile) {
      setTimeout(() => {
        $('.modal-backdrop').attr('style', 'z-index: 3002 !important');
        $('modal-container:last-child').css('z-index', '3003');
      }, 1000);
    }
    (<ImageSelectorComponent>modal.content).onClose.subscribe(
      (selectedImage) => {
        if (selectedImage) {
          if (typeof selectedImage === 'object') {
            if (selectedImage && selectedImage.urls) {
              this.coverPageImage = selectedImage.urls.thumb;
              this.isCoverUpdated = true;
              this.attachment.AttachmentData[this.selectedLang].Cover =
                selectedImage.urls.thumb;
              this.languages.forEach((element) => {
                if (this.selectedLang === element.Code) {
                  element.text = 'text';
                  this.checkForTick(element);
                }
              });
            }
          } else {
            this.updateAttachmentCover(selectedImage);
          }
        }
      }
    );
    modal.onHidden.subscribe(() => {
      $('.modal-backdrop').attr('style', 'z-index: 2040 !important');
    });
  }

  onFileNameChange(value): void {
    this.attachment.AttachmentData[this.selectedLang].Name = value;
    this.languages.forEach((element) => {
      if (this.selectedLang === element.Code) {
        element.text = value;
        this.checkForTick(element);
      }
    });
  }

  changeQuillEditorText(quillContent: any): void {
    this.attachment.AttachmentData[this.selectedLang].Description =
      quillContent;
    this.languages.forEach((element) => {
      if (this.selectedLang === element.Code) {
        element.text = quillContent ? quillContent.trim() : null;
        this.checkForTick(element);
      }
    });
  }

  performTranslate() {
    const name = this.attachment.AttachmentData[this.selectedLang].Name;
    const desc = this.attachment.AttachmentData[this.selectedLang].Description;
    if (name || desc) {
      this.languages.forEach((element) => {
        if (element.Code === this.selectedLang) {
          element.text = name;
        }
        if (name) {
          this.translate(name, element, 'Name');
        }
        if (desc) {
          this.translate(desc, element, 'Description');
        }
      });
    }
  }

  translate(text: string, element: any, key: string) {
    this.coreService
      .translateText(text, this.selectedLang, element.Code)
      .subscribe((result: any) => {
        this.attachment.AttachmentData[element.Code][key] =
          result.TranslatedText;
        this.checkForTick(element);
      });
  }

  checkForTick(element) {
    const desc =
      this.attachment.AttachmentData[this.selectedLang]['Description'];
    if (
      !this.attachment.AttachmentData[this.selectedLang]['Name'] ||
      !desc ||
      !(desc && desc.trim()) ||
      !this.coverPageImage
    ) {
      element.text = '';
    }
  }
}
