import {
  Component,
  OnInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { Subscription } from 'rxjs';
import { CoreService } from 'app/shared/services/core.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { BottomSheetOverviewComponent } from '../bottom-sheet-overview/bottom-sheet-overview.component';

@Component({
  selector: 'custom-radio-dropdown',
  templateUrl: './custom-radio-dropdown.component.html',
  styleUrls: ['./custom-radio-dropdown.component.scss']
})
export class CustomRadioDropdownComponent
  implements OnInit, OnChanges, OnDestroy
{
  @ViewChild('searchInput') ngSelect: NgSelectComponent;
  @Output() itemSelected = new EventEmitter();
  @Input() options: any[] = [];
  @Input() multiple = false;
  @Input() searchable = true;
  @Input() clearable = true;
  @Input() disabled = false;
  @Input() isRequired = false;
  @Input() defaultValue;
  @Input() bindLabel = 'Name';
  @Input() optionLabel = 'Name';
  @Input() showArrow = false;
  @Input() type: string;
  @Input() isOpen = false;
  @Input() titleName: string;
  @Input() isBackDrop = false;
  isCalander: boolean = false;
  selectedItem;
  showMandatory: boolean = false;
  subscription: Subscription;

  constructor(
    public coreService: CoreService,
    private _bottomSheet: MatBottomSheet
  ) {}

  ngOnInit(): void {
    if (this.defaultValue) {
      this.selectedItem = this.defaultValue;
    }
    if (this.options[4]?.click == 'YTD') {
      this.isCalander = true;
    }
    if (this.isOpen) {
      this.openBottomSheet();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isOpen) {
      this.openBottomSheet();
    }
    if (changes && changes.options) {
      if (
        changes.options.currentValue &&
        !changes.options.currentValue.length
      ) {
        this.selectedItem = null;
      }
    }
    this.subscription = this.coreService.getRadioSelect().subscribe((item) => {
      if (item) {
        this.selectedItem = item;
        this.selectionChange(item);
        this.isBackDrop = false;
      }
    });
  }

  onChange(item) {
    if (item.Id) {
      if (item.Id === this.selectedItem?.Id) {
        setTimeout(() => {
          this.selectedItem = null;
          this.showMandatory = true;
          this.itemSelected.emit(null);
          this.ngSelect.blur();
        }, 10);
      }
    } else if (item.value) {
      if (item.value === this.selectedItem?.value) {
        setTimeout(() => {
          this.selectedItem = null;
          this.showMandatory = true;
          this.itemSelected.emit(null);
          this.ngSelect.blur();
        }, 10);
      }
    }
  }

  selectionChange(e) {
    this.itemSelected.emit(e);
  }

  closeDropdown() {
    setTimeout(() => {
      const selectedItem = this.selectedItem;
      this.itemSelected.emit(selectedItem);
      this.ngSelect.close();
    }, 10);
  }

  openBottomSheet(): void {
    const data = [
      {
        options: this.options,
        title: this.titleName,
        selectedItem: this.selectedItem
      }
    ];
    this._bottomSheet.open(BottomSheetOverviewComponent, { data });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
