import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeTeamPopUpComponent } from 'app/shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { WallService } from '../wall.service';
import { InappropriateDialogComponent } from './../inappropriate-dialog/inappropriate-dialog.component';
import { EvaluatorDialogComponent } from './../evaluator-dialog/evaluator-dialog.component';
import { SharedService } from 'app/shared/services/shared.service';
import { Router } from '@angular/router';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { ChoicePopUpComponent } from 'app/shared/components/choice-pop-up/choice-pop-up.component';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';

@Component({
  selector: 'feed-feedback',
  templateUrl: './feed-feedbak.component.html',
  styleUrls: [
    './feed-feedbak.component.scss',
    '../common-feed-style.scss',
    '../../../common_styles.scss'
  ]
})
export class FeedFeedbackComponent implements OnInit {
  @Input() showReported: boolean;
  @Input() readOnly: boolean;
  @Input() item: any;
  @Input() type: any;
  @Output() reloadRequired = new EventEmitter();
  @Output() reportedAsInappropriate = new EventEmitter<any>();
  @Output() feedDeleted = new EventEmitter();
  @Output() feedUpdated = new EventEmitter<any>();
  @Input() selectedTab: any;
  @Input() inappropriatebtn: boolean;
  empInfo: any;

  bsModalRef: BsModalRef;
  userInfo: any;
  isShowSkill = false;
  changeText: boolean;
  bgvalue: any;
  empInfos: any;
  sendingComment = false;
  profileName: any;

  constructor(
    public coreService: CoreService,
    private modalService: BsModalService,
    private service: WallService,
    private route: Router,
    private sharedService: SharedService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {
    this.userInfo = this.coreService.getUserInfo();
    this.changeText = false;
  }
  hidetext = false;
  text1 = `Ai giovani dico che la cosa più importante è cercareiù importante è cercare di capire cosa uno vuole fare, cosa si diverte a fare. La cosa estremamente importante è che uno non deve non fare le cose per la paurare cosa uno vuole fare, cosa si diverte a fare. La cosa estremamente importante è che uno non deve non fare le cose per la paura di non riuscircimmmmmmmm `;

  ngOnInit() {
    if (this.inappropriatebtn) {
      this.item.IsReported = false;
    }
    this.item.totalComments = 0;
    let firstEval = this.item.Evaluations[0];
    this.item.Date = this.sharedService.getLocalDate(this.item.Date);
    this.item['ratingOnSkill'] = [];
    for (let n = firstEval.ScaleMin; n <= firstEval.ScaleMax; n++) {
      this.item['ratingOnSkill'].push({
        rate: n,
        activeRate: 0
      });
    }
    let half = Math.floor(this.item.OverAll / 0.5 + 0.5);
    let round = Math.round(this.item.OverAll) / 0.5;
    this.item['RoundedValue'] = this.item.OverAll.toFixed(1);
    this.item['ValueClass'] = 'badValue';
    if (this.item.RoundedValue > 2) {
      this.item['ValueClass'] = 'mediumValue';
    }
    if (this.item.RoundedValue > 3.4) {
      this.item['ValueClass'] = 'goodValue';
    }
    this.item['Stars'] = Math.round(this.item.OverAll);
    this.item['HasHalf'] = half > round;
    for (let i = 0; i < this.item.Stars; i++) {
      this.item.ratingOnSkill[i].activeRate = 2;
    }
    if (this.item.HasHalf) {
      this.item.ratingOnSkill[this.item.Stars].activeRate = 1;
    }

    this.item.Evaluations.forEach((x) => {
      x['ratingOnSkill'] = [];
      for (let n = x.ScaleMin; n <= x.ScaleMax; n++) {
        x['ratingOnSkill'].push({
          rate: n,
          activeRate: 0
        });
      }
      for (let i = 0; i < x.Amount; i++) {
        x.ratingOnSkill[i].activeRate = 1;
      }
    });
    if (this.service.profiles.length) {
      const employee = this.service.profiles.find(
        (x) => x.Id === this.item.Target.Id
      );
      if (employee) {
        this.empInfo = employee;
      } else {
        this.getProfileInfo();
      }
      const employees = this.service.profiles.find(
        (x) => x.Id === this.item.Author.Id
      );
      if (employees) {
        this.empInfos = employees;
      } else {
        this.getProfileInfos();
      }
    } else {
      this.getProfileInfo();
      this.getProfileInfos();
    }
    this.manageCountComment();
    this.profileName = this.sortName(this.item.Author.FullName);
  }
  sortName(Datas) {
    if (Datas.split(' ').length > 2) {
      let fname = Datas.split(' ')[0];
      let lname = Datas.split(' ')[2];
      return fname + ' ' + lname;
    } else {
      return Datas;
    }
  }
  updateCount(comments) {
    for (let i of comments) {
      if (i?.SubComments?.length > 0) {
        this.item.totalComments += i.SubComments.length;
        this.updateCount(i.SubComments);
      }
    }
  }

  manageCountComment() {
    if (this.item?.Comments?.length > 0) {
      this.item.totalComments =
        this.item?.Comments?.length + this.item?.totalComments;
      this.updateCount(this.item.Comments);
    }
  }

  getProfileInfo() {
    this.service.getEmployeeInfo(this.item.Target.Id).subscribe((data: any) => {
      if (data) {
        this.empInfo = data;
        const employee = this.service.profiles.find(
          (x) => x.Id === this.item.Target.Id
        );
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  getProfileInfos() {
    this.service.getEmployeeInfo(this.item.Author.Id).subscribe((data: any) => {
      if (data) {
        this.empInfos = data;
        const employees = this.service.profiles.find(
          (x) => x.Id === this.item.Author.Id
        );
        if (!employees) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  showHideTranslated(item, property) {
    if (item.ShowTranslated) {
      item.ShowTranslated = !item.ShowTranslated;
    } else {
      let selectedLang = this.userInfo.language;
      const text = item[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          item['TranslatedText'] = result.TranslatedText;
          item.ShowTranslated = true;
        });
    }
  }
  shortcutForPat(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;
    this.bsModalRef = this.modalService.show(EmployeeTeamPopUpComponent, {
      class: 'width232'
    });
    this.bsModalRef.content.team = team;

    this.bsModalRef.content.addNewPat.subscribe((data: any) => {
      this.reloadRequired.emit();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  expandMenu(item) {
    item.expandMenu = !item.expandMenu;
  }

  reportInnapropriate(item) {
    item.expandMenu = false;
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    const content: any = {
      title: msg,
      type: 'Feedback'
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation'
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.GroupId,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 0
          };

          switch (item.Type) {
            case 'ReceivedAsk':
              req.FeedType = 3;
              break;
            case 'ReceivedEvaluation':
              req.FeedType = 2;
              break;
            case 'ReceivedToken':
              req.FeedType = 1;
              break;
            case 'Pat':
              req.FeedType = 0;
              break;
            default:
              req.FeedType = 4;
              break;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }
  goToProfile(idofemployee) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: idofemployee },
      replaceUrl: true
    });
    // this.bsModalRef.hide();
  }
  showSkills(item) {
    this.isShowSkill = !this.isShowSkill;

    if (item.IsNew) {
      item.IsNew = false;
      this.service.viewFeedback(item.GroupId).subscribe((data: any) => {});
    }
  }

  evaluatorFeedback(item) {
    if (
      !this.userInfo.evaluateFeedbackAuthor ||
      item.Author.FirstName === 'Anonymous' ||
      !item.IsNew
    ) {
      this.showSkills(item);
    } else {
      var title = this.coreService.getTranslation(
        'Messages.EvaluatorFeedbackTitle'
      );
      var msg = this.coreService.getTranslation(
        'Messages.EvaluatorFeedbackMessage'
      );
      title = title.replace('{{item.Author.Name}}', item.Author.Name);
      msg = msg.replace('{{item.Author.FirstName}}', item.Author.FirstName);
      const content: any = {
        title: title,
        subtitle: msg,
        skills: ''
      };
      item.Evaluations.forEach(function (e) {
        content.skills += e.Skill ? e.Skill.Name + ', ' : e.Goal.Name + ', ';
      });
      content.skills = content.skills.substring(0, content.skills.length - 2);
      const modal = this.modalService.show(EvaluatorDialogComponent, {
        class: 'evaluator-dialog'
      });
      (<EvaluatorDialogComponent>modal.content).showModal(content);
      (<EvaluatorDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            var v = modal.content.value;
            var req = {
              EvaluationId: item.GroupId,
              Value: modal.content.value,
              Text: modal.content.text
            };
            this.service.postEvaluatorFeedback(req).subscribe();

            this.showSkills(item);
          }
        }
      );
    }
  }

  getShortEmployeeName(employee) {
    var name =
      (employee.SecondName ? employee.SecondName : '') +
      ' ' +
      employee.FirstName;
    var len = 35;
    if (window.innerWidth < 350) {
      len = 21;
    }
    if (name.length > len) {
      name = employee.SecondName.charAt(0) + '.' + ' ' + employee.FirstName;
    }

    return name;
  }
  getConfigMenu(item: any) {
    return {
      editAction: item.Author.Id === this.userInfo.linkedEmployeeId,
      deleteAction: item.Author.Id === this.userInfo.linkedEmployeeId,
      inappropriateAction:
        !item.IsReported && item.Author.Id !== this.userInfo.linkedEmployeeId
    };
  }

  async edit(item: any, comment: any) {
    if (comment) {
      console.log('feeedback', comment);
    }
    this.bsModalRef = this.modalService.show(ChoicePopUpComponent, {
      class: 'choicePopUp ammazza wallPopup',
      ignoreBackdropClick: true,
      initialState: { selectedFeedback: item }
    });
    this.bsModalRef.content.postEval.subscribe((data: any) => {});
    this.bsModalRef.content.onClose.subscribe((data: any) => {
      console.log(data);
    });
  }

  delete(item: any, comment: any) {
    if (comment) {
      alert('TO BE IMPLEMENTED');
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DELETE'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DELETE_POST'),
        subtitle: this.coreService.getTranslation('WALL.FEEDBACK_DELETE')
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation'
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.pp360SpinnerService.showSpinner(true);
            this.service.deleteFeedback(item.Id).subscribe(
              (data: any) => {
                item.expandMenu = false;
                this.feedDeleted.emit(item);
                this.coreService.setShowEliminated({ type: 'Feedback' });
                this.pp360SpinnerService.showSpinner(false);
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
                this.pp360SpinnerService.showSpinner(false);
              }
            );
          }
        }
      );
    }
  }
  hidei: any;
  bvg: any;
  counts: any;
  hideval(ds) {
    // if(this.hidetext==false){
    this.counts = this.hidetext;
    this.hidetext = ds.Id;
    if (this.counts == this.hidetext) {
      this.hidei = !this.hidei;
    } else {
      this.hidei = true;
    }

    this.bvg = ds.Evaluations;
  }

  onComment(item) {
    item.isComment = !item.isComment;
  }
  name() {
    return 'Feedback';
  }
  sendMsg(comment) {
    this.item.totalComments++;
    this.sendingComment = true;
    let reqBody = {
      FeedbackId: this.item.Id,
      AuthorId: this.userInfo.linkedEmployeeId,
      Text: comment?.comment ? comment?.comment : ' ',
      Picture: comment?.image ? comment?.image : null
    };
    if (reqBody.Text) {
      this.pp360SpinnerService.showSpinner(true);
      this.service.commentOnWall(reqBody, 'Evaluation').subscribe(
        (data: any) => {
          this.item.Comments.unshift(data);
          this.pp360SpinnerService.showSpinner(false);
          this.sendingComment = false;
        },
        (err: any) => {
          console.log('errrr', err);
          this.pp360SpinnerService.showSpinner(false);
          this.sendingComment = false;
        }
      );
    }
  }
}
