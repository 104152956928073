import { Component, OnInit, Input } from '@angular/core';

import { CoreService } from 'app/shared/services/core.service';
import { Subject } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-import-configuration',
  templateUrl: './import-configuration.component.html',
  styleUrls: ['./import-configuration.component.css']
})
export class ImportConfigurationComponent implements OnInit {
  @Input() companyId: any;

  companyName: string;
  onClose: Subject<any>;
  config: any;

  constructor(
    private coreSrv: CoreService,
    private _bsModalRef: BsModalRef
  ) {
    this.onClose = new Subject();
  }

  ngOnInit(): void {
    if (this.companyId) {
      this.loadData();
    }
  }

  loadData() {
    this.coreSrv
      .loadEmployeeTableMapping(this.companyId)
      .subscribe((obj: any) => {
        this.config = obj;
      });
  }

  cancel() {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  save() {
    this.coreSrv
      .saveEmployeeTableMapping(this.companyId, this.config)
      .subscribe(
        (obj: any) => {
          this.coreSrv.toasterMessage('success', 'Table Mapping', 'Saved!');
          this.onClose.next(true);
          this._bsModalRef.hide();
        },
        (error) => {
          this.coreSrv.toasterMessage('error', 'Table Mapping', error.Message);
          this.onClose.next(false);
          this._bsModalRef.hide();
        }
      );
  }

  public showModal(companyId, companyName): void {
    this.companyId = companyId;
    this.companyName = companyName;
    this.loadData();
  }
}
