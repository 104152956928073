import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild,
  Inject,
  OnDestroy
} from '@angular/core';
import { AuthService } from '../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreService } from '../../shared/services/core.service';
import { BiometricService } from 'app/shared/services/cordova/biometric.service';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { navigationService } from 'app/shared/services/navigation.service';
import * as moment from 'moment';
import 'moment/min/locales';
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest
} from '@azure/msal-browser';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { UserService } from '../../super-admin/user/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['../login.common.scss', './login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  isProcessing: boolean = false;
  forgotSelected: boolean = false;
  loginSelected: boolean = false;
  showResetMailMsg: boolean = false;
  isKeyboardVisible: boolean = false;
  isRememberMe: boolean = true;
  show: boolean = false;
  showtexts: boolean = false;
  coopyright: any;
  model = {
    username: '',
    password: '',
    grant_type: 'password'
  };
  isEmailFocused = false;
  isPasswordFocused = false;
  inValidCredentials = false;
  userdata: any = [];
  slideConfig;
  // count = 0;
  // rememberMe = true;
  @ViewChild(NgForm) ngForm: NgForm;
  @ViewChild('navarrow') navarrow: ElementRef;

  constructor(
    public service: AuthService,
    public router: Router,
    public route: ActivatedRoute,
    public coreService: CoreService,
    public biometricService: BiometricService,
    public translate: TranslateService,
    public userSer: UserService,
    public navService: navigationService,
    private renderer: Renderer2,
    private pp360SpinnerService: Pp360SpinnerService,
    private ref: ChangeDetectorRef,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService
  ) {
    const d = new Date();
    const y = d.getFullYear();

    this.coopyright = y + '-' + (y - 2000 + 1);
  }

  ngOnInit() {
    this.service.getData().subscribe((response: any[]) => {
      let ids: any = localStorage.getItem('newFeaturedIds');
      if (ids) {
        ids = JSON.parse(ids);
        response.forEach((d) => {
          if (d.IsNewFeature) {
            d.IsNewFeature = true;
          }
        });
      }
      this.userdata = response;
      console.log(this.userdata);
    });
    this.forgotSelected = false;
    this.loginSelected = true;
    this.showResetMailMsg = false;
    this.route.queryParams.subscribe((params) => {
      if (params.isForgotPassword) {
        this.hideForm('login');
        if (params.email) {
          this.model.username = params.email;
        }
      }
    });
  }

  ngAfterViewChecked() {
    this.ref.detectChanges();
  }
  ngAfterViewInit(): void {
    this.slideConfig = {
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
      infinite: false,
      draggable: false
    };
  }

  setColorOfButtons(e) {
    const slickDotList = e.slick.$dots;
    if (this.userdata && slickDotList[0]?.children) {
      let dataArray = slickDotList[0].children;
      for (let i = 0; i < dataArray.length; i++) {
        if (this.userdata[i].IsNewFeature) {
          dataArray[i].classList.add('testingClass');
        }
      }
    }
  }

  slickInit(e) {
    this.setColorOfButtons(e);
  }

  beforeChange(e) {
    let slickDotLists = e.slick.$dots;
    if (this.userdata && slickDotLists[0]?.children) {
      let dataArray = slickDotLists[0].children;
      for (let i = 0; i < dataArray.length; i++) {
        if (i === e.currentSlide && this.userdata[i].IsNewFeature) {
          dataArray[i].classList.remove('testingClass');
          // this.count++;
        }
      }
    }
  }

  setNewFeature(e) {
    const ind = e.currentSlide;
    const itemId = this.userdata[ind].$id;
    if (this.userdata[ind] && this.userdata[ind].IsNewFeature) {
      let ids: any = localStorage.getItem('newFeaturedIds');
      if (ids) {
        ids = JSON.parse(ids);
        if (!ids.includes(itemId)) {
          ids.push(itemId);
        }
      } else {
        ids = [itemId];
      }
      localStorage.setItem('newFeaturedIds', JSON.stringify(ids));
    }
  }

  hideForm(e) {
    if (e === 'login') {
      this.forgotSelected = true;
      this.loginSelected = false;
    } else if (e === 'forgot') {
      this.forgotSelected = false;
      this.loginSelected = true;
      this.showResetMailMsg = false;
    } else {
      // this.forgotSelected = false;
      // this.loginSelected = true;
      // this.showResetMailMsg = false;
    }
    this.inValidCredentials = false;
    this.isEmailFocused = false;
    this.isPasswordFocused = false;
  }

  onSubmitForgot(isvalid) {
    if (isvalid) {
      this.inValidCredentials = false;
      this.isProcessing = true;
      this.service.forgot(this.model).subscribe(
        (data: any) => {
          // if (data && data.error_description) {
          //   this.coreSevice.toasterMessage(
          //     "error",
          //     "auth",
          //     data.error_description
          //   );
          // } else {
          this.showResetMailMsg = true;
          // }
          this.isProcessing = false;
        },
        (error) => {
          // this.inValidCredentials = true;
          this.showResetMailMsg = true;

          // this.coreSevice.toasterMessage(
          //   "error",
          //   "auth",
          //   error.error_description ? error.error_description : error.Message
          // );
          this.isProcessing = false;
        },
        () => (this.isProcessing = false)
      );
    }
  }

  onSubmit(isvalid) {
    if (isvalid) {
      this.inValidCredentials = false;
      this.isProcessing = true;
      let cValue = this.isRememberMe;
      if (cValue) {
        localStorage.setItem('keepLoggedIn', 'true');
        sessionStorage.removeItem('keepLoggedIn');
      } else {
        localStorage.setItem('keepLoggedIn', 'false');
        sessionStorage.setItem('keepLoggedIn', 'true');
      }
      this.service.login(this.model).subscribe(
        async (data: any) => {
          this.isProcessing = false;
          if (data.error_description) {
            this.coreService.toasterMessage(
              'error',
              'auth',
              data.error_description
            );
          } else {
            this.postLogin(data);
          }
        },
        (error: any) => {
          this.inValidCredentials = true;
          console.log('login error =>', error);
          // this.coreSevice.toasterMessage(
          //   "error",
          //   "auth",
          //   error.error_description ? error.error_description : error.Message
          // );
          // error = "";
          this.isProcessing = false;
        },
        () => ((this.isProcessing = false), (this.inValidCredentials = false))
      );
    }
  }

  async postLogin(data) {
    await this.service.setUserToken(data);

    if (!data.Theme) {
      this.userdata;
      data.Theme = {
        $id: '2',
        Code: 'RB',
        VariationCode: 'L',
        Name: 'Royal Blue',
        VariationName: 'Light',
        RGB1: 'FFFFFF',
        RGB2: 'F2F2F2',
        RGB3: 'A09AB2',
        RGB4: '676688',
        RGB5: '0C0039',
        RGB6: '0057FF',
        RGB7: 'FF001A',
        RGB8: '1A44C4',
        RGB9: '2CAF66'
      };
      if (data) localStorage.setItem('user-info', JSON.stringify(data));
      this.pp360SpinnerService.showSpinner(true);
      this.userSer
        .setUserTheme({
          Code: 'RB',
          VariationCode: 'L'
        })
        .subscribe(async () => {
          this.pp360SpinnerService.showSpinner(false);
        });
    }

    document.documentElement.style.setProperty(
      '--white',
      '#' + data.Theme.RGB1
    );
    document.documentElement.style.setProperty(
      '--feed-background',
      '#' + data.Theme.RGB2
    );
    document.documentElement.style.setProperty(
      '--login-textfield-color',
      '#' + data.Theme.RGB3
    );
    document.documentElement.style.setProperty(
      '--login-textfield-border',
      '#' + data.Theme.RGB4
    );
    document.documentElement.style.setProperty(
      '--login-text-color',
      '#' + data.Theme.RGB5
    );
    document.documentElement.style.setProperty(
      '--login-button',
      '#' + data.Theme.RGB6
    );
    document.documentElement.style.setProperty(
      '--login-text-error',
      '#' + data.Theme.RGB7
    );
    document.documentElement.style.setProperty(
      '--login-button-hover',
      '#' + data.Theme.RGB8
    );
    document.documentElement.style.setProperty(
      '--okr-green',
      '#' + data.Theme.RGB9
    );

    if (data.roles != 'User') this.getLanguages();
    const lang = data.language;
    let x = lang.match(/en|it|pl|nl|es|pt|de|ru|zh|fr/) ? lang : 'en';
    moment.locale(x);
    if (this.translate.currentLang != x) {
      await this.translate.use(x).toPromise<void>();
    }
    this.service.redirectUser(data.PreferredRole);
    this.navService.setUpdateSideMenu(true);

    if (window['cordova']) {
      window.location.reload();
    }
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  getLanguages() {
    this.isProcessing = true;
    this.service.getLanguages().subscribe(
      (data: any) => {
        this.service.setLanguages(data);
      },
      (error) => {},
      () => {}
    );
  }

  goToFirstLogin() {
    this.router.navigate(['/firstlogin']);
  }

  performBiometricLogin() {
    this.biometricService
      .performBiometricLogin()
      .then((result) => {
        try {
          this.model = JSON.parse(result);
          this.onSubmit(true);
        } catch (error) {
          console.log('PARSE ERROR => ', error);
        }
      })
      .catch((error: any) => {
        console.log('performBiometricLogin ERROR =>', error);
      });
  }

  onRememberMe() {
    if (!this.isRememberMe) {
      this.isRememberMe = true;
    } else if (this.isRememberMe) {
      this.isRememberMe = false;
    }
  }
  myFocus() {
    this.renderer.selectRootElement('#password').focus();
  }
  myFocusEmail() {
    this.renderer.selectRootElement('#emailselect').focus();
  }
  openSite(siteUrl) {
    siteUrl = 'https://patpat360.com/pulse/';
    window.open(siteUrl, '_blank');
  }

  msLogin() {
    if (window['cordova']) {
      this.appMsLogin();
    } else {
      this.desktopMsLogin();
    }
  }

  desktopMsLogin() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            // this.router.navigate(['/sso_error']);
            localStorage.setItem('msal-token', response.accessToken);
            this.getPatPatToken();
            this.setActiveAccount(response.account);
          });
      } else {
        this.authService
          .loginPopup()
          .subscribe((response: AuthenticationResult) => {
            this.setActiveAccount(response.account);
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
        this.router.navigate(['sso_error']);
      }
    }
  }

  appMsLogin() {
    let isAvailable =
      typeof window['cordova'].plugins.msalPlugin !== 'undefined';

    if (!isAvailable) return;

    const options: any = {
      clientId: 'c81dc69a-0b9e-4795-8a9b-d2933ca94478',
      authorities: [
        {
          type: 'AAD',
          audience: 'AzureADandPersonalMicrosoftAccount',
          authorityUrl: '',
          cloudInstance: 'MSALAzurePublicCloudInstance',
          default: true
        }
      ]
    };

    window['cordova'].plugins.msalPlugin.msalInit(
      () => {
        // Success logic goes here
        window['cordova'].plugins.msalPlugin.signInInteractive(
          async (resp) => {
            // resp is an object containing information about the signed in user, see below.
            // Set authToken for bearer authentication inside interceptor
            localStorage.setItem('msal-token', resp.token);
            //await this.nativeStorage.setItem("msal-token", resp.token);
            this.getPatPatToken();
          },
          (err) => {
            // Usually if we get an error it just means the user cancelled the
            // signin process and closed the popup window. Handle this however
            // you want, depending again if you want guest access or not.
            console.log('XXX', 'signInInteractive', JSON.stringify(err));
          }
        );
      },
      (err) => {
        console.log('XXX', 'msalInit', JSON.stringify(err));
        // err has your exception message
      },
      options
    );
  }

  getPatPatToken(): void {
    this.pp360SpinnerService.showSpinner(true);
    this.service.getToken().subscribe(
      (data: any) => {
        this.pp360SpinnerService.showSpinner(false);
        localStorage.removeItem('msal-token');
        this.postLogin(data);
      },
      (error) => {
        this.pp360SpinnerService.showSpinner(false);
      }
    );
  }

  setActiveAccount(account: any) {
    this.authService.instance.setActiveAccount(account);
  }

  ngOnDestroy(): void {}
}
