import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-payout-structure',
  templateUrl: './payout-structure.component.html',
  styleUrls: ['./payout-structure.component.css', '../../common-styles.scss']
})
export class PayoutStructureComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
