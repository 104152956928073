import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { CoreService } from 'app/shared/services/core.service';
import { SharedService } from 'app/shared/services/shared.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'view-okrs-cycle',
  templateUrl: './view-okrs-cycle.component.html',
  styleUrls: ['./view-okrs-cycle.component.scss']
})
export class ViewOkrsCycleComponent implements OnInit {
  selectedOkrCycle: any = [];
  cycle: any = [];
  public onClose: Subject<any>;
  constructor(
    public coreService: CoreService,
    public router: Router,
    public sharedService: SharedService,
    public pp360SpinnerService: Pp360SpinnerService,
    private _bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    console.log(this.selectedOkrCycle);
    console.log(this.cycle);
    this.onClose = new Subject();
  }
  onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }
}
