import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { CoreService } from '../services/core.service';
import { AuthService } from '../../auth/auth.service';

@Injectable()
export class EmployeeGuard implements CanActivate {
  constructor(
    private coreService: CoreService,
    public service: AuthService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const currentUserInfo = this.coreService.getUserInfo();
    if (
      currentUserInfo?.PreferredRole === 'User' ||
      state.url.indexOf('user-profile') !== -1 ||
      state.url.indexOf('/User/story') !== -1 ||
      state.url.indexOf('/notify') !== -1 ||
      state.url.indexOf('/User/orgchart') !== -1
    ) {
      return true;
    } else {
      this.service.redirectUser(currentUserInfo?.PreferredRole);
      return;
    }
  }
}
