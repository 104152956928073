import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DndItemComponent } from './dnd-item.component';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [DndItemComponent],
  exports: [DndItemComponent],
  imports: [CommonModule, SharedModule]
})
export class DndItemModule {}
