import { Component, OnInit } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'app/auth/auth.service';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: [
    './page-not-found.component.scss',
    '../../../auth/login.common.scss'
  ]
})
export class PageNotFoundComponent implements OnInit {
  errId: any;
  lang: any;
  count: number = 0;
  Message: string;
  userdata: any;
  coopyright: any;
  slideConfig = {
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    draggable: false,
    prevArrow: false,
    nextArrow: false
  };
  constructor(
    public coresService: CoreService,
    public translate: TranslateService,
    public service: AuthService,
    public router: Router,
    public activatedRoute: ActivatedRoute
  ) {
    this.errId = this.activatedRoute.snapshot.queryParams['errid'];
    this.lang = this.activatedRoute.snapshot.queryParams['lang'] || 'en';
    this.translate.addLangs([
      'en',
      'it',
      'pl',
      'es',
      'pt',
      'nl',
      'de',
      'zh',
      'fr',
      'ru'
    ]);
    this.translate.use(this.lang);

    const d = new Date();
    const y = d.getFullYear();

    this.coopyright = y + '-' + (y - 2000 + 1);
  }

  ngOnInit() {
    this.Message = this.getErrorMessage();
    console.log(this.Message);
    console.log(this.errId);

    this.service.getData(this.lang).subscribe((response: any[]) => {
      let ids: any = localStorage.getItem('newFeaturedIds');
      if (ids) {
        ids = JSON.parse(ids);
        response.forEach((d) => {
          if (d.IsNewFeature) {
            d.IsNewFeature = ids.includes(d.$id) ? false : true;
          }
        });
      }
      this.userdata = response;
    });
  }

  getErrorMessage() {
    switch (this.errId) {
      case '101':
        this.count = 101;
        return 'ERRORS.INVITATION_EXPIRED';

        break;
      case '102':
        this.count = 102;
        return 'ERRORS.FORGOT_EXPIRED';

        break;
      default:
        return 'ERRORS.SSO_Rejected';
        break;
    }
  }
  backtoLogin() {
    this.router.navigate(['login']);
  }

  onHome() {
    let role = this.coresService.getUserRole();
    let computedRole: any;

    if (role.includes('User')) {
      computedRole = 'User';
    }

    this.router.navigate([
      `${computedRole == 'User' ? ['User/wall/feed'] : ['SuperAdmin']}`
    ]);
  }

  setNewFeature(e) {
    const ind = e.currentSlide;
    const itemId = this.userdata[ind].$id;
    if (this.userdata[ind] && this.userdata[ind].IsNewFeature) {
      let ids: any = localStorage.getItem('newFeaturedIds');
      if (ids) {
        ids = JSON.parse(ids);
        if (!ids.includes(itemId)) {
          ids.push(itemId);
        }
      } else {
        ids = [itemId];
      }
      localStorage.setItem('newFeaturedIds', JSON.stringify(ids));
    }
  }

  beforeChange(e) {
    let slickDotLists = e.slick.$dots;
    if (this.userdata && slickDotLists[0]?.children) {
      let dataArray = slickDotLists[0].children;
      for (let i = 0; i < dataArray.length; i++) {
        if (i === e.currentSlide && this.userdata[i].IsNewFeature) {
          dataArray[i].classList.remove('testingClass');
        }
      }
    }
  }

  setColorOfButtons(e) {
    const slickDotList = e.slick.$dots;
    if (this.userdata && slickDotList[0]?.children) {
      let dataArray = slickDotList[0].children;
      for (let i = 0; i < dataArray.length; i++) {
        if (this.userdata[i].IsNewFeature) {
          dataArray[i].classList.add('testingClass');
        }
      }
    }
  }

  slickInit(e) {
    this.setColorOfButtons(e);
    console.log(this.userdata);
  }
}
