import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { EmployeeTeamPopUpComponent } from 'app/shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { WallService } from '../wall.service';
import { InappropriateDialogComponent } from './../inappropriate-dialog/inappropriate-dialog.component';
import { ListOfEmployeesComponent } from 'app/shared/components/list-of-employees/list-of-employees.component';
import { SharedService } from 'app/shared/services/shared.service';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { NewPatComponent } from 'app/shared/components/new-pat/new-pat.component';
import { Router } from '@angular/router';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { RepatDialogComponent } from '../repat-dialog/repat-dialog.component';

@Component({
  selector: 'feed-pat',
  templateUrl: './feed-pat.component.html',
  styleUrls: [
    './feed-pat.component.scss',
    '../common-feed-style.scss',
    '../../../common_styles.scss'
  ]
})
export class FeedPatComponent implements OnInit {
  @Input() item: any;
  @Input() inWall: boolean;
  @Input() isPatWithDesc: boolean;
  @Input() showReported: boolean;
  @Input() readOnly: boolean;
  repattedBy: any;
  @Output() reloadRequired = new EventEmitter();
  @Output() feedDeleted = new EventEmitter();
  @Output() feedUpdated = new EventEmitter<any>();
  @Output() reportedAsInappropriate = new EventEmitter<any>();
  @Output() fetchSinglePat = new EventEmitter<any>();
  @Input() selectedTab: any;
  @Input() inappropriatebtn: any;

  bsModalRef: BsModalRef;
  userInfo: any;
  userData: any;
  router: any;
  team: any;
  isAfterContentChecked: boolean = false;

  hoverImage: boolean = false;
  showRepatOption: boolean = false;
  empInfo: any;
  empInfos: any;
  empInfod: any;
  sendingComment = false;
  lengthComment: Number;
  langcheck: boolean = false;
  profileName: any;
  changeIconss: boolean;

  constructor(
    public coreService: CoreService,
    private modalService: BsModalService,
    private service: WallService,
    private route: Router,
    private sharedService: SharedService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {
    this.userInfo = this.coreService.getUserInfo();
  }

  ngOnInit() {
    if (this.inWall) {
      this.repattedBy = JSON.parse(JSON.stringify(this.item));
    }
    if (
      (this.userInfo.language == 'pl' || this.userInfo.language == 'ru') &&
      this.item.Author.Id === this.userInfo.linkedEmployeeId
    ) {
      this.langcheck = true;
    } else {
      if (
        this.userInfo.language == 'en' ||
        this.userInfo.language == 'es' ||
        this.userInfo.language == 'fr' ||
        this.userInfo.language == 'it' ||
        this.userInfo.language == 'nl' ||
        this.userInfo.language == 'pt'
      ) {
        this.langcheck = false;
      }
    }

    this.profileName = this.sortName(this.item.Author.FullName);

    this.item.totalComments = 0;
    if (this.item.InternalPat && !this.item.Description) {
      this.repattedBy = JSON.parse(JSON.stringify(this.item));
      this.item.InternalPat['Skill'] = this.item?.Skill;
      this.item = this.item.InternalPat;
    }
    this.userInfo = this.coreService.getUserInfo();
    if (this.service.profiles.length < 0) {
      const employee = this.service.profiles.find(
        (x) => x.Id === this.item.Target.Items[0].Id
      );
      if (employee) {
        this.empInfo = employee;
      } else {
        this.getProfileInfo();
      }
      const employees = this.service.profiles.find(
        (x) => x.Id === this.item.Author.Id
      );
      if (employees) {
        this.empInfos = employees;
      } else {
        this.getProfileInfos();
      }
      if (this.repattedBy && !this.readOnly) {
        const employeesd = this.service.profiles.find(
          (x) => x.Id === this.repattedBy?.Author.Id
        );
        if (employeesd) {
          this.empInfod = employees;
        } else {
          this.getProfileInfod();
        }
      }
    } else {
      this.getProfileInfo();
      this.getProfileInfos();
      if (this.repattedBy && !this.readOnly) {
        this.getProfileInfod();
      } else {
      }
    }

    this.item.Date = this.sharedService.getLocalDate(this.item.Date);
    this.item.Target.Employees.forEach((e) => {
      if (e.Id == this.userInfo.linkedEmployeeId) {
        this.item['isMine'] = true;
      }
    });

    this.item['isComment'] = false;
    this.item['commentArr'] = [];
    this.item.Comments.reverse();
    if (this.item.Comments.length) {
      for (
        let i = this.item.Comments.length - 1;
        i >= this.item.Comments.length - 3;
        i--
      ) {
        this.item['commentArr'].push(this.item.Comments[i]);
      }
    }
    if (this.item.Likes.length) {
      for (let i = 0; i < this.item.Likes.length; i++) {
        if (this.item.Likes[i].Author.Id == this.userInfo.linkedEmployeeId) {
          this.item['isCheckLike'] = true;
          break;
        }
      }
    }
    if (this.item.RePats.length) {
      for (let i = 0; i < this.item.RePats.length; i++) {
        if (this.item.RePats[i].Author.Id == this.userInfo.linkedEmployeeId) {
          this.item['isCheckRePat'] = true;
          break;
        }
      }
    }
    this.item.NumberOfTargets =
      this.item.Target.Employees.length + this.item.Target.Teams.length;

    this.userData = this.coreService.getUserInfo();
    this.manageCountComment();

    this.profileName = this.sortName(this.item.Author.FullName);
  }
  ngDoCheck() {
    this.item.Description = this.item?.Description?.replaceAll('<br>', '')
      .replaceAll('<br/>', '')
      .replace(/\r?\n|\r/, '');
    this.manageCountComment();
    this.lengthComment = this.item.Comments.length;
  }
  sortName(Datas) {
    if (Datas.split(' ').length > 2) {
      let fname = Datas.split(' ')[0];
      let lname = Datas.split(' ')[2];
      return fname + ' ' + lname;
    } else {
      return Datas;
    }
  }

  updateCount(comments) {
    for (let i of comments) {
      if (i.SubComments.length > 0) {
        this.item.totalComments += i.SubComments.length;
        this.updateCount(i.SubComments);
      }
    }
  }

  manageCountComment() {
    this.item.totalComments = 0;
    if (this.item.Comments.length > 0) {
      this.item.totalComments =
        this.item.Comments.length + this.item.totalComments;
      this.updateCount(this.item.Comments);
    }
  }

  getProfileInfod() {
    this.service
      .getEmployeeInfo(this.repattedBy?.Author?.Id)
      .subscribe((data: any) => {
        if (data) {
          this.empInfod = data;
          const employee = this.service.profiles.find(
            (x) => x.Id === this.repattedBy?.Author.Id
          );
          if (!employee) {
            this.service.profiles.push(data);
          }
        }
      });
  }

  getProfileInfo() {
    this.service
      .getEmployeeInfo(this.item.Target.Items[0].Id)
      .subscribe((data: any) => {
        if (data) {
          this.empInfo = data;
          const employee = this.service.profiles.find(
            (x) => x.Id === this.item.Target.Items[0].Id
          );
          if (!employee) {
            this.service.profiles.push(data);
          }
        }
      });
  }

  getProfileInfos() {
    this.service.getEmployeeInfo(this.item.Author.Id).subscribe((data: any) => {
      if (data) {
        this.empInfos = data;
        const employees = this.service.profiles.find(
          (x) => x.Id === this.item.Author.Id
        );
        if (!employees) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  showRepat($event) {
    // this.showRepatOption=true
    this.changeIconss = false;

    $event.stopPropagation();
    this.showRepatOption = !this.showRepatOption;
  }

  @HostListener('document:click', ['$event']) onDocumentClick(event) {
    this.showRepatOption = false;
  }

  delete(item: any, comment: any) {
    if (comment) {
      console.log(comment);
    } else if (this.repattedBy && !this.readOnly) {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DELETE'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DELETE_POST'),
        subtitle: this.coreService.getTranslation('WALL.DELETE_REPAT_TITLE')
      };
      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation'
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.pp360SpinnerService.showSpinner(true);
            this.service.deletePat(item.Id).subscribe(
              (data: any) => {
                item.expandMenu = false;
                this.feedDeleted.emit(item);
                this.coreService.setShowEliminated({ type: 'Pat' });
                this.pp360SpinnerService.showSpinner(false);
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
                this.pp360SpinnerService.showSpinner(false);
              }
            );
          }
        }
      );
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DELETE'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DELETE_POST'),
        subtitle: this.coreService.getTranslation('WALL.DELETE_PAT_TITLE')
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation'
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.pp360SpinnerService.showSpinner(true);
            this.service.deletePat(item.Id).subscribe(
              (data: any) => {
                item.expandMenu = false;
                this.feedDeleted.emit(item);
                this.coreService.setShowEliminated({ type: 'Pat' });
                this.pp360SpinnerService.showSpinner(false);
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
                this.pp360SpinnerService.showSpinner(false);
              }
            );
          }
        }
      );
    }
  }

  async edit(item: any, comment: any) {
    if (comment) {
      console.log(comment);
    } else {
      //recupero la lista degli impiegati
      let all_Employees_Teams: any = await this.sharedService
        .getFilterEmployees(this.userInfo.linkedEmployeeId, 'Pat', 'List')
        .toPromise();
      if (all_Employees_Teams.length > 0) {
        all_Employees_Teams.forEach((element: any) => {
          if (element.Id && element.Id.indexOf(':') < 0) {
            if (element.Type == 'Team') {
              element['Name'] = element.Name + ' Team';
              element['Id'] = 't:' + element['Id'];
            } else {
              element['Name'] = element.Name;
              element['Id'] = 'e:' + element['Id'];
            }
          }

          element.isPreselect =
            element.Type == 'Employee' &&
            item.Target.Employees.findIndex((t) => element.Id == 'e:' + t.Id) >=
              0;
        });

        if (this.item.Goal && this.item.PostSubject == 2) {
          this.item.patType = 'goal';
        } else if (this.item.Skill && this.item.PostSubject == 1) {
          this.item.patType = 'behavior';
        } else {
          this.item.patType = 'skill';
        }
        const modal = this.modalService.show(NewPatComponent, {
          class: 'wallPopup',
          ignoreBackdropClick: true
        });

        (<NewPatComponent>modal.content).showModal({
          pat: this.item,
          all_Employees_Teams: all_Employees_Teams
        });

        (<NewPatComponent>modal.content).onClose.subscribe(
          (newPatData: boolean) => {
            this.feedUpdated.emit(newPatData);
            // this.reloadRequired.emit();
          }
        );
      }
    }
  }

  openRepatDialog(item) {
    // console.log(item);
    this.showRepatOption = false;
    const modal = this.modalService.show(RepatDialogComponent, {
      class: 'wallPopup repat-preview',
      ignoreBackdropClick: true,
      initialState: { pat: item }
    });

    (<RepatDialogComponent>modal.content).onClose.subscribe((res: boolean) => {
      console.log(res);
    });
  }

  showHideTranslated(item, property) {
    if (item.ShowTranslated) {
      item.ShowTranslated = !item.ShowTranslated;
    } else {
      let selectedLang = this.userInfo.language;
      const text = item[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          item['TranslatedText'] = result.TranslatedText;
          item.ShowTranslated = true;
        });
    }
  }
  goToProfile(idofemployee) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: idofemployee },
      replaceUrl: true
    });
    // this.bsModalRef.hide();
  }
  shortcutForPat(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;
    this.bsModalRef = this.modalService.show(EmployeeTeamPopUpComponent, {
      class: 'width232'
    });
    this.bsModalRef.content.team = team;

    this.bsModalRef.content.addNewPat.subscribe((data: any) => {
      this.reloadRequired.emit();
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  addALike(item) {
    item['isCheckLike'] = true;
    var reqBody = {
      TapFeedId: item.Id,
      AuthorId: this.userInfo.linkedEmployeeId
    };
    this.service.postALike(reqBody).subscribe(
      (data: any) => {
        item.Likes.push(data);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  removeALike(item) {
    item['isCheckLike'] = false;

    this.service.deleteALike(item.Id, this.userInfo.linkedEmployeeId).subscribe(
      (data: any) => {
        var index = item.Likes.findIndex(
          (e: any) => e.AuthorId === this.userInfo.linkedEmployeeId
        );
        item.Likes.splice(index, 1);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  listOfEmp(empTeamList, mode) {
    this.bsModalRef = this.modalService.show(ListOfEmployeesComponent, {
      class: 'modal-md pop-width list-of-emp',
      ignoreBackdropClick: true
    });

    (<ListOfEmployeesComponent>this.bsModalRef.content).onClose.subscribe(
      (res: { type: string; emp: any }) => {
        if (res.type === 'repat') {
          const rePat = this.item.RePats.find(
            (x) => x.Author.Id === res.emp.Id
          );
          console.log(rePat);
          if (rePat) {
            this.fetchSinglePat.emit(rePat.TapFeedId);
          }
        }
        // this.reloadRequired.emit();
      }
    );
    this.bsModalRef.content.empTeamList = empTeamList;
    this.bsModalRef.content.mode = mode;
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  showReactions(item, type) {
    if (type === 'comment') {
      this.onComment(item);
    } else {
      var emps = [];
      var items: any;
      switch (type) {
        case 'like':
          items = item.Likes;
          break;

        case 'repat':
          items = item.RePats;
          break;
      }

      items.forEach((element: any) => {
        var e = {
          Description: element.Author.JobTitle,
          Id: element.Author.Id,
          Location: element.Author.Location,
          Name: element.Author.Name,
          Type: 'Employee',
          Picture: element.Author.Picture,
          SomethingAbout: element.Author.SomethingAbout
        };
        emps.push(e);
      });
      if (emps.length > 0) this.listOfEmp(emps, type);
    }
  }

  rePat(item) {
    this.showRepatOption = false;
    this.pp360SpinnerService.showSpinner(true);
    item['isCheckRePat'] = true;
    var reqBody = {
      TapFeedId: item.Id,
      AuthorId: this.userInfo.linkedEmployeeId
    };

    this.service.postARepat(reqBody).subscribe(
      (data: any) => {
        this.pp360SpinnerService.showSpinner(false);
        item.RePats.push(data);
        this.coreService.setShowPublished({ type: 'Pat', isEdit: false });
      },
      (err: any) => {
        this.pp360SpinnerService.showSpinner(false);
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  removeRePat(item) {
    return;
    console.log(item.RePats);
    item['isCheckRePat'] = false;
    const tapFeed = item.RePats.filter(
      (e: any) => e.Author.Id === this.userInfo.linkedEmployeeId
    );
    console.log(tapFeed[0].TapFeedId);

    this.service.deleteARepat(tapFeed[0].Id).subscribe(
      (data: any) => {
        var index = item.RePats.findIndex(
          (e: any) => e.AuthorId === this.userInfo.linkedEmployeeId
        );
        item.RePats.splice(index, 1);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  onComment(item) {
    item.isComment = !item.isComment;
  }

  showAllComments(item) {
    if (item.Comments.length) {
      item.commentArr = item.Comments;
    }
  }

  expandMenu(item) {
    item.expandMenu = !item.expandMenu;
  }

  reportInnapropriate(item, comment: any = null) {
    item.expandMenu = false;
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    if (comment) {
      msg = msg.replace('{{item.Author.Name}}', comment.Author.Name);
    } else {
      msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    }

    const content: any = {
      title: msg,
      type: 'Pat'
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation'
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.Id,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 0
          };

          switch (item.Type) {
            case 'ReceivedAsk':
              req.FeedType = 3;
              break;
            case 'ReceivedEvaluation':
              req.FeedType = 2;
              break;
            case 'ReceivedToken':
              req.FeedType = 1;
              break;
            case 'Pat':
              req.FeedType = 0;
              break;
            default: //COMMENT
              req.FeedType = 4;
              break;
          }

          if (comment) {
            req.FeedType = 4;
            req.FeedId = comment.Id;
            item['commentId'] = comment.Id;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }
  name() {
    if (this.repattedBy && this.readOnly) {
      return 'RePat';
    } else {
      return 'pat';
    }
  }
  patName() {
    return 'pat';
  }
  deleteName() {
    if (this.repattedBy && !this.readOnly) {
      return 'RePat';
    }
  }
  sendMsg(comment) {
    this.item.totalComments++;
    this.sendingComment = true;
    let reqBody = {
      TapFeedId: this.item.Id,
      AuthorId: this.userInfo.linkedEmployeeId,
      Text: comment?.comment ? comment?.comment : ' ',
      Picture: comment?.image ? comment?.image : null
    };
    // if (reqBody.Text) {
    this.pp360SpinnerService.showSpinner(true);
    this.service.commentOnWall(reqBody).subscribe(
      (data: any) => {
        this.item.Comments.unshift(data);
        this.sendingComment = false;
        this.pp360SpinnerService.showSpinner(false);
        // this.toRefresh=false;
      },
      (err: any) => {
        this.sendingComment = false;
        this.pp360SpinnerService.showSpinner(false);
        // console.log("errrr", err);
      }
    );
    // }
  }

  getShortEmployeeName(employee) {
    var name =
      (employee.SecondName ? employee.SecondName : '') +
      ' ' +
      employee.FirstName;
    var len = 35;
    if (window.innerWidth < 350) {
      len = 21;
    }
    if (name.length > len) {
      name = employee.SecondName.charAt(0) + '.' + ' ' + employee.FirstName;
    }

    return name;
  }

  getPatImage(item: any) {
    // se l'immagine contiene un base 64 invece di un url
    return item.ImagePath.indexOf('data:image/jpeg;base64,') >= 0
      ? item.ImagePath
      : this.coreService.getImageURL(item.ImagePath, null, item.IsReported);
  }
}
