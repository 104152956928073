import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import Quill from 'quill';
import 'quill-emoji/dist/quill-emoji.js';
import * as Emoji from 'quill-emoji';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
Quill.register('modules/emoji', Emoji);
@Component({
  selector: 'pp360-editor',
  templateUrl: './pp360-editor.component.html',
  styleUrls: ['./pp360-editor.component.scss']
})
export class Pp360EditorComponent implements OnInit, OnDestroy {
  quillConfig;
  @Input() placeholder: string;
  @Input() height: string;
  @Input() width: string;
  @Input() content: string;
  @Input() variables = [];
  @Input() isDisabled = false;
  size = { height: '140px', width: '310px' };
  @Output() contentChange = new EventEmitter();
  @ViewChild('editor') editor;
  onContentChange: Subject<any> = new Subject();
  subscription: Subscription;
  constructor() {}

  ngOnInit(): void {
    // console.log(this.editor)
    this.size.height = this.height;
    this.size.width = this.width;
    this.quillConfig = {
      'emoji-shortname': true,
      'emoji-textarea': true,
      'emoji-toolbar': true,
      toolbar: [
        ['bold', 'underline', 'italic'],
        ['emoji'],
        [{ list: 'ordered' }, { list: 'bullet' }]
      ]
    };
    this.subscription = this.onContentChange
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.contentChange.emit(this.content);
      });
  }

  onContentChanged() {
    this.onContentChange.next(true);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onClick(val) {
    const index = this.editor.quillEditor.selection.savedRange.index;
    this.editor.quillEditor.insertText(index, ' [' + val.value + '] ', {
      bold: true,
      color: '#004d7f'
    });
    this.content = this.editor.quillEditor.root.innerHTML;
    this.contentChange.emit(this.content);
  }
}
