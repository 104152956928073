import { Component, OnInit, Input } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CoreService } from 'app/shared/services/core.service';
import { EmployeeTeamPopUpComponent } from 'app/shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { ConstantPool } from '@angular/compiler';
import { Router } from '@angular/router';
import { WallService } from 'app/employee/wall/wall.service';
import { Subject } from 'rxjs';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';

@Component({
  selector: 'app-list-of-employees',
  templateUrl: './list-of-employees.component.html',
  styleUrls: ['./list-of-employees.component.scss']
})
export class ListOfEmployeesComponent implements OnInit {
  isAfterContentChecked: boolean = false;
  empTeamList: any;
  Verb = '';
  isMobile: boolean;
  @Input() searchedSkillName: any;
  @Input() searchEmployeeTemplate: any;
  isEmployee: boolean = false;
  repatVar: boolean = false;
  innerModalRef: any;
  userData: any;
  isVisualizations: boolean = false;
  isReviewer360: boolean = false;
  isreviewerManager: boolean = false;
  searchText;

  onClose: Subject<{ type: string; emp: any }>;
  praise: any;
  theWall: boolean = false;
  defaultList: any;
  constructor(
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private route: Router,
    private service: WallService,
    public screenSizeService: ScreenSizeService,
    public coreService: CoreService
  ) {
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  ngOnInit() {
    this.onClose = new Subject();
    this.userData = this.coreService.getUserInfo();
    this.theWall = true;
  }

  getProfileInfo(emp) {
    this.service.getEmployeeInfo(emp.Id).subscribe((data: any) => {
      if (data) {
        emp.empInfo = data;
        const employee = this.service.profiles.find((x) => x.Id === emp.Id);
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  ngAfterContentChecked() {
    if (this.bsModalRef['content'] && !this.isAfterContentChecked) {
      this.empTeamList = this.bsModalRef['content'].empTeamList || [];
      this.defaultList = this.bsModalRef['content'].empTeamList || [];
      this.praise = this.bsModalRef['content'].praise || false;
      this.empTeamList.forEach((emp: any) => {
        if (this.service.profiles.length) {
          const employee = this.service.profiles.find((x) => x.Id === emp.Id);
          if (employee) {
            emp.empInfo = employee;
          } else {
            this.getProfileInfo(emp);
          }
        } else {
          this.getProfileInfo(emp);
        }
      });
      this.empTeamList.sort((a: any, b: any) => {
        return a.Name.toLowerCase() > b.Name.toLowerCase()
          ? 1
          : a.Name.toLowerCase() < b.Name.toLowerCase()
            ? -1
            : 0;
      });
      var mode = this.bsModalRef['content'].mode || 'list';
      console.log(mode);
      if (mode === 'repat') {
        this.repatVar = true;
      }
      var len = this.empTeamList.length;
      var msg = '';
      switch (mode) {
        case 'list':
          msg = this.coreService.getTranslation('Generic.Target2');
          break;
        case 'like':
          msg =
            len > 1
              ? this.coreService.getTranslation('Generic.Liked2')
              : this.coreService.getTranslation('Generic.Liked1');

          break;
        case 'repat':
          msg =
            len > 1
              ? this.coreService.getTranslation('Generic.Repat2')
              : this.coreService.getTranslation('Generic.Repat1');
          break;
        case 'repatpart':
          msg =
            len > 1
              ? this.coreService.getTranslation('Generic.Repatpart2')
              : this.coreService.getTranslation('Generic.Repatpart1');
          break;
        case 'visualizations':
          msg = this.coreService.getTranslation('PRAISES.NumVisualization', {
            N: len
          });
          this.isVisualizations = true;
          break;
        case 'reviewer360':
          msg =
            len > 1
              ? this.coreService.getTranslation(
                  'PERFORMANCE_REVIEW.Reviewers.360Reviewers'
                )
              : this.coreService.getTranslation(
                  'PERFORMANCE_REVIEW.Reviewers.360Reviewer'
                );
          this.isReviewer360 = true;
          break;
        case 'reviewerManager':
          msg = this.coreService.getTranslation(
            'PERFORMANCE_REVIEW.Reviewers.ManagerReviewer'
          );
          this.isreviewerManager = true;
          break;
      }
      msg = msg.replace('{0}', len);
      this.Verb = msg;

      this.empTeamList.forEach((el) => {
        if (el.Type == 'Employee') this.isEmployee = true;
      });
      this.isAfterContentChecked = true;
    }
  }
  seerepat(emp) {
    this.onClose.next({ type: 'repat', emp });
    this.bsModalRef.hide();
  }
  goToProfile(emp) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: emp.Id },
      replaceUrl: true
    });
    this.bsModalRef.hide();
  }
  shortcutForPat(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;
    this.innerModalRef = this.modalService.show(EmployeeTeamPopUpComponent, {
      class: 'popup-user'
    });
    this.innerModalRef.content.team = team;
    this.innerModalRef.content.closeBtnName = 'Close';
  }
  searchEmployee($event) {
    let value = $event.target.value;
    if (!value) {
      this.empTeamList = this.defaultList;
      return this.empTeamList;
    } else if (value.length) {
      this.empTeamList = this.empTeamList.filter((item) => {
        return item.empInfo.Name.toLowerCase().includes(value);
      });
    }
  }
}
