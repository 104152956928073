//import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PlansComponent } from './plans/plans.component';
import { ResultsComponent } from './results/results.component';

const routes: Routes = [
  { path: 'plans', component: PlansComponent },
  { path: 'results', component: ResultsComponent }
];

//export const AdminLTIRouting: ModuleWithProviders = RouterModule.forChild(routes);

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AdminLTIRouting {}
