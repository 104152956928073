import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CoreService } from '../../shared/services/core.service';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss', '../../common_styles.scss']
})
export class ConfirmDialogComponent implements OnInit {
  public active: boolean = false;
  public body: string = 'Are you sure you want to delete?';
  public title: string = 'Delete confirmation';
  public onClose: Subject<any>;

  public constructor(
    private _bsModalRef: BsModalRef,
    private coreService: CoreService
  ) {}

  public ngOnInit(): void {
    this.onClose = new Subject();
  }

  public showConfirmationModal(
    type,
    alertMessage = null,
    question = null
  ): void {
    this.setTitleBody(type);
    if (alertMessage) {
      this.body += "<br/><span class='text-danger'>" + alertMessage + '</span>';
    }
    if (question) {
      this.body += '<br/><span>' + question + '</span>';
    }
  }

  public showGenericConfirmationModal(
    title,
    body,
    alertMessage = null,
    question = null
  ): void {
    this.title = title;
    this.body = body;
    if (alertMessage) {
      this.body += "<br/><span class='text-danger'>" + alertMessage + '</span>';
    }
    if (question) {
      this.body += '<br/><span>' + question + '</span>';
    }
  }

  setTitleBody(type) {
    switch (type) {
      case 'delete':
        this.title = this.coreService.getTranslation(
          'Messages.DeleteConfirmation'
        );
        this.body = this.coreService.getTranslation('Messages.SureDelete');
        break;
      case 'accept':
        this.title = this.coreService.getTranslation(
          'Messages.AcceptConfirmation'
        );
        this.body = this.coreService.getTranslation('Messages.SureAccept');
        break;
      case 'submitted':
        this.title = this.coreService.getTranslation(
          'Messages.EvaluationConfirmation'
        );
        this.body = this.coreService.getTranslation('Messages.OnceSubmitted');
        break;
      default:
        this.title = this.title;
        this.body = this.body;
    }
    this.active = true;
  }

  public onConfirm(): void {
    if (!this.active) return;
    this.active = false;
    this.onClose.next(true);
    this._bsModalRef.hide();
  }

  public onCancel(): void {
    this.active = false;
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.active = false;
    this.onClose.next(null);
    this._bsModalRef.hide();
  }
}
