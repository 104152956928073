import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
// Services
import { CycleManagerService } from '../cycle-manager.service';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
import { CompanyReportService } from 'app/company-report/company-report.service';
import { CyclesUtilitiesService } from 'app/shared/services/cycles-utilities.service';

// Components
import {
  ObjSheetPopupComponent,
  REFERRAL_ACTION,
  REFERRAL,
  VIEW_MODE
} from 'app/company-report/objectives-individual/objective/obj-sheet-popup/obj-sheet-popup.component';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//Chart.register(ChartDataLabels)

//Utilities
import * as moment from 'moment';
import { tickStep } from 'd3-array';
import { BlankLayoutComponent } from '../../layouts/blankLayout.component';
import * as Vibrant from 'node-vibrant/dist/vibrant.js';

export enum periodType {
  CYCLE_START = 'CycleStart',
  WEEK = 'Week',
  WTD = 'WTD',
  MONTH = 'Month',
  MTD = 'MTD',
  QUARTER = 'Quarter',
  QTD = 'QTD',
  SEMESTER = 'Semester'
}

@Component({
  selector: 'app-okr-dashboard',
  templateUrl: './okr-dashboard.component.html',
  styleUrls: [
    './okr-dashboard.component.scss',
    '../../../../common_styles.scss',
    '../../../../magic-checkbox.scss'
  ]
})
export class OkrDashboardComponent implements OnInit {
  @Input() cycle: any;
  @Input() selectedLang: any;
  @Input() manager: string;
  @Input() isGlobal: boolean;

  okrProgress: any;
  okrProgressFilter: any;
  currentUserInfo: any;
  iamManager: boolean;
  isSuperAdmin: boolean;
  isCompanyAdmin: boolean;
  isLocalAdmin: boolean;
  groupBy: string = 'ByObjective';

  // PROGRESS
  catSortType: string;
  valSortType: string;

  // CHART
  private chart: ElementRef;
  @ViewChild('chart') set _chart(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.chart = content;
    }
  }
  myChart: any;
  graphData: any;
  currentZoom: number = 0; // 0 base, 1 zoomato

  // DETAILS TABLE
  DetailedResultsOrig: any;
  editMode: boolean = false;
  tableSortType: any = {
    OKRs: '',
    Progress: '',
    Pace: '',
    Updated: '',
    Owner: '',
    Manager: ''
  };
  nudgesSent: any;

  openedDetailsMap = {};

  // Filters
  Period: string;
  CategoriesFilter: any;
  SelectedCategoriesFilter: any;
  OwnersFilter: any;
  SelectedOwnersFilter: any;
  TeamsFilter: any;
  SelectedTeamsFilter: any;
  RolesFilter: any;
  SelectedRolesFilter: any;
  LocationsFilter: any;
  SelectedLocationsFilter: any;
  SexFilter: any;
  SelectedSexFilter: any;
  ManagersFilter: any;
  SelectedManagersFilter: any;

  filterSettingsSingle: any = {
    singleSelection: true,
    enableSearchFilter: true,
    enableFilterSelectAll: false,
    classes: 'multiselectDropdown singleSelection',
    showCheckbox: false,
    enableCheckAll: false,
    autoPosition: true,
    badgeShowLimit: 1,
    searchBy: ['itemName'],
    lazyLoading: false
  };

  filterSettings: any = {
    singleSelection: false,
    enableSearchFilter: true,
    enableFilterSelectAll: false,
    classes: 'multiselectDropdown',
    showCheckbox: true,
    enableCheckAll: false,
    autoPosition: true,
    badgeShowLimit: 1,
    searchBy: ['itemName'],
    lazyLoading: true
  };

  currentGraph = 'GroupGraph';

  constructor(
    private cycleManagerService: CycleManagerService,
    private coreService: CoreService,
    private modalService: BsModalService,
    private translateService: TranslateService,
    private companyReportService: CompanyReportService,
    public cyclesUtilitiesService: CyclesUtilitiesService,
    private ref: ChangeDetectorRef
  ) {
    this.currentUserInfo = this.coreService.getUserInfo();
    this.iamManager =
      this.currentUserInfo.isManager || this.currentUserInfo.isEvaluator;
    this.isSuperAdmin = this.currentUserInfo.roles.indexOf('SuperAdmin') >= 0;
    this.isCompanyAdmin =
      this.currentUserInfo.roles.indexOf('CompanyAdmin') >= 0;
    this.isLocalAdmin = this.currentUserInfo.roles.indexOf('LocalAdmin') >= 0;
    this.Period = 'Week';

    this.initModelsFilter();
  }

  ngOnInit(): void {
    this.okrProgressFilter = {
      CycleId: this.cycle.Id,
      GroupBy: this.groupBy,
      Period: periodType.WEEK
    };

    this.getProgress();

    $(window).resize(() => {
      if (this.currentZoom == 1) {
        this.zoomIn();
      } else {
        this.zoomOut(true);
      }
    });
  }

  initModelsFilter() {
    this.SelectedCategoriesFilter = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation(
          'OKR_MEASURES_DASHBOARD.FILTERS.All'
        )
      }
    ];

    this.SelectedOwnersFilter = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation(
          'OKR_MEASURES_DASHBOARD.FILTERS.All'
        )
      }
    ];

    this.SelectedTeamsFilter = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation(
          'OKR_MEASURES_DASHBOARD.FILTERS.All'
        )
      }
    ];

    this.SelectedRolesFilter = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation(
          'OKR_MEASURES_DASHBOARD.FILTERS.All'
        )
      }
    ];

    this.SelectedLocationsFilter = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation(
          'OKR_MEASURES_DASHBOARD.FILTERS.All'
        )
      }
    ];

    this.SelectedSexFilter = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation(
          'OKR_MEASURES_DASHBOARD.FILTERS.All'
        )
      }
    ];

    this.SelectedManagersFilter = [
      {
        id: 'ALL',
        itemName: this.coreService.getTranslation(
          'OKR_MEASURES_DASHBOARD.FILTERS.All'
        )
      }
    ];

    this.filterSettings['noDataLabel'] = this.coreService.getTranslation(
      'Generic.NoDataAvailable'
    );
    this.filterSettings['filterUnSelectAllText'] =
      this.coreService.getTranslation('Generic.filterUnSelectAllText');
    this.filterSettings['filterSelectAllText'] =
      this.coreService.getTranslation('Generic.filterSelectAllText');
    this.filterSettings['searchPlaceholderText'] =
      this.coreService.getTranslation('Generic.Search');

    this.filterSettingsSingle['noDataLabel'] = this.coreService.getTranslation(
      'Generic.NoDataAvailable'
    );
    this.filterSettingsSingle['filterUnSelectAllText'] =
      this.coreService.getTranslation('Generic.filterUnSelectAllText');
    this.filterSettingsSingle['filterSelectAllText'] =
      this.coreService.getTranslation('Generic.filterSelectAllText');
    this.filterSettingsSingle['searchPlaceholderText'] =
      this.coreService.getTranslation('Generic.Search');
  }

  changeGroupBy(group: string) {
    this.groupBy = group;
    this.okrProgressFilter['GroupBy'] = this.groupBy;
    this.currentZoom = 0;
    this.currentGraph = 'GroupGraph';
    this.resetFilters();
    // this.getProgress();
  }

  getProgress(onlyData: boolean = false, excludeChart: boolean = false) {
    this.openedDetailsMap = {};
    if (this.okrProgress && this.okrProgress.DetailedResults) {
      if (this.groupBy === 'ByObjective') {
        this.okrProgress.DetailedResults.forEach((e) => {
          this.openedDetailsMap[e.Id] = e.ShowDetails;
        });
      } else if (this.groupBy === 'ByOwner') {
        this.okrProgress.DetailedResults.forEach((e) => {
          this.openedDetailsMap[e.Id] = {
            expandMainRow: e.expandMainRow
          };
          if (e.DetailedResults) {
            e.DetailedResults.forEach((d) => {
              this.openedDetailsMap[e.Id][d.Id] = {
                ShowDetails: d.ShowDetails
              };
            });
          }
        });
      }
    }

    const api =
      this.manager === 'AsManager'
        ? 'singleInExecutionAsManager'
        : 'singleInExecution';

    this.cycleManagerService[api](
      this.okrProgressFilter,
      this.isGlobal
    ).subscribe(async (res: any) => {
      this.DetailedResultsOrig = JSON.parse(
        JSON.stringify(res.DetailedResults)
      );
      this.okrProgress = res;
      // console.log('getProgress', this.okrProgress);
      this.sortDetails(this.currentColumnSorted, true); // Forzo il riordino, all'inizio colonna OKRs
      this.initAfterData(onlyData, excludeChart);
    });
  }

  initAfterData(onlyData: boolean = false, excludeChart: boolean = false) {
    if (this.groupBy === 'ByOwner') {
      this.changeSortByType();
    }

    this.nudgesSent = [];

    let categories = [];
    let owners = [];
    let managers = [];
    let teams = [];

    this.okrProgress.DetailedResults.forEach((detail: any) => {
      if (this.groupBy === 'ByOwner') {
        detail['expandMainRow'] = this.openedDetailsMap[detail.Id]
          ? this.openedDetailsMap[detail.Id].expandMainRow
          : false;
        detail.DetailedResults.forEach((d) => {
          d['ShowDetails'] =
            this.openedDetailsMap[detail.Id] &&
            this.openedDetailsMap[detail.Id][d.Id]
              ? this.openedDetailsMap[detail.Id][d.Id].ShowDetails
              : false;
        });
      } else {
        detail.ShowDetails = this.openedDetailsMap[detail.Id];
      }
      this.nudgesSent.push({
        left: false,
        right: false,
        Id: detail.Id
      });

      if (!categories.includes(detail.Group.Id)) {
        categories.push(detail.Group.Id);
      }
      if (!owners.includes(detail.Owner.Id)) {
        owners.push(detail.Owner.Id);
      }
      if (!managers.includes(detail.Manager.Id)) {
        managers.push(detail.Manager.Id);
      }
      if (!teams.includes(detail.Team.Id)) {
        teams.push(detail.Team.Id);
      }
    });

    // FILTERS
    if (!onlyData) {
      this.initFilters();
    } else {
      this.CategoriesFilter.forEach((category: any) => {
        category['hide'] =
          !categories.includes(category.id) && category.id != 'ALL';
      });

      // Se ho filtrato per titolare allora conrollo l'hide per Manager
      if (
        this.SelectedOwnersFilter.filter((o: any) => o.id != 'ALL').length > 0
      ) {
        this.ManagersFilter.forEach((manager: any) => {
          manager['hide'] =
            !managers.includes(manager.id) && manager.id != 'ALL';
        });

        const managersNotHidden = this.ManagersFilter.filter(
          (f: any) => f.id != 'ALL' && !f.hide
        );
        this.SelectedManagersFilter = [];
        managersNotHidden.forEach((f) => {
          this.SelectedManagersFilter.push(f);
        });
      }

      // Se ho filtrato per manager allora conrollo l'hide per titolare
      if (
        this.SelectedManagersFilter.filter((m: any) => m.id != 'ALL').length > 0
      ) {
        this.OwnersFilter.forEach((owner: any) => {
          owner['hide'] = !owners.includes(owner.id) && owner.id != 'ALL';
        });

        const ownersNotHidden = this.OwnersFilter.filter(
          (f: any) => f.id != 'ALL' && !f.hide
        );
        this.SelectedOwnersFilter = [];
        ownersNotHidden.forEach((f) => {
          this.SelectedOwnersFilter.push(f);
        });
      }
      if (
        this.SelectedTeamsFilter.filter((m: any) => m.id != 'ALL').length > 0
      ) {
        this.TeamsFilter.forEach((team: any) => {
          team['hide'] = !teams.includes(team.id) && team.id != 'ALL';
        });

        const teamsNotHidden = this.TeamsFilter.filter(
          (f: any) => f.id != 'ALL' && !f.hide
        );
        // this.SelectedTeamsFilter = [];
        // teamsNotHidden.forEach((f) => {
        //   this.SelectedTeamsFilter.push(f);
        // });
      }
    }
    // CHART
    if (!excludeChart) {
      setTimeout(() => {
        this.initChartJS(
          this.okrProgress[this.currentGraph].Points,
          this.currentGraph
        );
      }, 1000);
    }
  }

  getVertProgress() {
    let n = this.okrProgress.OverAllPace;
    let styles = {
      background:
        'linear-gradient(to bottom, var(--background3) ' +
        (100 - n) +
        '%, var(--footer-default) ' +
        n +
        '%)'
    };
    return styles;
  }

  checkHidePeriod(period: string) {
    const inizioCreazioneCiclo = moment(
      this.okrProgress.Cycle.CycleInfo.CreationPeriod.Start
    );
    let dateFrom;

    switch (period) {
      case 'Week':
        dateFrom = moment().clone().startOf('week');
        break;

      case 'WTD':
        dateFrom = moment().subtract(7, 'days');
        break;

      case 'Month':
        dateFrom = moment().clone().startOf('month');
        break;

      case 'MTD':
        dateFrom = moment().subtract(1, 'months');
        break;

      case 'Quarter':
        dateFrom = moment().clone().startOf('quarter');
        break;

      case 'QTD':
        dateFrom = moment().subtract(3, 'months');
        break;

      case 'Semester':
        const currentQuarter = moment().quarter();
        const currentYear = moment().year();
        switch (currentQuarter) {
          case 1:
          case 2:
            dateFrom = moment([currentYear, 0]).startOf('month');
            break;

          case 3:
          case 4:
            dateFrom = moment([currentYear, 6]).startOf('month');
            break;
        }
        break;
    }

    return dateFrom.isBefore(inizioCreazioneCiclo);
  }

  formatNumber(x: number, decimal: number) {
    return x.toLocaleString(this.translateService.currentLang, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  }

  async addImageProcess(src, r, modelName, Id) {
    const zMin = this.okrProgress[modelName].ZAxis.Min;
    const zMax = this.okrProgress[modelName].ZAxis.Max;
    const img = new Image();
    img.src = src;
    await img.decode();

    const ratio = img.height / img.width;

    const minR = 15;
    let k = 25;
    if (zMax !== zMin) {
      k = 25 / (zMax - zMin);
    }
    //if (r === 0) return;
    const delta = (r - zMin) * k;

    img.width = minR + delta;
    img.height = minR + delta;

    img.id = Id;

    return img;
  }

  calculateBubbleRadius(z) {
    let r = 1 + z;
    if (r > 15) r = 15;
    return r;
  }

  /** Init chart */
  async initChartJS(Points: any, modelName: string) {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    const formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR'
    });

    let data: any = [];
    let labels: any = [];

    let elencoDati = JSON.parse(JSON.stringify(Points));

    var minXAxes = this.okrProgress[modelName].XAxis.Min,
      minYAxes = this.okrProgress[modelName].YAxis.Min,
      maxXAxes = this.okrProgress[modelName].XAxis.Max,
      maxYAxes = this.okrProgress[modelName].YAxis.Max;

    minXAxes = Math.floor((minXAxes - 20) / 5) * 5;
    maxXAxes = Math.floor((maxXAxes + 20) / 5) * 5;

    minYAxes = Math.floor((minYAxes - 20) / 5) * 5;
    maxYAxes = Math.floor((maxYAxes + 20) / 5) * 5;

    minXAxes = -20;
    maxXAxes = 120;
    if (minYAxes > 0) {
      minYAxes = -20;
    }

    maxYAxes = 120;

    let backgrounds = [];
    for await (const record of elencoDati) {
      const img = await this.addImageProcess(
        this.coreService.getImageURL(
          record.Picture,
          'assets/images/category.gif'
        ),
        record.Z,
        modelName,
        record.ReferenceId
      );

      let palette = await Vibrant.from(
        this.coreService.getImageURL(
          record.Picture,
          'assets/images/category.gif'
        )
      ).getPalette();
      let hex =
        palette && palette.LightVibrant
          ? palette.LightVibrant.getHex()
          : '000000';
      backgrounds.push(hex);

      // console.log(record.X, record.Y, record.Name, hex, record.Picture);

      data.push({
        x: record.X,
        y: record.Y,
        r: this.calculateBubbleRadius(record.Z),
        z: record.Z,
        Picture: record.Picture,
        Name: record.Name,
        Id: record.ReferenceId,
        img: img
      });

      // if (minYAxes > record.Y-record.Z) minYAxes = Math.floor(record.Y-record.Z);
      // if (minXAxes > record.X-record.Z) minXAxes = Math.floor(record.X-record.Z);

      labels.push(record.Name);
    }

    this.graphData = {
      datasets: [
        {
          data: data,
          backgroundColor: backgrounds,
          borderColor: 'rgba(0, 0, 0, 0.6)'
        }
      ],

      labels: labels
    };

    if (this.myChart) {
      this.myChart.destroy();
    }

    // console.log(data);

    const numPoints = Points.length;

    let forceUpdate: Boolean = false;

    const sovrappostiFn = function (data) {
      let sovrapposti = [];
      data.forEach((d, i) => {
        if (!sovrapposti || sovrapposti.length == 0) {
          sovrapposti.push({
            x: data[i].x,
            y: data[i].y,
            indexes: [i]
          });
        } else {
          const alreadyExistsIndex = sovrapposti.findIndex((el) => {
            return (
              Math.abs(el.x - data[i].x) <= 1 && Math.abs(el.y - data[i].y) <= 1
            );
          });
          if (alreadyExistsIndex >= 0) {
            sovrapposti[alreadyExistsIndex].indexes.push(i);
          }
        }
      });

      // Se sovrapposti ha un solo elemento allora vuol dire che non c'è nulla
      // e lo svuoto.
      if (sovrapposti.length == 1 && sovrapposti[0].indexes.length == 1) {
        sovrapposti = [];
      }

      return sovrapposti;
    };

    const updateChartStyle = function (dataset) {
      // Cerco tutti i punti sovrapposti
      let sovrapposti: any = sovrappostiFn(dataset._dataset.data);

      dataset.data.forEach((d, i) => {
        const inSovrappostiIndex = sovrapposti.findIndex((el) => {
          return el.indexes.includes(i);
        });
        if (inSovrappostiIndex >= 0) {
          d.options.pointStyle = 'circle';
          // Se è il primo dell'array lo visualizzo,
          // gli altri li nascondo
          // tanto cmq nel tooltip vengono fuori tutti
          if (sovrapposti[inSovrappostiIndex].indexes[0] == i) {
            dataset._dataset.data[i].r = 10;
          } else {
            dataset._dataset.data[i].r = 0;
          }

          forceUpdate = true;
        } else if (numPoints > 10) {
          d.options.pointStyle = 'circle';
          dataset._dataset.data[i].r = 10;
        } else {
          d.options.pointStyle = dataset._dataset.data[i].img;
        }
      });
    };
    if (this.chart) {
      this.myChart = new Chart(this.chart.nativeElement, {
        type: 'bubble',
        plugins: [
          ChartDataLabels,
          {
            // Questo evento per evitare che il mouse over su un punto di tipo immagine lo faccia diventare di tipo circle.
            afterEvent: (chart, args, options) => {
              if (
                args.event.type == 'mousemove' ||
                args.event.type == 'mouseout' ||
                args.event.type == 'click'
              ) {
                const dataset = chart.getDatasetMeta(0);
                updateChartStyle(dataset);
              }
            },
            afterUpdate: (chart, args, options) => {
              const dataset = chart.getDatasetMeta(0);
              updateChartStyle(dataset);
            }
          }
        ],
        data: this.graphData,
        options: {
          name: 'MainGraph',
          title: {
            display: false
          },
          interaction: {
            mode: 'nearest',
            axis: 'xy'
          },
          plugins: {
            legend: {
              display: false
            },
            datalabels: {
              anchor: 'center',
              align: 'center',
              color: 'white',
              font: {
                weight: 'bold',
                size: 9
              },
              formatter: function (value, context) {
                let sovrapposti: any = sovrappostiFn(context.dataset.data);
                if (sovrapposti.length == 0) return '';

                const existsIndex = sovrapposti.findIndex((el) => {
                  return (
                    Math.abs(el.x - value.x) <= 1 &&
                    Math.abs(el.y - value.y) <= 1
                  );
                });
                if (existsIndex >= 0) {
                  return sovrapposti[existsIndex].indexes.length;
                } else {
                  return '';
                }
              },
              offset: 2,
              padding: 0
            },
            tooltip: {
              intersect: false,
              xPadding: 5,
              yPadding: 5,
              yAlign: 'bottom',
              // caretPadding: 100,
              callbacks: {
                label: (context) => {
                  let d = context.dataset.data[context.dataIndex];
                  let name = this.graphData.labels[context.dataIndex];
                  if (!name) {
                    name =
                      this.coreService.getTranslation('Generic.NoCategory');
                  }
                  return (
                    name +
                    ': \n' +
                    this.formatNumber(d.x, 0) +
                    '%, ' +
                    this.formatNumber(d.y, 0) +
                    '% [' +
                    this.formatNumber(d.z, 0) +
                    ']'
                  );
                }
              }
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              grid: {
                display: true,
                // borderDash: context => context.tick.value == 0 ? [1, 1] : [2, 5],
                color: (context) =>
                  context.tick.value == 0 ? 'lightgray' : 'white'
              },
              stacked: false,
              callback: (value, index, values) => {
                return value;
              },
              //min: minXAxes,
              //max: maxXAxes,
              scaleLabel: {
                display: true,
                labelString: this.coreService.getTranslation(
                  'OKR_MEASURES_DASHBOARD.CHART.X'
                )
              }
            },
            y: {
              id: 'y-axis-revenue',
              grid: {
                display: true,
                // borderDash: context => context.tick.value == 0 ? [0, 0] : [2, 5],
                color: (context) =>
                  context.tick.value == 0 ? 'lightgray' : 'white'
              },
              stacked: false,
              callback: (value, index, values) => {
                return value;
              },
              //min: minYAxes,
              //max: maxYAxes,
              scaleLabel: {
                display: true,
                labelString: this.coreService.getTranslation(
                  'OKR_MEASURES_DASHBOARD.CHART.Y'
                )
              }
            }
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0
            }
          }
        }
      });
    }

    if (forceUpdate) {
      setTimeout(() => {
        this.myChart.update();
      }, 100);
    }

    $('#chartContainer').off();
    $('#chartContainer').on('click', (ev) => {
      if (this.currentZoom > 0) return;
      var p = this.myChart.getElementsAtEventForMode(
        ev,
        'nearest',
        { intersect: false },
        true
      );

      if (p && p.length > 0) {
        const pointInfo = this.graphData.datasets[0].data[p[0].index];
        console.log('pointInfo', pointInfo);

        pointInfo.id = pointInfo.Id;
        this.zoomIn(pointInfo.Name);
        if (this.groupBy === 'ByOwner') {
          this.SelectedOwnersFilter = [
            {
              id: pointInfo.id,
              itemName: pointInfo.Name,
              Picture: pointInfo.Picture
            }
          ];
          this.filter(pointInfo, 'SelectedOwnersFilter', true);
        } else {
          this.SelectedCategoriesFilter = [
            {
              id: pointInfo.id,
              itemName: pointInfo.Name,
              Picture: pointInfo.Picture
            }
          ];
          this.filter(pointInfo, 'SelectedCategoriesFilter', true);
        }
      }
    });
  }

  zoomIn(categoryName: string = '') {
    this.currentZoom = 1;
    let obiettiviListi = categoryName
      ? this.okrProgress.DetailGraph.Points.filter(
          (detail: any) => detail.Group === categoryName
        )
      : JSON.parse(JSON.stringify(this.okrProgress.DetailGraph.Points));
    console.log('obiettiviListi', obiettiviListi, this.okrProgress.DetailGraph);
    this.initChartJS(obiettiviListi, 'DetailGraph');
    this.currentGraph = 'DetailGraph';
  }

  zoomOut(fromResizeEvent: boolean = false) {
    if (fromResizeEvent) return;

    this.currentZoom = 0;
    this.currentGraph = 'GroupGraph';
    this.initChartJS(this.okrProgress.GroupGraph.Points, 'GroupGraph');

    if (this.groupBy == 'ByOwner') {
      this.SelectedOwnersFilter = [
        {
          id: 'ALL',
          itemName: this.coreService.getTranslation(
            'OKR_MEASURES_DASHBOARD.FILTERS.All'
          )
        }
      ];
      this.filter(null, 'SelectedOwnersFilter');
    } else {
      this.SelectedCategoriesFilter = [
        {
          id: 'ALL',
          itemName: this.coreService.getTranslation(
            'OKR_MEASURES_DASHBOARD.FILTERS.All'
          )
        }
      ];
      this.filter(null, 'SelectedCategoriesFilter');
    }
  }

  initFilters() {
    let ele = {
      id: 'ALL',
      itemName: this.coreService.getTranslation(
        'OKR_MEASURES_DASHBOARD.FILTERS.All'
      )
    };
    this.CategoriesFilter = [ele];
    this.okrProgress.FilterData.Categories.forEach((element) => {
      this.CategoriesFilter.push({
        id: element.Id,
        itemName: element.Name,
        Picture: element.Picture
      });
    });
    this.CategoriesFilter.shift();
    this.CategoriesFilter.sort((a, b) => a.itemName.localeCompare(b.itemName));
    this.CategoriesFilter.unshift(ele);

    this.OwnersFilter = [ele];
    this.okrProgress.FilterData.Employees.forEach((element) => {
      this.OwnersFilter.push({
        id: element.Id,
        itemName: element.Name,
        Picture: element.Picture
      });
    });
    this.OwnersFilter.shift();
    this.OwnersFilter.sort((a, b) => a.itemName.localeCompare(b.itemName));
    this.OwnersFilter.unshift(ele);

    this.TeamsFilter = [ele];
    this.okrProgress.FilterData.Teams.forEach((element) => {
      this.TeamsFilter.push({
        id: element.Id,
        itemName: element.Name
      });
    });
    this.TeamsFilter.shift();
    this.TeamsFilter.sort((a, b) => a.itemName.localeCompare(b.itemName));
    this.TeamsFilter.unshift(ele);

    this.RolesFilter = [ele];
    this.okrProgress.FilterData.Roles.forEach((element) => {
      this.RolesFilter.push({
        id: element.Id,
        itemName: element.Name
      });
    });

    this.LocationsFilter = [ele];
    this.okrProgress.FilterData.Locations.forEach((element) => {
      this.LocationsFilter.push({
        id: element.Id,
        itemName: element.Name
      });
    });

    this.SexFilter = [ele];
    this.okrProgress.FilterData.Genders.forEach((element) => {
      this.SexFilter.push({
        id: element.Id,
        itemName: element.Name
      });
    });

    this.ManagersFilter = [ele];
    this.okrProgress.FilterData.Managers.forEach((element) => {
      this.ManagersFilter.push({
        id: element.Id,
        itemName: element.Name,
        Picture: element.Picture
      });
    });
    this.ManagersFilter.shift();
    this.ManagersFilter.sort((a, b) => a.itemName.localeCompare(b.itemName));
    this.ManagersFilter.unshift(ele);
  }

  getUrlImg(img: any) {
    return this.coreService.getImageURL(img, 'assets/images/category.gif');
  }

  getUserImg(img: any) {
    return this.coreService.getImageURL(img, 'assets/images/user.jpeg');
  }

  sortProgressByCategoryName() {
    if (this.catSortType !== 'ASC') {
      this.catSortType = 'ASC';
    } else {
      this.catSortType = 'DESC';
    }

    this.okrProgress.GroupedResults = this.okrProgress.GroupedResults.sort(
      (a: any, b: any) => {
        if (this.catSortType === 'DESC') {
          return a.Name > b.Name ? -1 : a.Name < b.Name ? 1 : 0;
        } else {
          return a.Name > b.Name ? 1 : a.Name < b.Name ? -1 : 0;
        }
      }
    );
  }

  sortProgressByValue() {
    if (this.valSortType !== 'ASC') {
      this.valSortType = 'ASC';
    } else {
      this.valSortType = 'DESC';
    }

    this.okrProgress.GroupedResults = this.okrProgress.GroupedResults.sort(
      (a: any, b: any) => {
        if (this.valSortType === 'DESC') {
          return a.Value > b.Value ? -1 : a.Value < b.Value ? 1 : 0;
        } else {
          return a.Value > b.Value ? 1 : a.Value < b.Value ? -1 : 0;
        }
      }
    );
  }

  nudgeAlreadySent(id: string, direction: string) {
    const nudge = this.nudgesSent.find((el) => el.Id === id);
    if (nudge) {
      return nudge[direction];
    } else {
      return false;
    }
  }

  currentColumnSorted: string = 'OKRs';
  sortDetails(column: string, noSwitch: boolean = false) {
    this.currentColumnSorted = column;
    if (!noSwitch) {
      if (this.tableSortType[column] !== 'ASC') {
        this.tableSortType[column] = 'ASC';
      } else {
        this.tableSortType[column] = 'DESC';
      }
    }
    this.okrProgress.DetailedResults = this.okrProgress.DetailedResults.sort(
      (a: any, b: any) => {
        switch (column) {
          case 'OKRs':
            if (this.tableSortType[column] === 'DESC') {
              return a.Name.toLowerCase() > b.Name.toLowerCase()
                ? -1
                : a.Name.toLowerCase() < b.Name.toLowerCase()
                  ? 1
                  : 0;
            } else {
              return a.Name.toLowerCase() > b.Name.toLowerCase()
                ? 1
                : a.Name.toLowerCase() < b.Name.toLowerCase()
                  ? -1
                  : 0;
            }
          case 'Progress':
            if (this.tableSortType[column] === 'DESC') {
              return a.Value > b.Value ? -1 : a.Value < b.Value ? 1 : 0;
            } else {
              return a.Value > b.Value ? 1 : a.Value < b.Value ? -1 : 0;
            }
          case 'Pace':
            if (this.tableSortType[column] === 'DESC') {
              return a.Pace > b.Pace ? -1 : a.Pace < b.Pace ? 1 : 0;
            } else {
              return a.Pace > b.Pace ? 1 : a.Pace < b.Pace ? -1 : 0;
            }
          case 'Updated':
            let ret = 0;
            let isValidA = moment(a.Updated);
            let isValidB = moment(b.Updated);
            console.log(this.tableSortType[column]);
            if (this.tableSortType[column] === 'DESC') {
              ret =
                !moment(b.Updated).isValid() ||
                moment(a.Updated).isAfter(
                  moment(b.Updated).format('YYYY-MM-DD')
                )
                  ? -1
                  : moment(a.Updated).isBefore(
                        moment(b.Updated).format('YYYY-MM-DD')
                      )
                    ? 1
                    : 0;
            } else {
              ret = moment(a.Updated).isAfter(
                moment(b.Updated).format('YYYY-MM-DD')
              )
                ? 1
                : !moment(b.Updated).isValid() ||
                    moment(a.Updated).isBefore(
                      moment(b.Updated).format('YYYY-MM-DD')
                    )
                  ? -1
                  : 0;
            }
            return ret;
          case 'Owner':
            if (this.tableSortType[column] === 'DESC') {
              return a.Owner.Name.toLowerCase() > b.Owner.Name.toLowerCase()
                ? -1
                : a.Owner.Name.toLowerCase() < b.Owner.Name.toLowerCase()
                  ? 1
                  : 0;
            } else {
              return a.Owner.Name.toLowerCase() > b.Owner.Name.toLowerCase()
                ? 1
                : a.Owner.Name.toLowerCase() < b.Owner.Name.toLowerCase()
                  ? -1
                  : 0;
            }
          case 'Manager':
            if (this.tableSortType[column] === 'DESC') {
              return a.Manager.Name.toLowerCase() > b.Manager.Name.toLowerCase()
                ? -1
                : a.Manager.Name.toLowerCase() < b.Manager.Name.toLowerCase()
                  ? 1
                  : 0;
            } else {
              return a.Manager.Name.toLowerCase() > b.Manager.Name.toLowerCase()
                ? 1
                : a.Manager.Name.toLowerCase() < b.Manager.Name.toLowerCase()
                  ? -1
                  : 0;
            }
          case 'DetailedResultLength':
            if (this.tableSortType[column] === 'DESC') {
              return a.DetailedResults.length > b.DetailedResults.length
                ? -1
                : a.DetailedResults.length < b.DetailedResults.length
                  ? 1
                  : 0;
            } else {
              return a.DetailedResults.length > b.DetailedResults.length
                ? 1
                : a.DetailedResults.length < b.DetailedResults.length
                  ? -1
                  : 0;
            }
        }
      }
    );

    // console.log(
    //   'sortDetails',
    //   this.tableSortType[column],
    //   this.okrProgress.DetailedResults
    // );
  }

  getTooltip(kr: any) {
    if (this.editMode) return;
    const perc = Number(
      ((kr.Value - kr.Min) / (kr.Max - kr.Min)) * 100
    ).toLocaleString(this.translateService.currentLang, {
      minimumFractionDigits: kr.MeasureType == 1 ? 0 : 0,
      maximumFractionDigits: kr.MeasureType == 1 ? 0 : 0
    });
    const value = Number(kr.Value).toLocaleString(
      this.translateService.currentLang,
      {
        minimumFractionDigits: kr.MeasureType == 1 ? 2 : 0,
        maximumFractionDigits: kr.MeasureType == 1 ? 2 : 0
      }
    );
    const max = Number(kr.Max).toLocaleString(
      this.translateService.currentLang,
      {
        minimumFractionDigits: kr.MeasureType == 1 ? 2 : 0,
        maximumFractionDigits: kr.MeasureType == 1 ? 2 : 0
      }
    );
    return `${value} / ${max} (${perc}%)`;
  }

  openObjectiveSheet(objectiveId: string, referralAction: REFERRAL_ACTION = 1) {
    this.cycleManagerService
      .getObjectiveById(objectiveId, this.iamManager ? 'AsManager' : '')
      .subscribe((objectiveResult: any) => {
        console.log('objectiveResult', objectiveResult);
        this.showObjectiveSheet(objectiveResult, true, referralAction);
      });
  }

  updateParentObj(row) {
    let sum = 0;
    let count = 0;
    row.KeyResults.forEach((kr) => {
      sum += (kr.Value - kr.Min) / (kr.Max - kr.Min);
      count++;
    });

    row.Value = (sum * 100) / count;
  }

  showObjectiveSheet(
    obj: any,
    readOnly: boolean = false,
    referralAction: REFERRAL_ACTION = 1
  ) {
    const content: any = {
      objective: obj,
      state: 'EXECUTION_STATE',
      cycle: this.cycle,
      ViewMode: !this.iamManager ? VIEW_MODE.ADMIN : VIEW_MODE.MANAGER,
      ReadOnly: readOnly,
      referral: REFERRAL.CYCLES,
      referralAction: referralAction
    };

    const modal = this.modalService.show(ObjSheetPopupComponent, {
      class: 'objective-sheet-popup'
    });
    (<ObjSheetPopupComponent>modal.content).showModal(content);
    (<ObjSheetPopupComponent>modal.content).updateObjectiveInSheets.subscribe(
      (objective) => {
        this.updateObjectiveInSheet(objective);
      }
    );

    // ----- Handles in sheets objective remotions
    (<ObjSheetPopupComponent>modal.content).assignObjective.subscribe(
      (result: any) => {
        this.companyReportService
          .assignObjective(result.id, result.objective)
          .subscribe((r) => {
            this.updateObjectiveInSheet(result.objective);
          });
      }
    );
  }

  updateObjectiveInSheet(objective: any) {
    let o;
    if (this.groupBy === 'ByOwner') {
      this.okrProgress.DetailedResults.forEach((element) => {
        let find = element.DetailedResults.find((x) => x.Id === objective.Id);
        if (find) {
          o = find;
        }
      });
    } else {
      o = this.okrProgress.DetailedResults.find((x) => x.Id === objective.Id);
    }
    let needReload = false;
    if (Math.abs(o.Value - objective.ObjectiveProgressPercentage) > 0.1) {
      needReload = true;
    } else {
      if (o.KeyResults) {
        o.KeyResults.forEach((kr) => {
          let nKr = objective.CheckList.find((x) => x.Id == kr.Id);
          if (Math.abs(kr.Value - nKr.Value) > 0.1) {
            needReload = true;
          }
        });
      }
    }
    if (needReload) {
      this.getProgress(true);
    }
  }

  whatCanIdoDetailed(hasChildren: boolean = false): string {
    if (!this.editMode) return 'VIEW_ONLY';

    const progressPhase =
      this.okrProgress.Cycle &&
      this.okrProgress.Cycle.CycleInfo &&
      this.okrProgress.Cycle.CycleInfo.ActivePhases &&
      this.okrProgress.Cycle.CycleInfo.ActivePhases.includes('Progress');
    const evaluationPhase =
      this.okrProgress.Cycle &&
      this.okrProgress.Cycle.CycleInfo &&
      this.okrProgress.Cycle.CycleInfo.ActivePhases &&
      this.okrProgress.Cycle.CycleInfo.ActivePhases.includes('Evaluation');
    let ret = 'VIEW_ONLY';

    if (progressPhase && !hasChildren) {
      ret = 'DRAG';
    } else if (evaluationPhase && !hasChildren) {
      if (this.isCompanyAdmin || this.isSuperAdmin || this.isLocalAdmin) {
        ret = 'DRAG';
      } else {
        ret = this.iamManager ? 'VIEW_ONLY' : 'DRAG';
      }
    }

    return ret;
  }

  resetOKR() {
    this.okrProgress.DetailedResults = JSON.parse(
      JSON.stringify(this.DetailedResultsOrig)
    );
    this.editMode = false;
    this.sortDetails(this.currentColumnSorted, true);
  }

  saveOKR() {
    const content: any = {
      YesCaption: this.coreService.getTranslation(
        'OKR_MEASURES_DASHBOARD.SAVE_DIALOG.ACTION'
      ),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation(
        'OKR_MEASURES_DASHBOARD.SAVE_DIALOG.TITLE'
      ),
      subtitle: this.coreService.getTranslation(
        'OKR_MEASURES_DASHBOARD.SAVE_DIALOG.SUBTITLE'
      )
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          let savingDetails = this.getModifiedItems();
          this.cycleManagerService
            .saveOkrDetails(savingDetails)
            .subscribe(async (res: any) => {
              this.coreService.toasterMessage(
                'success',
                '',
                this.coreService.getTranslation(
                  'OKR_MEASURES_DASHBOARD.SAVE_DIALOG.SUCCESS'
                )
              );
              this.DetailedResultsOrig = JSON.parse(
                JSON.stringify(this.okrProgress.DetailedResults)
              );
              this.editMode = false;
              this.getProgress(true);
            });
        }
      }
    );
  }

  getModifiedItems() {
    let details = [];
    if (this.groupBy === 'ByOwner') {
      this.DetailedResultsOrig.forEach((initMain: any) => {
        initMain.DetailedResults.forEach((init: any) => {
          let newItem = this.okrProgress.DetailedResults.find(
            (x) => x.Id === initMain.Id
          ).DetailedResults.find((x) => x.Id === init.Id);
          if (init.KeyResults.length > 0) {
            //Check inner OKR
            init.KeyResults.forEach((oldKr: any) => {
              let newKr = newItem.KeyResults.find((x) => x.Id === oldKr.Id);
              if (Math.abs(newKr.Value - oldKr.Value) > 0.1) {
                details.push({ Id: newKr.Id, Value: newKr.Value, Type: 'KR' });
              }
            });
          } else {
            if (Math.abs(init.Value - newItem.Value) > 0.1) {
              details.push({
                Id: newItem.Id,
                Value: newItem.Value,
                Type: 'Objective'
              });
            }
          }
        });
      });
    } else {
      this.DetailedResultsOrig.forEach((init: any) => {
        let newItem = this.okrProgress.DetailedResults.find(
          (x) => x.Id === init.Id
        );
        if (init.KeyResults.length > 0) {
          //Check inner OKR
          init.KeyResults.forEach((oldKr: any) => {
            let newKr = newItem.KeyResults.find((x) => x.Id === oldKr.Id);
            if (Math.abs(newKr.Value - oldKr.Value) > 0.1) {
              details.push({ Id: newKr.Id, Value: newKr.Value, Type: 'KR' });
            }
          });
        } else {
          if (Math.abs(init.Value - newItem.Value) > 0.1) {
            details.push({
              Id: newItem.Id,
              Value: newItem.Value,
              Type: 'Objective'
            });
          }
        }
      });
    }
    return details;
  }

  filterPeriod(event: any) {
    console.log('filterPeriod', this.okrProgressFilter);
    this.performFilter(undefined);
  }

  filter(event: any, modelName: string, excludeChart: boolean = false) {
    if (event && event.id !== 'ALL') {
      this[modelName] = this[modelName].filter(
        (item: any) => item.id !== 'ALL'
      );
      if (
        (this.groupBy == 'ByObjective' &&
          modelName == 'SelectedCategoriesFilter') ||
        (this.groupBy == 'ByOwner' && modelName == 'SelectedOwnersFilter')
      ) {
        this.currentGraph = 'DetailGraph';
        this.currentZoom = 1;
      }
    } else {
      this[modelName] = [
        {
          id: 'ALL',
          itemName: this.coreService.getTranslation(
            'OKR_MEASURES_DASHBOARD.FILTERS.All'
          )
        }
      ];
    }

    this.performFilter(modelName, excludeChart);
  }

  performFilter(modelName: string, excludeChart: boolean = false) {
    // CycleId
    // GroupBy
    // Period

    // CategoryIds
    // OwnerIds
    // TeamIds
    // RoleIds
    // Locations
    // Genders
    // ManagerIds

    // this.okrProgressFilter = {
    //   CycleId: this.cycle.Id,
    //   GroupBy: 'ByObjective',
    //   Period: this.Period
    // };

    if (
      modelName === 'SelectedOwnersFilter' ||
      modelName === 'SelectedTeamsFilter'
    ) {
      this.SelectedManagersFilter = [
        {
          id: 'ALL',
          itemName: this.coreService.getTranslation(
            'OKR_MEASURES_DASHBOARD.FILTERS.All'
          )
        }
      ];
    }
    if (
      modelName === 'SelectedOwnersFilter' ||
      modelName === 'SelectedManagersFilter'
    ) {
      this.SelectedTeamsFilter = [
        {
          id: 'ALL',
          itemName: this.coreService.getTranslation(
            'OKR_MEASURES_DASHBOARD.FILTERS.All'
          )
        }
      ];
    }
    if (
      modelName === 'SelectedTeamsFilter' ||
      modelName === 'SelectedManagersFilter'
    ) {
      this.SelectedOwnersFilter = [
        {
          id: 'ALL',
          itemName: this.coreService.getTranslation(
            'OKR_MEASURES_DASHBOARD.FILTERS.All'
          )
        }
      ];
    }
    this.okrProgressFilter['CategoryIds'] = [];
    this.okrProgressFilter['OwnerIds'] = [];
    this.okrProgressFilter['TeamIds'] = [];
    this.okrProgressFilter['RoleIds'] = [];
    this.okrProgressFilter['Locations'] = [];
    this.okrProgressFilter['Genders'] = [];
    this.okrProgressFilter['ManagerIds'] = [];

    if (
      this.SelectedCategoriesFilter &&
      this.SelectedCategoriesFilter.filter((item: any) => item.id !== 'ALL')
        .length > 0
    ) {
      this.okrProgressFilter['CategoryIds'] = [];
      this.SelectedCategoriesFilter.forEach((element) => {
        this.okrProgressFilter['CategoryIds'].push(element.id);
      });
    }

    if (
      this.SelectedOwnersFilter &&
      this.SelectedOwnersFilter.filter((item: any) => item.id !== 'ALL')
        .length > 0
    ) {
      this.okrProgressFilter['OwnerIds'] = [];
      this.SelectedOwnersFilter.forEach((element) => {
        this.okrProgressFilter['OwnerIds'].push(element.id);
      });
    }

    if (
      this.SelectedTeamsFilter &&
      this.SelectedTeamsFilter.filter((item: any) => item.id !== 'ALL').length >
        0
    ) {
      this.okrProgressFilter['TeamIds'] = [];
      this.SelectedTeamsFilter.forEach((element) => {
        this.okrProgressFilter['TeamIds'].push(element.id);
      });
    }
    if (
      this.SelectedRolesFilter &&
      this.SelectedRolesFilter.filter((item: any) => item.id !== 'ALL').length >
        0
    ) {
      this.okrProgressFilter['RoleIds'] = [];
      this.SelectedRolesFilter.forEach((element) => {
        this.okrProgressFilter['RoleIds'].push(element.id);
      });
    }
    if (
      this.SelectedLocationsFilter &&
      this.SelectedLocationsFilter.filter((item: any) => item.id !== 'ALL')
        .length > 0
    ) {
      this.okrProgressFilter['Locations'] = [];
      this.SelectedLocationsFilter.forEach((element) => {
        this.okrProgressFilter['Locations'].push(element.id);
      });
    }
    if (
      this.SelectedSexFilter &&
      this.SelectedSexFilter.filter((item: any) => item.id !== 'ALL').length > 0
    ) {
      this.okrProgressFilter['Genders'] = [];
      this.SelectedSexFilter.forEach((element) => {
        this.okrProgressFilter['Genders'].push(element.id);
      });
    }
    if (
      this.SelectedManagersFilter &&
      this.SelectedManagersFilter.filter((item: any) => item.id !== 'ALL')
        .length > 0
    ) {
      this.okrProgressFilter['ManagerIds'] = [];
      this.SelectedManagersFilter.forEach((element) => {
        this.okrProgressFilter['ManagerIds'].push(element.id);
      });
    }

    const api = this.iamManager
      ? 'singleInExecutionAsManager'
      : 'singleInExecution';

    this.getProgress(true, excludeChart);
  }

  resetFilters() {
    if (this.currentZoom == 1) {
      this.zoomOut();
    }

    this.currentZoom = 0;
    this.initModelsFilter();

    this.CategoriesFilter.forEach((category: any) => {
      category['hide'] = false;
    });

    this.OwnersFilter.forEach((owner: any) => {
      owner['hide'] = false;
    });

    this.ManagersFilter.forEach((manager: any) => {
      manager['hide'] = false;
    });

    this.performFilter(undefined);
  }

  onNudge(rowIndex: number, row: any, direction: string) {
    // check per vedere se si può fare il nudge

    if (this.nudgesSent[rowIndex][direction]) return;

    let toUser = true;
    if (direction === 'right') {
      toUser = false;
    }

    const content: any = {
      YesCaption: this.coreService.getTranslation('CYCLE_MANAGER.NudgeOk'),
      NoCaption: this.coreService.getTranslation('CYCLE_MANAGER.NudgeCancel'),
      type: 'YesNo',
      title: this.coreService.getTranslation('CYCLE_MANAGER.NudgeTitle'),
      subtitle: this.coreService.getTranslation('CYCLE_MANAGER.NudgeMeggage1')
    };

    content.subtitle = this.coreService.getTranslation(
      'CYCLE_MANAGER.NudgeMeggage2'
    );

    if (toUser) {
      content.subtitle = content.subtitle.replace('{{Name}}', row.Owner.Name);
    } else {
      content.subtitle = content.subtitle.replace('{{Name}}', row.Manager.Name);
    }

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.cycleManagerService
            .nudge(row.Owner.Id, row.Manager.Id, toUser, this.cycle.Id, row.Id)
            .subscribe((r) => {
              this.coreService.toasterMessage('success', 'Nudge', 'Ok');

              this.nudgesSent[rowIndex][direction] = true;
            });
        }
      }
    );
  }

  // By OWNER
  sortByType_byOwner: string = 'UpAndRunning';
  changeSortByType() {
    if (!this.okrProgress.DetailGraph.YAxis) return;
    if (!this.okrProgress.DetailGraph.XAxis) return;

    console.log('changeSortByType', this.sortByType_byOwner);
    const YMax = this.okrProgress.DetailGraph.YAxis.Max;
    const YMin = this.okrProgress.DetailGraph.YAxis.Min;
    const XMax = this.okrProgress.DetailGraph.XAxis.Max;
    const XMin = this.okrProgress.DetailGraph.XAxis.Min;

    //  CatchingUp  |    UpRunning
    //              |
    //              |
    //              |
    //              |
    // -------------|--------------
    //              |
    //              |
    //              |
    //              |
    //  LowSlow     |     UpStuck
    //
    //straight diagonal line Up (LowSlow => UpRunning)
    // y=Mx+Q
    var M = (YMax - YMin) / (XMax - XMin);
    var Q = YMin - M * XMin;

    //straight diagonal line Down (CatchingUp => UpStuck)
    // y=mx+q
    var m = (YMin - YMax) / (XMax - XMin);
    var q = YMin - M * XMax;

    var medianaProgress = (XMax - XMin) / 2;
    var medianaPace = (YMax - YMin) / 2;

    switch (this.sortByType_byOwner) {
      case 'UpAndRunning':
        this.okrProgress.GroupedResults.forEach((element: any) => {
          element.show =
            element.Pace >= medianaPace && element.Value >= medianaProgress;
        });
        this.okrProgress.GroupedResults.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );

        // this.okrProgress.GroupedResults = this.okrProgress.GroupedResults.sort((a, b) => {
        //     //projection on the diagonal line
        //     var ka = a.Pace + a.Value / M;
        //     var kb = b.Pace + b.Value / M;

        //     const aX = (ka - Q) * (M + 1 / M);;
        //     const bX = (kb - Q) * (M + 1 / M);;
        //     return bX - aX;
        // });

        break;
      case 'UpButStuck':
        this.okrProgress.GroupedResults.forEach((element: any) => {
          element.show =
            element.Pace < medianaPace && element.Value >= medianaProgress;
        });
        this.okrProgress.GroupedResults.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );

        // this.okrProgress.GroupedResults = this.okrProgress.GroupedResults.sort((a, b) => {
        //     //projection on the diagonal line
        //     var ka = a.Pace + a.Value / m;
        //     var kb = b.Pace + b.Value / m;

        //     const aX = (ka - q) * (m + 1 / m);
        //     const bX = (kb - q) * (m + 1 / m);
        //     return aX - bX;
        // });

        break;
      case 'LowAndSlow':
        this.okrProgress.GroupedResults.forEach((element: any) => {
          element.show =
            element.Pace < medianaPace && element.Value < medianaProgress;
        });
        this.okrProgress.GroupedResults.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        // this.okrProgress.GroupedResults = this.okrProgress.GroupedResults.sort((a, b) => {
        //     //projection on the diagonal line
        //     var ka = a.Pace + a.Value / M;
        //     var kb = b.Pace + b.Value / M;

        //     const aX = (ka - Q) * (M + 1 / M);;
        //     const bX = (kb - Q) * (M + 1 / M);;
        //     return aX - bX;
        // });

        break;
      case 'CatchingUp':
        this.okrProgress.GroupedResults.forEach((element: any) => {
          element.show =
            element.Pace >= medianaPace && element.Value < medianaProgress;
        });
        this.okrProgress.GroupedResults.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );

      // this.okrProgress.GroupedResults = this.okrProgress.GroupedResults.sort((a, b) => {
      //     //projection on the diagonal line
      //     var ka = a.Pace + a.Value / m;
      //     var kb = b.Pace + b.Value / m;

      //     const aX = (ka - q) * (m + 1 / m);
      //     const bX = (kb - q) * (m + 1 / m);
      //     return aX - bX;
      // });
      // break;
    }
  }

  editKRObjectiveValue(kr: any) {
    kr.editValue = !kr.editValue;
    setTimeout(() => {
      $(`#input_${kr.Id}`).focus();
      $(`#input_${kr.Id}`).select();
    }, 250);
  }
}
