import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as d3 from 'd3-sankey-diagram';
import * as _d3 from 'd3';
import { TranslateService } from '@ngx-translate/core';

interface ResponseData {
  features: any[];
}

interface RequestInit {
  links: any[];
  groups: any[];
  nodes: any[];
  order: any[];
}

@Component({
  selector: 'app-target-bonus',
  templateUrl: './target-bonus.component.html',
  styleUrls: ['./target-bonus.component.css', '../common-styles.scss']
})
export class TargetBonusComponent implements OnInit, AfterViewInit {
  @Input() plan: any;
  @Input() userId: any;
  data: any;
  total: number;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    let sankeyData = {
      nodes: [],
      links: []
    };

    let tot = 0;

    for (var y = this.plan.YearStart; y <= this.plan.YearEnd; y++) {
      let nd = {
        node: 'acc-' + y,
        name: y
      };
      sankeyData.nodes.push(nd);
    }

    for (
      var y = this.plan.PayoutStructure.PayoutYearStart;
      y <= this.plan.PayoutStructure.PayoutYearEnd;
      y++
    ) {
      let nd = {
        node: 'pay-' + y,
        name: y
      };
      sankeyData.nodes.push(nd);
    }

    this.plan.PartecipantsByYear.forEach((pby) => {
      let acc_y = pby.Year;
      let tb = pby.Partecipants[0].TargetBonus;
      let pyr = this.plan.PayoutStructure.Rows.find(
        (x) => x.AccrueYear == acc_y
      );
      if (pyr) {
        pyr.PayoutValues.forEach((v) => {
          let pay_y = v.PayoutYear;
          let p = v.Cash / 100 + v.Equity / 100;
          let lnk = {
            source: 'acc-' + acc_y,
            target: 'pay-' + pay_y,
            value: tb * p
          };
          if (pay_y >= acc_y && lnk.value > 0) {
            sankeyData.links.push(lnk);
            tot += lnk.value;
          }
        });
      }
    });
    this.data = sankeyData;
    this.total = tot;
  }

  ngAfterViewInit() {
    // var layout = d3.sankey()
    //   .extent([[40, 0], [740, 400]]);
    // var diagram = d3.sankeyDiagram()
    //   .linkTitle(d3.sankeyLinkTitle(function(d) { return d.title; },
    //     function(d) { return d.title; },
    //     _d3.format('.3s')))
    //   .linkColor(function(d) { return d.color; });
    // var el = _d3.select('#sankey svg')
    //   .datum(layout(this.data)).call(diagram);
  }

  formatNumber(x: any, decimal: number = 2) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    return x.toLocaleString(this.translateService.currentLang, {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal
    });
  }

  getTotalTargetBonus() {
    return this.formatNumber(this.total);
  }
}
