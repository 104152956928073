import {
  Directive,
  Input,
  HostListener,
  Optional,
  ElementRef
} from '@angular/core';
import { NgModel } from '@angular/forms';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  selector: '[checkMinMax]'
})
export class CheckMinMaxDirective {
  @Input() min: number;
  @Input() max: number;
  minmaxChange: Subject<any> = new Subject<any>();

  constructor(
    @Optional() public model: NgModel,
    private el: ElementRef
  ) {
    this.minmaxChange
      .pipe(debounceTime(1000)) // wait 300ms after the last event before emitting last event
      .subscribe(() => {
        if (this.min > this.el.nativeElement.value) {
          this.el.nativeElement.value = this.min;
        } else if (this.max < this.el.nativeElement.value) {
          this.el.nativeElement.value = this.max;
        }
        this.el.nativeElement.select();
      });
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: any) {
    this.minmaxChange.next();
  }
}
