// import { BsModalRef } from 'ngx-bootstrap/modal';
import { Component, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

@Component({
  selector: 'app-inappropriate-dialog',
  templateUrl: './inappropriate-dialog.component.html',
  styleUrls: ['./inappropriate-dialog.component.css']
})
export class InappropriateDialogComponent implements OnInit {
  public value: number = 0;
  public text: string = '';
  public lastValue: number = 0;
  public onClose: Subject<any>;
  public content: any = {};
  public reasons: any[];
  selectedReson: any;

  Arr = Array;
  textdetail: string;
  textdesc: string;
  @Output() TheValue = 0;
  public constructor(
    private _bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private translate: TranslateService,
    private coreService: CoreService
  ) {}

  public ngOnInit(): void {
    this.onClose = new Subject();

    const reasons = [
      {
        Id: 1,
        Name: this.coreService.getTranslation(
          'Messages.InappropriateOptions.SexualContent'
        )
      },
      {
        Id: 2,
        Name: this.coreService.getTranslation(
          'Messages.InappropriateOptions.Violence'
        )
      },
      {
        Id: 3,
        Name: this.coreService.getTranslation(
          'Messages.InappropriateOptions.Spam'
        )
      },
      {
        Id: 4,
        Name: this.coreService.getTranslation(
          'Messages.InappropriateOptions.FakeNews'
        )
      },
      {
        Id: 5,
        Name: this.coreService.getTranslation(
          'Messages.InappropriateOptions.UnwantedMarketing'
        )
      },
      {
        Id: 6,
        Name: this.coreService.getTranslation(
          'Messages.InappropriateOptions.RudeContent'
        )
      },
      {
        Id: 7,
        Name: this.coreService.getTranslation(
          'Messages.InappropriateOptions.Other'
        )
      }
    ];
    this.reasons = reasons;
  }

  setStar(value: number) {
    this.value = value;
  }
  selectedResonId: string;
  onSelectReson(event: any) {
    if (event) {
      this.selectedResonId = event.Id;
      this.selectedReson = event.Name;
    }
  }

  public showModal(content: any): void {
    this.content = content;
    if (this.content.type == 3) {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Success'
      );
      this.textdesc = this.coreService.getTranslation('Report_Success_Comment');
    } else if (this.content.type == 2) {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Info'
      );
      this.textdesc = this.coreService.getTranslation('Report_Info_Image');
    } else if (this.content.type == 1) {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Event'
      );
      this.textdesc = this.coreService.getTranslation('Report_Event_Image');
    } else if (this.content.type == 'Feedback') {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_FEEDBACK'
      );
      this.textdesc = this.coreService.getTranslation('ReportFeedbackComment');
    } else if (this.content.type == 'Token') {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Token'
      );
      this.textdesc = this.coreService.getTranslation('ReportToken');
    } else if (this.content.type == 'Pat') {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_PAT'
      );
      this.textdesc = this.coreService.getTranslation('Report_Pat_Image');
    } else if (this.content.type == 'Event') {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Event'
      );
      this.textdesc = this.coreService.getTranslation('Report_Event_Image');
    } else if (this.content.type == 'Repat') {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Repat'
      );
      this.textdesc = this.coreService.getTranslation('Report_Repat_Comment');
    } else if (this.content.type == 'Comment') {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Comment'
      );
      this.textdesc = this.coreService.getTranslation('ReportCommentImage');
    } else {
      this.textdetail = this.coreService.getTranslation(
        'Employee.Why_Reporting_Feedback_Request'
      );
      this.textdesc = this.coreService.getTranslation('ReportFeedbackRequest');
    }
  }

  public onConfirm(): void {
    const content: any = {
      YesCaption: this.coreService.getTranslation('CONFIRM'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('WALL.REPORT_CHANGES'),
      subtitle: this.coreService.getTranslation('WALL.DISCARD_REPORT_TITLE')
    };

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.onClose.next(true);
          this.TheValue = this.value;
          this._bsModalRef.hide();
        }
      }
    );
  }

  public onCancel(): void {
    this.onClose.next(false);
    this._bsModalRef.hide();
  }

  public hideConfirmationModal(): void {
    this.onClose.next(null);
    this._bsModalRef.hide();
  }
}
