import { Component, Input, OnInit } from '@angular/core';
import { Pp360SpinnerService } from './pp360-spinner.service';
import { CoreService } from 'app/shared/services/core.service';

@Component({
  selector: 'pp360-spinner',
  templateUrl: './pp360-spinner.component.html',
  styleUrls: ['./pp360-spinner.component.scss']
})
export class Pp360SpinnerComponent implements OnInit {
  @Input() userInfo: any;

  constructor(
    public pp360SpinnerService: Pp360SpinnerService,
    public coreService: CoreService
  ) {}
  ngOnInit(): void {}
}
