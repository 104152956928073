import { Injectable } from '@angular/core';
import { RestfulService } from '../shared/services/restful.service';

@Injectable()
export class PartecipantLTIService {
  constructor(private restfulWebService: RestfulService) {}

  private fakeResponse: any = {
    CompanyId: '76fef197-16ca-48ba-80a3-73222985cd55',
    Name: 'Prova',
    YearEnd: 2023,
    YearStart: 2020,
    RoundBonusFlag: 'Up',
    RoundBonusValue: 100.0,
    ActivationDate: null,
    Note: null,
    Clauses: null,
    Document: null,
    CorrectiveThresholdMax: 0.0,
    CorrectiveThresholdMin: 0.0,
    CorrectiveType: 'Rolling',
    PayoutStructure: {
      PayoutType: 'Cash, Equity',
      PayoutYearEnd: 2024,
      PayoutYearStart: 2021,
      PayoutNote: 'Nota',
      Rows: [
        {
          AccrueYear: 2020,
          PayoutValues: [
            {
              PayoutYear: 2022,
              Cash: 0.25,
              Equity: 0.0
            },
            {
              PayoutYear: 2021,
              Cash: 0.25,
              Equity: 0.0
            },
            {
              PayoutYear: 2023,
              Cash: 0.25,
              Equity: 0.0
            }
          ]
        }
      ]
    },
    PartecipantsByYear: [
      {
        Year: '2020',
        Partecipants: [
          {
            EmployeeId: '5ad53de5-8c20-4149-ac20-c6612fe6b72d',
            TargetBonusPercentage: '0.2',
            GrossAnnualSalary: 100000.0
          }
        ]
      }
    ],
    Gateways: [
      {
        Index: 0,
        Name: 'Test',
        GoalId: 'db5bb477-9905-4d45-8db2-10047ec73093',
        MeasureId: '13da2390-dee5-44b8-ab63-2f07c10de004',
        IsCorridor: false,
        Min: 0.0,
        Max: 0.0,
        ConsultationType: 'Average',
        Currency: '€',
        FormatId: null,
        ScaleId: null,
        Weight: 0.4,
        Type: 'Punctual',
        TargetValue: 100,
        TargetThreshold: '90-100',
        BonusSensivity: '90-110',
        ResultValue: 75,
        Target: 100,
        Multiplier: 110,
        YearlyValues: [
          {
            Year: 2020,
            TargetValue: 10.0,
            CurrentValue: null
          },
          {
            Year: 2021,
            TargetValue: 12.0,
            CurrentValue: null
          }
        ]
      }
    ],
    Objectives: [
      {
        Index: 0,
        Name: 'Test',
        GoalId: 'db5bb477-9905-4d45-8db2-10047ec73093',
        MeasureId: '13da2390-dee5-44b8-ab63-2f07c10de004',
        IsCorridor: false,
        Min: 0.0,
        Max: 0.0,
        ConsultationType: 'Average',
        Currency: '€',
        FormatId: null,
        ScaleId: null,
        Weight: 0.4,
        Type: 'Punctual',
        TargetValue: 100,
        TargetThreshold: '90-100',
        BonusSensivity: '90-110',
        ResultValue: 75,
        Target: 100,
        Multiplier: 110,
        YearlyValues: [
          {
            Year: 2020,
            TargetValue: 10.0,
            CurrentValue: null
          },
          {
            Year: 2021,
            TargetValue: 12.0,
            CurrentValue: null
          }
        ]
      }
    ],
    Correctives: [
      {
        Index: 0,
        Name: 'Test',
        GoalId: 'db5bb477-9905-4d45-8db2-10047ec73093',
        MeasureId: '13da2390-dee5-44b8-ab63-2f07c10de004',
        IsCorridor: false,
        Min: 0.0,
        Max: 0.0,
        ConsultationType: 'Average',
        Currency: '€',
        FormatId: null,
        ScaleId: null,
        Weight: 0.4,
        YearlyValues: [
          {
            Year: 2020,
            TargetValue: 10.0,
            CurrentValue: null
          },
          {
            Year: 2021,
            TargetValue: 12.0,
            CurrentValue: null
          }
        ]
      }
    ],
    termsConditions: false,
    myLtiForm: false,
    vexatiousClauses: false,
    acceptedPlan: false
  };

  getPlanByEmployee(planId: string) {
    return this.restfulWebService.get(`LTI/RecipientPlan?planId=${planId}`);
  }

  acceptPlan(employeeId: string) {
    return this.restfulWebService.get(`LTI/Plans/accept/${employeeId}`);
  }
}
