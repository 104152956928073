import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { WallService } from '../wall.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ListOfEmployeesComponent } from 'app/shared/components/list-of-employees/list-of-employees.component';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { SharedService } from 'app/shared/services/shared.service';
import { RepatDialogComponent } from 'app/employee/wall/repat-dialog/repat-dialog.component';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';
import { Router } from '@angular/router';
import { InappropriateDialogComponent } from './../inappropriate-dialog/inappropriate-dialog.component';

@Component({
  selector: 'feed-repat',
  templateUrl: './feed-repat.component.html',
  styleUrls: [
    './feed-repat.component.scss',
    '../common-feed-style.scss',
    '../../../common_styles.scss'
  ]
})
export class FeedRepatComponent implements OnInit {
  @Input() item: any;
  @Input() showReported: boolean;
  @Input() readOnly: boolean;
  @Input() inappropriatebtn: boolean;

  @Output() feedDeleted = new EventEmitter();
  @Output() feedUpdated = new EventEmitter<any>();
  @Output() reportedAsInappropriate = new EventEmitter<any>();
  @Output() fetchSinglePat = new EventEmitter<any>();

  empInfo: any;
  bsModalRef: BsModalRef;
  sendingComment = false;
  userInfo: any;
  userData: any;
  showRepatOption: boolean = false;
  loaded = false;

  constructor(
    public coreService: CoreService,
    private service: WallService,
    private modalService: BsModalService,
    private pp360SpinnerService: Pp360SpinnerService,
    private sharedService: SharedService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.item.totalComments = 0;
    this.item.InternalPat['Skill'] = this.item?.Skill;
    if (this.service.profiles.length) {
      const employee = this.service.profiles.find(
        (x) => x.Id === this.item.Author.Id
      );
      if (employee) {
        this.empInfo = employee;
      } else {
        this.getProfileInfo();
      }
    } else {
      this.getProfileInfo();
    }
    this.userInfo = this.coreService.getUserInfo();
    this.userData = this.coreService.getUserInfo();
    // console.log(this.item);
    // console.log(this.userData);

    if (this.item.Likes.length) {
      for (let i = 0; i < this.item.Likes.length; i++) {
        if (this.item.Likes[i].Author.Id == this.userInfo.linkedEmployeeId) {
          this.item['isCheckLike'] = true;
          break;
        }
      }
    }
    this.manageCountComment();
  }

  updateCount(comments) {
    for (let i of comments) {
      if (i.SubComments.length > 0) {
        this.item.totalComments += i.SubComments.length;
        this.updateCount(i.SubComments);
      }
    }
  }

  manageCountComment() {
    if (this.item.Comments.length > 0) {
      this.item.totalComments =
        this.item.Comments.length + this.item.totalComments;
      this.updateCount(this.item.Comments);
    }
  }

  getProfileInfo() {
    this.service.getEmployeeInfo(this.item.Author.Id).subscribe((data: any) => {
      if (data) {
        this.empInfo = data;
        const employee = this.service.profiles.find(
          (x) => x.Id === this.item.Author.Id
        );
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }

  showHideTranslated(item, property) {
    if (item.ShowTranslated) {
      item.ShowTranslated = !item.ShowTranslated;
    } else {
      let selectedLang = this.userInfo.language;
      const text = item[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          item['TranslatedText'] = result.TranslatedText;
          item.ShowTranslated = true;
        });
    }
  }

  listOfEmp(empTeamList, mode) {
    this.bsModalRef = this.modalService.show(ListOfEmployeesComponent, {
      class: 'modal-md pop-width list-of-emp',
      ignoreBackdropClick: true
    });
    this.bsModalRef.content.empTeamList = empTeamList;
    this.bsModalRef.content.mode = mode;
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  showReactions(item, type) {
    if (type === 'comment') {
      this.onComment(item);
    } else {
      var emps = [];
      var items: any;
      switch (type) {
        case 'like':
          items = item.Likes;
          break;

        case 'repat':
          items = item.RePats;
          break;
      }

      items.forEach((element: any) => {
        var e = {
          Description: element.Author.JobTitle,
          Id: element.Author.Id,
          Location: element.Author.Location,
          Name: element.Author.Name,
          Type: 'Employee',
          Picture: element.Author.Picture,
          SomethingAbout: element.Author.SomethingAbout
        };
        emps.push(e);
      });
      if (emps.length > 0) this.listOfEmp(emps, type);
    }
  }

  onComment(item) {
    item.isComment = !item.isComment;
  }
  reportInnapropriate(item, comment: any = null) {
    item.expandMenu = false;
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    if (comment) {
      msg = msg.replace('{{item.Author.Name}}', comment.Author.Name);
    } else {
      msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    }

    const content: any = {
      title: msg,
      type: 'Repat'
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation'
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.Id,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 0
          };
          console.log(req.FeedType);

          switch (item.Type) {
            case 'ReceivedAsk':
              req.FeedType = 3;
              break;
            case 'ReceivedEvaluation':
              req.FeedType = 2;
              break;
            case 'ReceivedToken':
              req.FeedType = 1;
              break;
            case 'Pat':
              req.FeedType = 0;
              break;
            default: //COMMENT
              req.FeedType = 4;
              break;
          }

          if (comment) {
            req.FeedType = 4;
            req.FeedId = comment.Id;
            item['commentId'] = comment.Id;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }
  name() {
    return 'RePat';
  }
  sendMsg(comment) {
    this.item.totalComments++;
    this.sendingComment = true;
    let reqBody = {
      TapFeedId: this.item.Id,
      AuthorId: this.userInfo.linkedEmployeeId,
      Text: comment?.comment ? comment?.comment : ' ',
      Picture: comment?.image ? comment?.image : null
    };
    if (reqBody.Text) {
      this.pp360SpinnerService.showSpinner(true);
      this.service.commentOnWall(reqBody).subscribe(
        (data: any) => {
          this.item.Comments.unshift(data);
          this.sendingComment = false;
          this.pp360SpinnerService.showSpinner(false);
          // this.toRefresh=false;
        },
        (err: any) => {
          this.sendingComment = false;
          this.pp360SpinnerService.showSpinner(false);
          console.log('errrr', err);
        }
      );
    }
  }
  addALike(item) {
    item['isCheckLike'] = true;
    var reqBody = {
      TapFeedId: item.Id,
      AuthorId: this.userInfo.linkedEmployeeId
    };
    this.service.postALike(reqBody).subscribe(
      (data: any) => {
        item.Likes.push(data);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  removeALike(item) {
    item['isCheckLike'] = false;

    this.service.deleteALike(item.Id, this.userInfo.linkedEmployeeId).subscribe(
      (data: any) => {
        var index = item.Likes.findIndex(
          (e: any) => e.AuthorId === this.userInfo.linkedEmployeeId
        );
        item.Likes.splice(index, 1);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  async edit(item: any, comment: any = null) {
    if (comment) {
      alert('TO BE IMPLEMENTED');
    } else {
      this.showRepatOption = false;
      const modal = this.modalService.show(RepatDialogComponent, {
        class: 'wallPopup repat-preview',
        ignoreBackdropClick: true,
        initialState: { pat: item }
      });

      (<RepatDialogComponent>modal.content).onClose.subscribe((res: any) => {
        console.log(res);
        if (res) {
          this.item.Description = res.Description;
        }
      });
    }
  }

  goToProfile(idofemployee) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: idofemployee },
      replaceUrl: true
    });
    // this.bsModalRef.hide();
  }

  delete(item: any, comment: any = null) {
    if (comment) {
      alert('TO BE IMPLEMENTED');
    } else {
      const content: any = {
        YesCaption: this.coreService.getTranslation('DELETE'),
        NoCaption: this.coreService.getTranslation('CANCEL'),
        type: 'YesNo',
        title: this.coreService.getTranslation('WALL.DELETE_POST'),
        subtitle: this.coreService.getTranslation('WALL.DELETE_REPAT_TITLE')
      };

      const modal = this.modalService.show(SimpleDialogComponent, {
        class: 'self-evaluation'
      });
      (<SimpleDialogComponent>modal.content).showModal(content);
      (<SimpleDialogComponent>modal.content).onClose.subscribe(
        (result: boolean) => {
          if (result) {
            this.pp360SpinnerService.showSpinner(true);
            this.service.deletePat(item.Id).subscribe(
              (data: any) => {
                item.expandMenu = false;
                this.feedDeleted.emit(item);
                this.coreService.setShowEliminated({ type: 'Pat' });
                this.pp360SpinnerService.showSpinner(false);
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
                this.pp360SpinnerService.showSpinner(false);
              }
            );
          }
        }
      );
    }
  }
}
