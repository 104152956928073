import { Component, OnInit } from '@angular/core';

//RxJS
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

// Services
import { CoreService } from 'app/shared/services/core.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-search-employee',
  templateUrl: './search-employee.component.html',
  styleUrls: [
    './search-employee.component.scss',
    '../../../../common_styles.scss'
  ]
})
export class SearchEmployeeComponent implements OnInit {
  a2mSettings: any;
  employees: any;
  employeesFiltered: any;
  searchText: string;
  userInfo: any;
  modelChanged: Subject<string> = new Subject<string>();

  onClose: Subject<any> = new Subject();

  constructor(
    private coreService: CoreService,
    public bsModalRef: BsModalRef
  ) {}

  ngOnInit(): void {
    this.modelChanged.pipe(debounceTime(300)).subscribe(() => {
      this.employeesFiltered = this.employees.filter((emp: any) =>
        emp.itemName.toLowerCase().includes(this.searchText.toLowerCase())
      );
    });
  }

  showModal(content) {
    this.employees = content.employees;
    this.employeesFiltered = JSON.parse(JSON.stringify(content.employees));

    this.userInfo = this.coreService.getUserInfo();
  }

  filter() {
    console.log('searchText', this.searchText);
    this.modelChanged.next();
  }

  getNewPat(employee: any) {
    this.onClose.next({
      action: 'getNewPat',
      employee: employee
    });
    this.bsModalRef.hide();
  }

  getNewEvaluation(employee: any) {
    this.onClose.next({
      action: 'getNewEvaluation',
      employee: employee
    });
    this.bsModalRef.hide();
  }

  getTokenPopUp(employee: any) {
    this.onClose.next({
      action: 'getTokenPopUp',
      employee: employee
    });
    this.bsModalRef.hide();
  }
}
