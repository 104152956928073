import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoreService } from 'app/shared/services/core.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { InappropriateDialogComponent } from '../inappropriate-dialog/inappropriate-dialog.component';
import { WallService } from '../wall.service';
import { EmployeeTeamPopUpComponent } from 'app/shared/components/employee-team-pop-up/employee-team-pop-up.component';
import { SharedService } from 'app/shared/services/shared.service';
import { SimpleDialogComponent } from 'app/shared/simple-dialog/simple-dialog.component';

import { NewsDialogComponent } from 'app/employee/wall/news-dialog/news-dialog.component';
import * as moment from 'moment-timezone';
import { when } from 'jquery';
import { ProfileTooltip } from 'app/employee/wall/profile-tooltip/profile-tooltip.component';
import { ListOfEmployeesComponent } from 'app/shared/components/list-of-employees/list-of-employees.component';
import { Router } from '@angular/router';
import { Pp360SpinnerService } from 'app/pp360-components/pp360-spinner/pp360-spinner.service';
import { ScreenSizeService } from 'app/shared/services/screen-size.service';
import { MobileEventComponent } from '../mobile-event/mobile-event.component';

@Component({
  selector: 'feed-news-event',
  templateUrl: './feed-news-event.component.html',
  styleUrls: [
    './feed-news-event.component.scss',
    '../common-feed-style.scss',
    '../../../common_styles.scss'
  ]
})
export class FeedNewsEventComponent implements OnInit {
  @Input() item: any;
  @Input() showReported: boolean;
  @Input() readOnly: any;
  @Input() isMyEvents = false;
  @Input() itemIndex: number;
  @Output() reloadRequired = new EventEmitter();
  @Output() feedUpdated = new EventEmitter();
  @Output() feedDeleted = new EventEmitter();
  @Output() refreshParticipant = new EventEmitter();
  @Output() commentDeleted = new EventEmitter();
  isMobile: boolean;

  @Output() reportedAsInappropriate = new EventEmitter<any>();

  comment = '';
  showMore1: boolean = false;
  changeDetail: boolean = false;
  empInfo: any;
  userInfo: any;
  sendingComment = false;
  toggle: boolean = false;
  userData: any;
  onHover = false;
  hovertimeOut;
  startTime;
  constructor(
    public coreService: CoreService,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private service: WallService,
    private route: Router,
    private sharedService: SharedService,
    public screenSizeService: ScreenSizeService,
    private pp360SpinnerService: Pp360SpinnerService
  ) {
    this.userInfo = this.coreService.getUserInfo();
    this.screenSizeService.ScreenSize.subscribe((res: number) => {
      this.isMobile = res <= 991;
    });
  }

  ngOnInit() {
    this.item.totalComments = 0;
    this.userData = this.coreService.getUserInfo();
    this.item.Date = this.sharedService.getLocalDate(this.item.Date);
    if (this.item.StartTime && this.item.When && this.item.TimeZone) {
      const eventTime =
        this.item.When.split('T')[0] + 'T' + this.item.StartTime.split('T')[1];
      this.startTime = eventTime
        ? moment(eventTime).tz(this.item.TimeZone).format('HH:mm')
        : null;
    }
    this.showHideTranslated(this.item, 'Description', 'Title', false);
    if (this.service.profiles.length) {
      const user = this.item.Author ? this.item.Author : this.item.Target;
      const employee = this.service.profiles.find((x) => x.Id === user?.Id);
      if (employee) {
        this.empInfo = employee;
      } else {
        this.getProfileInfo();
      }
    } else {
      this.getProfileInfo();
    }
    this.item['isComment'] = false;
    this.item['commentArr'] = [];
    this.item.Comments.reverse();
    if (this.item.Comments.length) {
      for (
        let i = this.item.Comments.length - 1;
        i >= this.item.Comments.length - 3;
        i--
      ) {
        this.item['commentArr'].push(this.item.Comments[i]);
      }
    }
    if (this.item.Likes.length) {
      for (let i = 0; i < this.item.Likes.length; i++) {
        if (this.item.Likes[i].Author.Id == this.userData.linkedEmployeeId) {
          this.item['isCheckLike'] = true;
          break;
        }
      }
    }
    if (this.item.Partecipants.length) {
      const isPart = this.item?.Partecipants.find(
        (p) => p && p.Id === this.userData.linkedEmployeeId
      );
      if (isPart) {
        this.item['isParticipated'] = true;
      }
    }
    this.manageCountComment();
    this.calculateDiff();
  }
  colorChange: boolean = false;
  calculateDiff() {
    let dateSent = this.item.When;
    let currentDate = new Date();
    dateSent = new Date(dateSent);
    if (!(!this.isMyEvents && this.item.isParticipated)) {
      let y = Math.abs(
        Math.floor(
          (Date.UTC(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            currentDate.getDate()
          ) -
            Date.UTC(
              dateSent.getFullYear(),
              dateSent.getMonth(),
              dateSent.getDate()
            )) /
            (1000 * 60 * 60 * 24)
        )
      );
      if (y < 7) {
        this.colorChange = true;
      }
    }
  }

  updateCount(comments) {
    for (let i of comments) {
      if (i.SubComments.length > 0) {
        this.item.totalComments += i.SubComments.length;
        this.updateCount(i.SubComments);
      }
    }
  }

  manageCountComment() {
    if (this.item.Comments.length > 0) {
      this.item.totalComments =
        this.item.Comments.length + this.item.totalComments;
      this.updateCount(this.item.Comments);
    }
  }

  getProfileInfo() {
    const user = this.item.Author ? this.item.Author : this.item.Target;

    this.service.getEmployeeInfo(user?.Id).subscribe((data: any) => {
      if (data) {
        this.empInfo = data;
        const employee = this.service.profiles.find((x) => x.Id === user?.Id);
        if (!employee) {
          this.service.profiles.push(data);
        }
      }
    });
  }
  getImage(item) {
    if (item.Picture) {
      return {
        'background-image': `url('${item.Picture}')`
      };
    } else {
      return undefined;
    }
  }

  showHideTranslated(item, property, title, change) {
    if (item.TranslatedTitle && item.TranslatedText) {
      item.ShowTranslated = !item.ShowTranslated;
    } else {
      let selectedLang = this.userData.language;
      const text = item[property];
      this.coreService
        .translateText(text, null, selectedLang)
        .subscribe((result: any) => {
          item['TranslatedText'] = result.TranslatedText;
          if (change == true) {
            item.ShowTranslated = true;
          }
        });
      const titleText = item[title];
      this.coreService
        .translateText(titleText, null, selectedLang)
        .subscribe((result: any) => {
          item['TranslatedTitle'] = result.TranslatedText;
          if (change == true) {
            item.ShowTranslated = true;
          }
        });
    }
  }
  listOfEmp(empTeamList, mode) {
    this.bsModalRef = this.modalService.show(ListOfEmployeesComponent, {
      class: 'modal-md pop-width list-of-emp',
      ignoreBackdropClick: true
    });
    this.bsModalRef.content.empTeamList = empTeamList;
    this.bsModalRef.content.mode = mode;
    this.bsModalRef.content.closeBtnName = 'Close';
  }
  showReactions(item, type) {
    if (type === 'comment') {
      this.onComment(item);
    } else {
      var emps = [];
      var items: any;
      switch (type) {
        case 'like':
          items = item.Likes;
          items.forEach((element: any) => {
            var e = {
              Description: element.Author.JobTitle,
              Id: element.Author.Id,
              Location: element.Author.Location,
              Name: element.Author.Name,
              Type: 'Employee',
              Picture: element.Author.Picture,
              SomethingAbout: element.Author.SomethingAbout
            };
            emps.push(e);
          });
          break;

        case 'repatpart':
          items = item.Partecipants;
          items.forEach((element: any) => {
            var e = {
              Description: element.JobTitle,
              Id: element.Id,
              Location: element.Location,
              Name: element.Name,
              Type: 'Employee',
              Picture: element.Picture,
              SomethingAbout: element.SomethingAbout
            };
            emps.push(e);
          });
          break;

        case 'repat':
          items = item.Partecipants;
          items.forEach((element: any) => {
            var e = {
              Description: element.JobTitle,
              Id: element.Id,
              Location: element.Location,
              Name: element.Name,
              Type: 'Employee',
              Picture: element.Picture,
              SomethingAbout: element.SomethingAbout
            };
            emps.push(e);
          });
          break;
      }

      if (emps.length > 0) this.listOfEmp(emps, type);
    }
  }

  delete(item: any, comment: any) {
    const content: any = {
      YesCaption: this.coreService.getTranslation('DELETE'),
      NoCaption: this.coreService.getTranslation('CANCEL'),
      type: 'YesNo',
      title: this.coreService.getTranslation('WALL.DELETE_POST'),
      subtitle: this.coreService.getTranslation('WALL.DELETE_EVENT_TITLE')
    };

    if (comment) {
      (content.title = this.coreService.getTranslation('WALL.DELETE_COMMENT')),
        (content.subtitle = this.coreService.getTranslation(
          'WALL.DELETE_COMMENT_TITLE'
        ));
    }

    const modal = this.modalService.show(SimpleDialogComponent, {
      class: 'self-evaluation'
    });
    (<SimpleDialogComponent>modal.content).showModal(content);
    (<SimpleDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          this.pp360SpinnerService.showSpinner(true);
          if (comment) {
            this.service.deleteComment(comment.Id).subscribe(
              (data: any) => {
                this.coreService.toasterMessage(
                  'success',
                  'auth',
                  this.coreService.getTranslation('OK')
                );
                item.expandMenu = false;
                this.commentDeleted.emit({ item, comment });
                //this.reloadRequired.emit();
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
              }
            );
          } else {
            this.service.deleteNews(item.Id).subscribe(
              (data: any) => {
                item.expandMenu = false;
                this.feedDeleted.emit(item);
                this.coreService.setShowEliminated({ type: 'Pat' });
                this.pp360SpinnerService.showSpinner(false);
              },
              (err: any) => {
                this.coreService.toasterMessage('error', 'wall', err.Message);
                this.pp360SpinnerService.showSpinner(false);
              }
            );
          }
        }
      }
    );
  }
  name() {
    return 'Event';
  }
  getConfigMenu(item: any) {
    return {
      editAction:
        !item.IsReported && item.Author.Id === this.userData.linkedEmployeeId,
      deleteAction: item.Author.Id === this.userData.linkedEmployeeId,
      inappropriateAction:
        !item.IsReported &&
        item.Author.Id !== this.userData.linkedEmployeeId &&
        (item.Description || item.Picture),
      data: item.Description || item.Picture
    };
  }

  edit(item: any) {
    const content: any = {};
    const modal = this.modalService.show(NewsDialogComponent, {
      class: 'wallPopup',
      ignoreBackdropClick: true
    });
    this.item.StartTime = moment(this.item.StartTime).toDate();
    modal.content.initModal(JSON.parse(JSON.stringify(this.item)));
    (<NewsDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          // this.reloadRequired.emit();
        }
      }
    );
  }

  enter(item) {
    if (this.isMobile) {
      return;
    }
    this.hovertimeOut = setTimeout(() => {
      this.onHover = true;
      setTimeout(() => {
        document.getElementById('container').scrollLeft = this.itemIndex * 280;
      }, 10);
      //
      // doing some other stuff...
    }, 1000);
  }

  leave() {
    if (this.hovertimeOut) {
      clearTimeout(this.hovertimeOut);
    }
    this.onHover = false;
    // doing some other stuff...
  }

  shortcutForPat(team) {
    if (team.FirstName === 'Anonymous') return;
    team.isPreselect = true;
    let bsModalRef = this.modalService.show(EmployeeTeamPopUpComponent, {
      class: 'tooldirection'
    });
    bsModalRef.content.team = team;

    bsModalRef.content.addNewPat.subscribe((data: any) => {
      this.reloadRequired.emit();
    });
    // bsModalRef.content.closeBtnName = 'Close';
  }
  shortprofiletooltip(team) {
    if (this.changeDetail == true) {
      if (team.FirstName === 'Anonymous') return;
      team.isPreselect = true;
      let bsModalRef = this.modalService.show(ProfileTooltip, {
        class: 'tooldirection'
      });
      bsModalRef.content.team = team;

      bsModalRef.content.addNewPat.subscribe((data: any) => {
        this.reloadRequired.emit();
      });
    }
  }

  expandMenu(item) {
    item.expandMenu = !item.expandMenu;
  }

  redirect(link) {
    window.open(link, '_blank');
  }

  reportInnapropriate(item, comment = null) {
    item.expandMenu = false;
    var msg = this.coreService.getTranslation('Messages.InappropriateMessage');

    msg = msg.replace('{{item.Author.Name}}', item.Author.Name);
    const content: any = {
      title: msg,
      type: 'Event'
    };
    const modal = this.modalService.show(InappropriateDialogComponent, {
      class: 'self-evaluation'
    });
    (<InappropriateDialogComponent>modal.content).showModal(content);
    (<InappropriateDialogComponent>modal.content).onClose.subscribe(
      (result: boolean) => {
        if (result) {
          var req = {
            FeedId: item.Id,
            Reason: modal.content.selectedResonId,
            Text: modal.content.text,
            FeedType: 5 //<=News
          };
          if (comment) {
            req.FeedType = 6; //COMMENT
            req.FeedId = comment.Id;
          }

          this.service.postInappropriateFeedback(req).subscribe(
            (data: any) => {
              this.coreService.toasterMessage(
                'success',
                'auth',
                this.coreService.getTranslation(
                  'Messages.InnapropriateReported'
                )
              );
              this.reportedAsInappropriate.emit(item);
            },
            (err: any) => {
              this.coreService.toasterMessage('error', 'wall', err.Message);
            }
          );
        }
      }
    );
  }

  getNewsImage(item: any) {
    // se l'immagine contiene un base 64 invece di un url
    return item.Picture.indexOf('data:image/jpeg;base64,') >= 0
      ? item.Picture
      : this.coreService.getImageURL(item.Picture, null, item.IsReported);
  }

  addALike(item) {
    item['isCheckLike'] = true;
    var reqBody = {
      NewsId: item.Id,
      AuthorId: this.userData.linkedEmployeeId
    };
    this.service.postALike(reqBody, 'News').subscribe(
      (data: any) => {
        item.Likes.push(data);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  openLocation() {
    const url = `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${this.item.Latitude},${this.item.Longitude}`;
    this.redirect(url);
  }

  addAPart(item) {
    item['isParticipated'] = true;
    var reqBody = {
      NewsId: item.Id,
      AuthorId: this.userData.linkedEmployeeId
    };

    this.service.postAPart(reqBody, 'News').subscribe(
      (data: any) => {
        const userData = this.userData;
        userData.Id = userData.linkedEmployeeId;
        item.Partecipants.push(userData);
        this.refreshParticipant.emit(item);
      },
      (err: any) => {
        this.coreService.toasterMessage('error', 'wall', err.Message);
      }
    );
  }

  removeALike(item) {
    item['isCheckLike'] = false;

    this.service
      .deleteALike(item.Id, this.userData.linkedEmployeeId, 'News')
      .subscribe(
        (data: any) => {
          var index = item.Likes.findIndex(
            (e: any) => e.AuthorId === this.userData.linkedEmployeeId
          );
          item.Likes.splice(index, 1);
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
  }

  removeAPart(item) {
    item['isParticipated'] = false;
    this.service
      .deleteAPart(item.Id, this.userData.linkedEmployeeId, 'News')
      .subscribe(
        (data: any) => {
          var index = item.Partecipants.findIndex(
            (e: any) => e.Id === this.userData.linkedEmployeeId
          );
          item.Partecipants.splice(index, 1);
          this.refreshParticipant.emit(item);
        },
        (err: any) => {
          this.coreService.toasterMessage('error', 'wall', err.Message);
        }
      );
  }

  onComment(item) {
    this.comment = '';
    item.isComment = !item.isComment;
  }

  sendMsg(comment) {
    this.item.totalComments++;
    // console.log(comment);

    this.sendingComment = true;
    let reqBody = {
      NewsId: this.item.Id,
      AuthorId: this.userInfo.linkedEmployeeId,
      Text: comment?.comment ? comment?.comment : ' ',
      Picture: comment?.image ? comment?.image : null
    };
    if (reqBody.Text) {
      this.pp360SpinnerService.showSpinner(true);
      this.service.commentOnWall(reqBody, 'News').subscribe(
        (data: any) => {
          this.item.Comments.unshift(data);
          this.pp360SpinnerService.showSpinner(false);
          this.sendingComment = false;
        },
        (err: any) => {
          // console.log("errrr", err);
          this.pp360SpinnerService.showSpinner(false);
          this.sendingComment = false;
        }
      );
    }
  }
  goToProfile(idofemployee) {
    this.route.navigate(['/User/story'], {
      queryParams: { idVisitor: idofemployee },
      replaceUrl: true
    });
    // this.bsModalRef.hide();
  }
  openEventDialog(item) {
    if (!this.isMobile) {
      return;
    }
    const modal = this.modalService.show(MobileEventComponent, {
      class: 'wallPopup repat-preview',
      ignoreBackdropClick: false,
      initialState: { event: item }
    });

    (<MobileEventComponent>modal.content).onClose.subscribe((res: boolean) => {
      console.log(res);
    });
  }
}
