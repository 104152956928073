import { LoginComponent } from './login/login.component';
//import { ModuleWithProviders } from '@angular/core';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { FirstLoginComponent } from './first-login/first-login.component';
import { ChooseLoginComponent } from './choose-login/choose-login.component';
import { AuthGuard } from '../shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  { path: 'login', canActivate: [AuthGuard], component: LoginComponent },
  {
    path: 'firstlogin',
    canActivate: [AuthGuard],
    component: FirstLoginComponent
  },
  {
    path: 'choose-login',
    component: ChooseLoginComponent
  }
];

//export const AuthRouting: ModuleWithProviders = RouterModule.forChild(routes);
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRouting {}
