import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector:
    '[customMax][formControlName],[customMax][formControl],[customMax][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CustomMaxDirective, multi: true }
  ]
  // lifecycle: [LifecycleEvent.onChange]
})
export class CustomMaxDirective implements Validator {
  @Input() customMax: number;

  validate(c: FormControl): { [key: string]: any } {
    let v = c.value;
    console.log('aaay');
    return v > this.customMax ? { customMax: true } : null;
  }
}
