import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class Pp360SpinnerService {
  private showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  spinnerVal;

  constructor() {}

  public showSpinner(show: boolean = true) {
    this.showSpinner$.next(show);
    this.spinnerVal = show;
  }
}
