import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { CoreService } from 'app/shared/services/core.service';
import { Subscription } from 'rxjs';
import { ColumnFilter } from '../../column.model';

@Component({
  selector: 'filter-column',
  templateUrl: './filter-column.component.html',
  styleUrls: ['./filter-column.component.scss']
})
export class FilterColumnComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('textInput') textInput: ElementRef;
  @ViewChild('textInput', { read: MatAutocompleteTrigger })
  autoComplete: MatAutocompleteTrigger;
  @Output() updateInputValue = new EventEmitter();
  @Output() clearColumnFilter = new EventEmitter<boolean>(null);
  @Input() column: ColumnFilter;
  @Input() placeholder = 'All';
  @Input() isBulkFilter: boolean;
  @Input() defaultSelectedItems: any;
  placeholderToDisplay: string;
  selectedValue: any;
  selected: boolean = false;
  subscription: Subscription;
  options = [];
  originOptions;
  isAppliedChanges = false;
  isOpen = false;

  scrollEvent = (): void => {
    if (this.autoComplete.panelOpen) {
      this.autoComplete.updatePosition();
    }
  };

  constructor(public coreService: CoreService) {}

  displayFn(value): string {
    return this.selectedValue ? this.selectedValue : value;
  }

  openPanel() {
    setTimeout(() => {
      this.autoComplete.openPanel();
      this.textInput.nativeElement.focus();
    }, 0);
  }

  ngOnInit(): void {
    this.placeholderToDisplay = this.placeholder;
    this.initOptions();
    this.subscription = this.coreService.getClearFilter().subscribe((x) => {
      if (x) {
        this.resetFilter();
      }
    });
    if (this.defaultSelectedItems) {
      this.setSelectedItems();
    }
    if (this.column && this.column.Values) {
      this.setSelectedValuesLabel();
    }
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  searchData(searchValue: any) {
    const str = searchValue ? searchValue.toLowerCase() : '';
    this.options = this.column.Values.filter((item) => {
      return item.Name.toLowerCase().includes(str);
    });
  }

  initOptions() {
    if (this.column && this.column?.Values) {
      this.column.Values = this.column.Values.filter((x) => x.Name);
      this.options = JSON.parse(JSON.stringify(this.column.Values));
      this.originOptions = JSON.parse(JSON.stringify(this.column));
    } else {
      this.options = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.column) {
      this.column = changes.column.currentValue;
      this.initOptions();
      if (this.isBulkFilter) {
        this.resetFilter();
      }
    }
    if (changes && changes.defaultSelectedItems) {
      this.defaultSelectedItems = changes.defaultSelectedItems.currentValue;
      this.setSelectedItems();
    }
  }

  setSelectedItems() {
    let searchVal = this.defaultSelectedItems.search.value.replace(
      'exact|',
      ''
    );
    searchVal = searchVal.replace('object|Id|', '');
    const selectedVals = searchVal.split(';');
    this.column.Values.forEach((f) => {
      const isFind = selectedVals.find((fx) => fx === f.Name || fx === f.Id);
      if (isFind) {
        f.isSelected = true;
      } else {
        f.isSelected = false;
      }
    });
    this.initOptions();
    this.setSelectedValuesLabel();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onHidden(): void {
    this.isOpen = false;
    if (!this.column) {
      return;
    }
    if (!this.isAppliedChanges) {
      this.setOriginValues();
    }
    this.isAppliedChanges = false;
    this.setSelectedValuesLabel();
    this.textInput.nativeElement.blur();
  }

  setOriginValues() {
    this.originOptions.Values.forEach((element) => {
      this.column.Values.forEach((e) => {
        if (e.Id === element.Id) {
          e.isSelected = element.isSelected;
        }
      });
      this.options.forEach((e) => {
        if (e.Id === element.Id) {
          e.isSelected = element.isSelected;
        }
      });
    });
  }

  onShown(): void {
    this.isOpen = true;
    this.placeholderToDisplay = 'Enter search Text';
    this.selectedValue = '';
    if (this.column?.Values) {
      this.options = JSON.parse(JSON.stringify(this.column.Values));
    }
  }

  selectAll(event) {
    event.stopPropagation();
    this.column.Values.forEach((res: any) => {
      res.isSelected = true;
    });
    this.options.forEach((res: any) => {
      res.isSelected = true;
    });
  }

  deSelectAll(event) {
    event.stopPropagation();
    this.column.Values.forEach((res: any) => {
      res.isSelected = false;
    });
    this.options.forEach((res: any) => {
      res.isSelected = false;
    });
  }

  onSelect(value, event) {
    event.stopPropagation();
    value.isSelected = !value.isSelected;
  }

  applyFilter(): void {
    this.isAppliedChanges = true;
    this.column.Values = this.getSelectedArr();
    this.column.Values.forEach((element) => {
      this.originOptions.Values.forEach((e) => {
        if (e.Id === element.Id) {
          e.isSelected = element.isSelected;
        }
      });
    });
    this.setSelectedValuesLabel();
    const selectedObject = this.column.Values.filter((x) => x.isSelected);
    const filterData = {
      columnName: this.column.Id,
      selectedValues: selectedObject
    };
    this.updateInputValue.emit(filterData);
  }

  getSelectedArr() {
    const selectedArr = [];
    this.column.Values.forEach((c) => {
      const option = this.options.find((o) => o.Id === c.Id);
      if (option) {
        c = option;
      }
      selectedArr.push(c);
    });
    return selectedArr;
  }

  setSelectedValuesLabel() {
    const selected = this.column.Values.filter((x) => x.isSelected);
    if (selected.length === this.column.Values.length) {
      this.selectedValue = '';
      this.placeholderToDisplay = 'All';
    } else if (selected.length === 0) {
      this.selectedValue = '';
      this.placeholderToDisplay = this.isBulkFilter ? this.placeholder : 'None';
    } else {
      const selectedObject = this.column.Values.filter((x) => x.isSelected);
      if (selectedObject.length !== this.column.Values?.length) {
        setTimeout(() => {
          if (selectedObject.length > 1) {
            this.selectedValue = `${selectedObject.length} / ${this.column.Values.length} values`;
          } else {
            this.selectedValue = selectedObject.map((x) => x.Name).join(',');
          }
          setTimeout(() => {
            let el = document.getElementsByClassName('table');
            if (el && el.length) {
              (el[0] as HTMLElement).click();
            }
          }, 100);
          this.textInput.nativeElement.blur();
        }, 100);
      }
    }
  }

  resetFilter() {
    this.selectedValue = '';
    this.placeholderToDisplay = this.placeholder;
    if (this.column?.Values) {
      const options = this.column.Values;
      options.forEach((v) => (v.isSelected = !this.isBulkFilter));
      this.column.Values = [];
      this.column.Values = options;
    }
    if (this.originOptions?.Values) {
      const options = this.originOptions.Values;
      options.forEach((v) => (v.isSelected = !this.isBulkFilter));
      this.originOptions.Values = [];
      this.originOptions.Values = options;
    }
  }

  clearFilter(event) {
    event.stopPropagation();
    this.clearColumnFilter.emit(true);
    this.resetFilter();
  }
}
