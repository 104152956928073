import { Injectable } from '@angular/core';
import { RestfulService } from '../../shared/services/restful.service';
import { CoreService } from '../../shared/services/core.service';

@Injectable()
export class companyService {
  public allcompanyemployees = [];
  constructor(private restfulWebService: RestfulService) {}

  getAndSetAllEmployees(ID) {
    this.restfulWebService.get(`CompanyExt/EmployeesExt?id=${ID}`).subscribe(
      (d: any) => {
        this.allcompanyemployees = d || [];
      },
      (err: any) => {
        console.log(err);
      }
    );
  }

  getCompanyLanguages(ID) {
    return this.restfulWebService.get(
      `language/CompanyLanguages?companyId=${ID}`
    );
  }

  getCompanyObjCategories(id) {
    return this.restfulWebService.get(
      `CompanyExt/ObjectiveCategories?companyId=${id}`
    );
  }

  saveCompanyObjCategory(cat) {
    return this.restfulWebService.post(`CompanyExt/SaveObjectiveCategory`, cat);
  }

  updateCompanyObjCategory(cat) {
    return this.restfulWebService.put(
      `CompanyExt/UpdateObjectiveCategory`,
      cat
    );
  }
  deleteCompanyObjCategory(cat) {
    return this.restfulWebService.delete(
      `CompanyExt/DeleteCompanyObjectiveCategory?id=${cat.Id}`
    );
  }

  getGlobalScales() {
    return this.restfulWebService.get(`CompanyExt/GlobalScales`);
  }

  getCompanyScales(companyId) {
    return this.restfulWebService.get(
      `CompanyExt/Scales?companyId=${companyId}`
    );
  }

  saveCompanyScale(scale) {
    return this.restfulWebService.post(`CompanyExt/SaveScale`, scale);
  }

  getCompanyCycles(isGlobal: boolean, manager: string = '') {
    let apiURL = `Cycles/List${manager}`;
    if (apiURL === 'Cycles/List' && !isGlobal) {
      apiURL = `Cycles/List?asManager=false&isGlobal=false`;
    }
    return this.restfulWebService.get(apiURL);
  }

  getPerformanceReviews() {
    let apiUrl = `PerformanceReview/List`;
    return this.restfulWebService.get(apiUrl);
  }

  getLibraryActions() {
    let apiUrl = `PerformanceReview/LibraryActions`;
    return this.restfulWebService.get(apiUrl);
  }

  getActionTagIcons() {
    let apiUrl = `/Utility/AvailableIcons`;
    return this.restfulWebService.get(apiUrl);
  }

  getSinglePerformanceReviews(id: string) {
    let apiUrl = `PerformanceReview/Detail?id=${id}`;
    return this.restfulWebService.get(apiUrl);
  }

  savePerformanceReview(data: any) {
    return this.restfulWebService.post('PerformanceReview/Save', data);
  }

  deletePerformanceReview(id: string) {
    let apiUrl = `PerformanceReview/Delete?cycleId=${id}`;
    return this.restfulWebService.delete(apiUrl);
  }

  updatePerformanceReview(data: any) {
    return this.restfulWebService.put('PerformanceReview/Update', data);
  }

  uploadePerformanceReviewAttachment(formdata: any, attachmentId?: string) {
    const id = attachmentId ? attachmentId : null;
    return this.restfulWebService.fileUpload(
      `PerformanceReview/UploadAttachmentFile?id=${id}`,
      formdata
    );
  }

  getAttachementThumbnail(id: any) {
    let apiUrl = `PerformanceReview/Thumbnail?attachmentId=${id}`;
    return this.restfulWebService.get(apiUrl);
  }

  uploadAttachmentCover(formdata: any) {
    let apiUrl = `PerformanceReview/UploadAttachmentCover`;
    return this.restfulWebService.fileUpload(apiUrl, formdata);
  }

  getSingleCycle(
    id: string,
    isGlobal: boolean,
    manager: string = '',
    body: any = {}
  ) {
    let apiUrl = `Cycles/Single${manager}?id=${id}&isGlobal=${isGlobal}`;

    return this.restfulWebService.post(apiUrl, body);
  }

  getSystemLanguages() {
    return this.restfulWebService.get(`language`);
  }

  getAllExchangers() {
    return this.restfulWebService.get(`TokenExchanger`);
  }

  getCompanyExchangers(companyId) {
    return this.restfulWebService.get(`CompanyExt/Exchangers/${companyId}`);
  }

  saveCompanyDetails(company) {
    return this.restfulWebService.put(`CompanyExt/SavePartial`, company);
  }

  getLocalLanguages() {
    let Translations = [];
    let lan = localStorage.getItem('languages');
    let tempLanArr = lan ? JSON.parse(lan) : [];
    if (tempLanArr && tempLanArr.length > 0) {
      for (let l of tempLanArr) {
        Translations.push({
          LanguageCode: l.Code,
          Name: '',
          Description: ''
        });
      }
    }
    return Translations;
  }

  getAllEmployees() {
    return this.allcompanyemployees;
  }

  getCompanies(pageStart, pageSize) {
    return this.restfulWebService.get(
      `company?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  addCompany(company) {
    if (!company.Id) return this.restfulWebService.post(`company`, company);
    else return this.restfulWebService.put(`company/${company.Id}`, company);
  }

  deletePlan(ID) {
    return this.restfulWebService.delete(`company/${ID}`);
  }

  getPlans() {
    return this.restfulWebService.get(`plan`);
  }

  getCompanyDetails(ID, isGlobal) {
    return this.restfulWebService.get(
      `companyext/full?id=${ID}&isGlobal=${isGlobal}`
    );
  }
  getCompanySkillsAll(ID, isGlobal) {
    return this.restfulWebService.get(
      `CompanyExt/SkillsExt?id=${ID}&isGlobal=${isGlobal}`
    );
  }
  getCompanySkills(ID, isGlobal, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/SkillsExtPaged?id=${ID}&isGlobal=${isGlobal}&pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }
  getCompanyBehaviors(ID, isGlobal, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/BehaviorsExtPaged?id=${ID}&isGlobal=${isGlobal}&pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyRolesAll(ID, isGlobal) {
    return this.restfulWebService.get(
      `CompanyExt/RolesExt?id=${ID}&isGlobal=${isGlobal}`
    );
  }
  getCompanyRoles(ID, isGlobal, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/RolesExtPaged?id=${ID}&isGlobal=${isGlobal}&pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyTeamsAll(ID, isGlobal) {
    return this.restfulWebService.get(
      `CompanyExt/TeamsExt?id=${ID}&isGlobal=${isGlobal}`
    );
  }
  getCompanyTeams(ID, isGlobal, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/TeamsExtPaged?id=${ID}&isGlobal=${isGlobal}&pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyGoalsAll(ID) {
    return this.restfulWebService.get(`companyext/goals/${ID}`);
  }
  getCompanyGoals(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `companyext/goals/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyProjectTeamsAll(ID, isGlobal) {
    return this.restfulWebService.get(
      `CompanyExt/ProjectTeamsExt?id=${ID}&isGlobal=${isGlobal}`
    );
  }
  getCompanyProjectTeams(ID, isGlobal, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/ProjectTeamsExtPaged?id=${ID}&isGlobal=${isGlobal}&pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyActivitiesAll(ID) {
    return this.restfulWebService.get(`companyext/activities/${ID}`);
  }

  getCompanyActivities(ID, pageStart, pageSize) {
    return this.restfulWebService.get(
      `companyext/activities/${ID}?pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  getCompanyEmployeesAll(ID, isGlobal) {
    return this.restfulWebService.get(
      `CompanyExt/EmployeesExt?id=${ID}&isGlobal=${isGlobal}`
    );
  }

  getCompanyEmployees(ID, isGlobal, pageStart, pageSize) {
    return this.restfulWebService.get(
      `CompanyExt/EmployeesExtPaged?id=${ID}&isGlobal=${isGlobal}&pageStart=${pageStart}&pageSize=${pageSize}`
    );
  }

  addCompanyTeam(team) {
    if (!team.Id) return this.restfulWebService.post(`TeamExt`, team);
    else return this.restfulWebService.put(`TeamExt/${team.Id}`, team);
  }

  addCompanyActivity(activity) {
    if (!activity.Id)
      return this.restfulWebService.post(`ActivityExt`, activity);
    else
      return this.restfulWebService.put(`ActivityExt/${activity.Id}`, activity);
  }

  addCompanyProjectTeam(team) {
    if (!team.Id) return this.restfulWebService.post(`ProjectTeamExt`, team);
    else return this.restfulWebService.put(`ProjectTeamExt/${team.Id}`, team);
  }

  addCompanyGoal(goal) {
    if (!goal.Id) return this.restfulWebService.post(`CompanyGoalExt`, goal);
    else return this.restfulWebService.put(`CompanyGoalExt/${goal.Id}`, goal);
  }

  addCompanySkill(skill, reqBody, isFromLibrary) {
    if (isFromLibrary)
      return this.restfulWebService.post(`skillExt/AddFromLib`, reqBody);
    else {
      if (!skill.Id) return this.restfulWebService.post(`skillExt`, skill);
      else return this.restfulWebService.put(`skillExt/${skill.Id}`, skill);
    }
  }

  addCompanyRole(role, reqBody, isFromLibrary) {
    if (isFromLibrary)
      return this.restfulWebService.post(`roleExt/AddFromLib`, reqBody);
    else {
      if (!role.Id) return this.restfulWebService.post(`roleExt`, role);
      else return this.restfulWebService.put(`roleExt/${role.Id}`, role);
    }
  }

  addCompanyEmployee(employee) {
    if (!employee.Id)
      return this.restfulWebService.post(`EmployeeAdmin`, employee);
    else
      return this.restfulWebService.put(
        `EmployeeAdmin/${employee.Id}`,
        employee
      );
  }
  deleteCompanyTeam(ID) {
    return this.restfulWebService.delete(`teamExt/${ID}`);
  }

  deleteCompanyActivity(ID) {
    return this.restfulWebService.delete(`activityext/${ID}`);
  }

  deleteCompanyProjectTeam(ID) {
    return this.restfulWebService.delete(`projectteam/${ID}`);
  }

  deleteCompanyGoal(ID) {
    return this.restfulWebService.delete(`companygoalext/${ID}`);
  }

  deleteCompanyEmployee(ID) {
    return this.restfulWebService.delete(`employeeAdmin/${ID}`);
  }

  deleteCompanySkill(ID) {
    return this.restfulWebService.delete(`skillext/${ID}`);
  }

  deleteCompanyRole(ID) {
    return this.restfulWebService.delete(`roleext/${ID}`);
  }

  companyUploadLogo(ID, file) {
    return this.restfulWebService.fileUpload(`company/logo/${ID}`, file);
  }

  companyEmployeeUpload(ID, file) {
    return this.restfulWebService.fileUpload(`upload/employees?id=${ID}`, file);
  }

  companySkillUpload(ID, file) {
    return this.restfulWebService.fileUpload(`Upload/Skills?id=${ID}`, file);
  }

  companyTeamUploadPicture(ID, file) {
    return this.restfulWebService.fileUpload(`teamExt/picture/${ID}`, file);
  }

  companySkillUploadPicture(ID, file) {
    return this.restfulWebService.fileUpload(`skillExt/picture/${ID}`, file);
  }

  getAllCategories() {
    return this.restfulWebService.get(`SkillCategoryExt`);
  }

  getAvailableColumns(companyId) {
    return this.restfulWebService.get(
      `SkillExt/AvailableColumns?companyId=${companyId}`
    );
  }

  getLogs(companyId, type) {
    return this.restfulWebService.get(
      `Upload/GetImportLogs?companyId=${companyId}&type=${type}`
    );
  }

  getAllRoleCategories() {
    return this.restfulWebService.get(`RoleCategory`);
  }

  getAllSubcategories(catId) {
    return this.restfulWebService.get(
      `SubCategoryExt/Subcategories?categoryId=${catId}`
    );
  }

  getColumnValues(companyId, column) {
    return this.restfulWebService.get(
      `SkillExt/AvailableValues?companyId=${companyId}&columnName=${column}`
    );
  }

  getAllGlobalSkills() {
    return this.restfulWebService.get(`globalskillext`);
  }

  getAllGlobalRoles() {
    return this.restfulWebService.get(`globalroleext`);
  }

  getAllCompanyActivities(ID) {
    return this.restfulWebService.get(`company/activities/${ID}`);
  }

  getAllCompanyTeams(ID) {
    return this.restfulWebService.get(`company/teams/${ID}`);
  }

  getAllCompanyRoles(ID) {
    return this.restfulWebService.get(`company/roles/${ID}`);
  }

  getAllEmployeeInfo(ID) {
    return this.restfulWebService.get(
      `EmployeeExt/AllEmployeeInfo?employeeId=${ID}`
    );
  }

  //getAllCompanyEmployees(ID) { return this.restfulWebService.get(`company/employees/${ID}`) }
  getDivisions(companyId) {
    return this.restfulWebService.get(
      `CompanyExt/Divisions?companyId=${companyId}`
    );
  }

  saveDivisionManagers(companyId, selectedManagers) {
    return this.restfulWebService.post(
      `CompanyExt/SaveDivisionManagers?companyId=${companyId}`,
      selectedManagers
    );
  }

  getAllCompanyEmployees() {
    return this.getAllEmployees();
  }

  getAllMeasures() {
    return this.restfulWebService.get(`measureext`);
  }

  getGoalMeasures(id) {
    return this.restfulWebService.get(`LTI/Measures?goalId=${id}`);
  }

  getAllCompanyEmployeesWithTeam(companyId, teamId, isGlobal) {
    return this.restfulWebService.get(
      `companyext/employeesforteam?companyid=${companyId}&teamid=${teamId}&isGlobal=${isGlobal}`
    );
  }

  getLanguageJSON() {
    return this.restfulWebService.getJSON(
      'assets/json/ISO-639-1-language.json'
    );
  }

  invitionMailSelected(reqBody) {
    return this.restfulWebService.post('admin/MassiveSendVerificationMail', {
      Users: reqBody
    });
  }

  resetPasswordSelected(reqBody) {
    return this.restfulWebService.post('admin/MassiveResetPassword', {
      Users: reqBody
    });
  }

  setEnableSelected(reqBody) {
    return this.restfulWebService.post('admin/MassiveResetPassword', {
      Users: reqBody
    });
  }

  deleteSelected(reqBody) {
    return this.restfulWebService.post('admin/MassiveResetPassword', {
      Users: reqBody
    });
  }

  getCategoriesIcons() {
    return this.restfulWebService.get(`Integration/Icons`);
  }

  getGlobalCategories() {
    return this.restfulWebService.get(`ObjectivesCategoryExt`);
  }

  deleteCycle(ID) {
    return this.restfulWebService.delete(`Cycles/Delete?id=${ID}`);
  }

  sendCycle(ID) {
    return this.restfulWebService.get(`Cycles/Send?cycleId=${ID}`);
  }

  scheduleCycle(ID, date) {
    return this.restfulWebService.get(
      `Cycles/Schedule?cycleId=${ID}&when=${date}`
    );
  }

  addCycle(cycle) {
    return this.restfulWebService.post(`Cycles/Save`, cycle);
  }

  updateCycle(cycle) {
    return this.restfulWebService.put(`Cycles/Update`, cycle);
  }

  /**********************************************************
    __  __          _   _          _____ ______ _____
   |  \/  |   /\   | \ | |   /\   / ____|  ____|  __ \
   | \  / |  /  \  |  \| |  /  \ | |  __| |__  | |__) |
   | |\/| | / /\ \ | . ` | / /\ \| | |_ |  __| |  _  /
   | |  | |/ ____ \| |\  |/ ____ \ |__| | |____| | \ \
   |_|  |_/_/    \_\_| \_/_/    \_\_____|______|_|  \_\

   **********************************************************/

  /**********************************************************/

  SaveOKRProgressValue(kr: any) {
    return this.restfulWebService.post(`CycleObjective/SaveOKRProgressValue`, {
      Id: kr.Id,
      Value: kr.Value
    });
  }

  SaveObjectProgressValue(objectiveId: string, value: number) {
    return this.restfulWebService.post(
      `CycleObjective/SaveObjectProgressValue`,
      {
        Id: objectiveId,
        Value: value
      }
    );
  }

  getPerformanceReviewAvailableColumns(id: any) {
    return this.restfulWebService.get(
      `PerformanceReview/AvailableColumns?cycleId=${id}`
    );
  }

  getReviewees(id: string, request) {
    return this.restfulWebService.post(
      `/PerformanceReview/Partecipants?cycleId=${id}`,
      request
    );
  }

  getSuperVisoryList(id: string, request) {
    return this.restfulWebService.post(
      `/PerformanceReview/ManagerReviewers?cycleId=${id}`,
      request
    );
  }

  performBulkAction(id: string, request) {
    return this.restfulWebService.post(
      `/PerformanceReview/BulkAction?cycleId=${id}`,
      request
    );
  }

  getBulkTranslation(data: any) {
    return this.restfulWebService.post('Translator/BulkTranslate', data);
  }
}
