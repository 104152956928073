import { Injectable, EventEmitter, Output, TemplateRef } from '@angular/core';
import { ToasterService } from 'angular2-toaster';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ViewImageComponent } from '../components/view-image/view-image.component';
import { RestfulService } from '../services/restful.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { BiometricService } from 'app/shared/services/cordova/biometric.service';
import { Router } from '@angular/router';

import * as moment from 'moment';

@Injectable()
export class CoreService {
  @Output() onNewNotification: EventEmitter<any[]> = new EventEmitter();
  @Output() userInfoChanged: EventEmitter<string> = new EventEmitter();
  @Output() wallChanged: EventEmitter<string> = new EventEmitter();
  refreshPerformanceReview$: BehaviorSubject<string> =
    new BehaviorSubject<string>(null);
  clearFilter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  showLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  events$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  clearWallFilter$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    null
  );
  clearEmpFilter$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>(
    null
  );
  radioSelectedValue$: BehaviorSubject<{
    item: any[];
    id: string;
  }> = new BehaviorSubject<{
    item: any[];
    id: string;
  }>(null);
  showPublished$: BehaviorSubject<{ type: string; isEdit: boolean }> =
    new BehaviorSubject<{ type: string; isEdit: boolean }>(null);

  showEliminated$: BehaviorSubject<{ type: string }> = new BehaviorSubject<{
    type: string;
  }>(null);

  readonly BASE_URL = environment.serverURI;

  private defaultActionTemplate;

  readonly DEFAULT_IMAGE_URL = 'assets/images/default.png';
  readonly BASE_FLAG_URL = 'assets/images/flags/32/';
  public rowsPerPage: number = 15;
  public rowsPerPageOptions = [5, 15, 25, 50, 100];
  public CountryType = 'it';
  public showMenuWidth: boolean;
  public bsModalRef: BsModalRef;

  private toasterService: ToasterService;
  constructor(
    toasterService: ToasterService,
    private modalService: BsModalService,
    public restfulWebService: RestfulService,
    public translate: TranslateService,
    private nativeStorage: NativeStorage,
    public biometricService: BiometricService,
    public router: Router
  ) {
    this.toasterService = toasterService;
  }

  setDefaultActionTemplate(template) {
    this.defaultActionTemplate = template;
  }

  getDefaultActionTemplate() {
    return this.defaultActionTemplate;
  }

  setShowLoading(isLoading) {
    this.showLoading$.next(isLoading);
  }

  getShowLoading() {
    return this.showLoading$.asObservable();
  }

  setEvents(events) {
    this.events$.next(events);
  }

  getEvents() {
    return this.events$.asObservable();
  }

  setShowPublished(showPublished: { type: string; isEdit: boolean }) {
    this.showPublished$.next(showPublished);
  }

  getShowPublished() {
    return this.showPublished$.asObservable();
  }

  getShowEliminated() {
    return this.showEliminated$.asObservable();
  }

  setShowEliminated(showEliminated: { type: string }) {
    this.showEliminated$.next(showEliminated);
  }

  ClearDateGMT(date, h = 0, m = 0): any {
    let local = moment(date);
    if (h == 23) {
      local = local.endOf('day');
    } else {
      local = local.startOf('day');
    }
    return local.format('YYYY-MM-DD HH:mm:ss');
    // return new Date(date.getFullYear(), date.getMonth(), date.getDate(), h, m);
  }

  getBaseURL() {
    return this.BASE_URL;
  }

  getRefreshPerformanceReview() {
    return this.refreshPerformanceReview$.asObservable();
  }

  setRefreshPerformanceReview(value: string) {
    this.refreshPerformanceReview$.next(value);
  }

  getClearFilter() {
    return this.clearFilter$.asObservable();
  }

  setClearFilter(value: boolean) {
    this.clearFilter$.next(value);
  }

  getWallClearFilter() {
    return this.clearWallFilter$.asObservable();
  }

  setWallClearFilter(value: boolean) {
    this.clearWallFilter$.next(value);
  }

  getEmpClearFilter() {
    return this.clearEmpFilter$.asObservable();
  }

  setEmpClearFilter(value: number[]) {
    this.clearEmpFilter$.next(value);
  }
  getRadioSelect(): Observable<{
    item: any[];
    id: string;
  }> {
    return this.radioSelectedValue$.asObservable();
  }

  setRadioSelect(value: { item: any[]; id: string }) {
    this.radioSelectedValue$.next(value);
  }

  getFormattedNumber(
    number,
    minimumFractionDigits,
    maximumFractionDigits,
    style,
    currency
  ) {
    let lang = this.translate.currentLang;
    let obj = {
      minimumFractionDigits: minimumFractionDigits || 0,
      maximumFractionDigits: maximumFractionDigits || 2,
      style: style,
      currency: currency
    };

    let x = number.toLocaleString(lang, obj);
    return x;
  }

  getImageURL(picture, defaultPic, isReported = false, name = '') {
    if (isReported) {
      return 'https://images.unsplash.com/photo-1597767969494-9d40a3971936?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMzc1ODl8MHwxfHNlYXJjaHwxfHxibG9ja2VkfGVufDB8fHx8MTY0ODEzMjE2NQ&ixlib=rb-1.2.1&q=80&w=400';
    }
    if (picture) {
      if (
        picture.substring(0, 4) === 'http' ||
        picture.substring(0, 5) === 'data:'
      ) {
        return picture;
      } else {
        return this.BASE_URL + picture;
      }
    } else if (name) {
      name = name.toUpperCase();
      const splitName = name.split(' ');
      if (splitName.length == 2) {
        return `https://www.gravatar.com/avatar/254347aef3019580cb3532f2d1e8ec54?d=https://ui-avatars.com/api/name=${splitName[1][0]}${splitName[0][0]}&background=a09ab2&color=0c0039`;
      } else if (splitName.length > 2) {
        return `https://www.gravatar.com/avatar/254347aef3019580cb3532f2d1e8ec54?d=https://ui-avatars.com/api/name=${splitName[2][0]}${splitName[0][0]}&background=a09ab2&color=0c0039`;
      } else {
        return defaultPic;
      }
    } else {
      return defaultPic;
    }
  }

  getFullName(surname, name, team = undefined) {
    let fullName = name + ' ' + surname;

    if (team) {
      fullName += ' (' + team + ')';
    }

    return fullName;
  }

  getDefaultImage() {
    return this.DEFAULT_IMAGE_URL;
  }

  //
  getUserRole() {
    let user = this.getUserInfo();
    if (user) return user['roles'];
  }

  isLoggedIn(): boolean {
    let user = this.getUserInfo();
    return !!user;
  }

  // set token and logged in user-information in Localstorage
  async SetAuthToken(data) {
    new Promise(async (resolve) => {
      console.log('SetAuthToken', data);
      if (data) localStorage.setItem('user-info', JSON.stringify(data));
      if (data && data.access_token)
        localStorage.setItem('auth-token', data.access_token);
      if (data && data.refresh_token) {
        localStorage.setItem('refresh_token', data.refresh_token);
        if (window['cordova']) {
          await this.nativeStorage.setItem('refresh_token', data.refresh_token);
          console.log(
            'setItem =>refresh_token',
            await this.nativeStorage.getItem('refresh_token')
          );
        }
      }
      if (data && data.userName) {
        localStorage.setItem('userName', data.userName);
        if (window['cordova']) {
          await this.nativeStorage.setItem('userName', data.userName);
          console.log(
            'setItem =>userName',
            await this.nativeStorage.getItem('userName')
          );
        }
      }

      if (window['cordova'] && data.HasBiometricId) {
        //this.registerBiometricSecret();
      }

      console.log(
        'SetAuthToken =>',
        localStorage.getItem('userName'),
        localStorage.getItem('refresh_token')
      );

      resolve(true);
    });
  }

  async registerBiometricSecret() {
    const refreshToken = await this.nativeStorage.getItem('refresh_token');
    const userName = await this.nativeStorage.getItem('userName');

    const refreshModel: any = {
      refreshToken: refreshToken,
      userName: userName
    };

    console.log('refreshModel', refreshModel);

    this.biometricService.registerBiometricSecret(JSON.stringify(refreshModel));
  }

  setUserInfo(userInfo) {
    if (userInfo) localStorage.setItem('user-info', JSON.stringify(userInfo));
  }

  setCurrentPage(page) {
    if (page) localStorage.setItem('current-page', JSON.stringify(page));
  }

  getCurrentPage(page) {
    let tempInfo = localStorage.getItem('current-page');
    return tempInfo;
  }

  changedProperty(properties) {
    //console.log('userInfo changed: ', properties);
    this.userInfoChanged.emit(properties);
  }

  hasNewNotification() {
    this.restfulWebService
      .get('Account/Notifications')
      .subscribe((data: any) => {
        this.onNewNotification.emit(data);
      });
  }

  onWallChanged() {
    console.log('wall changed ');
    this.wallChanged.emit();
  }

  setLanguages(data) {
    if (data) localStorage.setItem('languages', JSON.stringify(data));
  }

  getEarnToken() {
    let tempInfo = localStorage.getItem('earned-Token');
    tempInfo = tempInfo ? JSON.parse(tempInfo) : null;
    return tempInfo;
  }

  // get Language JSON
  getLanguages() {
    let tempInfo = localStorage.getItem('languages');
    tempInfo = tempInfo ? JSON.parse(tempInfo) : null;
    return tempInfo;
  }

  getPeople(term: string = null, meeToo: boolean = false): Observable<any> {
    console.log('here');
    if (term) {
      return this.restfulWebService.get(
        `CompanyExt/AllEmployees?filter=${term}&pageStart=-1&pagesize=-1&meeToo=${meeToo}`
      );
    } else {
      return of([]);
    }
  }

  getONANetwork(): Observable<any> {
    return this.restfulWebService.get(`ONA/Network`);
  }

  saveONA(bosses, reports, coworkers, desired) {
    let body: any = {};
    if (bosses) body.Bosses = Array.from(bosses, (x: any) => x.Id);
    if (reports) body.Reports = Array.from(reports, (x: any) => x.Id);
    if (coworkers) body.Coworkers = Array.from(coworkers, (x: any) => x.Id);
    if (desired) body.Desired = Array.from(desired, (x: any) => x.Id);

    return this.restfulWebService.post(`ONA/Save`, body);
  }

  // get imageURL for flag
  getFlagImage(code) {
    return `${this.BASE_FLAG_URL}${code}.png`;
  }

  getFlagImageURL(code) {
    return `url(${this.BASE_FLAG_URL}${code}.png);`;
  }

  //  notification message
  toasterMessage(type, module, message) {
    switch (type) {
      case 'success':
        this.toasterService.pop(
          type,
          this.getTranslation('Messages.Successful'),
          message
        );
        break;
      case 'error':
        this.toasterService.pop(
          type,
          this.getTranslation('Messages.Unsuccessful'),
          message
        );
        break;
      case 'warning':
        this.toasterService.pop(
          type,
          this.getTranslation('Messages.Warning'),
          message
        );
        break;
      default:
        this.toasterService.pop(
          type,
          this.getTranslation('Messages.Successful'),
          message
        );
    }
  }

  getImportStatus(companyId, type) {
    return this.restfulWebService.get(
      `Upload/GetImportProgress?companyId=${companyId}&type=${type}`
    );
  }

  getTranslation(key, params?) {
    //let text=key;
    return this.translate.instant(key, params); // Synchronous
    //return this.translate.get(key).subscribe((t: string) => { return t });
    // let obj={Text:key};
    // this.getInnerTranslation(key, obj);
    // return obj.Text;
    //return  this.translate.get(key).first().toPromise().then(x=>{return x});
    // return text;
  }
  getInnerTranslation(key, obj) {
    this.translate.get(key).subscribe((t: string) => {
      obj.Text = t;
      obj.Done = true;
    });

    while (!obj.Done) {}
  }

  // common popup to show image in full size
  onImagePreview(image) {
    const modal = this.modalService.show(ViewImageComponent);
    let imageURL = this.getImageURL(image, 'assets/images/default.png');

    (<ViewImageComponent>modal.content).showImageModel(imageURL);
  }

  getRowsPerPage() {
    return this.rowsPerPage;
  }

  getUserInfo(): any {
    let tempInfo: any = localStorage.getItem('user-info');
    tempInfo = tempInfo ? JSON.parse(tempInfo) : null;
    return tempInfo;
  }

  getUserToken() {
    let tempInfo = localStorage.getItem('auth-token');
    tempInfo = tempInfo ? tempInfo : null;
    return tempInfo;
  }

  getUserRefreshToken() {
    let tempInfo = localStorage.getItem('refresh_token');
    tempInfo = tempInfo ? tempInfo : null;
    return tempInfo;
  }

  getDateFormat() {
    let tempFormat = 'YYYY/MM/DD';
    switch (this.CountryType) {
      case 'it':
        tempFormat = 'DD/MM/YYYY';
        break;
      case 'en':
        tempFormat = 'MM/DD/YYYY';
        break;
      default:
        tempFormat = 'DD/MM/YYYY';
    }
    return tempFormat;
  }

  GetSingleObjective(id) {
    return this.restfulWebService.get(
      `CycleObjective/SingleObjective?objectiveId=${id}`
    );
  }

  postNews(req) {
    return this.restfulWebService.post(`PatFeed/News`, req);
  }

  updateNews(req) {
    return this.restfulWebService.put(`PatFeed/News`, req);
  }

  follow(id) {
    return this.restfulWebService.get(
      `CycleObjective/Follow?objectiveId=${id}`
    );
  }

  getLocaleDate(date: string, locale: string) {
    const event = new Date(date);
    const options: any = { year: 'numeric', month: 'numeric', day: 'numeric' };
    return event.toLocaleDateString(locale, options);
  }

  setPreferredRole(role: string) {
    const userInfo = this.getUserInfo();
    userInfo.PreferredRole = role;
    this.setUserInfo(userInfo);
  }

  setUserProfile(image: string) {
    const userInfo = this.getUserInfo();
    userInfo.imageUrl = image;
    this.setUserInfo(userInfo);
  }

  loadEmployeeTableMapping(companyId) {
    return this.restfulWebService.get(
      `EmployeeExt/TableMapping?companyId=${companyId}`
    );
  }

  saveEmployeeTableMapping(companyId, obj) {
    return this.restfulWebService.post(
      `EmployeeExt/SaveTableMapping?companyId=${companyId}`,
      obj
    );
  }
  saveSomethingAbout(message) {
    return this.restfulWebService.post(`Story/SaveSomethingAbout`, {
      Val: message
    });
  }
  saveLanguage(language) {
    return this.restfulWebService.post(`Story/ChangeLanguage`, {
      Val: language
    });
  }

  getCompanyLanguages(ID) {
    return this.restfulWebService.get(
      `language/CompanyLanguages?companyId=${ID}`
    );
  }
  getCompanyLogo(companyId: string) {
    return this.restfulWebService.get(
      `Account/CompanyLogo?companyId=${companyId}`
    );
  }

  translateText(originalText, originalLang, targetLang) {
    if (originalText) {
      let item = {
        OriginalLanguageCode: originalLang,
        OriginalText: originalText,
        TargetLanguageCode: targetLang
      };
      return this.restfulWebService.post(`Translator/Translate`, item);
    }
  }

  getTicketTableauURL() {
    var data = {
      Username: 'patpat_superadmin',
      Site: 'PatPat360'
    };
    return this.restfulWebService.post(`Tableau/GetUrl`, data);
  }

  uploadQuestionFile(formData) {
    return this.restfulWebService.fileUpload(`Upload/QuestionFile`, formData);
  }

  getOrganizationChart(companyId) {
    return this.restfulWebService.get(
      `CompanyExt/OrganizationChart?companyId=${companyId}`
    );
  }

  getOrganizationNetwork(companyId) {
    return this.restfulWebService.get(
      `CompanyExt/OrganizationNetwork?companyId=${companyId}`
    );
  }

  cleanAttachment(attachment: any) {
    let obj: any = {};
    obj.AttachmentData = {};
    Object.keys(attachment.AttachmentData).forEach((key) => {
      // console.log(key)
      obj.AttachmentData[key] = {};
      obj.AttachmentData[key].UploadedFileId =
        attachment.AttachmentData[key].UploadedFileId;
      obj.AttachmentData[key].Cover = attachment.AttachmentData[key].Cover;
      obj.AttachmentData[key].Name = attachment.AttachmentData[key].Name;
      obj.AttachmentData[key].Description =
        attachment.AttachmentData[key].Description;
      obj.AttachmentData[key].IsCoverForAll =
        attachment.AttachmentData[key].IsCoverForAll;
    });
    obj.AvailableSelf = attachment.AvailableSelf;
    obj.Available360 = attachment.Available360;
    obj.AvailableManager = attachment.AvailableManager;
    obj.AvailableSupervisor = attachment.AvailableSupervisor;
  }

  deMapCycle(selectedPerformanceReview) {
    let performanceReview: any = {};
    Object.keys(selectedPerformanceReview).forEach((key) => {
      let value = selectedPerformanceReview[key];
      if (key === 'Name') {
        performanceReview[`${key}`] = this.mapLanguageObjectToArray(
          selectedPerformanceReview[`${key}`]
        );
      } else if (key === 'Guidelines') {
        performanceReview[`${key}`] = {};
        Object.keys(selectedPerformanceReview[`${key}`]).forEach((subKey) => {
          if (subKey !== '$id') {
            performanceReview[`${key}`][`${subKey}`] =
              this.mapLanguageObjectToArray(
                selectedPerformanceReview[`${key}`][subKey]
              );
          }
        });
      }
    });

    performanceReview.Period = selectedPerformanceReview.Period;
    performanceReview.HasSelfReview = selectedPerformanceReview.HasSelfReview;
    performanceReview.Has360Review = selectedPerformanceReview.Has360Review;
    performanceReview.HasManagerReview =
      selectedPerformanceReview.HasManagerReview;
    performanceReview.ManagerCanAddAction =
      selectedPerformanceReview.ManagerCanAddAction
        ? selectedPerformanceReview.ManagerCanAddAction
        : false;
    performanceReview.Interval = this.ClearIntervalGMT(
      selectedPerformanceReview.Interval
    );
    performanceReview.PeriodFill = this.ClearIntervalGMT(
      selectedPerformanceReview.PeriodFill
    );
    performanceReview.PeriodManagerReview = this.ClearIntervalGMT(
      selectedPerformanceReview.PeriodManagerReview
    );
    performanceReview.PeriodReview = this.ClearIntervalGMT(
      selectedPerformanceReview.PeriodReview
    );
    performanceReview.PeriodReview360 = this.ClearIntervalGMT(
      selectedPerformanceReview.PeriodReview360
    );
    performanceReview.PeriodSelfReview = this.ClearIntervalGMT(
      selectedPerformanceReview.PeriodSelfReview
    );
    performanceReview.PeriodSubmit = this.ClearIntervalGMT(
      selectedPerformanceReview.PeriodSubmit
    );
    performanceReview.PeriodSupervisorReview = this.ClearIntervalGMT(
      selectedPerformanceReview.PeriodSupervisorReview
    );
    performanceReview.PeriodSubmit.Start = '';
    performanceReview.PeriodSupervisorReview.Start = '';
    // performanceReview.$id = selectedPerformanceReview.$id;
    performanceReview.Id = selectedPerformanceReview.Id;
    performanceReview.OnlyAdminActsAsSupervisor =
      selectedPerformanceReview.OnlyAdminActsAsSupervisor;
    performanceReview.Partecipants =
      selectedPerformanceReview?.Partecipants || [];
    performanceReview.Attachments =
      selectedPerformanceReview.Attachments &&
      selectedPerformanceReview.Attachments?.length
        ? selectedPerformanceReview.Attachments
        : [];
    if (!performanceReview.Questions) {
      performanceReview.Questions = selectedPerformanceReview?.Questions || [];
    }
    if (!performanceReview.ActionQuestion) {
      performanceReview.ActionQuestion =
        selectedPerformanceReview?.ActionQuestion || null;
    }
    if (!performanceReview.MatrixQuestion) {
      performanceReview.MatrixQuestion =
        selectedPerformanceReview?.MatrixQuestion || null;
    }
    return performanceReview;
  }

  mapLanguageObjectToArray(languageObject: any) {
    let arr: any = {};

    Object.keys(languageObject).forEach((key) => {
      if (key !== '$id') {
        arr[key] = {};
        arr[key].Text = languageObject[key].Text;
      }
    });

    return arr;
  }

  ClearIntervalGMT(interval) {
    let obj: any = {};
    obj.Start = new Date(interval.Start);
    obj.End = new Date(interval.End);
    return obj;
  }
  getManagerEnteryPointDetail() {
    return this.restfulWebService.get(`ManagerEntryPoint`);
  }
  getManagerEnteryPointBoxDetail(keyId) {
    return this.restfulWebService.get(`ManagerEntryPoint?boxKey=${keyId}`);
  }
  deleteExcludeBox(req) {
    return this.restfulWebService.post(`ManagerEntryPoint/ExcludeBox`, req);
  }
  addIncludeBox(req) {
    return this.restfulWebService.post(`ManagerEntryPoint/IncludeBox`, req);
  }
  setPriorityBox(req) {
    return this.restfulWebService.post(`ManagerEntryPoint/Priority`, req);
  }

  // comapny-theme-API
  getCompanyTheme() {
    return this.restfulWebService.get(`CompanyExt/DefaultTheme`);
  }

  changeCompanyTheme(company) {
    return this.restfulWebService.post(`CompanyExt/SaveDefaultTheme`, company);
  }
  clearDivision() {
    return this.restfulWebService.get(`CompanyExt/ClearDivisions`);
  }
  refreshDivision() {
    return this.restfulWebService.get(`CompanyExt/RefreshDivisions`);
  }

  capitalizeWords(str) {
    return str
      .split(' ')
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(' ');
  }
}
