import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  forwardRef
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CoreService } from 'app/shared/services/core.service';
import { isUndefined } from 'util';

// Charts
import { Chart } from 'chart.js/dist/Chart.min.js';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss', '../common-styles.scss']
})
export class ResultsComponent implements OnInit {
  @Input() plan: any;
  @Input() userId: any;
  currentYear: number;

  // CHART
  private chart: ElementRef;
  @ViewChild('chart') set _chart(content: ElementRef) {
    if (content) {
      // initially setter gets called with undefined
      this.chart = content;
      this.initChartJS();
    }
  }
  myChart: any;

  constructor(
    private translateService: TranslateService,
    private coreService: CoreService,
    private ref: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentYear = this.plan.YearStart;
  }
  /*************************************************************** */
  getObjectTargetValue(obj, year) {
    let y = obj.YearlyValues.find((x) => x.Year == year);
    if (y) return y.calcTargetValue;
    else return undefined;
  }
  /*************************************************************** */
  getObjectTargetThreshold(obj, year) {
    if (obj.IsCorridor) {
      if (obj.Measure.MeasureType == 'scale')
        return obj.Min + '/' + obj.Max + 'Δ';
      else return obj.Min + '-' + obj.Max + '%';
    } else {
      return '';
    }
  }
  /*************************************************************** */
  getObjectSensivity(obj, year) {
    if (obj.IsCorridor) {
      return obj.Floor + '-' + obj.Cap + '%';
    } else {
      return '';
    }
  }
  /*************************************************************** */
  getObjectCurrentValue(obj, year) {
    let y = obj.YearlyValues.find((x) => x.Year == year);
    if (y) return y.CurrentValue;
    else return undefined;
  }
  /*************************************************************** */
  getObjectCalcTargetValue(obj, year) {
    let y = obj.YearlyValues.find((x) => x.Year == year);
    if (y && y.CurrentValue) return (y.CurrentValue * 100) / y.calcTargetValue;
    else return 0;
  }
  /*************************************************************** */
  getIsOverTargetValue(obj, year) {
    let y = obj.YearlyValues.find((x) => x.Year == year);
    if (y && y.CurrentValue) {
      console.log(y.CurrentValue);
      return y.CurrentValue >= y.calcTargetValue
        ? 'text-success'
        : 'text-danger';
    } else {
      return 'text-danger';
    }
  }
  /*************************************************************** */
  getMultiplier(obj, year) {
    let y = obj.YearlyValues.find((x) => x.Year == year);
    if (y) return y.multiplier.value * 100;
    else return undefined;
  }
  /*************************************************************** */
  formatNumber(x: any, decimal: number = 0, currency: boolean = true) {
    if ((!x && x !== 0) || String(x).includes('NaN')) return '';
    if (currency) {
      return x.toLocaleString(this.translateService.currentLang, {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    } else {
      return x.toLocaleString(this.translateService.currentLang, {
        minimumFractionDigits: decimal,
        maximumFractionDigits: decimal
      });
    }
  }

  getYears() {
    return new Array(this.plan.YearEnd - this.plan.YearStart)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  getYearsUntilCurrentYear(currentYear) {
    return new Array(currentYear + 1 - this.plan.YearStart)
      .fill(0)
      .map((val, idx) => this.plan.YearStart + idx);
  }

  getAmount(type: string) {
    let v = 0;
    if (this.plan) {
      let pby = this.plan.PartecipantsByYear.find(
        (x) => x.Year === this.currentYear
      );
      if (pby && pby.Partecipants.length > 0) {
        let p = pby.Partecipants[0];
        let pa = p.Values.PaybleDeferredArray.find(
          (x) => x.PayYear === this.currentYear
        );
        switch (type) {
          case 'accrued':
            v = p.Values.Accrued.value;
            break;
          case 'accrued_max':
            v = p.Values.Accrued.max;
            break;
          case 'accrued_payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'accrued_deferred':
            v = pa ? pa.DeferredCash.value + pa.DeferredEquity.value : 0;
            break;
          case 'payable':
            v = pa ? pa.Cash.value + pa.Equity.value : 0;
            break;
          case 'payable_cash':
            v = pa ? pa.Cash.value : 0;
            break;
          case 'payable_equity':
            v = pa ? pa.Equity.value : 0;
            break;
          case 'bonusbank':
            break;
        }
      }
    }
    return v;
  }

  expandAccrued: boolean = false;
  expandPayable: boolean = false;
  expandBonusBank: boolean = false;
  expand(type: string) {
    switch (type) {
      case 'accrued':
        this.expandAccrued = true;
        this.expandPayable = false;
        this.expandBonusBank = false;
        $('#bonusAccrued').css('order', 1);
        $('#bonusPayable').css('order', 2);
        $('#bonusBank').css('order', 3);
        break;
      case 'payable':
        this.expandAccrued = false;
        this.expandPayable = true;
        this.expandBonusBank = false;
        $('#bonusPayable').css('order', 1);
        $('#bonusAccrued').css('order', 2);
        $('#bonusBank').css('order', 3);
        break;
      case 'bonusbank':
        this.expandAccrued = false;
        this.expandPayable = false;
        this.expandBonusBank = true;
        $('#bonusBank').css('order', 1);
        $('#bonusAccrued').css('order', 2);
        $('#bonusPayable').css('order', 3);
        break;
    }

    this.ref.detectChanges();
  }

  collapse(type: string) {
    switch (type) {
      case 'accrued':
        this.expandAccrued = false;
        break;
      case 'payable':
        this.expandPayable = false;
        break;
      case 'bonusbank':
        this.expandBonusBank = false;
        break;
    }
    this.ref.detectChanges();
  }

  /*******************************************************************************
    ____   ____  _   _ _    _  _____            _____ _____ _____  _    _ ______ _____
   |  _ \ / __ \| \ | | |  | |/ ____|     /\   / ____/ ____|  __ \| |  | |  ____|  __ \
   | |_) | |  | |  \| | |  | | (___      /  \ | |   | |    | |__) | |  | | |__  | |  | |
   |  _ <| |  | | . ` | |  | |\___ \    / /\ \| |   | |    |  _  /| |  | |  __| | |  | |
   | |_) | |__| | |\  | |__| |____) |  / ____ \ |___| |____| | \ \| |__| | |____| |__| |
   |____/ \____/|_| \_|\____/|_____/  /_/    \_\_____\_____|_|  \_\\____/|______|_____/

  *******************************************************************************/

  getSummaryTargetBonus(year: number) {
    return this.formatNumber(0);
  }

  getSummaryTargetBonusMultiplier(year: number) {
    return this.formatNumber(0);
  }

  getSummaryAdmittedBonus(year: number) {
    return this.formatNumber(0);
  }

  getSummaryBonusMultiplier(year: number) {
    return this.formatNumber(0);
  }

  getSummaryBonusAccrued(year: number) {
    return this.formatNumber(0);
  }

  getSummaryPayable(year: number) {
    return this.formatNumber(0);
  }

  getSummaryDeferred(year: number) {
    return this.formatNumber(0);
  }

  /*******************************************************************************
    ____   ____  _   _ _    _  _____   ____          _   _ _  __
   |  _ \ / __ \| \ | | |  | |/ ____| |  _ \   /\   | \ | | |/ /
   | |_) | |  | |  \| | |  | | (___   | |_) | /  \  |  \| | ' /
   |  _ <| |  | | . ` | |  | |\___ \  |  _ < / /\ \ | . ` |  <
   | |_) | |__| | |\  | |__| |____) | | |_) / ____ \| |\  | . \
   |____/ \____/|_| \_|\____/|_____/  |____/_/    \_\_| \_|_|\_\
  *******************************************************************************/
  getPartecipantsByYear(year: number) {
    const partecipants = this.plan.PartecipantsByYear.filter(
      (planYear) => planYear.Year == year
    );
    return partecipants.length > 0 && partecipants[0].Partecipants.length > 0
      ? partecipants[0].Partecipants
      : [];
  }
  getPartecipant(year) {
    let partecipants = this.getPartecipantsByYear(year);
    let p = partecipants.find((x) => x.EmployeeId == this.userId);
    return p;
  }
  getBonusBankOpening(year: number) {
    if (this.plan && year > this.plan.YearStart) {
      return this.getBonusBankClosing(year - 1);
    } else {
      return 0;
    }
  }

  getBonusBankPastPayable() {
    let partecipant = this.getPartecipant(this.currentYear);
    return this.getBonusDetailPastYearsPayableValue(
      this.currentYear,
      partecipant
    );
  }
  getBonusBankDeferred() {
    let partecipant = this.getPartecipant(this.currentYear);
    return this.getBonusDeferredPartecipantValue(this.currentYear, partecipant);
  }

  getBonusBankClosing(year: number) {
    let n = this.getBonusBankRolling(year);

    return n * this.getTotMultiplierValue('corrective', year);
  }

  getBonusBankRolling(year: number) {
    let y = this.currentYear;
    if (year < this.currentYear) {
      y = year;
    }
    let partecipant = this.getPartecipant(y);

    let n = 0;
    let base = 0;
    if (this.plan) {
      if (this.plan.CorrectiveType == 'Rolling') {
        base =
          this.getBonusBankOpening(year) -
          this.getBonusDetailPastYearsPayableValue(year, partecipant) +
          this.getBonusDeferredPartecipantValue(year, partecipant);
      } else {
        if (year == this.plan.PayoutStructure.PayoutYearEnd) {
          base =
            this.getBonusBankOpening(year) -
            this.getBonusDetailPastYearsPayableValue(year, partecipant) +
            this.getBonusDeferredPartecipantValue(year, partecipant);
        } else {
          base = this.getBonusBankOpening(year);
        }
      }
    }
    return base;
  }

  getBonusDetailPastYearsPayableValue(year: number, partecipant: any) {
    let n = 0.0;
    if (this.plan && this.plan.PartecipantsByYear) {
      let items = this.plan.PartecipantsByYear.filter((x) => x.Year < year);
      items.forEach((element: any) => {
        let foundEmployee = element.Partecipants.find(
          (x) => x.EmployeeId == partecipant.EmployeeId
        );
        let payments = foundEmployee.Values.PaybleDeferredArray.filter(
          (x) => x.PayYear < year
        );
        if (payments) {
          payments.forEach((e: any) => {
            n += e.Cash.value + e.Equity.value;
          });
        }
      });
    }

    return n;
  }

  getTotMultiplierValue(type: string, year: number) {
    if (this.plan && this.plan.Multipliers) {
      let item = this.plan.Multipliers.ByYear.find((x) => x.year == year);
      if (item) {
        switch (type) {
          case 'gateway':
            return item.multipliers.gateways
              ? item.multipliers.gateways.value
              : 1;
            break;
          case 'objective':
            return item.multipliers.objectives
              ? item.multipliers.objectives.value
              : 1;
            break;
          case 'corrective':
            return item.multipliers.correctives
              ? item.multipliers.correctives.value
              : 1;
            break;
        }
      }
    }

    return 0;
  }

  getBonusDeferredPartecipantValue(year: number, partecipant: any) {
    let n = 0;
    if (partecipant.Values) {
      let item = partecipant.Values.PaybleDeferredArray.find(
        (x) => x.PayYear == year
      );
      if (item) n = item.DeferredCash.value + item.DeferredEquity.value;
    }
    return n;
  }

  getMax(year: number) {
    let v = 0;
    if (this.plan) {
      let pby = this.plan.PartecipantsByYear.find((x) => x.Year === year);
      if (pby && pby.Partecipants.length > 0) {
        let p = pby.Partecipants[0];
        let pa = p.Values.PaybleDeferredArray.find((x) => x.PayYear === year);
        v = p.Values.Accrued.max;
      }
    }
    return v;
  }

  getBonusBankOpeningProgressMax(year: number) {
    return 12;
  }
  getBonusBankPastPayableProgressMax() {
    return 15;
  }
  getBonusBankDeferredProgressMax() {
    return 55;
  }
  getBonusBankRollingProgressMax(year: number) {
    return 30;
  }

  getBonusBankWidgetOpening() {
    return this.formatNumber(0);
  }
  getBonusBankWidgetPastPayable() {
    return this.formatNumber(0);
  }
  getBonusBankWidgetDeferred() {
    return this.formatNumber(0);
  }
  getBonusBankWidgetBonusBankBase() {
    return this.formatNumber(0);
  }
  getBonusBankWidgetBonusBankMutiplier() {
    return this.formatNumber(0);
  }
  getBonusBankWidgetBonusBank() {
    return this.formatNumber(0);
  }

  /*******************************************************************************
    ____   ____  _   _ _    _  _____   _____    __     __      ____  _      ______
   |  _ \ / __ \| \ | | |  | |/ ____| |  __ \ /\\ \   / //\   |  _ \| |    |  ____|
   | |_) | |  | |  \| | |  | | (___   | |__) /  \\ \_/ //  \  | |_) | |    | |__
   |  _ <| |  | | . ` | |  | |\___ \  |  ___/ /\ \\   // /\ \ |  _ <| |    |  __|
   | |_) | |__| | |\  | |__| |____) | | |  / ____ \| |/ ____ \| |_) | |____| |____
   |____/ \____/|_| \_|\____/|_____/  |_| /_/    \_\_/_/    \_\____/|______|______|
  *******************************************************************************/

  graphData: any;

  /** Init chart */
  async initChartJS() {
    // For more information about the chartjs, visit this link
    // https://www.chartjs.org/docs/latest/getting-started/usage.html

    // let data: any = [];
    // let labels: any = [];

    // let elencoDati = this.getBonusPayableGraphData();

    // for await (const record of elencoDati) {
    //   data.push(record);
    //   // labels.push(record.Name);
    // }

    this.graphData = {
      datasets: [
        {
          data: [
            this.getAmount('payable_cash'),
            this.getAmount('payable_equity')
          ],
          backgroundColor: ['#FFDD45', '#dedede']
        }
      ],

      labels: [
        this.coreService.getTranslation('ADMIN_LTI.PAYOUT.BODY.CASH'),
        this.coreService.getTranslation('ADMIN_LTI.PAYOUT.BODY.EQUITY')
      ]
    };

    if (this.myChart) {
      this.myChart.destroy();
    }

    console.log(this.graphData);

    this.myChart = new Chart(this.chart.nativeElement, {
      type: 'pie',
      data: this.graphData,
      options: {
        title: {
          display: false
        },
        plugins: {
          legend: {
            display: true,
            position: this.expandPayable ? 'bottom' : 'right'
          },
          interaction: {
            mode: 'point',
            intersect: true
          }
          // tooltip: {
          //   intersect: false,
          //   mode: 'nearest',
          //   xPadding: 5,
          //   yPadding: 5,
          //   // caretPadding: 100,
          //   callbacks: {
          //     label: (context) => {
          //       let d = context.dataset.data[context.dataIndex];
          //       let name = this.graphData.labels[context.dataIndex];
          //       if (!name) {
          //         name = this.coreService.getTranslation("Generic.NoCategory")
          //       }
          //       return name + ': \n' + this.formatNumber(d.x, 0) + '%, ' + this.formatNumber(d.y, 0) + '% [' + this.formatNumber(d.z, 0) + ']';
          //     }
          //   }
          // },
        },
        responsive: true,
        maintainAspectRatio: false
      }
    });
  }

  getBonusPayableGraphData() {
    return [this.getAmount('payable_cash'), this.getAmount('payable_equity')];
  }

  getBonusPayableGraphDataColors() {
    return [
      {
        backgroundColor: ['#FFDD45', '#FFFFFF']
      }
    ];
  }

  getBonusPayableGraphDataLabels() {
    return [
      this.coreService.getTranslation('ADMIN_LTI.PAYOUT.BODY.CASH'),
      this.coreService.getTranslation('ADMIN_LTI.PAYOUT.BODY.EQUITY')
    ];
  }

  getBonusPayableGraphOptions() {
    return {
      responsive: true,
      maintainAspectRation: false,
      legend: {
        display: true,
        position: this.expandPayable ? 'bottom' : 'right'
      }
    };
  }

  getBonusPayableWidgetAccrued(year: number) {
    return 50;
  }

  getBonusPayableWidgetAccruedProgressMax(year: number) {
    return 100;
  }
}
