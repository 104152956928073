import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Pp360TextInputComponent } from './pp360-text-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  TranslateModule,
  TranslateLoader,
  TranslateParser
} from '@ngx-translate/core';

@NgModule({
  declarations: [Pp360TextInputComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
  exports: [Pp360TextInputComponent]
})
export class Pp360TextInputModule {}
